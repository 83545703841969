/* eslint-disable import/named */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import axios from "axios";
// import { useNavigate } from 'react-router-dom';
import { KEY_GUEST, SERVICE } from "src/constants/keys";
import { clearServiceInBasket } from "../GeneBook/action";
import { 
  getData, getEntriesData, setUserInfo, setLabInfo, setSearch
} from "./action";
import {
  LOGIN, REGISTRATION, LIST_FUNCTIONAL, ENTRIES_DATA, DATA_OF_BOOK, USER_INFO,
  CONFIRM_EMAIL, GET_EMAIL_CONFIRM, CHECK_PERSON_KEY, CHECK_AUTH, RECOVERY_PASSWORD,
  RESET_PASSWORD, EXIT_FROM_PROFILE,  LAB_INFO, GUESTCREATE, QRLOGIN, SEARCH, ANONIMCREATE
} from "../../constants/urls";
import { messageForAlert } from "../../constants/constant";
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/extensions
import {  postElement } from "../../constants/functions";
// import { history } from "../history"

export async function asyncGetChoiceFunctional() {
  const response = await fetch(LIST_FUNCTIONAL)
  const json = await response.json();
  return json
}

export async function asyncGetForEntriesData(ExternalKey, dispatch) {
  axios.post(ENTRIES_DATA, ExternalKey).then((res) => {
    const resp = res.data;
    if (resp.IsSuccess) {
      dispatch(getEntriesData(resp.Data));
    } else {

      alert(messageForAlert);
    }
    return res.IsSuccess
  });
}


// ---------------------------------------------------------------------------------------------------------------------------



// ---------------------------------------------------------------------------------------------------------------------------
export async function asyncSearch(data, dispatch) {
  const response = await fetch(SEARCH, postElement(data));
  const answer = await response.json();  

  dispatch(setSearch(answer.result));
  // setAnswerFromServer(answer.alerts && answer.alerts[0].message || answer.alert.message)
 // setAnswerFromServer(answer)  {
//  "text": "Хир",
//  "libs": [
//    123,
//    25,
//    121
//  ],
//  "levels": null,
//  "permit": {
//    "authkey": "uvwEg09uYxNoQE6wcviiE7Di"
//  }
// }
 // await answer.Alert.level === "success" && navigate(`/confirm/${data.email}`)
}

export async function getDataFromServer(data, dispatch, setShowComponent) {
  axios.post(DATA_OF_BOOK, data).then((res) => {
    const resp = res.data;
    if (resp.IsSuccess) {
      dispatch(getData(resp.Data));
    } else {
      setShowComponent(false);
   
      alert(messageForAlert);
    }
  });
}


export async function asyncResponseAnonimCreate(data, dispatch, navigate) {

  axios.post(ANONIMCREATE, data).then((res) => {
    const answer = res.data;
    if (answer?.protocol?.email) {
      localStorage.setItem("AUTHKEY", answer.protocol.authKey);
      localStorage.setItem("anonim", true);
      localStorage.setItem("LinkHash", answer.protocol.linkHash);
      localStorage.setItem("login", answer.protocol.email);
      localStorage.setItem("key", answer.protocol.password);
      localStorage.setItem("account", answer.protocol.userType);
      // dispatch(setUserInfo({ "AUTHKEY": answer.authkey, "account": value }));
      // asyncUserInfo(dispatch, { email: data.email, key: INFO_BY_EMAIL }, answer.authkey, value)
      navigate("/")
    } 
//     authKey: "vUkkp7pS76uT4rp1jUTL5KGs"
// email: "alex.minin@list.ru"
// linkHash: null
// password: "ddc2d6"
// userType: 0
    // answer.AuthKey && 
    // return res.IsSuccess
  });

  // const response = await fetch(ANONIMCREATE, getElement({}));
  // const answer = await response.json();

  // if (answer?.Protocol.Email) {
  //   localStorage.setItem("AUTHKEY", answer.AuthKey);
  //   localStorage.setItem("anonim", true);
  //   localStorage.setItem("login", answer.Protocol.Email);
  //   localStorage.setItem("key", answer.Protocol.Password);

  //   // dispatch(setUserInfo({ "AUTHKEY": answer.authkey, "account": value }));
  //   // asyncUserInfo(dispatch, { email: data.email, key: INFO_BY_EMAIL }, answer.authkey, value)
  // }
  // const email = await answer.Protocol.Email
  // const password = await answer.Protocol.Password
  // const value = 0
  // asyncLogin({ key: KEY_DEVELOPER, service: SERVICE, email, password }, dispatch, null, null, value, navigate)

  // await answer.authkey && navigate && navigate("/")
  // setAnswerFromServer(answer.alerts && answer.alerts[0].message || answer.alert.message)
  // setAnswerFromServer(answer)

  // await answer.authkey && navigate(`/`)
}


export async function asyncResponseGuestCreate(navigate) {
  const response = await fetch(GUESTCREATE, postElement({key: KEY_GUEST, application: SERVICE}));
  const answer = await response.json();  

  if (answer?.authkey) {
    localStorage.setItem("AUTHKEY", answer.authKey);
    localStorage.setItem("account", "0");
    localStorage.setItem("email", "Гость");
    // dispatch(setUserInfo({ "AUTHKEY": answer.authkey, "account": value }));
    // asyncUserInfo(dispatch, { email: data.email, key: INFO_BY_EMAIL }, answer.authkey, value)
  }
 // await answer.authkey && navigate && navigate("/")
  // setAnswerFromServer(answer.alerts && answer.alerts[0].message || answer.alert.message)
  // setAnswerFromServer(answer)
  await answer.authkey && navigate(`/`)
}

export async function asyncRegistrationEmail(data, dispatch, setAnswerFromServer, navigate) {
  const response = await fetch(USER_INFO, postElement(data));
  const answer = await response.json();
  // setAnswerFromServer(answer.alerts && answer.alerts[0].message || answer.alert.message)
  // setAnswerFromServer(answer)
  setAnswerFromServer && setAnswerFromServer(answer)
  await answer.alert.level === "error" && navigate(`/register/${data.email}`)

}

export async function asyncRegistration(data, dispatch, setAnswerFromServer,navigate) {
  const response = await fetch(REGISTRATION, postElement(data));
  const answer = await response.json();  
  // setAnswerFromServer(answer.alerts && answer.alerts[0].message || answer.alert.message)
  // setAnswerFromServer(answer)
  await (answer.level === "success" || answer.level === "error") && navigate(`/confirm/${data.email}`)
}

export async function asyncUserInfo(dispatch, data, authkey, value) {
  const response = await fetch(USER_INFO, postElement(data));
  const answer = await response.json();

  dispatch(setUserInfo({
    "AUTHKEY": authkey,
    "account": value, "userName":
      answer.firstName && answer.firstName !== "firstName" ? `${answer.firstName} ${answer.lastName}` : `Пользователь`
  }));

  localStorage.setItem("userName", answer.firstName !== "firstName" && answer.firstName ? `${answer.firstName} ${answer.lastName}` : `Пользователь`);
  localStorage.setItem("userId", answer.userId);
}

export async function asyncLaboratoryInfo(dispatch, data) {
  const response = await fetch(LAB_INFO, postElement(data));

//   axios.post("http://192.168.14.49:5010/api/requestUpdatePatientData", { Key, AuthKey, Guid }).then((res) => {  // send Data
//   const resp = res;

//   if (resp.data) {
//     // dispatch(getEntriesData(resp.data));
//   } else {
//     alert(messageForAlert);
//   }
//   return res.IsSuccess
// });

  const answer = await response.json();

  dispatch(setLabInfo( answer ));
  // localStorage.setItem("Laboratory", answer.firstName);
}
// requestLaboratoryInformation
export async function asyncQrLogin(data, navigate) {
  // const navigate = useNavigate();
  const response = await fetch(QRLOGIN, postElement(data));
  const answer = await response.json();
  // setAnswerFromServer(answer)
  // answer && setGetAuthKey(answer)
  if (answer?.authKey) {

    // localStorage.setItem("Passport", answer.passport);
    localStorage.setItem("AUTHKEY", answer.authKey);
    localStorage.setItem("account", answer.userType);    
    // localStorage.setItem("email", answer.email);
    // dispatch(setUserInfo({ "AUTHKEY": answer.authkey, "account": value }));
    // asyncUserInfo(dispatch, { email: data.email, key: INFO_BY_EMAIL }, answer.authkey, value)
  }
  await answer.authKey ? navigate("/dashboard/app") : navigate("/login")
  // value === 0 &&
}

export async function asyncLogin(data, dispatch, setAnswerFromServer, setGetAuthKey, value, navigate) {
  // const navigate = useNavigate();
  const response = await fetch(LOGIN, postElement(data));
  const answer = await response.json();
  setAnswerFromServer && setAnswerFromServer(answer)
  answer && setGetAuthKey && setGetAuthKey(answer)
  if (answer?.authkey) {
    localStorage.setItem("AUTHKEY", answer.authKey);
    localStorage.setItem("account", "0");
    localStorage.setItem("email", data.email);
    // dispatch(setUserInfo({ "AUTHKEY": answer.authkey, "account": value }));
    // asyncUserInfo(dispatch, { email: data.Protocol.Email, key: INFO_BY_EMAIL }, answer.AuthKey, value)
  }
  if (answer?.authKey) {
    localStorage.setItem("AUTHKEY", answer.authKey);
    localStorage.setItem("account", answer.userType);
    localStorage.setItem("email", data.email);
    // dispatch(setUserInfo({ "AUTHKEY": answer.authkey, "account": value }));
    // asyncUserInfo(dispatch, { email: data.Protocol.Email, key: INFO_BY_EMAIL }, answer.AuthKey, value)
  }
  await (answer.authkey || answer.authKey) && navigate("/")
// value === 0 &&
}

export async function asyncSendLab(data, dispatch, setAnswerFromServer, navigate) {
  navigate("/dashboard/status")
}

export async function asyncConfirmemail(data, dispatch, setAnswerFromServer, navigate) {
  const response = await fetch(CONFIRM_EMAIL, postElement(data));
  const answer = await response.json();
  setAnswerFromServer && setAnswerFromServer(answer)
  await answer.Alert.Level === "success" && navigate("/login")
}

export async function asyncGetEmailConfirm(data, dispatch, setAnswerFromServer, navigate) {
  const response = await fetch(GET_EMAIL_CONFIRM, postElement(data));
  const answer = await response.json();
  setAnswerFromServer && setAnswerFromServer(answer)
  await answer.Alert.Level === "success" && navigate(`/confirm/${data.email}`)
}

export async function asyncCheckPersonKey(data, dispatch, setAnswerFromServerAuthKey, navigate) {
  // const navigate = useNavigate();
  const response = await fetch(CHECK_PERSON_KEY, postElement({ key: data.personKey, application: "242" }));
  const answer = await response.json();
  await answer.level === "success" && localStorage.setItem("PersonalKey", data.personKey)
  // await answer.level === "success" && history.push("/entriesData")
  await answer.level === "success" && navigate("/")
  await answer.level !== "success" && setAnswerFromServerAuthKey(answer)
}

export async function asyncCheckAuth() {
  const response = await fetch(CHECK_AUTH + localStorage.getItem("AUTHKEY"))
  const json = await response.json();
  return json
}

export async function asyncGetRecovery(data, dispatch, setAnswerFromServer) {
  const response = await fetch(RECOVERY_PASSWORD, postElement(data));
  const answer = await response.json();
  setAnswerFromServer(answer)
  // await answer[0].level === "success" && navigate("/newpass")
}

export async function asyncGetResetPassword(data, dispatch, setAnswerFromServer, navigate) {

  const response = await fetch(RESET_PASSWORD, postElement(data));
  const answer = await response.json();
  setAnswerFromServer(answer)
  await answer.alert.level === "success" && navigate("/login")
}

// requestRegistrationAnonymous

export async function asyncExit( dispatch, navigate ) {
  await fetch(EXIT_FROM_PROFILE + localStorage.getItem("AUTHKEY"))

  dispatch(setUserInfo({ "AUTHKEY": "", "account": "", "userName": `` }));
  dispatch( clearServiceInBasket() )
  
  localStorage.clear()
  
  // localStorage.removeItem("AUTHKEY")
  // localStorage.removeItem("PersonalKey")
  // localStorage.removeItem("email")
  // localStorage.removeItem("userId")
  // localStorage.removeItem("account")
  // localStorage.removeItem("vip");
  // localStorage.removeItem("status");
  // localStorage.removeItem("anonim");
  // localStorage.removeItem("Passport");
  

  // localStorage.removeItem("firstName");
  // localStorage.removeItem("middleName");
  // localStorage.removeItem("lastName");
  // localStorage.removeItem("age");
  // localStorage.removeItem("sex");

  await navigate("/login")
  // window.location.assign("/login")
}

