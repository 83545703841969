/* eslint-disable import/no-unresolved */
import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
// import { Icon } from '@iconify/react';
// import plusFill from '@iconify/icons-eva/plus-fill';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, Container, Typography, Stack } from '@material-ui/core';
import { NavLink, useNavigate, Link as RouterLink } from 'react-router-dom';
// components
import { asyncResponseGuestCreate, asyncResponseAnonimCreate } from 'src/redux/Logistic/requestAction';
import { Box, styled } from '@material-ui/system';
import { MotionContainer } from 'src/components/animate';
import { isMobile } from 'src/_validations';
import Origin from 'src/components/_dashboard/app/Origin';
import AppFirst from '../components/_dashboard/app/AppFirst';
// import AppSecond from '../components/_dashboard/app/AppSecond';
import Page from '../components/Page';
import { AppNewUsers, AppBugReports, AppItemOrders,  AppWeeklySales } from '../components/_dashboard/app';  // AppTasks, AppNewsUpdate, AppOrderTimeline, AppCurrentVisits, AppWebsiteVisits, AppTrafficBySite, AppCurrentSubject, AppConversionRates, 
import { setChapterNow, changeFilterRisk } from "../redux/GeneBook/action";
// import Maps from 'src/layouts/dashboard/Maps';

const useStyles = makeStyles(() => ({
  parentGrid: { padding: "1em", display: "flex", flexDirection: "column", justifyContent: "center", backgroundColor: "#f9f9f9", backgroundImage: "url(../static/menu/backPhonePassport.svg)", backgroundSize: "contain", borderRadius: "20px", backgroundRepeat: "no-repeat", backgroundPositionY: "bottom", backgroundPositionX: "right", minHeight: "50px", maxHeight: "500px" },
  title: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', padding: "5px 8px", height: "30px", left: "20px", top: "20px", fontFamily: "OpenSans", fontStyle: "normal", fontWeight: "normal", fontSize: "calc(1em + 0.4vw)", lineHeight: "22px", color: "#262624" },
  subTitle: { padding: "8px", marginTop: "5px", left: "20px", top: "50px", fontFamily: "OpenSans", fontStyle: "normal", fontWeight: "normal", fontSize: "calc(0.8em + 0.4vw)", lineHeight: "22px", color: "#262624" },
  box: { maxWidth: 480, margin: 'auto', textAlign: 'center' },
  gridContent: { padding: "2.5em 0px" },
})); 

const RootStyle = styled(Page)(({ theme }) => ({
  // display: 'flex',
  // minHeight: '100%',
  // alignItems: 'center',
  // paddingTop: theme.spacing(15),
  // paddingBottom: theme.spacing(10)
}));

export default function DashboardApp() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const url = window.location;
  // const { nowChapter } = useSelector(state => state.reducerGeneBook)

  const authkey = localStorage.getItem("AUTHKEY")
  const account = localStorage.getItem("account")
  const vip = localStorage.getItem("vip")
  // useEffect(() => {
  //   // navigate("")
  // }, [])

  useEffect(() => {
    if (!authkey && !localStorage.getItem("AUTHKEY")) {
      asyncResponseAnonimCreate(dispatch,navigate)
      // asyncResponseGuestCreate(navigate)
    };
  }, [authkey, navigate])

  const onHandlerChapter = ( captionChapter ) => {
    dispatch( setChapterNow( captionChapter ) )
    dispatch( changeFilterRisk("all") )
  } 

  const arrBlockChapters = [
    { id: 1, chapter: "health", component: <AppWeeklySales />, path: "/dashboard/app/health" },
    { id: 2, chapter: "medications", component: <AppNewUsers />, path: "/dashboard/app/medications" },
    { id: 3, chapter: "nutrition", component: <AppItemOrders />, path: "/dashboard/app/nutrition" },
    { id: 4, chapter: "sport", component: <AppBugReports />, path: "/dashboard/app/sport" },
    { id: 5, chapter: "beauty", component: <AppFirst />, path: "/dashboard/app/beauty" },
    // { id: 6, chapter: "genetics", component: <Origin /> , path: "/dashboard/app/genetics" },
  ]

  return (
    <RootStyle title="Dashboard | ImGenius" style={vip  && localStorage.getItem("Passport") === "true" || account==="1" ? {} :{
      display: 'flex',
      minHeight: '100%',
      alignItems: 'center'
    }} >
      <Container maxWidth="xl" >
        { account==="1" 
        ? <>
            <Grid  className={ classes.parentGrid } >
                <Typography  className={ classes.title } > 
                  Ваш генетический паспорт
                </Typography>
                <Typography  className={ classes.subTitle } > 
                  Узнайте больше о себе
                </Typography>
            </Grid>
            <Grid className={ classes.gridContent }  container justifyContent="center" spacing={3}>
              {/* <Grid onClick={ () => navigate('/dashboard/health', { replace: true }) } item xs={12} sm={6} md={4}> history.push("/entriesData") */}
              { arrBlockChapters.map( block =>  
                <Grid key={ block.id } onClick={ () => onHandlerChapter( block.chapter ) } style={{ textDecoration: "none" }} component={ NavLink } to={ block.path } item xs={12} sm={6} md={4}> 
                  { block.component }
                </Grid>
              )}
            </Grid>
          </> 
        :
          <MotionContainer initial="initial" open>
            <Box  className={ classes.box } >
            <Typography variant="h4"> Нет данных </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Для получения Генетического заключения сдайте биоматериалы в лабораторию
            </Typography>
            <Button variant="contained" style={{ margin: "1em", textTransform: "none" }}  to="/dashboard/laboratories/map" component={RouterLink} >
              Лаборатории
            </Button>
            </Box>
          </MotionContainer>
        }
      </Container>
    </RootStyle>
  );
}