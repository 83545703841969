/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { Button, Drawer, IconButton, Skeleton, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap, InfoWindow,
  Marker,
  Circle
} from "react-google-maps";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// import { Router } from 'react-router';
import SearchBox from 'react-google-maps/lib/components/places/SearchBox';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-ic/round-add-location';
import menu4Fill from '@iconify/icons-ic/outline-add-location';
import closeFill from '@iconify/icons-eva/close-fill';
import { isMobile } from 'src/_validations';
import USERLIST from '../../_mocks_/copy';
import Modals from './Modals';


// const modal = (marker) => 

const defaultMapOptions = {
  // fullscreenControl: false,
  // mapTypeControl: false
  mapTypeControl: false,
  streetViewControl: false,

};

const CustomSkinMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap style={{ maxWidth: "100%" }}
      defaultZoom={13}
      disableDefaultUI // disable the default controls
       // add back streetView control
      // fullscreenControl={false}
      defaultOptions={defaultMapOptions}

      zoomControl
      yesIWantToUseGoogleMapApiInternals
      defaultCenter={props.coords}
      center={props.coords}
      onBoundsChanged={props.onBoundsChanged}
      onLoad={map => {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
      }}
    >

      <MarkerClusterer
        onClick={props.onMarkerClustererClick}

        averageCenter
        enableRetinaIcons

        // icon={{
        //   url: '/static/map/allOnClinic.svg',
        //   anchor: new google.maps.Point(5, 58),
        // }}
        minimumClusterSize={2}
        styles={[
          {
            url: '/static/map/allOnClinic.svg',

            height: 79,
            // lineHeight: 30,
            width: 55,
          },
      
        ]}
        gridSize={80}
      >

        {USERLIST.map(marker => marker.Lat && marker.Lng ?
          <Marker
            key={marker.Lat + marker.Lng}

            icon={{
              url: marker.License ? '/static/map/onCininc.svg' : '/static/map/offClinic.svg',
              anchor: new google.maps.Point(5, 58),
            }}
            position={{ lat: marker.Lat, lng: marker.Lng }}
            onClick={() => props.onMarkerClick(marker)}
           />


          : null)}
      </MarkerClusterer>
      <Marker
        clickable={false}      
        position={props.coords} />
    </GoogleMap>
  ))
);

export default function Maps(props) {
  const [marker, setMarker] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [showInfo, setShowInfo] = useState(null)

  function onMarkerClustererClick(markerClusterer) {
    // const clickedMarkers = markerClusterer.getMarkers()

  };

  function handleMarker(targetMarker) {
    setOpen(true)
    setMarker(targetMarker)
  }

  function handleMarkerClick(targetMarker) {

    setOpen(true)
    setMarker(targetMarker)
    setShowInfo({ lat: targetMarker.Lat, lng: targetMarker.Lng })
  
  }

  function handleMarkerClose(targetMarker) {

    setOpen(false)
    setMarker(null)
    setShowInfo(null)
   
  }

  // navigator.geolocation.getCurrentPosition(success, error, options);

  return (<div style={{ height: `100%`, maxWidth: "100%"  }}>
    <Button variant="contained"
      color="primary"
      component={RouterLink} to="/dashboard/laboratories"
      // controlPosition={google.maps.ControlPosition.TOP_LEFT}
      style={{
        position: "absolute",
        zIndex: 1,
        margin: "1em"
      }}>
      Список
    </Button>
    <CustomSkinMap
      isMarkerShown
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDy4rc8FJZLpAlm-1OiRDuHF0_D3Dr993s&&v=weekly&channel=2"
      loadingElement={<div style={{ height: `100%`, maxWidth: "100%"  }} />}
      containerElement={<div style={{ height: `100%`, maxWidth: "100%" }} />}
      mapElement={<div style={{ height: `100%`, maxWidth: "100%"  }} />}
      coords={props.openSearch}
      onClick={props.onClick}
      USERLIST={USERLIST}
      setMarker={handleMarker}
      onMarkerClustererClick={onMarkerClustererClick}
      onMarkerClick={handleMarkerClick}
      onMarkerClose={handleMarkerClose}
      showInfo={showInfo}
    />
    <Skeleton variant="rect" style={{
      height: "100%",
      width: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: -1
    }} />
    {!isMobile ? <Modals marker={marker} open={open} setOpen={setOpen} /> :
      <Drawer variant="persistent" anchor="bottom" marker={marker} open={open}  >
        <div style={{
          padding: "2em", display: "flex",
          flexDirection: "column"
        }}>
          {/* <Typography style={{ fontSize: 14 }} color="textSecondary" gutterBottom>
            Word of the Day
          </Typography> */}

          <Typography variant="h5" component="h2">
            {marker?.Name}      
          </Typography>
          <IconButton
                    aria-label="close"
                    onClick={() => setOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Icon icon={closeFill} />
                </IconButton>
          <Typography style={{ marginBottom: 12 }} color="textSecondary">
            {marker?.TimeTable}
          </Typography>
          <a href={marker?.Link}> открыть сайт </a>
          {/* <Typography style={{ marginBottom: 12 }} color="textSecondary">
            {marker?.Link}
          </Typography> */}
          <Typography variant="body2" component="p">
            {marker?.Address}
          </Typography>

          <Button variant="contained" style={{ marginTop: "2em" }} onClick={() => localStorage.setItem("status", new Date())}
            to="/dashboard/status" component={RouterLink}
          >
            Записаться
          </Button>
        </div>
      </Drawer>
    }
  </div >
  );
}

