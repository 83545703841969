/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector } from "react-redux";
import { isMobile, ucFirst } from "../../../../_validations";
import Loader from '../Loader';
import "../../../../index.css";
      
const BlockContent = () => {
    const descriptionOfStore = JSON.parse( localStorage.getItem("ObjectForDescription") )
    const urlQuery = new URLSearchParams(document.location.search.substring(1));
    const id = urlQuery.get("id")

    const { listDescription, loader } = useSelector( state => state.reducerGeneBook )

    const defineTheRisk = ( value ) =>  
      value <= 0.17 
    ? { color: "#00ab55", typeIcon: "low", nameType: "низкий риск", type: 1 }   // #1890FF
    : value <= 0.5
        ? { color: "#FFC107", typeIcon: "middle", nameType: "средний риск", type: 2 }
        : { color: "#FF4842", typeIcon: "high", nameType: "высокий риск", type: 3 }
    
    const description = listDescription.length < 1 ? descriptionOfStore : listDescription?.find( el => Number(el.MasterId) === Number(id))
    // const description = listDescription?.find( el => Number(el.MasterId) === Number(id))
    
    return (
        <> { loader ? <Loader /> : 
                <div>            
                    { description?.Title 
                        ?  <h2 style={{ display: !isMobile && "inline", marginBottom: "20px", borderRadius: "20px", color: defineTheRisk(description?.Percent).color, padding: isMobile ? "3px 15px" : "5px 15px", margin: isMobile && "3px 10px" }}> 
                            { ucFirst( description?.Title ) }  -  { Math.trunc( description?.Percent * 100) } % 
                            {/* ( {defineTheRisk(description?.Percent).nameType} ) */}
                        </h2> 
                        : 
                        <h3 style={{ display: "flex", justifyContent: "center", color: "#c4cdd5" }}> нет данных </h3> 
                    }

                    { description?.Items?.length < 1 
                        ? <span style={{ display: "flex", justifyContent: "center" }}> нет данных </span> 
                        :  description?.Items?.map( ( el, idx ) =>  
                        el?.Text.length > 3 
                            ?   <div key={idx} style={{ padding: "10px 23px" }}> 
                            {/* ?   <div key={idx} style={{ padding: idx === 0 ? "30px 23px" : "10px 23px" }}> */}
                                    { el?.Field !== "Описание для пациентов" && <h2 style={{ color: "#c4cdd5" }}> { el?.Field } </h2> }
                                    <div dangerouslySetInnerHTML={{__html: el?.Text?.split("\n")?.find( r => r === "<tbody>")?.length  ?  el?.Text?.split("<tbody>")[1]?.split("</tbody>")[0] : el?.Text }} /> 
                                </div> 
                            : "" 
                            // : "нет данных" 
                        )
                    }
                </div>
            }
        </>
    );
};

export default BlockContent;






