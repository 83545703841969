/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import { Container, Grid, Stack, Typography, Card, CardContent, CardActionArea, CardHeader, Divider, } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

// import searchFill from '@iconify/icons-eva/search-fill';
// material
// import { Clear } from '@material-ui/icons';
// import { content } from './content';
// import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../components/_dashboard/blog';

import { Icon } from '@iconify/react';
import triangle from '@iconify/icons-eva/alert-triangle-fill';
import circle from '@iconify/icons-eva/alert-circle-fill';
import info from '@iconify/icons-eva/info-fill';
import forward from '@iconify/icons-eva/arrow-circle-right-fill';

// import { isMobile } from 'src/_validations';
import {  BlogPostsSort, BlogPostsSearch } from '../blog';

// import ButtonImage from "./ButtonImage";

import { requestListForChapter, requestDescription, asyncSearch } from "../../../redux/GeneBook/requestAction";
import { isMobile, ucFirst } from "../../../_validations";
import Loader from './Loader';
import ProgressLine from './ProgressLine';

const useStyles = makeStyles(() => ({
  cardContent: {  borderRadius: '16px' },  // padding: '12px',  // backgroundColor: '#ebf8f2', // boxShadow: '0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)',  
  blockOfCardHeader: { display: 'flex',  flexFlow: 'row wrap-reverse',  justifyContent: 'space-between' },
  blockOfDescription: { paddingTop: '12px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
  card: { textDecoration: 'none' },
  cardActionArea: { textDecoration: 'none', borderRadius: '16px'},  // , background: "#ebf8f2" 
  cardAction: { textDecoration: 'none', borderRadius: '16px', padding: "12px" },
  cardHeader: { padding: 0, fontStyle: 'italic' },
  iconArrowRight: { position: "absolute", bottom: "8px", right: "10px", verticalAlign: "middle" },
  textMoreDetails: { position: "absolute", bottom: "7px", right: "19px" }
}));

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }

const ListBlock = () => {

  const captionChapter = window.location.href.split("/").reverse()[0]

  const classes = useStyles();
  const dispatch = useDispatch()
  const { chapters, filterRisk, loader } = useSelector(state => state.reducerGeneBook)

  // asyncSearch()

  useEffect(() => {
                    // chapters.find( el => el.name === captionChapter )
    // if (  chapters.find( el => el.name === captionChapter).listContent.length < 1 ) {
      requestListForChapter( dispatch, captionChapter )
    // }
  }, [captionChapter, dispatch] )

  // const [value, setValue] = React.useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  // const [choiceSort, setChoiceSort] = useState('middle');
  const [searchWord, setSearchWord] = useState('');
  // const [doneArr, setDoneArr] = useState( ...content.filter( e => e.chapter === nowChapter ) )
  const [doneArr] = useState( ...chapters.filter( e => e.name === captionChapter ) )

  // useEffect(() => {
  //   setDoneArr( ...chapters.filter( e => e.name === captionChapter ) )
  // }, [chapters])

  const defineTheRisk = ( value ) =>  
  // eslint-disable-next-line no-nested-ternary
      value <= 0.17 
    ? { color: "#00ab55", typeIcon: "low", nameType: "Ваш риск", type: 1 }   // #1890FF
    : value <= 0.5
        ? { color: "#FFC107", typeIcon: "middle", nameType: "Ваш риск", type: 2 }
        : { color: "#FF4842", typeIcon: "high", nameType: "Ваш риск", type: 3 }

    // const fff = doneArr
    // fff.listContent.push({ GenId: [], GenName: "", MutationName: "", MutationId: 78924621, GenoGaploTypeId: 457547, GenoGaploTypeName: "", Id: 6746234, Name: "тестовая", Percent: 0.8 })

  const SORT_OPTIONS = [
    { value: 'all', label: 'Все', contentLength: doneArr.length },
    { value: 'low', label: 'Низкий риск', contentLength: doneArr.listContent.filter( (el) => defineTheRisk( Number( el.Percent) ).typeIcon === "low" ).length },
    { value: 'middle', label: 'Средний риск', contentLength: doneArr.listContent.filter( (el) => defineTheRisk( Number( el.Percent) ).typeIcon === "middle" ).length },
    { value: 'high', label: 'Высокий риск', contentLength: doneArr.listContent.filter( (el) => defineTheRisk( Number( el.Percent) ).typeIcon === "high" ).length },
  ];

  // useEffect(() => {
  //   // filterArr(doneArr)

  //   if ( doneArr.blocks.filter( e => e.title === searchWord ).length > 0 ) {
  //   const obj = doneArr
  //   obj.blocks = doneArr.blocks.filter( e => e.title === searchWord )

  //     setDoneArr( obj )
  //   } else {
  //     setDoneArr( ...content.filter( e => e.chapter === captionChapter )  )
  //   }


  // }, [searchWord])


  // if ( doneArr.blocks.filter( e => e.title === searchWord ).length > 0 ) {

  //     const obj = doneArr
  //   obj.blocks = doneArr.blocks.filter( e => e.title === searchWord )

  //     setDoneArr( obj )
  // }

  const filterArr = () => {
    // if ( searchWord !== "" && doneArr.filter( e => e.title === searchWord ).length > 0 ) {

    //   return doneArr.filter( e => e.title === searchWord )
    //   // setDoneArr( doneArr.filter( e => e.title === searchWord ) )
    // }


    if (filterRisk === 'all') {
      return doneArr.listContent.sort((a, b) => b.numberType - a.numberType);
      // setDoneArr(doneArr.sort( (a,b) => b.numberType - a.numberType ))
    } 
      return doneArr.listContent.filter((el) => defineTheRisk( Number( el.Percent) ).typeIcon === filterRisk );
      // setDoneArr(doneArr.filter( el => el.type === choiceSort ))
    };
  
  return (
    <> {loader ? <Loader />
        :
    <Container maxWidth="xl" style={{ height: `100%` }}>
      <span  maxWidth="xl" style={{ position: "fixed", zIndex: 12, padding: "3px 0px", backgroundColor: "white", width: "100%" }}>
        {/* <Stack sx={{ marginBottom: "12px", marginTop: "5px" }} direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography  variant="h4"> { ucFirst( doneArr.caption ) }  </Typography>
        </Stack> */}
        <Stack sx={{  marginBottom: "10px" , paddingRight: isMobile && 4, marginTop: "1em" }} mb={5} direction="row" >
          <BlogPostsSearch searchWord={searchWord} setSearchWord={setSearchWord} posts={[]} />
          <BlogPostsSort  options={SORT_OPTIONS}   choiceSort={filterRisk}   
            //  setChoiceSort={setChoiceSort} 
            />
        </Stack>
      </span>

      <Grid style={{ paddingTop: "110px", marginBottom: '1em' }} container justifyContent="center" spacing={3}>
        { filterArr().length < 1 
          ? <h3><i> нет данных </i></h3> 
          : filterArr().filter((e) => e.Name.toUpperCase().indexOf(searchWord.toUpperCase()) >= 0 ).length < 1 
          ? <h3 style={{ color: "#dce0e4" }}><i> не найдено </i></h3> 
          : filterArr().filter((e) => e.Name.toUpperCase().indexOf(searchWord.toUpperCase()) >= 0 ).map((item) => (
            // <Grid style={{ padding: " 1.5px 8px", margin: "1.5px 8px" }}  key={item.id} item xs={12}>
            <Grid style={{ padding: "0.5px 0px 0px 19px",  margin: "3px 0px 2px 3px" }} key={item.id} item xs={12}>
              <Card onClick={ () => requestDescription( dispatch, item, window.location.href.split("/").reverse()[0] ) }  className={ classes.card }  component={NavLink}  to={`/dashboard/app/${captionChapter}/content?id=${item.Id}`} >
                <CardActionArea   className={ classes.cardActionArea }  >
                  <CardContent style={{ padding: "9px" }} className={ classes.cardContent } >
                    <CardHeader style={{ margin: 0, padding: 0 }}
                      // avatar={
                      //   <Icon color={ defineTheRisk( Number( item.Percent) ).color }  width={40} height={40}
                      //     icon={ defineTheRisk( Number( item.Percent) ).typeIcon === "low"? info: defineTheRisk( Number( item.Percent) ).typeIcon === "middle"? triangle: circle }
                      //   />
                      // }
                      title={ ucFirst( item.Name )}
                      subheader={ 
                        <>
                          <ProgressLine percent={ item.Percent } />
                          <Typography style={{ color: defineTheRisk( Number( item.Percent) ).color }}> { defineTheRisk( Number( item.Percent) ).nameType}  { Math.trunc( item.Percent * 100)  } %  </Typography>
                        </>
                     }

                      action={
                        !isMobile ? <>
                            <Typography edge="end"  component="span"  variant="body2"  className={classes.textMoreDetails}  color="textPrimary" >
                              Узнать подробнее 
                              {/* <Icon style={{ verticalAlign: "middle" }} icon={forward} width={20} height={20} /> */}
                              <ChevronRight style={{ verticalAlign: "middle" }} icon={forward} width={20} height={20} />
                            </Typography> 
                            </>
                          : 
                          // <Icon className={ classes.iconArrowRight } icon={forward} width={20} height={20} />
                          <ChevronRight className={ classes.iconArrowRight } icon={forward} width={20} height={20} />
                      }
                    />
                  </CardContent>
                </CardActionArea>
              </Card>
              <Divider/>
            </Grid>
        ))}
      </Grid>
    </Container>}
  </>
  );
};

export default ListBlock;