/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import { makeStyles } from '@material-ui/styles';
import { styled } from '@material-ui/core/styles';
import { Card, Stack, Link, Container, Typography, Button, Drawer } from '@material-ui/core';
// layouts
import { useEffect, useState } from 'react';
import { asyncLogin, asyncResponseAnonimCreate, asyncResponseGuestCreate } from 'src/redux/Logistic/requestAction';
import { useDispatch } from 'react-redux';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
// import AuthLayout from '../layouts/AuthLayout';
// components
import TemporaryDrawer from 'src/components/_dashboard/BlockContent/BlockDrawer';
import { isMobile } from 'src/_validations';
import Page from '../components/Page';
// import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
// import AuthSocial from '../components/authentication/AuthSocial';
import { infoCompany } from "../constants/constant";
import PinCode from './PinCode';
import ModalMailSend from './ForService/ModalMailSend';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: { display: 'flex',  flexDirection: "column",  color: "white",  width: "100%" }
}));

const useStyles = makeStyles((theme) => ({
  root: { height: 380,  transform: 'translateZ(0px)', flexGrow: 1 },
  speedDial: { position: 'absolute', bottom: theme.spacing(2), right: theme.spacing(2) },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  // margin: 'auto',
  display: 'flex', flexDirection: 'column', justifyContent: isMobile ? 'center' : 'end', 
  padding: theme.spacing(12, 0, 5), // minHeight: isMobile && '100vh',
}));

export default function Login() {
  useEffect(() => {
    localStorage.removeItem("AUTHKEY")
    localStorage.removeItem("PersonalKey")
    localStorage.removeItem("email")
    localStorage.removeItem("userName")
    localStorage.removeItem("userId")
    localStorage.removeItem("account")
  }, [])
  const classes = useStyles();
  const { pin } = useParams();
  const [getAuthKey, setGetAuthKey] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [answerFromServer, setAnswerFromServer] = useState("");
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const openDemo = (pin) => {
    if (!localStorage.getItem("AUTHKEY")) {
      asyncResponseAnonimCreate({ Billing: { Pin: pin } }, dispatch, navigate)
    }
  };
  const isPathName = window.location.pathname;
  // const tesxtt = "123.socmedica.12313.we"
  // console.log(tesxtt.indexOf('socmedica'));

  return (
    <RootStyle title="Login | ImGenius">
      <Button // startIcon={<ArrowBackIosIcon />}  
        size="small" variant="contained" style={{
          width: "max-content", position: "absolute",
          top: "1em",
          right: "1em"        }}
        onClick={() => 
          asyncLogin({ email: "imgenius1@yopmail.com", password: "111111" }, dispatch,setAnswerFromServer, setGetAuthKey, value, navigate )}>
        Демо режим
      </Button>
      <div style={{
        background: 'url(/static/icons/logo.png) 5% 5% no-repeat fixed, linear-gradient(321.69deg, #54827E 0%, #A4B7B7 100%)',
        backgroundSize: isMobile ? "35%, auto" : "20%, auto", /* Меняем масштаб */
        minHeight: "65vh",
        }}>
        <Container sx={{ minHeight: "inherit", display: "grid" }}>
          {/* <div> */}
          <ContentStyle >
          <Stack sx={{ mb: 3 }}>
            <Typography style={{ color: "white" }} variant="h3" gutterBottom>
                Личный кабинет
            </Typography>

          </Stack>
          <LoginForm onClick={(pin) => openDemo(pin)}/>
        </ContentStyle>
      </Container>
      </div>

      <Drawer style={{ width: isMobile ? '100vw' : '640px' }} anchor="right" open={pin} onClose={() => navigate("/login")}>
        <PinCode />
      </Drawer>

      <ModalMailSend />
      <Container >
      {infoCompany}
      </Container>
    </RootStyle>
  );
}