
export const SET_CHAPTER = "SET_CHAPTER";

// export const SET_LIST_INVITRO = "SET_LIST_INVITRO";

export const SET_LIST_SERVIСES_IN_BASKET = "SET_LIST_SERVIСES_IN_BASKET";

export const SET_LIST_CONTENT_FOR_CHAPTERS = "SET_LIST_CONTENT_FOR_CHAPTERS";

export const SET_LIST_DESCRIPTION = "SET_LIST_DESCRIPTION";

export const CLEAR_SERVICE_IN_BASKET = "CLEAR_SERVICE_IN_BASKET";

export const CHANGE_FILTER_RISK = "CHANGE_FILTER_RISK";

export const SET_LIST_SERVICE = "SET_LIST_SERVICE";

export const SET_LIST_STATUS_ORDERS = "SET_LIST_STATUS_ORDERS";

export const ACTIVATE_LOADER = "ACTIVATE_LOADER";

export const SET_LIST_CONTENT_FOR_CHAPTERS_DEMO = "SET_LIST_CONTENT_FOR_CHAPTERS_DEMO";

export const SET_LIST_DESCRIPTION_DEMO = "SET_LIST_DESCRIPTION_DEMO";

export const REMOVE_SERVICES_FROM_BASKET = "REMOVE_SERVICES_FROM_BASKET";