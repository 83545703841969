/* eslint-disable import/no-unresolved */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { MenuBook, Autorenew } from '@material-ui/icons';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Divider } from '@material-ui/core';
import { isMobile } from 'src/_validations';

export default function Modals({ block, openModal, setOpenModal, listHistory }) {
  const [open, setOpen] = React.useState(openModal);
  const scroll = 'paper';

  // const handleClickOpen = (scrollType) => () => {
  //   setOpen(true);
  //   setScroll(scrollType);
  // };

  const handleClose = () => {
    setOpen(false);
    setOpenModal(false)
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div >
      <Dialog  maxWidth="lg"  fullScreen={ !!isMobile }  open={open}  onClose={handleClose}  scroll={scroll}  aria-labelledby="scroll-dialog-title"  aria-describedby="scroll-dialog-description" >
        <DialogTitle style={{ backgroundColor: "#f4f6f8" }} id="scroll-dialog-title">
           Паспорт  - <i style={{ fontWeight: 500 }}> { block.caption } </i>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'} >
          <DialogContentText id="scroll-dialog-description"  ref={descriptionElementRef} tabIndex={-1} >  {/* style={{ padding: "2em" }} */}
            {/* { type === "service" */}
              {/* ?  (serviceInBasket.length > 0 ? creationOfStructureForBasket() : <Typography style={{ minWidth: !isMobile && "600px" }} > Вы ничего не выбрали </Typography>) */}
              {/* : content.list.map( el => <Typography> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {el} </Typography> )   */}
            {/* } */}
            { block?.organization?.map( item => 
              <>
                  <Typography style={{ minWidth: !isMobile && "600px", display: "flex",  flexDirection: isMobile && "column", justifyContent: isMobile ? "flex-end" : "space-between", margin: "5px 5px" }} >
                      <Typography style={{paddingTop: "5px"}} >
                          {item}  ( обнавлено 1.10.2021 )
                      </Typography> 
                      <Typography>
                          <Button style={{ marginRight: "7px" }} component={ NavLink } to="/dashboard/service" size="small"  variant="outlined" color="primary" > 
                            {!isMobile && <span style={{ marginRight: "7px" }} > Обновить </span> }
                            <Autorenew fontSize="small"  /> 
                          </Button>
                          <Button size="small" component={ NavLink } to="/dashboard/app"  variant="outlined" color="primary" >
                            {!isMobile && <span> смотреть </span> } &nbsp;
                            <MenuBook />
                          </Button>
                      </Typography>  
                  </Typography>  
                  <Divider /> 
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "space-around", background: "#f4f6f8" }} >
        {/* { type === "service"  &&  <Button style={{ borderRadius: "20px" }} variant="contained" disabled={ serviceInBasket.length < 1} >  </Button> }  */}
          <Button  variant="outlined" color="error" onClick={handleClose}> Закрыть </Button>
          {/* <Button onClick={handleClose}>Subscribe</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}