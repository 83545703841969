/* eslint-disable no-case-declarations */
import { content } from "../../components/_dashboard/BlockContent/content";
// import { listInvitro } from "../../constants/constant";
import { SET_CHAPTER,  SET_LIST_SERVIСES_IN_BASKET, SET_LIST_CONTENT_FOR_CHAPTERS, SET_LIST_CONTENT_FOR_CHAPTERS_DEMO, SET_LIST_DESCRIPTION, SET_LIST_DESCRIPTION_DEMO, CLEAR_SERVICE_IN_BASKET, CHANGE_FILTER_RISK, SET_LIST_STATUS_ORDERS, SET_LIST_SERVICE, ACTIVATE_LOADER, REMOVE_SERVICES_FROM_BASKET
  // SET_LIST_INVITRO,
} from "./actionType";


// { id: 111, title: "Здоровье",  chapter: "health",      blocks: [ health1, health2, health3 ] },
// { id: 222, title: "Лекарства", chapter: "medications", blocks: listData( 1, [ 1, 1, 2 ], [ "middle", "middle", "high" ], [ "Трамадол", "Метотрексат", "Варфарин" ], "medications" ) },
// { id: 333, title: "Питание",   chapter: "nutrition",   blocks: listData( 2, [ 2, 1, 2 ], [ "high", "middle", "high" ], [ "Мороженое", "Сникерс", "Банан" ], "nutrition" ) },
// { id: 444, title: "Спорт",     chapter: "sport",       blocks: listData( 3, [ 1, 1, 1 ], [ "middle", "middle", "middle" ], [ "Бег", "Отжимания", "Брусья" ], "sport" ) },
// { id: 555, title: "Красота",   chapter: "beauty",      blocks: listData( 4, [ 2, 1, 2 ], [ "high", "middle", "high" ], [ "Лицо", "Волосы", "Ногти" ], "beauty" ) },
// { id: 666, title: "Генетика",  chapter: "genetics",    blocks: listData( 5, [ 2, 1, 2 ], [ "middle", "middle", "middle" ], [ "Страна", "Раса", "Степь" ], "genetics" ) },

const initialStste = {
  chapters: [
    { id: 1, chapter: "health", name: "health", caption: "Здоровье", listContent: [] },
    { id: 2, chapter: "medications", name: "medications", caption: "Лекарства", listContent: [] },
    { id: 3, chapter: "nutrition", name: "nutrition", caption: "Питание", listContent: [] },
    { id: 4, chapter: "sport", name: "sport", caption: "Спорт", listContent: [] },
    { id: 5, chapter: "beauty", name: "beauty", caption: "Красота", listContent: [] },
    { id: 6, chapter: "genetics", name: "genetics", caption: "Генетика", listContent: [] },
  ],
  chaptersDemo: [
    { id: 11, chapter: "healthDemo", name: "healthDemo", caption: "Здоровье-демо", listContent: [] },
    { id: 22, chapter: "medicationsDemo", name: "medicationsDemo", caption: "Лекарства-демо", listContent: [] },
    { id: 33, chapter: "nutritionDemo", name: "nutritionDemo", caption: "Питание-демо", listContent: [] },
    { id: 44, chapter: "sportDemo", name: "sportDemo", caption: "Спорт-демо", listContent: [] },
    { id: 55, chapter: "beautyDemo", name: "beautyDemo", caption: "Красота-демо", listContent: [] },
    { id: 66, chapter: "geneticsDemo", name: "geneticsDemo", caption: "Генетика-демо", listContent: [] },
  ],
  content,
  // service: [],
  nowChapter: "health",
  // listInvitro: [],
  listService: [],
  servicesInBasket: [],
  listDescription: [],
  listDescriptionDemo: [],
  filterRisk: "all",
  listStatusOrders: [],
  loader: false
}

// [ "Риски развития заболевания", "Эффективность лекарственных средств", "Особенности питания", "Личностные особенности", "Полногеномный анализ", "Спорт"]

export default function todos(state = initialStste, action) {
  switch (action.type) {
    case SET_CHAPTER:
      return {
        ...state,
        nowChapter: action.payload,
    };
    // case SET_LIST_INVITRO:
    //   return {
    //     ...state,
    //     listService: action.payload,
    // };
    case SET_LIST_SERVICE:
      return {
        ...state,
        listService: action.payload,
    };
    // case SET_LIST_SERVIСES_IN_BASKET:
    //   return {
    //     ...state,
    //     servicesInBasket: action.payload,
    // };

    case SET_LIST_SERVIСES_IN_BASKET:
      return {
        ...state,
        servicesInBasket: [ ...state.servicesInBasket, action.payload ],
    };

    case REMOVE_SERVICES_FROM_BASKET:
      return {
        ...state,
        servicesInBasket: [ ...state.servicesInBasket.filter( el => el.id !== action.payload.id )  ],
    };

    case SET_LIST_CONTENT_FOR_CHAPTERS:
      // eslint-disable-next-line no-case-declarations
      const changeChapter = state.chapters.find( el => el.name === action.payload.chapter )
      changeChapter.listContent =  action.payload.list
      // eslint-disable-next-line no-case-declarations
      const newData = state.chapters
      newData.splice( state.chapters.findIndex( el => el.name === action.payload.chapter ), 1, changeChapter )
      return {
        ...state,
        chapters: newData,
    };
    case SET_LIST_CONTENT_FOR_CHAPTERS_DEMO:
      // eslint-disable-next-line no-case-declarations
      const changeChapterDemo = state.chaptersDemo.find( el => el.name === action.payload.chapter )
      changeChapterDemo.listContent =  action.payload.list
      // eslint-disable-next-line no-case-declarations
      const newDataDemo = state.chaptersDemo
      newDataDemo.splice( state.chaptersDemo.findIndex( el => el.name === action.payload.chapter ), 1, changeChapterDemo )
      return {
        ...state,
        chaptersDemo: newDataDemo,
    };




    // case SET_LIST_DESCRIPTION:

    //   console.log(action.payload, "action.payload");
    //     // eslint-disable-next-line no-case-declarations
    //     const nowListDescription = state.listDescription;
    //     // eslint-disable-next-line no-case-declarations
    //     const searchIndexDescription = state.listDescription.findIndex( el => el.id === action.payload.id )
    //     if ( searchIndexDescription === -1 ) {
    //       console.log(action.payload , "-1");
    //       nowListDescription.push( ...action.payload )
    //     } else {
    //       nowListDescription.splice( searchIndexDescription, 1, ...action.payload )
    //       console.log(nowListDescription , "-1 else");
    //     }
  
    //     console.log(nowListDescription , "after payload");
  
    //     return {
    //       ...state,
    //       listDescription: nowListDescription,
    //   };

    case SET_LIST_DESCRIPTION:
      // console.log(action.payload, "action.payload");
    const mutationListDescription = () => {
      let arr = state.listDescription
      // eslint-disable-next-line array-callback-return
      // action.payload.map( e => {
          if ( state.listDescription.filter( el => el.Title === action.payload.Title ).length === 0 ) { 
            arr.push(action.payload)
          } else {
            arr = [ ...state.listDescription.filter( el => el.Title !== action.payload.Title ), action.payload ]
          }
      // })
      return arr
    };
      return {
        ...state,
        listDescription: mutationListDescription(),
    };


    case SET_LIST_DESCRIPTION_DEMO:
      // console.log(action.payload, "action.payload");
    const mutationListDescriptionDemo = () => {
      let arr = state.listDescriptionDemo
      // eslint-disable-next-line array-callback-return
      // action.payload.map( e => {
          if ( state.listDescriptionDemo.filter( el => el.Title === action.payload.Title ).length === 0 ) { 
            arr.push(action.payload)
          } else {
            arr = [ ...state.listDescriptionDemo.filter( el => el.Title !== action.payload.Title ), action.payload ]
          }
      // })
      return arr
    };
      return {
        ...state,
        listDescriptionDemo: mutationListDescriptionDemo(),
    };




    // case SET_LIST_DESCRIPTION:
    //   console.log(action.payload, "action.payload");
    // const mutationListDescription = () => {
    //   let arr = state.listDescription
    //   // eslint-disable-next-line array-callback-return
    //   action.payload.map( e => {
    //       if ( state.listDescription.filter( el => el.Id === e.Id ).length === 0 ) { 
    //         arr.push(e)
    //       } else {
    //         arr = [ ...state.listDescription.filter( el => el.Id !== e.Id ), e ]
    //       }
    //   })
    //   return arr
    // }
    //   return {
    //     ...state,
    //     listDescription: mutationListDescription(),
    // };








  case CLEAR_SERVICE_IN_BASKET:
    // eslint-disable-next-line array-callback-return
    state.listService.map( el => {
        el.Items.map( item => {
            const choiceObject = item
            choiceObject.choice = choiceObject.choice === true && !choiceObject.choice
            return choiceObject
        })
    })

    return {
      ...state,
      servicesInBasket: [],
      listService: [...state.listService]
  };
  case CHANGE_FILTER_RISK:
    return {
      ...state,
      filterRisk: action.payload,
  };
  case SET_LIST_STATUS_ORDERS:
    return {
      ...state,
      listStatusOrders: action.payload,
  };
  case ACTIVATE_LOADER:
    return {
      ...state,
      loader: action.payload,
  };
    default:
      return state
  }
}

