import React from 'react';
import { Grid, Typography, Card, CardContent, CardActionArea } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  card: { backgroundColor: "#F9F9F9", padding: 0 },
  parentBlock: {textAlign: 'center',  backgroundColor: theme.palette.primary.dark,  borderRadius: "50%",  boxShadow: "0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)" },
  titleChapter: { width: "100%", fontFamily: "OpenSans", fontStyle: "normal", fontWeight: "normal", fontSize: "18px", lineHeight: "25px", color: "#262624" },
  descriptionChapter: { width: "100%", fontFamily: "OpenSans", fontStyle: "normal", fontWeight: "normal", fontSize: "13px", lineHeight: "18px", color: "#262624" },
  blockHeader: { display: "flex",  flexDirection: "column", alignItems: "center", textAlign: "left" },
  cardContent: { padding: "14px 8px", border: "1px solid #919eab3d", borderRadius: "16px" },
}));

export default function CardBlock({ title, description, pathImage }) {
  const classes = useStyles();
  return (
    <Grid className={classes.parentBlock} >
        <Card className={classes.card} >
            <CardActionArea> 
                <CardContent className={ classes.cardContent } >
                    <Grid display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                      <Grid gridColumn="span 3" item>
                        <img src={pathImage} alt="" width="80px" height="80px" />
                      </Grid>
                      <Grid gridColumn="span 9" item className={ classes.blockHeader } >
                        <Typography className={ classes.titleChapter } > { title } </Typography>
                        <Typography className={ classes.descriptionChapter } > { description } </Typography>
                      </Grid>
                    </Grid>
                </CardContent> 
            </CardActionArea>
        </Card>
    </Grid>
  );
}