/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';
import { Navigate, useRoutes, useNavigate } from 'react-router-dom';
// import { } from 'react-router-dom';
// import { useDispatch, useSelector } from "react-redux";
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Privacy from './pages/Privacy';
import Login from './pages/Login';
import Confirm from './pages/Confirm';
import ResetPass from './pages/ResetPass';
import NewPass from './pages/NewPass';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Laboratories from './pages/Laboratories';
import Menu from './pages/Menu';
import ServiceContainer from './pages/ServiceСontainer';
import Products from './pages/Products';
import Scaner from './pages/Scaner';
import Blog from './pages/Blog';
import User from './pages/User';
import Profile from './pages/Profile';
import Status from './pages/Status/Status';
import DemoVersion from "./components/_dashboard/BlockContent/DemoVersion/DemoVersion";

import ListPassports from "./components/_dashboard/BlockContent/ListPasports/ListPassports";

// Exit
import NotFound from './pages/Page404';
import ListBlock from './components/_dashboard/BlockContent/ListBlock';
import ListBlockDemo from './components/_dashboard/BlockContent/DemoVersion/ListBlockDemo';
import BlockContent from './components/_dashboard/BlockContent/BlockContent';
import BlockContentDemo from './components/_dashboard/BlockContent/DemoVersion/BlockContentDemo';

// import { asyncUserInfo, asyncResponseGuestCreate } from "./redux/Logistic/requestAction";
// import { INFO_BY_EMAIL } from './constants/keys';
import Test from './pages/Test';
// import LSScanner from './pages/Scaner/Scanner';
import NoNetwork from './pages/PageNoNetwork';
import Checkout from './pages/Checkout';
import StartPage from './pages/StartPage';
import Pin from './pages/Pin';
import Scanner from './pages/Scaner/Scanner';
import HistoryPay from './pages/HistoryPay';
// ----------------------------------------------------------------------

export default function Router() {

  const authkey = localStorage.getItem("AUTHKEY")
  const pin = localStorage.getItem("pin")
  const account = localStorage.getItem("account")
 
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,// : <Navigate to="/login" />,
      children: [
        { path: '/', element: <Navigate to={authkey ? "/dashboard/menu" : pin ? "/login/pin" : "/login"} replace /> },

        { path: 'demo/healthDemo', element: <ListBlockDemo /> },
        { path: 'demo/medicationsDemo', element: <ListBlockDemo /> },
        { path: 'demo/nutritionDemo', element: <ListBlockDemo /> },
        { path: 'demo/sportDemo', element: <ListBlockDemo /> },
        { path: 'demo/beautyDemo', element: <ListBlockDemo /> },

        { path: 'demo/healthDemo/content', element: <BlockContentDemo /> },
        { path: 'demo/medicationsDemo/content',  element: <BlockContentDemo /> },
        { path: 'demo/nutritionDemo/content',  element: <BlockContentDemo /> },
        { path: 'demo/sportDemo/content',  element: <BlockContentDemo /> },
        { path: 'demo/beautyDemo/content',  element: <BlockContentDemo /> },

        { path: 'app/health', element: <ListBlock /> },
        { path: 'app/health/content', element: <BlockContent /> },
        { path: 'app/medications/content',  element: <BlockContent /> },
        { path: 'app/medications', element: <ListBlock /> },
        { path: 'app/nutrition/content',  element: <BlockContent /> },
        { path: 'app/nutrition', element: <ListBlock /> },
        { path: 'app/sport/content',  element: <BlockContent /> },
        { path: 'app/sport', element: <ListBlock /> },
        { path: 'app/beauty/content',  element: <BlockContent /> },
        { path: 'app/beauty', element: <ListBlock /> },
        // { path: 'app/origin/content',  element: <BlockContent /> },
        // { path: 'app/origin', element: <ListBlock /> },
        { path: 'app/genetics/content',  element: <BlockContent /> },
        { path: 'app/genetics', element: <ListBlock /> },
        { path: 'listPassports', element: <ListPassports /> },
        { path: 'menu', element: <Menu />},
        { path: 'app', element: <DashboardApp /> },
        { path: 'demo', element: <DemoVersion /> },  
        { path: 'profile', element: <Profile /> },
        { path: 'user', element: <User /> },
        { path: 'status', element: <Status /> },
        // { path: 'scaner/camera', element: <LSScanner /> },
        // { path: 'scaner', element: <Scaner /> },
        { path: 'scaner', element: <Scaner /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        { path: 'historyPay', element: <HistoryPay /> },
        { path: 'laboratories/:map', element: <Laboratories /> },
        { path: 'laboratories', element: <Laboratories /> },
        { path: 'service', element: <ServiceContainer /> },
        { path: 'checkout', element: <Checkout /> },
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'start', element: <StartPage /> },

        { path: '#action=confirm&code=:code&email=:email&service=:service', element: <Confirm /> },
        { path: 'login/:login/:pass*', element: <Test /> },
        { path: 'passport/:id*', element: <Test /> },
        { path: 'authorization/:id*', element: <Test /> },

        { path: 'confirm/:code/:email/:service', element: <Confirm /> },
        { path: 'confirm/:email', element: <Confirm /> },
        { path: 'confirm', element: <Confirm /> },
        { path: 'newpass/:code/:email/:service', element: <NewPass /> },
        { path: 'newpass', element: <NewPass /> },
        { path: 'login', element: <Login /> },
        { path: 'pin', element: <Pin /> },
        { path: 'scaner/qr', element: <Scanner /> },
        { path: 'login/:pin', element: <Login /> },
        { path: 'privacy', element: <Privacy /> },
        { path: 'resetpass', element: <ResetPass /> },
        { path: 'register', element: <Register /> },
        { path: 'register/:email', element: <Register /> },
        { path: 'network', element: <NoNetwork /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        // { path: '/', element: authkey ? <Navigate to="/dashboard" /> : <StartPage /> },
        //         { path: '/', element: <Navigate to={authkey ? "/dashboard" : "/login"} /> },

        { path: '*', element: <Navigate to="/404" /> }
      ]
    },

    // { path: 'network', element: <Navigate to="/network" replace /> },
    { path: '*', element: <Navigate to="/404" replace /> }

  ]);
}
