import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, NavLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@material-ui/core';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import { sidebarConfig, sidebarConfigDoc } from './SidebarConfig';
import account2 from '../../_mocks_/account';

// import {requestListForChapterDemo} from "../../redux/GeneBook/requestAction";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200]
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const profile = localStorage.getItem("account")
  const { account, userName } = useSelector(state => state.reducerLogistic)
  const email = localStorage.getItem("email")
  const anonim = localStorage.getItem("anonim")
  const vip = localStorage.getItem("vip")
  // localStorage.getItem("userName")
  // const userName = localStorage.getItem("userName")
  // firstName 

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);


  const renderContent = (
    <Scrollbar style={{
      display: "flex",
      flexDirection: "column"
    }}
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
          <Logo />   
        </Box>
      </Box>

      {email && !anonim && !vip ? <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="/dashboard/profile">
          <AccountStyle>
            <Avatar src={account?.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2, width: "76%" }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {userName || "Аноним"}
              </Typography>
              {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {profile && profile === "1" ? "Врач" : "Пациент"}
              </Typography> */}
            </Box>
          </AccountStyle>
        </Link>
      </Box> : null}

      <NavSection navConfig={sidebarConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{
            p: 2.5,
            pt: 5,
            borderRadius: 2,
            position: 'relative',
            bgcolor: 'grey.200'
          }}
        >
          {/* <Box
            component="img"
            // src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          /> */}


{/* "/dashboard/app/health" */}


{/* style={{ textDecoration: "none" }} component={ NavLink } to={ block.path } */}

{/* demo */}

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Демо версия
            </Typography>
            {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              From only $69
            </Typography> */}
          </Box>

          <Button
          // onClick={ () => requestListForChapterDemo( "health" ) }
          style={{ textDecoration: "none" }} component={ NavLink } to="/dashboard/demo"
            fullWidth
            // href="/dashboard/demo"
            // target="_blank"
            variant="contained"
          >
            Смотреть
          </Button>
        </Stack>
      </Box>

 
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer anchor="left"
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer 
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
