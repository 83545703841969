import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import { Box, Card, Link, Container, Typography } from '@material-ui/core';
import AuthLayout from '../layouts/AuthLayout';
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
// import { ResetPassForm } from '../components/authentication/resetPass';
import { NewPassForm } from '../components/authentication/newPass';
// import AuthSocial from '../components/authentication/AuthSocial';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: { display: 'flex' }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',  maxWidth: 464,  display: 'flex',  flexDirection: 'column',  justifyContent: 'center',  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,  margin: 'auto',  display: 'flex', justifyContent: 'center', padding: theme.spacing(12, 0),  flexDirection: 'column',  // minHeight: '100vh',
}));

// ----------------------------------------------------------------------
// function a11yProps(index) {
//   return {
//     id: `scrollable-force-tab-${index}`,
//     'aria-controls': `scrollable-force-tabpanel-${index}`
//   };
// }

export default function Register() {
  // const [value, setValue] = useState(0);
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  return (
    <RootStyle title="ResetPass | ImGenius">
      <AuthLayout>
        Вспомнил пароль? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/login">
          Войти
        </Link>
      </AuthLayout>
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Управляйте здоровьем более эффективно
          </Typography>
          <img alt="register" src="/static/illustrations/Sign in-pana.png" />
        </SectionStyle>
      </MHidden>
      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Введите Ваш новый пароль
            </Typography>
            {/* <Typography sx={{ color: 'text.secondary' }}>
              Для сброса пароля
            </Typography> */}
          </Box>
          <NewPassForm />
          <MHidden width="smUp">
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: 'center' }}>
              Вспомнил пароль?&nbsp;
              <Link to="/login" component={RouterLink}>
                Войти
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}