/* eslint-disable import/named */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { CHANGE_CURRENT_ACTION,  GET_DATA,  SET_SIZE_BOOK,  CHANGE_NOW_PAGE,  SET_NUMBER_PAGE,  SET_HEIGHT_CHAPTER,  SET_HEIGHT_ONE_TABLE,  ADD_VALUE_INTO_ARRAY_HEIGHT_BEFORE_TABLE,
  SET_COUNT_PAGES, PLUS_ONE_COUNT, SET_STATUS_ABOUT_TWO_PAGE_PATIENT,  SET_COUNT_OF_PAGE_TAKES_TABLE, ADD_IMAGE_KAROTYPE, ADD_LIST_NUMBERS_PAGE,ADD_IMAGE_LOCALIZATION, GET_ENTRIES_DATA, 
  HEIGHT_BLOCK_FOR_DATA_PATIENT, ADD_TEXT_DATA_FOR_DIAGNOSIS, CLEAR_DATA, SET_COUNT_FOR_GENERATION_PDF, SET_CHECK_DOWNLOAD_DATA, SET_QUANTITY_ALL_ELEMENTS_FOR_DOWNLOAD, SET_DATA_FOR_SOFT,
  SET_DATA_FOR_IN_WORK, CHECK_INTERVAL_FOR_STATUS, GET_SEARCH_LIB, SET_DATA_FOR_IN_HISTORY_COMPLETED, SET_DATA_FOR_IN_HISTORY_CANCELED, SET_USER_INFO, GET_LAB_INFO
} from "./actionTypes";
import { formatDateToStr } from "../../constants/functions";

// import { sjson } from "../parse";
// import { json2 } from "../parse";
// import { json3 } from "../parse";

const initialState = {
  // Data: [...sjson],
  // Data: [...json3],
  Data: [],
  DataForSoft: [],
  sizeBook: 0,
  nowPage: 0,
  numberPage: 1,
  EntriesData: [],
  differenceToNewPage:
    Math.ceil(
      (Number(localStorage.getItem("heightAllTables")) +
        Number(localStorage.getItem("heightAllBlockText"))) /
        2070
    ) *
      2070 -
    (Number(localStorage.getItem("heightAllTables")) +
      Number(localStorage.getItem("heightAllBlockText"))),
  heightOneTableaaa: 0,
  heightChapter: 0,
  arrayHeightBeforeTable:[],
  countOfPages: 0,
  arrPageForTables: [],
  image:'', 
  imageLocalization:"",
  ListNumbersPage: "",
  heightBlockForDataPatient: 0,
  textBlockDataForDiagnosis: [],
  counter: 0,
  statusTwoPagePatient: false,
  countElementsForGenerationPdf: 0,
  dataLoaded: false, 
  allElementsForDownload: 0,
  inWork: [],
  inHistoryCompleted: [],
  inHistoryCanceled: [],
  intervalForStatus: true,

  searchLib: [],

  labInfo: [],
  AUTHKEY: "",
  userName: "",
  account: 0
};


export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_LAB_INFO:
      return {
        ...state,
        labInfo: action.payload,
      };
    case GET_SEARCH_LIB:
        return {
          ...state,
          searchLib: action.payload,
        };
    case SET_USER_INFO:
      return {
        ...state,
        AUTHKEY: action.payload.AUTHKEY,
        userName: action.payload.userName,
        account: action.payload.account,
      };
    case CHANGE_CURRENT_ACTION:
      return {
        ...state,
        page: action.payload,
      };
    case GET_DATA:
      return {
        ...state,
        Data: [action.payload],
        dataLoaded: true
      };
    case SET_CHECK_DOWNLOAD_DATA:
      return {
        ...state,
        dataLoaded: action.payload,
      };
    case SET_COUNT_FOR_GENERATION_PDF:
      return {
        ...state,
        countElementsForGenerationPdf: action.payload,
      };
    case CLEAR_DATA:
      return {
        ...state,
        Data: [],
      };
    case GET_ENTRIES_DATA:
      return {
        ...state,
        EntriesData: action.payload,
      };
    case SET_SIZE_BOOK:
      return {
        ...state,
        sizeBook: action.payload,
      };
    case CHANGE_NOW_PAGE:
      return {
        ...state,
        nowPage: action.payload,
      };
    case SET_NUMBER_PAGE:
      return {
        ...state,
        numberPage:
          Math.ceil(state.nowPage / 2070) !== Math.ceil(state.sizeBook / 2070)
          ? Math.ceil(state.nowPage / 2070) + 1
            : Math.ceil(state.sizeBook / 2070),
      };
    case SET_HEIGHT_CHAPTER:
      return {
        ...state,
        heightChapter: action.payload,
      };
    case SET_HEIGHT_ONE_TABLE:
      return {
        ...state,
        heightOneTableaaa: action.payload,
      };
    case ADD_VALUE_INTO_ARRAY_HEIGHT_BEFORE_TABLE:
      return {
        ...state,
        arrayHeightBeforeTable: action.payload,
      };
    case SET_COUNT_PAGES:
      return {
        ...state,
        countOfPages: state.countOfPages + action.payload,
      };
    case SET_COUNT_OF_PAGE_TAKES_TABLE:
      return {
        ...state,
        arrPageForTables: state.arrPageForTables.concat(action.payload),
      };
    case ADD_IMAGE_KAROTYPE:
      return {
        ...state,
        image: action.payload,
      };
    case ADD_IMAGE_LOCALIZATION:
      return {
        ...state,
        imageLocalization: action.payload,
      };
    case ADD_LIST_NUMBERS_PAGE:
      return {
      ...state,
      ListNumbersPage: action.payload,
    };
    case HEIGHT_BLOCK_FOR_DATA_PATIENT:
      return {
        ...state,
        heightBlockForDataPatient: action.payload,
      };
    case ADD_TEXT_DATA_FOR_DIAGNOSIS:
      return {
        ...state,
        textBlockDataForDiagnosis: state.textBlockDataForDiagnosis.concat(action.payload),
      };
    case PLUS_ONE_COUNT:
      return {
        ...state,
        counter: state.counter + 1,
      };
    case SET_STATUS_ABOUT_TWO_PAGE_PATIENT:
      return {
        ...state,
        statusTwoPagePatient: true,
      };
    case SET_QUANTITY_ALL_ELEMENTS_FOR_DOWNLOAD:
      return {
        ...state,
        allElementsForDownload: action.payload,
      };
      // case SET_DATA_FOR_SOFT:
      //   let hhh = []
      //   action.payload.map( (el, id) => {
      //     if ( id === 0 ) {
      //       let dataTime = action.payload[0]
      //       dataTime.Value = formatDateToStr()
      //       hhh.push(dataTime)
      //     } else {
      //       hhh.push(el)
      //     }
      //   })
      //   return {
      //     ...state,
      //     DataForSoft: hhh,
      //   };
    case SET_DATA_FOR_SOFT:
  
      action.payload.InputsForSoft[0].Value = formatDateToStr()
      return {
        ...state,
        DataForSoft: action.payload,
      };
    case SET_DATA_FOR_IN_WORK:
      return {
        ...state,
        inWork: action.payload,
      };
    case SET_DATA_FOR_IN_HISTORY_COMPLETED:
      return {
        ...state,
        inHistoryCompleted: action.payload,
      };
    case SET_DATA_FOR_IN_HISTORY_CANCELED:
      return {
        ...state,
        inHistoryCanceled: action.payload,
      };
    case CHECK_INTERVAL_FOR_STATUS:
      return {
        ...state,
        intervalForStatus: false,
      };
    default:
      return state;
  }
}


    