import { SET_CHAPTER, SET_LIST_SERVIСES_IN_BASKET, SET_LIST_CONTENT_FOR_CHAPTERS, SET_LIST_CONTENT_FOR_CHAPTERS_DEMO, SET_LIST_DESCRIPTION, SET_LIST_DESCRIPTION_DEMO, CLEAR_SERVICE_IN_BASKET, CHANGE_FILTER_RISK, SET_LIST_SERVICE, SET_LIST_STATUS_ORDERS, ACTIVATE_LOADER, REMOVE_SERVICES_FROM_BASKET
  // SET_LIST_INVITRO
} from "./actionType";
  
  
  export function setChapterNow( chapter ) {
    return {
      type: SET_CHAPTER,
      payload: chapter,
    };
  }

  // export function setListInvitro( list ) {
  //   return {
  //     type: SET_LIST_INVITRO,
  //     payload: list,
  //   };
  // }

  export function setListServicesInBasket( list ) {
    return {
      type: SET_LIST_SERVIСES_IN_BASKET,
      payload: list,
    };
  }

  export function setListContentForChapters( list, nameChapter ) {
    return {
      type: SET_LIST_CONTENT_FOR_CHAPTERS,
      payload: { list, chapter: nameChapter },
    };
  }

  export function setListContentForChaptersDemo( list, nameChapter ) {
    return {
      type: SET_LIST_CONTENT_FOR_CHAPTERS_DEMO,
      payload: { list, chapter: nameChapter },
    };
  }

  export function setListDescription( object ) {
    return {
      type: SET_LIST_DESCRIPTION,
      payload: object,
    };
  }

  export function setListDescriptionDemo( object ) {
    return {
      type: SET_LIST_DESCRIPTION_DEMO,
      payload: object,
    };
  }

  export function clearServiceInBasket() {
    return {
      type: CLEAR_SERVICE_IN_BASKET,
    };
  }


  export function changeFilterRisk( risk ) {
    return {
      type: CHANGE_FILTER_RISK,
      payload: risk,
    };
  }

  export function setListServiceStore( arr ) {
    return {
      type: SET_LIST_SERVICE,
      payload: arr,
    };
  }

  export function setListStatusOrders( arr ) {
    return {
      type: SET_LIST_STATUS_ORDERS,
      payload: arr,
    };
  }

  export function activateLoader( boolean ) {
    return {
      type: ACTIVATE_LOADER,
      payload: boolean,
    };
  }

  export function removeServiceFromBasket( item ) {
    return {
      type: REMOVE_SERVICES_FROM_BASKET,
      payload: item,
    };
  }
  

  