import React from "react";
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
// material
import { styled } from '@material-ui/core/styles';
import { Box, TextField, Autocomplete, InputAdornment } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  '& .MuiAutocomplete-root': {
    width: 200,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': {
      width: 240,
      '& .MuiAutocomplete-inputRoot': {
        boxShadow: theme.customShadows.z12
      }
    }
  },
  '& .MuiAutocomplete-inputRoot': {
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`
    }
  },
  '& .MuiAutocomplete-option': {
    '&:not(:last-child)': {
      borderBottom: `solid 1px ${theme.palette.divider}`
    }
  }
}));

// ----------------------------------------------------------------------

BlogPostsSearch.propTypes = {
  posts: PropTypes.array.isRequired,
  setSearchWord: PropTypes.func
};

  export default function BlogPostsSearch({ posts, searchWord, setSearchWord }) {

  return (
    <RootStyle>
      {/* <Autocomplete
        // noOptionsText="Не найдено"
        size="small"
        disablePortal
        popupIcon={null}
        options={posts}
        noOptionsText={null}
        // getOptionLabel={(post) => post.title}
        renderInput={(params) => ( */}
          <TextField
          //  style={{ width: "80%" }}  
          size="small"
          value={searchWord}
          onChange={ (e) => setSearchWord( e.target.value ) }
            // {...params}
            placeholder="Поиск ..."
            InputProps={{
              // ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment  position="start">
                    <Box
                      component={Icon}
                      icon={searchFill}
                      sx={{
                        // ml: 1,
                        width: 20,
                        height: 20,
                        color: 'text.disabled',
                      }}
                    />
                  </InputAdornment>
                  {/* {params.InputProps.startAdornment} */}
                  <Clear onClick={ () => setSearchWord("") } style={{ position: "absolute", right: 5, color: "#98a4b0", cursor: "pointer",  zIndex: 5, backgroundColor: "white" }} />
                </>
              )
            }}
          />
       {/* )}
       /> */}

    </RootStyle>
  );
}
