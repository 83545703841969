/* eslint-disable import/no-unresolved */
import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { isMobile } from 'src/_validations';
import { AppOrderTimeline } from 'src/components/_dashboard/app';

export default function Modals({ title, openModal, setOpenModal }) {
  const [open, setOpen] = React.useState(openModal);
  const scroll = 'paper';

  const handleClose = () => {
    setOpen(false);
    setOpenModal(false)
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div >
      <Dialog  maxWidth="lg"  fullScreen={ !!isMobile }  open={open}  onClose={handleClose}  scroll={scroll}  aria-labelledby="scroll-dialog-title"  aria-describedby="scroll-dialog-description" >
        <DialogTitle style={{ backgroundColor: "#f4f6f8" }} id="scroll-dialog-title">
           Статусы заказа  - <i style={{ fontWeight: 500 }}> { title } </i>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'} >
          <DialogContentText  id="scroll-dialog-description"  ref={descriptionElementRef}  tabIndex={-1}  >
             <AppOrderTimeline />
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "space-around", background: "#f4f6f8" }} >
          <Button  variant="outlined" color="error" onClick={handleClose}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

