import { useDispatch } from "react-redux";
import { Grid, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { NavLink } from 'react-router-dom';
import { setChapterNow, changeFilterRisk } from "../../../../redux/GeneBook/action";
import Chapters from "./Chapters";

const useStyles = makeStyles(() => ({
  parentGrid: { padding: "1em", display: "flex", flexDirection: "column", justifyContent: "center", backgroundColor: "#f9f9f9", backgroundImage: "url(../static/menu/backPhonePassport.svg)", backgroundSize: "contain", borderRadius: "20px", backgroundRepeat: "no-repeat", backgroundPositionY: "bottom", backgroundPositionX: "right", minHeight: "50px", maxHeight: "500px" },
  title: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', padding: "5px 8px", height: "30px",left: "20px",top: "20px",fontFamily: "OpenSans",  fontStyle: "normal",fontWeight: "normal",fontSize: "calc(1em + 0.4vw)",lineHeight: "22px",color: "#262624" },
  subTitle: { padding: "8px", marginTop: "5px", left: "20px", top: "50px", fontFamily: "OpenSans", fontStyle: "normal", fontWeight: "normal", fontSize: "calc(0.8em + 0.4vw)", lineHeight: "22px", color: "#262624" },
  box: { maxWidth: 480, margin: 'auto', textAlign: 'center' },
  gridContent: { padding: "2.5em 0px" }
}));

export default function DemoVersion() {
  const classes = useStyles();
  const dispatch = useDispatch()

  const onHandlerChapter = ( captionChapter ) => {
    dispatch( setChapterNow( captionChapter ) )
    dispatch( changeFilterRisk("all") )
  } 

  const arrBlockChapters = [
    { id: 1, caption: "Здоровье-демо", chapter: "healthDemo", path: "/dashboard/demo/healthDemo", pathIcon: "../static/menu/healthman.svg", description: "ДНК-тест определяет вероятность развития заболеваний и наличие мутаций."},
    { id: 2, caption: "Лекарства-демо", chapter: "medicationsDemo", path: "/dashboard/demo/medicationsDemo", pathIcon: "../static/menu/medicametIcon.svg", description: "Генетические особенности, относящиеся к эффективности и дозировке лекарств."},
    { id: 3, caption: "Питание-демо", chapter: "nutritionDemo", path: "/dashboard/demo/nutritionDemo", pathIcon: "../static/menu/foodIcon.svg", description: "Генетические особенности, связанные с непереносимостью продуктов, дефицитом витаминов."},
    { id: 4, caption: "Спорт-демо", chapter: "sportDemo", path: "/dashboard/demo/sportDemo", pathIcon: "../static/menu/sportIcon.svg", description: "ДНК-тест расскажет, склонны вы к силе или к выносливости, покажет совпадения с геномами атлетов."},
    { id: 5, caption: "Красота-демо", chapter: "beautyDemo", path: "/dashboard/demo/beautyDemo", pathIcon: "../static/menu/beautyIcon.svg", description: "Генетические особенности"},
    // { id: 6, chapter: "genetics", path: "/dashboard/app/genetics" },
  ]

  return (
      <Container maxWidth="xl" >
        <Grid className={ classes.parentGrid } >
            <Typography className={ classes.title } > 
                Ваш генетический паспорт
            </Typography>
            <Typography className={ classes.subTitle } > 
                Узнайте больше о себе
            </Typography> 
        </Grid>
        <Grid className={ classes.gridContent } container justifyContent="center" spacing={3}>
            { arrBlockChapters.map( block =>  
                <Grid key={ block.id } onClick={ () => onHandlerChapter( block.chapter ) } style={{ textDecoration: "none" }} component={ NavLink } to={ block.path } item xs={12} sm={6} md={4}> 
                    <Chapters data={block} />
                </Grid>
            )}
        </Grid>
      </Container>
  );
}
