/* eslint-disable react/jsx-key */
/* eslint-disable import/no-unresolved */
import { useEffect, useState } from 'react';
import {
  Box,
  // Card,
  Link,
  Container,
  Typography,
  Grid,
  Button,
  IconButton,
} from '@material-ui/core';

import { isMobile, isPathName } from 'src/_validations';
import BackspaceIcon from '@material-ui/icons/Backspace';

import Pagination from 'src/components/_dashboard/modules/Pagination';
import { asyncResponseAnonimCreate } from 'src/redux/Logistic/requestAction';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

export default function PinInput(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [index, setIndex] = useState(null);
    const [confirm, setConfirm] = useState(false);
  
    const [pin, setPin] = useState("");
    const [pinConfirm, setPinConfirm] = useState("");
  
    const butt = [{ title: "1", text: "_" }, { title: "2", text: "а б в г" }, { title: "3", text: "д е ж з" },
    { title: "4", text: "и й к л" }, { title: "5", text: "м н о п" }, { title: "6", text: "р с т у" },
    { title: "7", text: "ф х ц ч" }, { title: "8", text: "ш щ ъ ы" }, { title: "9", text: "ь э ю я" }, null, { title: "0", text: "+" }]

    return (
        <div style={{
            display: "flex", flexWrap: "wrap", position: "fixed",
            bottom: 0, marginBottom: isMobile ? "1em" : "2em"
          }}>
  
            {butt.map((item) => <div style={{ textAlign: "center", width: "32%" }}>
              {item && <Button onMouseDown={() => window.navigator && window.navigator.vibrate && window.navigator.vibrate(30)}
                onClick={() => props.onCilckNum(item.title)} style={{
                  // display: "flow-root",
                  height: "80px",
                  width: "80px"
                }}><div>
                  <Typography variant="h3" gutterBottom>
                    {item.title}
                  </Typography>
                  {/* <Typography variant="caption" sx={{ color: 'text.secondary' }}>{item.text}</Typography> */}
                </div>
              </Button>}
            </div>)}
  
            <div style={{ textAlign: "center", width: "32%" }}>
  
              <Button 
              onMouseDown={() => window.navigator && window.navigator.vibrate && window.navigator.vibrate(30)}
                onClick={() => props.onCilckDel()} style={{
                  // display: "flow-root",
                  height: "80px",
                  width: "80px"
                }}>
                <BackspaceIcon />
              </Button>
  
            </div>
          </div>
    )

}