/* eslint-disable react/jsx-key */
/* eslint-disable import/no-unresolved */
import {ExpandMore} from '@material-ui/icons';
import { LoadingButton } from '@material-ui/lab';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
// material
import { Card, CardHeader, Accordion, AccordionSummary, AccordionDetails, Typography, CardContent, Container } from '@material-ui/core';
// components
import { CheckoutForm } from 'src/components/authentication/checkout';
import Page from '../components/Page';
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../components/_dashboard/blog';
import POSTS from '../_mocks_/blog';

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' }
];

export default function Checkout() {
  const { servicesInBasket } = useSelector(state => state.reducerGeneBook)
  return (
    <Page title="Dashboard: Blog | ImGenius">
      <Container>
        {/* <Card style={{ marginBottom: "2em" }}>
          <CardHeader  title="Редактирование профиля"  subheader="можете изменить данные ниже" />
            {servicesInBasket.map( item => 
              <Accordion>
                <AccordionSummary  expandIcon={<ExpandMore />}  aria-controls="panel1a-content"  id="panel1a-header" >
                    <Typography> {item.Title} </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <CardContent>
                        <CheckoutForm />
                    </CardContent>
                </AccordionDetails>
              </Accordion>
            )}
        </Card> */}


        {/* <div>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Accordion 1</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                malesuada lacus ex, sit amet blandit leo lobortis eget.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div> */}

        {/* <LoadingButton style={{ marginLeft: 'auto' }}  size="large"  type="submit"  variant="contained" // fullWidth loading={isSubmitting} style={{ backgroundColor: "white", color: "#54827E" }}
          >
            Оплатить
        </LoadingButton> */}
      </Container>
    </Page>
  );
}
