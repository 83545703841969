/* eslint-disable import/no-unresolved */
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/styles';
import { Stack, Container, Typography, Grid } from '@material-ui/core';
import { AppOrderTimeline } from 'src/components/_dashboard/app';
import Loader from 'src/components/_dashboard/BlockContent/Loader';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import BlockCardStatus from "./BlockCardStatus";

const useStyles = makeStyles((theme) => ({
  parentGrid: { padding: "1em", display: "flex", flexDirection: "column", justifyContent: "center", backgroundColor: "#f9f9f9", backgroundImage: "url(../static/menu/backStatus.svg)", backgroundSize: "contain", borderRadius: "20px",  backgroundRepeat: "no-repeat", backgroundPositionY: "bottom", backgroundPositionX: "right", minHeight: "50px", maxHeight: "500px" },
  subtitle: { marginTop: "5px", left: "20px", top: "50px", fontFamily: "OpenSans", fontStyle: "normal", fontWeight: "normal", fontSize: "calc(0.8em + 0.4vw)", lineHeight: "22px", color: "#262624" },
  title: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', height: "25px", left: "20px", top: "20px", fontFamily: "OpenSans", fontStyle: "normal",  fontWeight: "normal", fontSize: "calc(1em + 0.4vw)", lineHeight: "22px", color: "#262624" },
  gridOfCard: {  marginBottom: "1em", marginTop: "1em" },
}));

const list = [
  { id: 19, caption: "Генетический паспорт.Здоровье", organization: "Гемотест" },
  { id: 20, caption: "Эффективность лекарственных средств", organization: "Genotek" },
  { id: 21, caption: "Особенности питания", organization: "Invitro" },
]

export default function User() {
  const { loader } = useSelector( state => state.reducerGeneBook )
  const classes = useStyles();
  return ( 
  <>
    { loader ? <Loader />  :
      <Page title="Status | ImGenius">
        <Container  style={{ height: `100%` }}>
            {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
              <Scrollbar > */}
              <Grid  className={ classes.parentGrid } >
                <Typography  className={ classes.title } >
                  Этапы получения заключения
                  {/* <br/> <i style={{ color: "#C4CDD5" }}> ( обновлен 21.09.2021 ) </i> */}
                </Typography>
                <Typography  className={ classes.subtitle } >
                  Дождитесь завершения всех этапов.
                </Typography>
            </Grid>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}  className={ classes.gridOfCard } >
                { list.map( block =>  
                    <Grid  key={ block.id }  Grid item xs={12} sm={8} md={4}
                        // style={{ padding: 0, margin: 0 }}  onClick={ () => onHandlerChapter( block.chapter ) } style={{ textDecoration: "none" }}
                        // component={ NavLink }  to={ block.path } item xs={12} sm={6} md={4}
                    > 
                      <BlockCardStatus block={block} />
                    </Grid>
                )}
            </Grid>
            {/* <Scrollbar style={{ marginTop: "2em" }}>
              <AppOrderTimeline />
          </Scrollbar> */}
        </Container>
      </Page> 
    }
    </>
  );
}
