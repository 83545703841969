/* eslint-disable import/no-unresolved */
import { useDispatch } from "react-redux";
import PropTypes from 'prop-types';
// material
import { MenuItem, TextField } from '@material-ui/core';
import { isMobile } from "src/_validations";
import { changeFilterRisk } from "../../../redux/GeneBook/action";


// ----------------------------------------------------------------------

BlogPostsSort.propTypes = {
  options: PropTypes.array,
  onSort: PropTypes.func,
  setChoiceSort: PropTypes.func,
  choiceSort: PropTypes.string
};

export default function BlogPostsSort({ options, choiceSort, setChoiceSort, onSort }) {
  const dispatch = useDispatch()
  
  return (
    // <TextField select size="small" value="latest" onChange={onSort}>
    <TextField style={{ minWidth: isMobile && "25%" }} select size="small" value={choiceSort}
      onChange={(e) => { dispatch(changeFilterRisk(e.target.value)); 
      // setChoiceSort(e.target.value) 
      }}>
      {options.map((option) => (
        <MenuItem  disabled={ option.contentLength === 0 } key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

// font-size: calc(1em + 1vw)
