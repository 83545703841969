/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-key */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import { useEffect, useState } from 'react';

import {
  Box,
  // Card,
  Link,
  Container,
  Typography,
  Grid,
  Button,
  IconButton,
  CircularProgress,
} from '@material-ui/core';

import { isMobile } from 'src/_validations';
import BackspaceIcon from '@material-ui/icons/Backspace';

import Pagination from 'src/components/_dashboard/modules/Pagination';
import { asyncRegistration, asyncResponseAnonimCreate } from 'src/redux/Logistic/requestAction';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import PinInput from 'src/components/_dashboard/BlockContent/PinInput';
import Logo from 'src/components/Logo';
import { NavLink } from 'react-router-dom';
import DialogWindow from 'src/components/DialogWindow';

// ----------------------------------------------------------------------

export default function PinCode(prop) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [index, setIndex] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState("");
  const [pinConfirm, setPinConfirm] = useState("");
  const isPathName = window.location.pathname;
  const [open, setOpen] = useState(false);
  const pinCode = localStorage.getItem("pin")
  const [answerFromServer, setAnswerFromServer] = useState({ alert: { level: "error", message: "Пароль не подходит" } });
//  console.log(prop.open, prop.dataReg);
  function onCilckNum(item) {

    if (pin.length < 5) {
      setPin(pin + item)

      if (pin.length === 4 && (isPathName === "/pin" || isPathName === "/login/pin") && pinCode) {
        // console.log(pinCode, pin + item)
        setLoading(true)

        if (pinCode === pin + item) {
          // console.log("не Ошибка");

          navigate("/dashboard/menu")
        } else {
          // console.log("Ошибка");
          setAnswerFromServer({ alert: { level: "error", message: "Пароль не подходит" } })
          setOpen(true)
          window.navigator.vibrate(1000);
          setPin('')
          setLoading(false)
        }

        // pinCode
      }

    } else {

      setPinConfirm(pinConfirm + item)
    }

    if (index === null) {
      setIndex(0)
    } else if (index + 1 !== 4) {
      setIndex(index + 1)
    } else {
      setIndex(null)
    }



    if (pin.length && pinConfirm.length && pin.length === pinConfirm.length + 1 && pin !== pinConfirm + item) {
      // Ошибка ввода повтора  
      // console.log("Ошибка");
      if (window.navigator && window.navigator.vibrate) {
        setAnswerFromServer({ alert: { level: "error", message: "Пароль не подходит" } })
        setOpen(true)
        window.navigator.vibrate(1000);
        setPinConfirm("")
        setLoading(false)
      }

    }else if (pinConfirm.length && pin.length === pinConfirm.length + 1 && pin === pinConfirm + item){

      setLoading(true)

      // пинкоды совпадают

      if (!localStorage.getItem("AUTHKEY")) {
        localStorage.setItem("pin", pin)
        if (prop.dataReg) {
          asyncRegistration({ ...prop.dataReg, pin }, dispatch, setAnswerFromServer, navigate)
        } else {
          asyncResponseAnonimCreate({ pin }, dispatch, navigate)
        }




        navigate("/dashboard/menu")
      } else {
        console.log("вход");

      }

    }

  }

  function onCilckDel() {

    if (index === 0) {
      setIndex(null)
    } else {
      setIndex(index - 1)
    }
    if (pin.length < 5) {
      setPin(pin.slice(0, pin.length - 1))
    } else {
      setPinConfirm(pinConfirm.slice(0, pinConfirm.length - 1))
    }


  }
  // console.log(isPathName , pinCode);
  return (
    <div title="ResetPass | ImGenius" style={{ width: isMobile ? '100vw' : '640px' }}>

      <Container sx={{ minHeight: "inherit", display: "grid", textAlign: "center" }}>

        <div style={{
          top: "3em",
          left: 0,
          padding: "1em",
          width: "100%",
          position: "absolute"
        }}>
          <Typography variant="h4" >
            {(isPathName === "/pin" || isPathName === "/login/pin") && pinCode ?
              "Введите пароль" : pin.length < 5 ? "Придумайте код" : "Повторите код"}
            <Typography variant="body1" >
              {(isPathName === "/pin" || isPathName === "/login/pin") && pinCode ?
                null :
              (pin.length < 5 ?
                "Ваш код авторизации для входа в приложение" :
                  "Повторите ранее введённый код для подтверждения")}
            </Typography>
          </Typography>

          <div style={{ margin: isMobile ? "1em" : "2em" }}>
            {!loading ? <Pagination dots={5} index={index} onChangeIndex={setIndex} pin /> :
              <CircularProgress />}
          </div>

          {(isPathName === "/pin" || isPathName === "/login/pin") && pinCode ? <Button component={NavLink} to="/login">
            Не можете войти?
          </Button> : null}
          <DialogWindow width="30%"
            margin=""
            onClick={() => { setOpen(false) }}
            //  AnswerFromServerAuthKey={AnswerFromServerAuthKey} 
            answerFromServer={answerFromServer}
            open={open} />
        </div>

        <PinInput onCilckDel={onCilckDel} onCilckNum={onCilckNum} />

        </Container>



    </div>
  );
}
