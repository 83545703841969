/* eslint-disable import/no-unresolved */
import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { asyncExit } from 'src/redux/Logistic/requestAction';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha } from '@material-ui/core/styles';
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@material-ui/core';
// components
import MenuPopover from '../../components/MenuPopover';
//
import account from '../../_mocks_/account';



// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  // {
  //   label: 'Home',
  //   icon: homeFill,
  //   linkTo: '/'
  // },
  {
    label: 'Профиль',
    icon: personFill,
    linkTo: '/dashboard/profile'
  },
  // {
  //   label: 'Русский',
  //   icon: settings2Fill,
  //   linkTo: '#'
  // }
];

const MENU_ANONIM_OPTIONS = [
  // {
  //   label: 'Home',
  //   icon: homeFill,
  //   linkTo: '/'
  // },
  {
    label: 'Персонализация',
    icon: personFill,
    linkTo: '/dashboard/profile'
  },
  {
    label: 'Настройки',
    icon: settings2Fill,
    linkTo: '#'
  }
];
// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const header = window.location.pathname;
  const [open, setOpen] = useState(false);
  const { account } = useSelector(state => state.reducerLogistic)
  const email = localStorage.getItem("email")
  const userName = localStorage.getItem("userName")
  const dispatch = useDispatch()
  
  const handleOpen = () => {
    if(header === "/"){
      navigate("/login")
    }else{
      setOpen(true);
    }
    // header === "/"
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const anonim = localStorage.getItem("anonim")
  const vip = localStorage.getItem("vip")
  const menu = email && !anonim && !vip ? MENU_OPTIONS: MENU_ANONIM_OPTIONS

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src={account?.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {email && !anonim && !vip ? (userName || "ФИО") : "Аноним"}
          </Typography>
          {/* <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {account === "1" ? "Врач" : "Пациент"}
          </Typography> */}
        </Box>

        <Divider sx={{ my: 1 }} />

        {menu.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        {/* {const email = localStorage.getItem("email")<Box sx={{ p: 2, pt: 1.5 }}>
          <Button component={RouterLink} to="/login" fullWidth color="inherit" variant="outlined">
            Войти
          </Button>
        </Box>: */}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button onClick={() => asyncExit(dispatch, navigate) }
          // component={RouterLink} to="/login" 
          fullWidth color="inherit" variant="outlined">
            Выход
          </Button>
        </Box>
        
       {/* }  */}
      </MenuPopover>
    </>
  );
}
