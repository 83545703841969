/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate, useParams } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment, Typography, Link, FormControlLabel, Checkbox, Drawer } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import DialogWindow from 'src/components/DialogWindow';
import { useDispatch } from 'react-redux';
import { asyncRegistration } from 'src/redux/Logistic/requestAction';
import { isMobile } from 'src/_validations';
import PinCode from 'src/pages/PinCode';
import { SERVICE, KEY_DEVELOPER, PARENT_ID_FOR_REGISTRATION } from "../../../constants/keys";
// ----------------------------------------------------------------------
import ModalWindows from "../../../pages/ForService/ModalWindows";
import { arrayPrivacyPolicy, arrayConsentToPersonalData } from "../../../constants/constant";


export default function RegisterForm() {
  const navigate = useNavigate();
  const [openPin, setOpenPin] = useState(false)
  const [pincode, setPincode] = useState('')
  const [dataReg, setDataReg] = useState({})

  const [dataForModal, setDataForModal] = useState("")
  const [showPassword, setShowPassword] = useState(false);
  const [openModal, setOpenModal] = useState( false )
  const { email } = useParams();
  const [remember, setRemember] = useState(true);
  // const [code, setCode] = useState("");
  // const [password, setPassword] = useState("");
  // const [personKey, setPersonKey] = useState("");
  // const [getAuthKey, setGetAuthKey] = useState("");
  const [answerFromServer, setAnswerFromServer] = useState("");
  // const [AnswerFromServerAuthKey, setAnswerFromServerAuthKey] = useState("");
  const [open, setOpen] = useState(true);

  const RegisterSchema = Yup.object().shape({
    // firstName: Yup.string()
    //   .min(2, 'Слишком коротко!')
    //   .max(50, 'Слишком длинно!')
    //   .required('Требуется имя'),
    // lastName: Yup.string().min(2, 'Слишком коротко!').max(50, 'Слишком длинно!').required('Требуется фамилия'),
    // email: Yup.string().email('Электронная почта должна быть действительна').required('Требуется электронная почта'),
    password: Yup.string().min(6, 'Слишком коротко!').max(50, 'Слишком длинно!').required('Необходим пароль'),
    confirm: Yup.string().min(6, 'Слишком коротко!').max(50, 'Слишком длинно!').required('Необходим пароль')
  });


  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      middleName: '',
      // email: '',
      // remember: false,
      password: '',
      confirm: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: (values, actions) => {
      sendDataForRegistration(email, values.password, values.confirm, values.firstName, values.lastName, values.middleName)
      actions.setSubmitting(false);
      // navigate('/dashboard', { replace: true });
    }
  });
  const dispatch = useDispatch();
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  let sendDataForRegistration = (email, password, confirm, firstName, lastName, middleName) => {
    // e.preventDefault();middleName,
   
    if (confirm === password) {
      setOpen(true);
      if (!firstName) {
        firstName="firstName"
      }
      if (!lastName) {
        lastName="lastName"
      }
      setOpenPin(true)
      setDataReg({ email, password, firstName, lastName, middleName })
      // console.log("htubcnhfwbz");

    }else{
      setAnswerFromServer({ alert: {level: "error", message: "Пароли не совпадают" } })
    }

  };

  const sendRegistration = (pin) => {

    console.log({ ...dataReg, pin });
    // asyncRegistration({...dataReg, pin}, dispatch, setAnswerFromServer, navigate);

  }


  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}> */}
            <TextField color="success" variant="outlined"
              style={{ backgroundColor: "#e8f0fe", borderRadius: "12px" }}
              InputLabelProps={{
                shrink: true, sx: { color: !isMobile && "#1e2028" }
              }}
              fullWidth
              placeholder="Имя"
              {...getFieldProps('firstName')}
              // error={Boolean(touched.firstName && errors.firstName)}
              // helperText={touched.firstName && errors.firstName}
            />

            <TextField variant= "outlined"
              style={{ backgroundColor: "#e8f0fe", borderRadius: "12px" }}
              // InputLabelProps={{
              //   shrink: true, sx: { color: !isMobile && "#1e2028" }
              // }}
              fullWidth
              placeholder="Фамилия"
              {...getFieldProps('lastName')}
            // error={Boolean(touched.lastName && errors.lastName)}
            // helperText={touched.lastName && errors.lastName}
            />

            <TextField variant= "outlined"
              style={{ backgroundColor: "#e8f0fe", borderRadius: "12px" }}
              // InputLabelProps={{
              //   shrink: true, sx: { color: !isMobile && "#1e2028" }
              // }}
              fullWidth
              placeholder="Отчество"
              {...getFieldProps('middleName')}
              // error={Boolean(touched.lastName && errors.lastName)}
              // helperText={touched.middleName && errors.middleName}
            />
          {/* </Stack> */}

          {/* <TextField variant="outlined"
            style={{ backgroundColor: "#e8f0fe", borderRadius: "12px" }}
            fullWidth
            // InputLabelProps={{
            //   shrink: true, sx: { color: !isMobile && "#1e2028" }
            // }}
            // autoComplete="username"
            type="email"
            placeholder="Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          /> */}
          <Stack spacing={2}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>


          <TextField variant= "outlined"
            sx={{ backgroundColor: "#e8f0fe", borderRadius: "12px" }}
            fullWidth
            // InputLabelProps={{
            //   shrink: true, sx: { color: !isMobile && "#1e2028", borderRadius: "12px"}
            // }}
            // autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            placeholder="Пароль"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />    
          {/* #e8f0fe */}
          <TextField variant="outlined"
            sx={{ backgroundColor: "#e8f0fe", borderRadius: "12px" }}
            fullWidth
            // InputLabelProps={{
            //   shrink: true, sx: { color: !isMobile && "#1e2028" }
            // }}
            // autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            placeholder="Подтверждение пароля"
            {...getFieldProps('confirm')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.confirm && errors.confirm)}
            helperText={touched.confirm && errors.confirm}
          />
            </Stack>
          </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 1 }}>
            {/* <FormControlLabel
              control={<Checkbox />}
              placeholder=""
            /> */}
            {/* checked={values.remember}  */}
            {/* <Link component={RouterLink} variant="subtitle2" to="#">
            Forgot password?
          </Link> */}
          {/* setOpenModal */} 
          <FormControlLabel style={{ color: "white" }}
            control={<Checkbox style={{ color: "white" }} {...getFieldProps('remember')} checked={remember} onClick={()=>setRemember(!remember)}/>}
            label={<div align="left" style={{ color: 'text.secondary', mt: 1, fontSize: "14px" }}>
            <span style={{ color: "white"  }}> Регистрируясь, я принимаю &nbsp; </span >
              <Link onClick={() => { setOpenModal(true); setDataForModal(arrayPrivacyPolicy) }} underline="always" sx={{ color: 'text.primary' }}
                style={{ color: "white", cursor: "pointer", textDecoration: "underline" }}>
                Политику конфиденциальности
              </Link>
              <span style={{ color: "white" }}> &nbsp;&nbsp; и даю &nbsp;&nbsp; </span >
              <Link onClick={() => { setOpenModal(true); setDataForModal(arrayConsentToPersonalData) }} underline="always"
                sx={{ color: 'text.primary' }} style={{ color: "white", cursor: "pointer", textDecoration: "underline" }}>
                Согласие на обработку персональных данных 
              </Link>
              <ModalWindows openModal={ openModal } setOpenModal={ setOpenModal } content={ dataForModal }/>
              {/* <ModalWindows openModal={openModal} /> */}
            </div>}
          />

            {/* <div variant="body2" align="center" sx={{ color: 'text.secondary', mt: 1 }}>
            <span style={{ color: "white"  }}> Регистрируясь, я принимаю &nbsp; </span >
              <Link onClick={() => { setOpenModal(true); setDataForModal(arrayPrivacyPolicy) }} underline="always" sx={{ color: 'text.primary' }}
                style={{ color: "white", cursor: "pointer", textDecoration: "underline" }}>
                Политику конфиденциальности
              </Link>
              <span style={{ color: "white" }}> &nbsp;&nbsp; и даю &nbsp;&nbsp; </span >
              <Link onClick={() => { setOpenModal(true); setDataForModal(arrayConsentToPersonalData) }} underline="always"
                sx={{ color: 'text.primary' }} style={{ color: "white", cursor: "pointer", textDecoration: "underline" }}>
                Согласие на обработку персональных данных 
              </Link>
              <ModalWindows openModal={ openModal } setOpenModal={ setOpenModal } content={ dataForModal }/>
      
            </div> */}

          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            style={{ backgroundColor: "white", color: "#54827E" }}
          >
            Регистрация
          </LoadingButton>
        </Stack>
      </Form>
      <Drawer anchor="right" open={openPin} onClose={() => setOpenPin(false)}>
        <PinCode open={openPin} onClick={sendRegistration} dataReg={dataReg}/>
      </Drawer>
      {answerFromServer && (<DialogWindow width="30%"
        // margin={"-7% 0% 0% 50%"}
        margin=""
        onClick={() => { setOpen(false) }}
        // AnswerFromServerAuthKey={AnswerFromServerAuthKey} 
        answerFromServer={answerFromServer}
        open={open} />)}
    </FormikProvider>
  );
}
