/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
//
import { isMobile } from "src/_validations";
import Loader from "../../components/_dashboard/BlockContent/Loader";
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import ModalMailSend from '../../pages/ForService/ModalMailSend';
import { requestPrice, requestOrderStatus } from "../../redux/GeneBook/requestAction";
// ----------------------------------------------------------------------



const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + (window.location.href.split("/").reverse()[0] !== "map" && 24),
  paddingBottom: window.location.href.split("/").reverse()[0] !== "map" ? theme.spacing(5): 0,
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + (window.location.href.split("/").reverse()[0] !== "map" && 24),
    paddingLeft: window.location.href.split("/").reverse()[0] !== "map" ? theme.spacing(2): 0,
    paddingRight: window.location.href.split("/").reverse()[0] !== "map" ? theme.spacing(2): 0
  },
  // backgroundImage: 'url(../static/menu/chistyj-belyj-fon_34.jpg)' // фон
  backgroundColor: window.location.href.split("/").reverse()[0] === "menu" && !isMobile && "#c4cdd5c4"
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {

  const dispatch = useDispatch()
  const navigate = useNavigate();

  useEffect(() => {

    if ( localStorage.getItem("AUTHKEY") && localStorage.getItem("AUTHKEY").length > 0 ) {
       requestPrice( dispatch )
       requestOrderStatus( dispatch )
    }
    // console.log(localStorage.getItem("remember"));
    if (localStorage.getItem("remember") === "false") {
      console.log("localStorage.getItem()");
      // navigate("/")
      // localStorage.clear();
    }

    setInterval(() => {

      if (!navigator.onLine) {
        navigate("/network")
      }
    }, 2000);

  }, [])
  
  const [open, setOpen] = useState(false);

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle style={{  paddingBottom: isMobile && "0" }}> 
          <Outlet />
        </MainStyle>
        <ModalMailSend />
    </RootStyle>
  );
}
