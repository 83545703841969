/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
// import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// material
// import { useTheme } from '@material-ui/core/styles';
import { Alert, Box, Button, Container, Grid, Snackbar, Stack, Typography } from '@material-ui/core';
// import { LoadingButton } from '@material-ui/lab';
// import { Icon } from '@iconify/react';
// import shareFill from '@iconify/icons-eva/share-fill';
// components
// import * as Yup from 'yup';
// import { useFormik } from 'formik';
import QRCode from 'react-qr-code';
import Scrollbar from 'src/components/Scrollbar';
import { useNavigate } from 'react-router-dom';
// import { TabPanel } from '@material-ui/lab';
// import util from 'src/_validations/util';
import { SERVER } from 'src/constants/server';
// import YandexShare from 'react-yandex-share';
// import Page from '../components/Page';
import { makeStyles } from '@material-ui/styles';
// import Button from '@material-ui/core/Button';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import SaveIcon from '@material-ui/icons/Save';
// import PrintIcon from '@material-ui/icons/Print';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailIcon from '@material-ui/icons/Mail';
import TelegramIcon from '@material-ui/icons/Telegram';

import ShareIcon from '@material-ui/icons/Share';
// import FavoriteIcon from '@material-ui/icons/Favorite';

import EditIcon from '@material-ui/icons/Edit';

import { copyToClipboard, isMobile } from 'src/_validations';
import { MotionContainer } from 'src/components/animate';
import Page from 'src/components/Page';
// import { Share } from '@capacitor/share';
import ScanMaterial from './Scaner/LSScanner';

// import {
//   ProductSort,
//   ProductList,
//   ProductCartWidget,
//   ProductFilterSidebar
// } from '../components/_dashboard/products';
//
// import PRODUCTS from '../_mocks_/products';
const useStyles = makeStyles((theme) => ({
  root: { height: 380,  transform: 'translateZ(0px)', flexGrow: 1 },
  speedDial: { position: 'absolute', bottom: theme.spacing(2), right: theme.spacing(2) },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
// ----------------------------------------------------------------------

export default function EcommerceShop() {
  // const [text, setText] = useState("false");
  // const theme = useTheme(); 
  const account = localStorage.getItem("account")
  // const userId = localStorage.getItem("userId")
  const value = +account;

  const navigate = useNavigate();

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openAns, setOpenAns] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [basicShare, setBasicShare] = useState(false)

  // useEffect(() => () => {
  //   if (basicShare) {
  //     Share.share({
  //       title: 'ImGenius',
  //       text: 'Генетический паспорт здоровья',
  //       url: "https://imgenius.socmedica.dev/dashboard/app",
  //       dialogTitle: 'Im`Genius',
  //     });
  //   }
  //   setBasicShare(false)

  // },[basicShare]);

  const actions = [
    { icon: <FileCopyIcon />, name: 'Copy', action: shareClick, operation: 'copy' },
    { icon: <MailIcon />, name: 'Mail', action: shareClick, operation: 'mail' },

    // { icon: <WhatsAppIcon />, name: 'WhatsApp', action: shareClick, operation: 'whatsApp' },
    // { icon: <TelegramIcon />, name: 'Telegram', action: shareClick, operation: 'telegram' },
    // { icon: <ShareIcon />, name: 'Share', action: shareClick, operation: 'share' },
    // { icon: <FavoriteIcon />, name: 'Like' },
  ];

  const handleVisibility = () => {
    setHidden((prevHidden) => !prevHidden);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const {permissions} = window.cordova.plugins;
  const height = window.innerHeight;
  const width = window.innerWidth;
  const smallSize = width < height ? width : height;
  const size = smallSize * (isMobile ? 0.6 : 0.4);

  // function copyToClipboard(textToCopy) {
  //   // navigator clipboard api needs a secure context (https)   
  //     // text area method
  //     const textArea = document.createElement("textarea");
  //     textArea.value = textToCopy;
  //     // make the textarea out of viewport
  //     textArea.style.position = "fixed";
  //     textArea.style.left = "-999999px";
  //     textArea.style.top = "-999999px";
  //     document.body.appendChild(textArea);
  //     textArea.focus();
  //     textArea.select();
  //     // document.execCommand("copy");
  //     return new Promise((res, rej) => {
  //       // here the magic happens
  //       document.execCommand('copy') ? res() : rej();
  //       textArea.remove();
  //     });
  // }

  // function setClipboard(text) {
  //   const type = "text/plain";
  //   const blob = new Blob([text], { type });
  //   const data = [new ClipboardItem({ [type]: blob })];

  //   navigator.clipboard.write(data).then(
  //     () => {
  //       alert("4654654")
  //       /* success */
  //     },
  //     () => {
  //       alert("error132132")
  //       /* failure */
  //     }
  //   );
  // }
  function myFunction() {
    const copyText = document.getElementById("myInput");
    copyText.select();
    document.execCommand("copy");
    alert(`Copied the text: ${  copyText.value}`);
  }

  function shareClick(e, operation, link) {

    const titleLinkElement = document.createElement("a");
    titleLinkElement.href = link.link;
    titleLinkElement.innerHTML = "Генетический паспорт здоровья";
    titleLinkElement.title = "Генетический паспорт здоровья";

    e.preventDefault();
    if (operation === "mail") {
      window.location.href = `mailto:?subject=ImGenius&body=Генетический паспорт здоровья ${titleLinkElement}`;
      // do something 
    } else if (operation === "share") {
      // setBasicShare(true)
      // window.Ya.share2('my-share', {
      //   theme: {
      //     services: 'messenger,facebook,lj,viber,twitter',
      //     lang: 'uk',
      //     limit: 3,
      //     size: 's',
      //     bare: false
      //   },content: { url: 'example.com' },
      // });
      // do something else
    } else if (operation === "whatsApp") {
      window.location.href = `whatsapp://send?text=Генетический паспорт здоровья ${link.link}`
      // do something else
    } else if (operation === "telegram") {
      window.location.href = `tg://msg_url?url=${link.link}&text=Генетический паспорт здоровья`
      // do something else
    } else if (operation === "copy") {

      copyToClipboard(link.link)
        .then(() => setOpenAns(true))
        .catch((err) => {     
        });
    }
    setOpen(!open);// to close the speed dial, remove this line if not needed.
  }

  function shouldRequestData() {
    return true;
  }

  return (
    <Page title="Scaner | ImGenius" style={{
      display: 'flex',
      minHeight: '100%',
    }}>
      {/* // style={{ height: `100%` }} */}
    <Container  style={{ marginBottom: "60px" }}>

      <Stack direction="row" alignItems="center" justifyContent="space-between" >
        <Scrollbar style={{
          width: "100%"
        }}>

            <Grid style={{
              padding: "1em", display: "flex", flexDirection: "column", justifyContent: "center", backgroundColor: "#f9f9f9",
              backgroundImage: "url(../static/menu/backQR.svg)", backgroundSize: "contain", borderRadius: "20px",
              backgroundRepeat: "no-repeat", backgroundPositionY: "bottom", backgroundPositionX: "right", minHeight: "50px", maxHeight: "500px"
            }}>
              <Typography style={{
                whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                // padding: "5px 8px", 
                height: "25px", left: "20px", top: "20px", fontFamily: "OpenSans", fontStyle: "normal",
                fontWeight: "normal", fontSize: "calc(1em + 0.4vw)", lineHeight: "22px", color: "#262624"
              }} >
                Ваш индивидуальный QR-код
                {/* <br/> <i style={{ color: "#C4CDD5" }}> ( обновлен 21.09.2021 ) </i> */}
              </Typography>

              <Typography style={{
                // padding: "8px", 
                marginTop: "5px", left: "20px", top: "50px", fontFamily: "OpenSans",
                fontStyle: "normal", fontWeight: "normal", fontSize: "calc(0.8em + 0.4vw)", lineHeight: "22px", color: "#262624"
              }} >
                Покажите врачу, для предоставления доступа!
              </Typography>
            </Grid>
        </Scrollbar>

      </Stack>
        <MotionContainer initial="initial" open style={{  display: 'flex',  minHeight: '80%', alignItems: 'center' }}>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <QRCode m={3} value={SERVER === "dev" ? "https://imgenius.socmedica.dev/dashboard/app" : "https://imgenius.socmedica.com/dashboard/app"}
              fgColor="primary.main" size={size} />
          </Box>
        </MotionContainer>

        {isMobile && value ? <Scrollbar style={{ textAlign: "center" }}>
          <Stack alignItems="center" justifyContent="space-between" m={3}>
          <ScanMaterial
          close={() => navigate('/dashboard/app')}
          // storageScanner
          // onOpen={setTrack}
          onAccess={() => navigate('/dashboard/app')}
          shouldRequestData={shouldRequestData}
          headerMessage=""
          />
          {isMobile ? <Typography sx={{ color: 'text.secondary' }}>
            {account === "1" ? "Cканируйте QR-код пациента, для получения его генетического заключения!" :
              "Сканируйте QR-код врача для предастовления ему, вашего генетического заключения!"}
          </Typography> : null}
        </Stack></Scrollbar> : null}

        <SpeedDial
          ariaLabel="SpeedDial openIcon example"
          className={classes.speedDial}
          hidden={hidden}
          icon={<SpeedDialIcon icon={<ShareIcon />} />}
          onClose={handleClose}
          onClick={handleOpen}
          open={open}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={(e) => {
                shareClick(e, action.operation, { link: (SERVER === "dev" ? "https://imgenius.socmedica.dev/dashboard/app" : "https://imgenius.socmedica.com/dashboard/app") })
              }}
            />
          ))}
        </SpeedDial>
      
      </Container>
      <Snackbar open={openAns} autoHideDuration={3000} onClose={() => setOpenAns(false)}>
        <Alert onClose={() => setOpenAns(false)} severity="success">
          Ссылка сохранена в буфер обмена
        </Alert>
      </Snackbar>
    </Page>
  );
}