/* eslint-disable no-undef */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-bitwise */
/* eslint-disable no-const-assign */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
// material
import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Box, Grid, Container, Typography, Stack, Snackbar, Alert, Button, Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, IconButton } from '@material-ui/core';
import { NavLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { autoPlay } from 'react-swipeable-views-utils';
import SwipeableViews from 'react-swipeable-views';
import { useTheme, styled } from '@material-ui/core/styles';
import GenPass from 'src/components/_dashboard/menu/GenPass';
import LabMap from 'src/components/_dashboard/menu/LabMap';
import Profile from 'src/components/_dashboard/menu/Profile';
import Share from 'src/components/_dashboard/menu/Share';
import Pagination from 'src/components/_dashboard/modules/Pagination';
import QRCode from 'react-qr-code';
import { Icon } from '@iconify/react';
import closeIcon from '@iconify/icons-eva/close-fill';
import { SERVER } from 'src/constants/server';
import { isMobile, device, copyToClipboard } from 'src/_validations';
import { setChapterNow } from "../redux/GeneBook/action";
import Page from '../components/Page';

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const RootStyle1 = styled('div')(({ theme }) => ({
  color: theme.palette.black.dark,
  backgroundColor: theme.palette.black.lighter,
}))

const styles = {
  root: { position: 'relative',  margin: "1em 0",  borderRadius: "10px" },
  slide: { borderRadius: "10px" },
  slide1: { backgroundColor: '#F9F9F9' },
  slide2: { backgroundColor: '#F9F9F9' },
  slide3: { backgroundColor: '#F9F9F9' },
  slide4: { backgroundColor: '#F9F9F9' },
  slide5: { backgroundColor: '#F9F9F9' },
  menu: { textDecoration: "none", width: "47%", paddingTop: "1em" },
}

// <div onClick={() => {
//   dispatch(setChapterNow("health"))
//   navigate("/dashboard/app")
// }}
//   style={{ textDecoration: "none", width: "47%", paddingTop: "1em" }}  >
//   <GenPass />
// </div>
// console.log(navigator.userAgent, isMobile, "navigator.userAgentData.mobile")

export default function Menu() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useTheme()
  const value = 0
  // const { index } = this.state;
  const [index, setIndex] = React.useState(0)

  const email = localStorage.getItem("email")
  const anonim = localStorage.getItem("anonim")
  const login = localStorage.getItem("login")
  const key = localStorage.getItem("key")

  const saveQR = localStorage.getItem("saveQR")
  const LinkHash = localStorage.getItem("LinkHash")

  const [open, setOpen] = React.useState(false)
  const [openAns, setOpenAns] = React.useState(false)

  const menu = [{ title: "health", link: "/dashboard/app", content: <GenPass /> },
  { title: "sport", link: "/dashboard/scaner", content: <Share /> },
  { title: "nutrition", link: "/dashboard/listPassports", content: <Profile /> },
  { title: "medications", link: "/dashboard/laboratories/map", content: <LabMap /> }]

  const handleClose = () => {
    // myFunction()
    setOpen(false)
  }

  useEffect(() => {
    if (anonim) {
      setOpen(true)
    }
  }, [anonim, email])

  const styleForBlock = {
    width: "100%", height: "100%",
  }

  const styleForBlock2 = { mimWidth: "50px", minHeight: "50px", maxWidth: "200px", maxHeight: "200px" }
  const gridStyle = { display: "flex",  flexDirection: "column", justifyContent: "center", alignItems: "center",  marginBottom: "1em" }
  const textStyle = { fontSize: "13pt", mimWidth: "50px", minHeight: "50px", maxWidth: "200px", maxHeight: "200px" }

  const arrData = [
    // новое
    { id: 0, pathImage: "../static/menu/slider/1.svg", pathImageStep: "../static/menu/slider/imac.png", description: "Выберите услугу и оплатите её" },
    { id: 1, pathImage: "../static/menu/slider/2.svg", pathImageStep: "../static/menu/slider/maplab.png", description: "Мы направим вас в ближайшую лабораторию" },
    { id: 2, pathImage: "../static/menu/slider/3.svg", pathImageStep: "../static/menu/slider/blood.png", description: "Вы сдаете биоматериал в лаборатории" },
    { id: 3, pathImage: "../static/menu/slider/4.svg", pathImageStep: "../static/menu/slider/ipad.png", description: "Лаборатория проводит анализ вашего генома" },
    { id: 4, pathImage: "../static/menu/slider/5.svg", pathImageStep: "../static/menu/slider/pass.png", description: "Вы получаете готовый генетический паспорт" },
  ]

  const styleForBlockCenter = { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }

  // function copyToClipboard(textToCopy) {
  //   // navigator clipboard api needs a secure context (https)   
  //     // text area method
  //     console.log(textToCopy,"textToCopy");
  //     const textArea = document.createElement("textarea");
  //     textArea.value = textToCopy;
  //     // make the textarea out of viewport
  //     textArea.style.position = "fixed";
  //     textArea.style.left = "-999999px";
  //     textArea.style.top = "-999999px";
  //     document.body.appendChild(textArea);
  //     textArea.focus();
  //     textArea.select();
  //     // document.execCommand("copy");
  //     return new Promise((res, rej) => {
  //       // here the magic happens
  //       document.execCommand('copy') ? res() : rej();
  //       textArea.remove();
  //     });
  // }

  // function copyToClipboard(textToCopy) {
  //   // navigator clipboard api needs a secure context (https)
  //   // text area method
  //   const type = "text/plain";
  //   const blob = new Blob([textToCopy], { type });
  //   const data = [new ClipboardItem({ [type]: blob })];

  //   navigator.clipboard.write(data).then(
  //     () => {
  //       /* success */
  //     },
  //     () => {
  //       /* failure */
  //     }
  //   );



  //   const textArea = document.createElement("textarea")
  //   textArea.value = textToCopy
  //   // make the textarea out of viewport
  //   textArea.style.position = "fixed"
  //   textArea.style.left = "-999999px"
  //   textArea.style.top = "-999999px"
  //   document.body.appendChild(textArea)
  //   textArea.focus()
  //   textArea.select()
  //   return new Promise((res, rej) => {
  //     // here the magic happens
  //     document.execCommand('copy') ? res() : rej()
  //     textArea.remove()
  //   })
  // }

  function myFunction(link) {
    /* Get the text field */
    console.log(link);
    copyToClipboard(link.link)
      .then(() => {
        setOpenAns(true);
        localStorage.setItem("saveQR", true)
      })
      .catch((err) => {

        // alert(err)
      })
    setOpen(false)
  }

  return (
    //  style={{ backgroundImage: 'url(../static/menu/disp.png)' }} 
    <Page 
     title="Dashboard | ImGenius" >
      <Container style={ !isMobile ? styleForBlockCenter : {} }  maxWidth="xl" >
        {anonim && !saveQR ? <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Внимание!
            {handleClose ? (
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Icon icon={closeIcon} />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{ textAlign: "center" }}>

              <Stack sx={{ mb: 3 }}>
                <Typography sx={{ color: 'text.secondary' }}>
                  Вы вошли анонимно, обязательно сохраните QR-код для повторного входа, иначе данные будут утерянны безвозвратно
                </Typography>
              </Stack>
              {/* SERVISE */}
              {/* SERVER */}
              <QRCode value={LinkHash ? `${LinkHash}` : (SERVER === "dev" ? `https://imgenius.socmedica.dev/login/${login}/${key}` :
                `https://imgenius.socmedica.com/login/${login}/${key}`)}
                fgColor="primary.main" size={150} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => myFunction({ link: LinkHash ? `${LinkHash}` : (SERVER === "dev" ? `https://imgenius.socmedica.dev/login/${login}/${key}` :
              `https://imgenius.socmedica.com/login/${login}/${key}`)})} color="primary" autoFocus>
              Сохранить
            </Button>
          </DialogActions>
        </Dialog> : null}

        { isMobile ? <div style={styles.root}>
              <AutoPlaySwipeableViews  index={index} onChangeIndex={setIndex} style={{ borderRadius: "10px", marginBottom: "2em" }}  >
                { arrData.map( item => 
                    <RootStyle1 key={item.id} style={({ ...styles.slide, ...styles.slide1 })} value={value} index={item.id} dir={theme.direction}>
                      <img src={item.pathImageStep} alt="" style={{ position: "absolute", height: "100%" }} />
                      <img src={ item.pathImage } alt="" style={styleForBlock} /> 
                    </RootStyle1>
                )}
              </AutoPlaySwipeableViews>

          <Pagination dots={5} index={index} onChangeIndex={setIndex} />
            </div>

            :
              <>
                  <Typography variant="h2" style={{ marginBottom: "2rem",  fontFamily: "open sans,sans-serif", color: "#4a4f4a", fontSize: "calc(1.5em + 1vw)" }}> Шаги для получения генетического паспорта </Typography>
                  <Grid  container justifyContent="center" alignItems="center" >
                    {arrData.map( e => 
                      <Grid key={ e.id } style={gridStyle} item xs={12} md={6} lg={4} >
                        <img src={ e.pathImageStep } alt="" style={styleForBlock2}  /> 
                        <Typography style={textStyle} >  { e.description }  </Typography>
                      </Grid>
                    )}
                    <Grid style={{ display: "flex", justifyContent: "center" }} item xs={ 4 } >
                      <Button size="large" component={ NavLink } to="/dashboard/service" style={{ color: "white", minWidth: "200px" , border:"none", 
                        background: "linear-gradient(154.39deg, #C4C4C4 -60.92%, #4A4F4A 91.42%)" }}  variant="outlined"> 
                        <span style={{ fontSize: "12pt" }}> Заказать паспорт  </span>
                      </Button>  
                    </Grid>
                  </Grid>
              </>
            }

        {isMobile && <div style={{
          display: "flex", justifyContent: "space-between",
          flexWrap: "wrap"
        }} >
          {menu.map((item) => (
            <div onClick={() => {
              dispatch(setChapterNow(item.title))
              navigate(item.link)
            }} style={styles.menu}  >
              {item.content}
              {/* <GenPass /> */}
            </div>
          ))}
        </div>
        }

      </Container>
      <Snackbar open={openAns} autoHideDuration={3000} onClose={() => setOpenAns(false)}>
        <Alert onClose={handleClose} severity="success">
          QR-код сохранён в буфер обмена
        </Alert>
      </Snackbar>
    </Page>
  )
}