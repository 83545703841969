// import { useEffect } from 'react';
// routes
// import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { useState} from "react";
import Router from './routes';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import Stepper from "./components/_dashboard/BlockContent/Stepper";

import "./index.css";

// import { requestAccountAuthorization, requestAccountRegistrationUserAnonymous, 
  // asyncGetFilesFromDirectory 
// } from "./redux/GeneBook/requestAction";
// import { requestAccountAuthorization, requestAccountRegistrationUserAnonymous } from "./redux/GeneBook/requestAction";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeConfig>
      <ScrollToTop />
      <Router />
    </ThemeConfig>
  );
}
