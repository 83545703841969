/* eslint-disable camelcase */
export const back_Phone = "/backPhone.png";

export const caption_Email = "Электронная почта";

export const text_Error_Format = "Неверный формат почты";

export const text_Code_Confirm = "Код подтверждения";

export const text_Insert_Code = "Введите код";

export const text_Password = "Пароль";

export const text_alert_for_password = "Пароль должен быть больше 5 символов";

export const text_Person_key = "Персональный ключ";

export const text_Insert_key = "Введите ключ";

export const text_Surname = "Фамилия";

export const text_Name = "Имя";

export const text_Middle_Name = "Отчество";

export const text_Confirm_Password = "Подтвердите пароль";

export const text_Password_Does_Not_Match = "Пароль не совпадает";

export const text_Code_For_Recovery_Password = "Код для восстановления пароля";

export const text_New_Password = "Новый пароль";
