// export function isEmail(n) {
//     let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//     return !re.test(n) && n !== "";
// }

// export const postElement = ( elements )  => ({
//   method: "POST",
//   headers: {"Content-Type": "application/json"},
//   body: JSON.stringify(elements),
// });
export const getElement = ( elements )  => ({
  method: "GET",
  headers: {"Content-Type": "application/json"},
  body: JSON.stringify(elements),
});

export const postElement = ( elements )  => ({
     method: "POST",
     headers: {"Content-Type": "application/json"},
     body: JSON.stringify(elements),
   });

export const formatDateToStr = () => {

    const getDate = new Date()
  
    let day = getDate.getDate();
    if (day < 10) day = `0${  day}`;
  
    let month = getDate.getMonth() + 1;
    if (month < 10) month = `0${  month}`;
  
    const year = getDate.getFullYear();
  
    let min = getDate.getMinutes();
    if (min < 10) min = `0${  min}`;
  
    let hour = getDate.getHours();
    if (hour < 10) hour = `0${  hour}`;
  
    let second = getDate.getHours();
    if (second < 10) second = `0${  second}`;

    return `${day  }.${  month  }.${  year  }  ${  hour  }:${  min  }:${  second}` ;
}
