/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable import/no-unresolved */
import { useState } from 'react';
// import { Icon } from '@iconify/react';
// import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
// import {
//   Card, CardHeader,
//   CardContent, Grid, Button, Container, Stack, Typography
// } from '@material-ui/core';
import './Scanner.css';
import { isMobile } from 'src/_validations';
import { Drawer } from '@material-ui/core';
import LSScanner from './LSScanner';
import PinCode from '../PinCode';

// ----------------------------------------------------------------------

// export default function goto(prop) {
//   const navigator = useNavigate()
//   return navigator(prop.link)
// }
export default function Scanner(prop) {
  const navigate = useNavigate()
  const [pin, setPin] = useState(false);
  // console.log(prop);
  return (<>
      <LSScanner
      close={() => navigate(-1)}
      // storageScanner
      // onOpen={setTrack} navigate(props)
      onAccess={(props) => window.location.href = props}
      // shouldRequestData={shouldRequestData}
      headerMessage=""
      />
      {/* <Drawer style={{ width: isMobile ? '100vw' : '640px' }} anchor="right" open={pin} onClose={() => navigate("/login")}>
        <PinCode />
      </Drawer> */}
    </>
  );
 
};
