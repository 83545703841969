/* eslint-disable no-plusplus */
import React, { useState,  } from 'react';
import PropTypes from 'prop-types';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Router, Link as RouterLink } from 'react-router-dom';
import { Button, IconButton } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachFileIcon from '@material-ui/icons/AttachFile';
// ----------------------------------------------------------------------

export default function ButtonDownload({  color = 'primary', variant = 'contained', type, children, ...other }) {


  const inputFile = React.useRef(null);
  const openFile = () => {
    // console.log("sdfsdfsdf");
    inputFile.current.click();
  };
  return <>
      {type === "icon"?<IconButton onClick={openFile} edge="end">
                  <AttachFileIcon />
                </IconButton>:
      <Button color={color} variant={variant}  style={{margin: 0, marginTop: "24px"}}
        onClick={openFile} endIcon={<GetAppIcon />}
      >Загрузить</Button>}

      <input type='file' id='files' accept={other.accept} ref={inputFile}
        style={{ display: 'none' }}
        onChange={other.onDowenload}  />
        </>
 
}

ButtonDownload.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error'
  ]),
  variant: PropTypes.oneOf(['filled', 'outlined', 'ghost'])
};
