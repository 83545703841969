/* eslint-disable no-var */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { Drawer, IconButton, Skeleton, Typography, Button as ButtonMaterial } from '@material-ui/core';
// import ButtonMaterial from '@material-ui/core/Button';
import React, { useState } from 'react';

import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { Icon } from '@iconify/react';

import closeFill from '@iconify/icons-eva/close-fill';
import { isMobile } from 'src/_validations';
import { YMaps, Map, Clusterer, Placemark, Button } from "react-yandex-maps";
import USERLIST from '../../_mocks_/copy';
import Modals from './Modals';


export default function MapsYa(props) {
  const [marker, setMarker] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [showInfo, setShowInfo] = useState(null)
  const navigate = useNavigate();
  const [ymaps, setYmaps] = useState(null);

  function handleMarkerClick(targetMarker) {

    setOpen(true)
    setMarker(targetMarker)
    setShowInfo({ lat: targetMarker.Lat, lng: targetMarker.Lng })

  }

  // const mapState = {
  //   center: [55.751574, 37.573856],
  //   zoom: 5
  // };

  //   const location = geolocation.get({
  //     provider: 'yandex',
  //     mapStateAutoApply: true,
  //     autoReverseGeocode: true
  // });

  console.log(ymaps, "location");

  const onLoadMap = (inst) => {
    var location = inst.geolocation.get(
      { provider: 'yandex', autoReverseGeocode: true, mapStateAutoApply: true },
    )
    console.log('ymaps  ', inst)
// Асинхронная обработка ответа.
    location.then(
      (result) => {
        // Добавление местоположения на карту.
        myMap.geoObjects.add(result.geoObjects);
        console.log('location ', result)
        result.geoObjects.options.set('preset', 'islands#redCircleIcon');
        result.geoObjects.get(0).properties.set({
          balloonContentBody: 'Мое местоположение'
        });
        inst.geoObjects.add(result.geoObjects);      },
      (err) => {
        console.log(`Ошибка: ${  err}`)
      },
    )
  }
// const location = ymaps.geolocation;
// const myMap = new ymaps.Map('map', {
//     center: [55.76, 37.64],
//     zoom: 10
// }, {
//     searchControlProvider: 'yandex#search'
// });

// // Получение местоположения и автоматическое отображение его на карте.
// location.get({
//         mapStateAutoApply: true
//     })
// .then(
//     (result) => {
//         // Получение местоположения пользователя.
//         const userAddress = result.geoObjects.get(0).properties.get('text');
//         const userCoodinates = result.geoObjects.get(0).geometry.getCoordinates();
//         // Пропишем полученный адрес в балуне.
//         result.geoObjects.get(0).properties.set({
//             balloonContentBody: `Адрес: ${  userAddress 
//                                 }<br/>Координаты:${  userCoodinates}`
//     });
//         myMap.geoObjects.add(result.geoObjects)
//     },
//     (err) => {
//         console.log(`Ошибка: ${  err}`)
//     }
// );

  return (<div style={{
    height: "100%",
    maxWidth: "100%",
  }} >

    <YMaps query={{
      apikey: '703b2263-b6ce-4005-b6af-d01c3b6c8d09',
      lang: "ru_RU", load: "package.full",
    }}

      mode='debug' csp style={{
        height: "100%",
        width: "100%",
      }}>
      <Map style={{
        height: "100%",
        maxWidth: "100%",
      }}
        state={{
          center: [props.openSearch.lat, props.openSearch.lng],
          zoom: 13,
          margin: 10,
          controls: ['zoomControl', 'routeButtonControl', 'geolocationControl']
        }}
        options={{
          searchControlProvider: 'yandex#search',
          provider: 'yandex',
          mapStateAutoApply: true,
          autoReverseGeocode: true
        }}
        // geolocation={{
        //   provider: 'yandex',
        //   mapStateAutoApply: true,
        //   autoReverseGeocode: true
        // }}
        modules={["geolocation", "geocode"]}
        // controls={['zoomControl', 'searchControl', 'typeSelector', 'fullscreenControl', 'routeButtonControl']} , 'GeolocationControl' 
        // defaultState={mapState}
        // onLoad={ymaps => setYmaps({ ymaps })}
        onLoad={(inst) => {onLoadMap(inst); setYmaps({ inst })}}
      >
        <Button data={{ content: 'Список' }} onClick={() => navigate("/dashboard/laboratories") } options={{ float: 'right' }} />

        <Clusterer
          options={{
            clusterIconLayout: "default#pieChart",
            clusterIconPieChartRadius: 20,
            clusterIconPieChartCoreRadius: 10,
            clusterIconPieChartStrokeWidth: 1,
          
          }}
    
        >
          {/* <Placemark
          geometry={[props.openSearch.lat, props.openSearch.lng]}
          properties={{
            hintContent: 'Собственный значок метки',
            balloonContent: 'Это красивая метка'
          }}
          // options={{
          //   // searchControlProvider: 'yandex#search',
          //   iconLayout: 'islands#redCircleIcon',
          //   iconImageHref: 'https://api-maps.yandex.ru/2.1.79/build/release/images/geolocation/YaRu.svg',
            options={{
              iconContent: "IG",
              // iconColor: point.License ? '#6EA1A5' : "#aaa",
              iconImageHref: 'https://api-maps.yandex.ru/2.1.79/build/release/images/geolocation/YaRu.svg',
              iconLayout: 'default#image',             
              iconImageSize: [30, 30],                
              iconImageOffset: [-5, -38]             
            }}
          // }}
        /> */}
          {ymaps &&
            USERLIST.map((point, index) => (
              <Placemark
                modules={[
                  "geoObject.addon.balloon",
                  "geoObject.addon.hint"
                ]}
                key={index}
                geometry={[point.Lat, point.Lng]}

               
                onClick={() => handleMarkerClick(point)}
                options={{
                  iconContent: "IG",
                  iconColor: point.License ? '#6EA1A5' : "#aaa",
                  iconImageHref: point.License ? '/static/map/onCininc.svg' : '/static/map/offClinic.svg',
                  iconLayout: 'default#image',             
                  iconImageSize: [30, 42],                
                  iconImageOffset: [-5, -38]             
                }}
              />
            ))}
        </Clusterer>
      </Map>
    </YMaps>

   
    <Skeleton variant="rect" style={{
      height: "100%",
      width: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: -1
    }} />
    {
      !isMobile ? <Modals marker={marker} open={open} setOpen={setOpen} /> :
        <Drawer variant="persistent" anchor="bottom" marker={marker} open={open}  >
          <div style={{
            padding: "2em", display: "flex",
            flexDirection: "column"
          }}>
         

            <Typography variant="h5" component="h2">
              {marker?.Name}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setOpen(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Icon icon={closeFill} />
            </IconButton>
            <Typography style={{ marginBottom: 12 }} color="textSecondary">
              {marker?.TimeTable}
            </Typography>
            <a href={marker?.Link}> открыть сайт </a>
      
            <Typography variant="body2" component="p">
              {marker?.Address}
            </Typography>

            <ButtonMaterial variant="contained" style={{ marginTop: "2em" }} onClick={() => localStorage.setItem("status", new Date())}
              to="/dashboard/status" component={RouterLink}
            >
              Записаться
            </ButtonMaterial>
          </div>
        </Drawer>
    }
  </div >
  );
}

