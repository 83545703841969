/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import * as React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, Box } from '@material-ui/core';
import { isMobile } from 'src/_validations';

function LinearProgressWithLabel( props ) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: isMobile ? '80%' : '30%', mr: 1 }}>
        <LinearProgress style={{ minWidth: 35,  backgroundColor:"lightGrey", borderRadius: "25px" }} variant="determinate" {...props} />
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const defineTheRisk = ( value ) =>  
    value <= 0.17 
  ? { color: "success", typeIcon: "low", nameType: "Ваш риск", type: 1 }   // #1890FF
  : value <= 0.5
      ? { color: "warning", typeIcon: "middle", nameType: "Ваш риск", type: 2 }
      : { color: "error", typeIcon: "high", nameType: "Ваш риск", type: 3 }

export default function ProgressLine({ percent }) {

  return (
    <Box sx={{ width: '100%', margin: "4px 0px", backgroundColor:"grey" }}>
      <LinearProgressWithLabel value={Math.trunc( percent * 100)}  color={ defineTheRisk( Number( percent ) ).color } />
    </Box>
  );
}

