/* eslint-disable no-unused-expressions */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
const ruLocale = 'ru-RU';
const language = localStorage.getItem("userLanguage") === "ru" ? "ru-RU" : "en-US";

// Увеличить регистр
export function ucFirst(str) {
  if (!str) return str;
  return str[0].toUpperCase() + str.slice(1);
}

export const isPathName = window.location.pathname;

// Удалить дублирования в массиве
export function removDoubl(array) {

  return array.filter((thing, index) => {
    const _thing = JSON.stringify(thing);
    return index === array.findIndex(obj => JSON.stringify(obj) === _thing);
  });
}

export function copyToClipboard(textToCopy) {
  // navigator clipboard api needs a secure context (https)   
    // text area method
    console.log(textToCopy,"textToCopytextToCopytextToCopy");
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    // document.execCommand("copy");
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand('copy') ? res() : rej();
      textArea.remove();
    });
}
// function doScrolling(elementY) {
//   var startingY = window.pageYOffset;
//   var diff = elementY - startingY;
//   var start;

//   // Bootstrap our animation - it will get called right before next frame shall be rendered.
//   window.requestAnimationFrame(function step(timestamp) {
//     if (!start) start = timestamp;
//     // Elapsed milliseconds since start of scrolling.
//     var time = timestamp - start;
//     // Get percent of completion in range [0, 1].
//     var percent = Math.min(time / 1000, 1);

//     window.scrollTo(0, startingY + diff * percent);
//     // Proceed with animation as long as we wanted it to.
//     if (time < 1000) {
//       window.requestAnimationFrame(step);
//     }
//   })

// }

// скролинг на верх
export function _scrollToTop(name, down) {
  const elTop = document.getElementById(name || 'navigationScroll');

  const startingY = elTop && elTop.scrollTop;
  const scrollHeight = elTop && elTop.scrollHeight
  const diff = down ? scrollHeight - startingY : 0 - startingY;
  let start;


  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    // Elapsed milliseconds since start of scrolling.
    const time = timestamp - start;
    // Get percent of completion in range [0, 1].
    const percent = Math.min(time / 500, 1);
    // startingY + diff * percent

    elTop.scrollTo(0, startingY + diff * percent);
    // Proceed with animation as long as we wanted it to.
    if (time < 500) {
      window.requestAnimationFrame(step);
    }
  })

};

// преобразовать в денежный формат
export function formatCurrency(amount) {
  return amount?.toLocaleString(language, { style: 'currency', currency: 'RUB' })
}
// сортировка
export function byField(field, type) {

  return (a, b) => a[field] > b[field] ?
    (type === "DESC" ? -1 : 1) :
    (type === "DESC" ? 1 : -1);
}

export function formatQty(amount, digits = 3) {
  return amount?.toLocaleString(language, { style: 'decimal', minimumFractionDigits: digits, maximumFractionDigits: digits })
}

export function formatMMDDtoDDMM(date) {
  const arr = date.split(".")
  return `${arr[1]  }.${  arr[0]  }.${  arr[2]}`
}

export function formatDateTime(dateTime) {
  return `${dateTime.toLocaleDateString(language)  } ${  dateTime.toLocaleTimeString(language)}`
}
export function formatTime(dateTime) {
  return dateTime.toLocaleTimeString(language)
}
// export function formatTimeHoursMinutes(dateTime) {
//   var min = dateTime.getMinutes();
//   if (min < 10) min = '0' + min;
//   var hour = dateTime.getHours();
//   if (hour < 10) hour = '0' + hour;

//   return hour+":"+min
// }
export function formatDate(dateTime, option) {
  const result = new Date(dateTime).toLocaleDateString(language, option)
  return !isNaN(result) ? result : dateTime
}
export function formatStrtoDate(date, time) {
  const dateStr = date
  return new Date()
}

export function GetNewBigId(lib, id) {
  return (+id * 10000 + +lib);
}

export function addDate(date, days) {

  // const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;

}

export function formatDatetoStr(date, time) {
  const newDate = new Date(date);
  let dd = newDate.getDate();
  if (dd < 10) dd = `0${  dd}`;
  let mm = newDate.getMonth() + 1;
  if (mm < 10) mm = `0${  mm}`;
  const yyyy = newDate.getFullYear();

  let min = newDate.getMinutes();
  if (min < 10) min = `0${  min}`;
  let hour = newDate.getHours();
  if (hour < 10) hour = `0${  hour}`;

  return `${dd  }.${  mm  }.${  yyyy  }${time ? (` ${  hour  }:${  min}`) : ""}`;
  // return  time ? (dd + '.' + mm + '.' + yyyy + time) : (" " +hour+":"+min);
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}
export function isValidDate(date) {
  return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
}

export function formatPrice(amount) {
  const price = Number.prototype.toFixed.call(parseFloat(amount) || 0, 2);
  let pricesep = price.replace(/(\D)/g, ",");
  pricesep = pricesep.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
  return pricesep;
}

export function isGrowth(n) {
  return (n < 10 || n > 225) && n !== "";
}
export function isWeight(n) {
  return (n < 5 || n > 420) && n !== "";
}
export function isPhone(n) {
  const re = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11,15}(\s*)?$/;
  return !re.test(n) && n !== ""
}
export function isRegexes(n, reg, mask) {
  const re = new RegExp(reg);
  const ma = mask && mask === "ed/nm/zyyy" && new Date(n) && new Date() > new Date(n)
  return !re.test(n) && n !== "" && !ma
}

export function isEmail(n) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !re.test(n) && n !== ""
}

export function isExperience(n) {
  const re = /^(\d{2})$/;
  return !re.test(n) && n !== ""
}


export function isPassword(n) {
  return !!n;
}
export function isAge(n) {
  const year = new Date().getFullYear();
  return ((n < 0 || n > 130) && (year - n > 130 || year - n < 0)) && n !== "";
}

const userDeviceArray = [
  { device: 'Android', platform: /Android/ },
  { device: 'iPhone', platform: /iPhone/ },
  { device: 'iPad', platform: /iPad/ },
  { device: 'Symbian', platform: /Symbian/ },
  { device: 'Windows Phone', platform: /Windows Phone/ },
  { device: 'Tablet OS', platform: /Tablet OS/ },
  { device: 'Linux', platform: /Linux/ },
  { device: 'Windows', platform: /Windows NT/ },
  { device: 'Macintosh', platform: /Macintosh/ }
];

const platform = navigator.userAgent;

export function getPlatform() {
  for (const i in userDeviceArray) {
    if (userDeviceArray[i].platform.test(platform)) {
      return userDeviceArray[i].device;
    }
  }
  return platform;
}

export const isWebView = navigator.userAgent.includes('wv')

const userAgent = window.navigator.userAgent.toLowerCase();
    const safari = /safari/.test( userAgent );
    const chrome = /chrome/.test( userAgent );
    const ios = /iphone|ipod|ipad/.test( userAgent );

export function webviewOrBrowser() {
      if( ios ) {
          if ( safari ) {
            return "browser"
          } if ( !safari ) {
            return "webview"
          };
      } else {

          // not iOS
      };

    }

    //  const h = window.innerHeight;
    //  const w = window.innerWidth;
    export const view = window.innerHeight > window.innerWidth ? 'portrait' : 'landscape';

    export const device = (window.innerWidth < 480 && view === 'portrait') || (window.innerWidth < 1024 && view === 'landscape') ?
       'mobile' : (
         (window.innerWidth < 1200 && view === 'portrait') || (window.innerWidth < 1200 && view === 'landscape') ?
           'tablet' : 'pc'
       );

export function onResize() {

  const h = window.innerHeight;
  const w = window.innerWidth;
  const v = h > w ? 'portrait' : 'landscape';
  const d = (w < 480 && v === 'portrait') || (w < 1024 && v === 'landscape') ?
    'mobile' : (
      (w < 1280 && v === 'portrait') || (w < 1280 && v === 'landscape') ?
        'tablet' : 'pc'
    );
  const mobilewarning = (d === 'mobile');
  const view = v;
  const device = d;
  const height = h;
  const width = w;
  const scroll = 0;
  // console.log(mobilewarning, "mobilewarning");
  return mobilewarning
}

export const isCordova = window.hasOwnProperty("cordova") || typeof(cordova) === "object" || !!window.cordova 
export const isMobile = /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(navigator.userAgent) && device === 'mobile';
// mobile
// pc
// tablet

// (function (a, b) 
// { 
//   if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) window.location = b 
// }
// )(navigator.userAgent || navigator.vendor || window.opera, 'http://detectmobilebrowser.com/mobile');


export const isVertical = window.innerHeight > window.innerWidth;

export function ageSelect(age) {
  let txt;
  let count = age % 100;
  if (count >= 5 && count <= 20) {
    txt = 'лет';
  } else {
    count %= 10;
    if (count === 1) {
      txt = 'год';
    } else if (count >= 2 && count <= 4) {
      txt = 'года';
    } else {
      txt = 'лет';
    }
  }
  return `${age  } ${  txt}`;
}