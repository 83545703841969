// /* eslint-disable jsx-a11y/alt-text */
// // import { Icon } from '@iconify/react';
// // import appleFilled from '@iconify/icons-ant-design/apple-filled';
// // material
// import { alpha, styled } from '@material-ui/core/styles';
// import { Card, Typography } from '@material-ui/core';
// // utils
// // import { fShortenNumber } from '../../../utils/formatNumber';

// // ----------------------------------------------------------------------

// const RootStyle = styled(Card)(({ theme }) => ({
//   // boxShadow: "0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)",
//   textAlign: 'center',
//   padding: theme.spacing(2, 0),
//   color: theme.palette.primary.darker,
//   backgroundColor: theme.palette.primary.lighter
// }));

// const IconWrapperStyle = styled('div')(({ theme }) => ({
//   margin: 'auto',
//   display: 'flex',
//   borderRadius: '50%',
//   alignItems: 'center',
//   width: theme.spacing(12.5),
//   height: theme.spacing(12.5),
//   justifyContent: 'center',
//   marginBottom: theme.spacing(3),
//   color: theme.palette.primary.dark,
//   backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
//     theme.palette.primary.dark,
//     0.24
//   )} 100%)`
// }));

// // ----------------------------------------------------------------------

// // const TOTAL = 1352831;

import React from 'react';
import CardBlock from "../BlockContent/CardBlock";

export default function AppWeeklySales() {
  return (
      // <CardBlock title="Лекарства" pathImage="../static/icons/2.svg" />
      <CardBlock title="Лекарства" description="Генетические особенности, относящиеся к эффективности и дозировке лекарств." pathImage="../static/menu/medicametIcon.svg" />
  );
}
