/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */
import React from 'react';
import clsx from 'clsx';
// import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ClearIcon from '@material-ui/icons/Clear';
import { Container, IconButton, Typography } from '@material-ui/core';
import { isMobile, ucFirst } from 'src/_validations';
import PinCode from 'src/pages/PinCode';
import CustomButton from 'src/theme/overrides/CustomButton';
import Logo from 'src/components/Logo';
// import { isMobile, ucFirst } from '../../_validations';
// import Divider from '@material-ui/core/Divider';
// import BlockPDF from './BlockPDF';

// const useStyles = makeStyles({
//   list: {
//     width: 640,
//   },
//   fullList: {
//     width: '100vw',
//   },
//   margin: {
//     height: "fit-content"
//   }
// });

export default function TemporaryDrawer(props) {
  // const classes = useStyles();
  const { block, title, title3, value3 } = props;
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const text = value3 || (block?.Content && block?.Content?.find((item) => item.Type === "comment"));

  const list = (anchor) => (
    <div
      style={{ width: isMobile ? '100vw' : '640px' }}
      // className={clsx(classes.list, {
      //   [classes.fullList]: isMobile,
      // })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div >
      {/* <Logo /> */}
        <IconButton onClick={toggleDrawer(anchor, false)} style={{
          float: "right",
          margin: isMobile ? "0.5em" : "1em",

        }}>
          <ClearIcon />
        </IconButton>
      </div>
      {/* <BlockPDF block={block} /> */}
      {/* <Divider /> */}
      <Container style={{ margin: "4em 0" }}>
        {title3 ? <h2>{ucFirst(title3)}</h2> : null}
        {/* <Divider /> */}
        {text && <div style={{ margin: "2em 0" }} dangerouslySetInnerHTML={{ __html: ucFirst(text.Value || text) }} />}
      </Container>

    </div>
  );

  return (
    <>
      <React.Fragment key="right">
        {title ? <CustomButton variant="outlined" size="large"
          style={{ color: "white", backgroundColor: "#68938F", border: "solid 1px white", justifyContent: "start", padding: "16.5px 14px" }}
          onClick={toggleDrawer('right', true)} sx={{ mt: 3 }}> Анонимно </CustomButton>

          :
          <div onClick={toggleDrawer('right', true)} style={{ display: "block" }}>
            <IconButton onClick={toggleDrawer('right', true)}
              aria-label="delete" style={{ height: "fit-content" }} >
              <ErrorOutlineIcon fontSize="small" style={{ transform: "rotate(180deg)" }} />
            </IconButton>

            <Typography style={{
              marginRight: "20px",
              textAlign: "center",
              color: "#abb8c4",
              fontSize: "16px",
              display: "contents"
            }}>
              Подробнее...
            </Typography>

          </div>
        }
        <Drawer anchor="right" open={state.right} onClose={toggleDrawer('right', false)}>
          {list('right')}
          {/* 56456 */}
          <PinCode />
        </Drawer>

      </React.Fragment>
    </>
  );
}