import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  root: {
    height: 15,
    width: 15,
    cursor: 'pointer',
    border: 0,
    background: 'none',
    padding: 0,
  },
  root24: {
    height: 25,
    width: 25,
    cursor: 'pointer',
    border: 0,
    background: 'none',
    padding: 0,
  },
  dot: {
    backgroundColor: '#fff',
    height: 6,
    width: 6,
    borderRadius: 6,
    // margin: 3,
    border: "solid #C4C4C4 1px"
  },
  dotPin: {
    backgroundColor: '#fff',
    height: 12,
    width: 12,
    borderRadius: 6,
    margin: "auto",
    // margin: 6,
    // padding: 5,
    border: "solid #C4C4C4 1px"
  },
  active: {
    border: "none",
    backgroundColor: "#79908E"
  },
  activePin: {
    border: "none",
    backgroundColor: "#79908E"
  },
};

class PaginationDot extends React.Component {
  handleClick = event => {
    this.props.onClick(event, this.props.index);
  };

  render() {
    const { active, pin } = this.props;

    let styleDot;

    if (active) {
      styleDot = pin ? { ...styles.dotPin, ...styles.activePin } : { ...styles.dot, ...styles.active };
    } else {
      styleDot = pin ? styles.dotPin : styles.dot;
    }

    return (
      <button type="button" style={pin ? styles.root24 : styles.root} onClick={!pin && this.handleClick}>
        <div style={styleDot} />
      </button>
    );
  }
}

PaginationDot.propTypes = {
  active: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  pin: PropTypes.bool
};

export default PaginationDot;