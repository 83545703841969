/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { MenuBook } from '@material-ui/icons';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Box, Divider, styled, Paper, Stack, Badge, IconButton, Drawer } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { NavLink } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { isMobile } from 'src/_validations';

const StyledBadge = withStyles((theme) => ({
  badge: { right: -3, top: 13, border: `2px solid ${theme.palette.background.paper}`, padding: '0 4px' },
}))(Badge);

export default function ModalDescription({ value, openModal, setOpenModal }) {
  const [open, setOpen] = React.useState(openModal);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenModal(false)
  };

  useEffect(() => {
    setOpen(openModal)
    // if ( serviceInBasket.length === 0 ) {
    //   handleClose()
    // }
  }, [openModal])


  const Item = styled(Paper)(({ theme, position }) => ({
    ...theme.typography.body2,
    // padding: theme.spacing(1),
    textAlign: position,
    color: theme.palette.text.secondary,
    // fontWeight: position === "center" && "bold",
    // fontSize: isMobile ? "calc(0.5em + 1vw)" : "calc(0.1em + 1vw)",
    fontSize: isMobile && "calc(0.6em + 1vw)" ,
    // backgroundColor: position === "center" && "#ebf8f2", 
    // width: position === "center" && "7em",
    borderRadius: "20px"
  }));

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
 
  return (
    // <Dialog  maxWidth="lg"  fullScreen={ !!isMobile }  open={open}  onClose={handleClose}  scroll={scroll}  aria-labelledby="scroll-dialog-title"  aria-describedby="scroll-dialog-description" >
    <Drawer anchor="right"
      // style={{ minWidth: "500px", maxWidth: "808px" }}
      // maxWidth="lg"
      // fullScreen={ !!isMobile }
      open={open}
      onClose={handleClose}
      style={{ width: isMobile ? '100vw' : '640px' }}
    // scroll={scroll}
    // aria-labelledby="scroll-dialog-title"
    // aria-describedby="scroll-dialog-description"
    >
      <DialogTitle style={{ display: "flex", justifyContent: "space-between",  alignItems: "center" }} id="scroll-dialog-title">
        {value && value?.Title}
        <IconButton aria-label="close" onClick={handleClose} style={{ float: "right" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers={scroll === 'paper'} style={{ width: isMobile ? '100vw' : '640px' }}>
          <DialogContentText id="scroll-dialog-description"  ref={descriptionElementRef}  tabIndex={-1} >
            <Typography style={{paddingTop: "5px"}} >
            {value?.Description ? <div dangerouslySetInnerHTML={{ __html: value?.Description }} /> : "нет описания"}
            </Typography>  
          </DialogContentText>
      </DialogContent>
      <DialogActions style={{ background: "#f4f6f8" }} >
        {/* { type === "service"  &&  <Button style={{ borderRadius: "20px" }} variant="contained" disabled={ serviceInBasket.length < 1} >  </Button> }  */}
        {/* <Button style={{ borderRadius: "20px" }} variant="outlined" color="error" onClick={handleClose}> Закрыть </Button> */}
        {/* <Button variant="contained" onClick={handleClose}>Добавить</Button> */}
      </DialogActions>
    </Drawer>

  );
}

