/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import listFill from '@iconify/icons-eva/list-fill';
import bookFill from '@iconify/icons-eva/book-fill';
import pinFill from '@iconify/icons-eva/pin-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
// bxs-book-heart qrcode-outlined border-verticle-outlined
import scaner from '@iconify/icons-ant-design/border-verticle-outlined';
import qrcode from '@iconify/icons-ant-design/qrcode-outlined';
import shake from '@iconify/icons-eva/shake-outline';
import serviceFill from '@iconify/icons-ri/service-fill';
import { isMobile } from 'src/_validations';

// import storageSolid from '@iconify/icons-clarity/storage-solid';
// import historyOutlined from '@iconify/icons-ant-design/history-outlined';
import lockFill from '@iconify/icons-eva/lock-fill';
// import personAddFill from '@iconify/icons-eva/person-add-fill';
// import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;


const account = localStorage.getItem('account')
const vip = localStorage.getItem('vip')

export const sidebarConfig = isMobile ? [
  {
    title: 'Услуги',
    path: '/dashboard/service',
    icon: getIcon(serviceFill)
  },  
  {
    title: 'Лаборатории',
    path: '/dashboard/laboratories/map',
    icon: getIcon(pinFill)
  },
  {
    title: 'Статус заказа',
    path: '/dashboard/status',
    icon: getIcon(shake)
  },
  {
    title: 'Ваш QR-код',
    path: '/dashboard/scaner',
    icon: getIcon(qrcode)
  },
  {
    title: 'Генетическая карта',
    path: '/dashboard/app',
    icon: getIcon(bookFill)
  },
  {
    title: 'Список паспортов',
    path: '/dashboard/listPassports',
    icon: getIcon(listFill)
  },
  {
    title: 'История платежей',
    path: '/dashboard/historyPay',
    icon: getIcon(shoppingBagFill)
  },
  // {
  //   title: 'Генетическое заключение',
  //   path: '/dashboard/listPassports',
  //   icon: getIcon(bookFill)
  // },
  // {
  //   title: 'Место для хранения генетического паспорта',
  //   path: '/dashboard/storage',
  //   icon: getIcon(storageSolid)
  // },
  // {
  // title: 'Выйти',
  // path: '/login',
  // icon: getIcon(lockFill)
  // }
] : [
  {
    title: 'Услуги',
    path: '/dashboard/service',
    icon: getIcon(serviceFill)
  },
  {
    title: 'Лаборатории',
    path: '/dashboard/laboratories/map',
    icon: getIcon(pinFill)
  },
  {
    title: 'Статус заказа',
    path: '/dashboard/status',
    icon: getIcon(shake)
  },
  {
    title: 'Ваш QR-код',
    path: '/dashboard/scaner',
    icon: getIcon(qrcode)
  },
  {
    title: 'Генетическая карта',
    path: '/dashboard/app',
    icon: getIcon(bookFill)
  },
  {
    title: 'Список паспортов',
    path: '/dashboard/listPassports',
    icon: getIcon(listFill)
  },
  {
    title: 'История платежей',
    path: '/dashboard/historyPay',
    icon: getIcon(shoppingBagFill)
  },
  // {
  //   title: 'Место для хранения генетического паспорта',
  //   path: '/dashboard/storage',
  //   icon: getIcon(storageSolid)
  // },
  // {
  //   title: 'products',
  //   path: '/dashboard/products',
  //   icon: getIcon(shoppingBagFill)
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon(fileTextFill)
  // },
  // {
  //   title: 'Выйти',
  //   path: '/login',
  //   icon: getIcon(lockFill)
  //   },
];

// export default sidebarConfig;

export const sidebarConfigDoc = isMobile ? [
  {
    title: 'Сканер',
    path: '/dashboard/scaner',
    icon: getIcon(scaner)
  },

]  :  [
      {
        title: 'Сканер',
        path: '/dashboard/scaner',
        icon: getIcon(scaner)
      },

    ] 

    export const sidebarConfigGuest = isMobile ? [
      {
        title: 'Сканер',
        path: '/dashboard/scaner',
        icon: getIcon(scaner)
      },    
    ]  :  [
          {
            title: 'Сканер',
            path: '/dashboard/scaner',
            icon: getIcon(scaner)
          },
        ] 
  