    const health1 = { id: 0, numberType: 1, type: "middle", title: "Мигрень", description: `Мигрень — сильная головная боль, которая часто сопровождается нарушением зрения, тошнотой и рвотой. Первые приступы обычно начинаются в 30 лет, с увеличением возраста растет заболеваемость. Чаще наблюдается у женщин.
    Существует две основные формы мигрени: мигрень с aypoй и без ауры. Аурой называется группа симптомов, главным образом зрительных, которые появляются перед началом головной боли. Мигрень с аурой наблюдается примерно в 1 из 5 случаев мигрени.
    Некоторые люди переживают обе формы мигрени в различные моменты времени. Иногда мигрень может быть семейным заболеванием.`, 
    content: `<div><div style="font-family: OpenSans-Regular_0;font-weight: normal;font-size: calc(1em + 1.5vw);color: rgb(204, 153, 0);text-align: center;margin-bottom: 30px;/* margin: 0px 50px 25px 50px; */">У Вас выявлен повышенный риск
    развития мигрени.</div><div id="root"><div><div style="margin: 0px 20px 10px 30px;">
    <div style="margin-right: 120px; overflow-wrap: break-word; display: flex; flex-direction: column-reverse; width: 100%;"><div style="justify-content: center; align-items: center; top: 0px; position: relative;"><div style="font-family: OpenSans-Regular_0;font-weight: normal;font-size: calc(1em + 1.5vw);color: rgb(0, 107, 163);background-color: rgb(255, 255, 255);text-align: center;">Проявление заболевания  и самодиагностика:</div><table class="MuiTable-root" aria-label="customized table"></table></div></div><div style="/* margin-left: 23px; *//* width: 98%; *//* display: grid; *//* grid-template-columns: 780px 510px; */"><div style="/* margin-right: 120px; */overflow-wrap: break-word;"><div style="justify-content: center; align-items: center; top: 0px; position: relative;"><div style="font-family: OpenSans-Light; font-weight: normal; font-size: 18pt; color: rgb(0, 0, 0); text-align: justify;"></div><table class="MuiTable-root" aria-label="customized table"></table><table style="/* border-collapse: collapse; *//* width: 660px; */" border="0">
    <tbody>
    <tr>
    <td style="width: 98.9551%;text-align: justify;">
    <p style="line-height: 1.3;font-size: (1em + 1vcalcw);"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;"><span style="font-family: OpenSans-Bold; font-weight: bold; font-style: normal;"><strong>Мигрень </strong></span> — сильная головная боль, которая часто</span><span style="font-family: OpenSans-Light; font-weight: 300; font-style: normal;"><span style="">сопровождается нарушением зрения, тошнотой и рвотой.</span><span style=""> Первые приступы обычно начинаются в 30 лет, </span><span style="">с увеличением возраста растет заболеваемость. Чаще </span><span style="">наблюдается у женщин.</span></span><br><span style="font-family: OpenSans-Light; font-weight: 300; font-style: normal;"><span style="">Существует две основные формы мигрени: мигрень </span><span style="">с aypoй и без ауры. Аурой называется группа симпто</span><span style="">мов, главным образом зрительных, которые появля</span><span style="">ются перед началом головной боли. Мигрень с аурой </span><span style="">наблюдается примерно в 1 из 5 случаев мигрени.</span></span><br><span style="font-family: OpenSans-Light; font-weight: 300; font-style: normal;"><span style="">Некоторые люди переживают обе формы мигрени </span><span style="">в различные моменты времени. </span><span style="">Иногда мигрень может быть семейным заболеванием.</span></span></p>
    </td>
    </tr>
    </tbody>
    </table></div></div><div style="justify-content: center;align-items: center;top: 0px;position: relative;font-size: calc(1em + 1vw);"><div style="font-family: OpenSans-Light;font-weight: normal;color: rgb(0, 0, 0);font-size: calc(1em + 1vw);text-align: justify;"></div><table class="MuiTable-root" aria-label="customized table"></table><table style="/* border-collapse: collapse; *//* width: 660px; */" border="0">
    <tbody>
    <tr>
    <td style="width: 98.8921%; line-height: 1.3;">
    <p style="text-align: justify;line-height: 1.3;/* font-size: calc(1em + 1vw); */"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;"><span style="font-family: OpenSans-Bold; font-weight: bold; font-style: normal;"><strong>Ген </strong><strong>LRP</strong><strong>1</strong></span> кодирует рецепторы, которые участвуют в передаче и модуляции нервного импульса.</span></p>
    </td>
    </tr>
    </tbody>
    </table></div></div><div style="justify-content: center; align-items: center; top: 0px; position: relative;"><br><div style="font-family: OpenSans-Regular_0;font-weight: normal;font-size: calc(1em + 1.5vw);color: rgb(0, 107, 163);background-color: rgb(255, 255, 255);text-align: center;">Факторы, влияющие на развитие  заболевания:</div><table class="MuiTable-root" aria-label="customized table"></table></div><div style="justify-content: center;align-items: center;top: 0px;position: relative;/* font-size: calc(1em + 1vw); */"><div style="font-family: OpenSans-Light; font-weight: normal; font-size: 18pt; color: rgb(0, 0, 0); text-align: justify;"></div><table class="MuiTable-root" aria-label="customized table"></table><table style="/* border-collapse: collapse; *//* width: 660px; */" border="0">
    <tbody>
    <tr>
    <td style="width: 98.8921%;/* font-size: calc(1em + 1vw); */">
    <ul style="font-size: calc(1em + 1vw);">
    <li style="color: #e41f26;">
    <div style="color: black;padding-left: 10px;line-height: 1.3;text-align: left;/* font-size: calc(1em + 1vw); */"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">нервное и физическое перенапряжение;</span></div>
    </li>
    <li style="color: #e41f26; line-height: 1.3; text-align: left;">
    <div style="color: black; padding-left: 10px;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">некоторые пищевые продукты (сыр, шоколад, красное вино);</span></div>
    </li>
    <li style="color: #e41f26; line-height: 1.3; text-align: left;">
    <div style="color: black; padding-left: 10px;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">алкоголь;</span></div>
    </li>
    <li style="color: #e41f26; line-height: 1.3; text-align: left;">
    <div style="color: black; padding-left: 10px;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">прием гормональных контрацептивов;</span></div>
    </li>
    <li style="color: #e41f26; line-height: 1.3; text-align: left;">
    <div style="color: black; padding-left: 10px;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">менструация;</span></div>
    </li>
    <li style="color: #e41f26; line-height: 1.3; text-align: left;">
    <div style="color: black; padding-left: 10px;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">недостаток или избыток сна;</span></div>
    </li>
    <li style="color: #e41f26;">
    <div style="color: black; padding-left: 10px; line-height: 1.3; text-align: left;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">погодные факторы.</span></div>
    </li>
    </ul>
    </td>
    </tr>
    </tbody>
    </table></div><div style="overflow-wrap: break-word;"><div style="justify-content: center; align-items: center; top: 0px; position: relative;"><div style="font-family: OpenSans-Light;font-weight: normal;color: rgb(0, 0, 0);text-align: justify;"></div><table class="MuiTable-root" aria-label="customized table"></table><table style="/* border-collapse: collapse; *//* width: 510px; */" border="0">
    <tbody>
    <tr>
    <td style="width: 98.9551%;">
    <ul style="">
    <li style="color: #e41f26;line-height: 1.3;font-size: calc(1em + 1vw);">
    <div style="color: black;padding-left: 10px;text-align: justify;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;"><span style="">боли при мигрени чаще возникают </span><span style="">с одной стороны головы, но иногда бы</span><span style="">вают двухсторонними. Боль может охва</span><span style="">тывать верхнюю челюсть, глаза и шею, </span><span style="">пульсировать и усиливаться от сильных </span><span style="">запахов и громких звуков; </span></span></div>
    </li>
    <li style="color: #e41f26;line-height: 1.3;font-size: calc(1em + 1vw);">
    <div style="color: black;padding-left: 10px;text-align: justify;"><span style="font-family: OpenSans-Light; font-weight: 300; font-style: normal;"><span style="">часто во время приступа появляется </span><span style="">тошнота, чувство тяжести и спазмы </span><span style="">в желудке, рвота;</span></span></div>
    </li>
    <li style="color: #e41f26;text-align: justify;line-height: 1.3;/* font-size: calc(1em + 1vw); */font-size: calc(1em + 1vw);">
    <div style="color: black;padding-left: 10px;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">нередко возникает головокружение и нарушение ориентации в пространстве;</span></div>
    </li>
    <li style="color: #e41f26;text-align: justify;line-height: 1.3;font-size: calc(1em + 1vw);">
    <div style="color: black; padding-left: 10px;"><span style="font-family: OpenSans-Light; font-weight: 300; font-style: normal;"><span style="">во время приступа может возникать раздражительность и подавленное настро</span><span style="">ение, присутствовать возбуждение или, наоборот, сонливость;</span></span></div>
    </li>
    <li style="color: #e41f26;font-size: calc(1em + 1vw);">
    <div style="color: black;padding-left: 10px;text-align: justify;line-height: 1.3;"><span style="font-family: OpenSans-Light;/* font-size: 18pt; */font-weight: 300;font-style: normal;">у некоторых людей, страдающих мигренью, перед приступом могут появиться предвестники (аура). Они свидетельствуют о скором начале сильной головной боли. В основном это туман перед глазами, головокружение, зрительные, тактильные и слуховые галлюцинации, нарушения координации и речи, плаксивость.</span></div>
    </li>
    </ul>
    </td>
    </tr>
    </tbody>
    </table></div></div><div style="justify-content: center; align-items: center; top: 0px; position: relative;"><br><div style="font-family: OpenSans-Regular_0;font-weight: normal;font-size: calc(1em + 1.5vw);color: rgb(0, 107, 163);background-color: rgb(255, 255, 255);text-align: center;">Предупреждающие меры  и профилактика:</div><table class="MuiTable-root" aria-label="customized table"></table></div><div style="/* margin-left: 23px; */justify-content: center;align-items: center;top: 0px;position: relative;"><div style="font-family: OpenSans-Light; font-weight: normal; font-size: 18pt; color: rgb(0, 0, 0); text-align: justify;"></div><table class="MuiTable-root" aria-label="customized table"></table><table style="/* border-collapse: collapse; *//* width: 510px; */" border="0">
    <tbody>
    <tr>
    <td style="width: 98.9551%; text-align: justify;">
    <p style="line-height: 1.3;font-size: calc(1em + 1vw);"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">Существуют два подхода к лечению мигрени: профилактическая терапия и снятие болевого синдрома во время приступа.</span></p>
    </td>
    </tr>
    </tbody>
    </table></div></div><div></div><div></div></div></div>
    </div>
    `, }

    const health2 = { id: 1, numberType: 1, type: "middle", title: "Гипотиреоз", description: `Гипотиреоз — состояние, при котором снижается функция щитовидной железы. Это одно из самых часто встречаемых заболеваний щитовидной железы. Особенно часто оно встречается в йододефицитных регионах, то есть вдали от моря — горные районы, в том числе Урал, Кавказ, степи, равнины.
    Наиболее богаты йодом: морская рыба, морепродукты, морская капуста, чукка.`, 
    content: `<div><div class="css-o7d50o"><div style="display: flex;justify-content: center;align-items: center;top: 0px;position: relative;/* margin-left: 150px; *//* margin-right: 100px; */margin: -90px 0px 9px 0px;"><div style="font-family: OpenSans-Regular_0;font-weight: normal;font-size: calc(1em + 1.5vw);color: rgb(204, 153, 0);text-align: center;margin-bottom: 30px;/* margin: 0px 50px 25px 50px; */">У Вас выявлен повышенный риск
    развития гипотиреоза.</div><table class="MuiTable-root" aria-label="customized table"></table></div><div><div style="
    margin: 0px 20px 10px 30px;"><div><div>
    
    <div style="margin-right: 120px; overflow-wrap: break-word; display: flex; flex-direction: column-reverse; width: 100%;"><div style="justify-content: center; align-items: center; top: 0px; position: relative;"><div style="font-family: OpenSans-Regular_0;font-weight: normal;font-size: calc(1em + 1.5vw);color: rgb(0, 107, 163);background-color: rgb(255, 255, 255);text-align: center;">Проявление заболевания  и самодиагностика:</div><table class="MuiTable-root" aria-label="customized table"></table></div></div><div style="/* margin-left: 23px; *//* width: 98%; *//* display: grid; *//* grid-template-columns: 780px 510px; *//* text-align: justify; */"><div style="/* margin-right: 120px; */overflow-wrap: break-word;/* text-align: right; */"><div style="justify-content: center; align-items: center; top: 0px; position: relative;"><div style="font-family: OpenSans-Light;font-weight: normal;font-size: 18pt;color: rgb(0, 0, 0);text-align: justify;"></div><table class="MuiTable-root" aria-label="customized table"></table><table style="/* border-collapse: collapse; *//* width: 660px; */" border="0">
    <tbody>
    <tr>
    <td style="width: 98.9551%;text-align: justify;">
    <p style="/* text-align: justify; */line-height: 1.3;font-size: calc(1em + 1vw);"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;"><span style="font-family: OpenSans-Bold; font-weight: bold; font-style: normal;">Гипотиреоз</span> — состояние, при котором снижается функция щитовидной железы. <span style="font-family: OpenSans-Bold; font-weight: bold; font-style: normal;">Это одно из самых часто встречаемых заболеваний щитовидной железы.</span> Особенно часто оно встречается в йододефицитных регионах, то есть вдали от моря — горные районы, в том числе Урал, Кавказ, степи, равнины.</span><br><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;"><span style="font-family: OpenSans-Bold; font-weight: bold; font-style: normal;"><strong>Наиболее богаты йодом: </strong></span>морская рыба, морепродукты, морская капуста, чукка.</span></p>
    </td>
    </tr>
    </tbody>
    </table></div></div><div style="justify-content: center; align-items: center; top: 0px; position: relative;"><div style="font-family: OpenSans-Light;font-weight: normal;color: rgb(0, 0, 0);text-align: justify;"></div><table class="MuiTable-root" aria-label="customized table"></table><table style="/* border-collapse: collapse; *//* width: 660px; */" border="0">
    <tbody>
    <tr>
    <td style="width: 98.8921%;text-align: justify;font-size: calc(1em + 1vw);">
    <p style="line-height: 1.3;"><span style="font-family: OpenSans-Bold; font-weight: bold; font-style: normal;"><span style="">Ген FOXE1</span></span><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;"> кодирует транскрипционный фактор щитовидной железы, который ответственен за развитие щитовидной железы в детстве, а также за поддержание ее работы во взрослом возрасте.</span></p>
    </td>
    </tr>
    </tbody>
    </table></div></div><div style="justify-content: center; align-items: center; top: 0px; position: relative;"><table class="MuiTable-root" aria-label="customized table"></table></div><div style="position: relative; margin-top: 16px; padding-left: 0px; padding-right: 2px; width: 100%;"></div><div style="justify-content: center; align-items: center; top: 0px; position: relative;"><div style="font-family: OpenSans-Regular_0;font-weight: normal;font-size: calc(1em + 1.5vw);color: rgb(0, 107, 163);background-color: rgb(255, 255, 255);text-align: center;">Факторы, влияющие на развитие  заболевания:</div><table class="MuiTable-root" aria-label="customized table"></table></div><div style="justify-content: center;align-items: center;top: 0px;/* margin-left: 27px; */position: relative;"><div style="font-family: OpenSans-Light;font-weight: normal;font-size: 18pt;color: rgb(0, 0, 0);text-align: justify;"></div><table class="MuiTable-root" aria-label="customized table"></table><table style="/* border-collapse: collapse; *//* width: 660px; */font-size: calc(1em + 1vw);overflow-wrap: break-word;" border="0">
    <tbody>
    <tr>
    <td style="width: 98.9551%;">
    <ul>
    <li style="color: #e41f26;">
    <div style="color: black;padding-left: 10px;line-height: 1.3;text-align: left;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">генетические особенности;</span></div>
    </li>
    <li style="color: #e41f26; line-height: 1.3; text-align: left;">
    <div style="color: black; padding-left: 10px;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">проживание в йододефицитных регионах;</span></div>
    </li>
    <li style="color: #e41f26; line-height: 1.3; text-align: left;">
    <div style="color: black; padding-left: 10px;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">беременность;</span></div>
    </li>
    <li style="color: #e41f26;">
    <div style="color: black; padding-left: 10px; line-height: 1.3; text-align: left;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">прием некоторых лекарственных препаратов.</span></div>
    </li>
    </ul>
    </td>
    </tr>
    </tbody>
    </table></div><div style="overflow-wrap: break-word;"><div style="justify-content: center; align-items: center; top: 0px; position: relative;"><div style="font-family: OpenSans-Light; font-weight: normal; font-size: 18pt; color: rgb(0, 0, 0); text-align: justify;"></div><table class="MuiTable-root" aria-label="customized table"></table><table style="" border="0">
    <tbody>
    <tr>
    <td style="width: 98.9551%;">
    <ul style="font-size: calc(1em + 1vw);">
    <li style="color: #e41f26;">
    <div style="color: black; padding-left: 10px; line-height: 1.3; text-align: left;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">общая слабость;</span></div>
    </li>
    <li style="color: #e41f26; line-height: 1.3; text-align: left;">
    <div style="color: black; padding-left: 10px;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">вялость;</span></div>
    </li>
    <li style="color: #e41f26; line-height: 1.3; text-align: left;">
    <div style="color: black; padding-left: 10px;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">сонливость;</span></div>
    </li>
    <li style="color: #e41f26; line-height: 1.3; text-align: left;">
    <div style="color: black; padding-left: 10px;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">снижение умственных способностей;</span></div>
    </li>
    <li style="color: #e41f26; line-height: 1.3; text-align: left;">
    <div style="color: black; padding-left: 10px;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">снижение физической активности;</span></div>
    </li>
    <li style="color: #e41f26; line-height: 1.3; text-align: left;">
    <div style="color: black; padding-left: 10px;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">сухость кожи;</span></div>
    </li>
    <li style="color: #e41f26;">
    <div style="color: black; padding-left: 10px; line-height: 1.3; text-align: left;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">выпадение волос.</span></div>
    </li>
    </ul>
    </td>
    </tr>
    </tbody>
    </table></div></div><div style="justify-content: center; align-items: center; top: 0px; position: relative;"><br><div style="font-family: OpenSans-Regular_0;font-weight: normal;font-size: calc(1em + 1.5vw);color: rgb(0, 107, 163);background-color: rgb(255, 255, 255);text-align: center;">Предупреждающие меры  и профилактика:</div><table class="MuiTable-root" aria-label="customized table"></table></div>
    <div style="justify-content: center; align-items: center; top: 0px; position: relative;"><div style="font-family: OpenSans-Light; font-weight: normal; font-size: 18pt; color: rgb(0, 0, 0); text-align: justify;"></div><table class="MuiTable-root" aria-label="customized table"></table><table style="border-collapse: collapse;/* width: 510px; */" border="0">
    <tbody>
    <tr>
    <td style="width: 98.8921%;text-align: justify;font-size: calc(1em + 1vw);">
    <p style="/* margin-left: 23px; */line-height: 1.3;/* font-size: calc(1em + 1vw); */"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">Нет специфических мер профилактики. Люди с повышенным риском гипотиреоза должны регулярно проходить обследование функции щитовидной железы.</span></p>
    </td>
    </tr>
    </tbody>
    </table>
    </div></div></div></div></div></div></div>` }

    const health3 = { id: 2, numberType: 2, type: "high", title: "Бронхиальная астма", description: `Бронхиальная астма — это хроническое неинфекционное заболевание дыхательных путей, которое сопровождается сужением бронхов из-за мышечного спазма, что приводит к одышке, удушью, кашлю.`, 
    content: `<div><div style="font-family: OpenSans-Regular_0;font-weight: normal;font-size: calc(1em + 1.5vw);color: #ee8366;text-align: center;margin-bottom: 30px;/* margin: 0px 50px 25px 50px; */">У Вас выявлен повышенный риск развития
    бронхиальной астмы.</div>
    <div id="root">
    <div style="margin-right: 120px; overflow-wrap: break-word; display: flex; flex-direction: column-reverse; width: 100%;"><div style="justify-content: center; align-items: center; top: 0px; position: relative;"><div style="font-family: OpenSans-Regular_0;font-weight: normal;font-size: calc(1em + 1.5vw);color: rgb(0, 107, 163);background-color: rgb(255, 255, 255);text-align: center;">Проявление заболевания  и самодиагностика:</div><table class="MuiTable-root" aria-label="customized table"></table></div></div><div style="/* margin-left: 30px; *//* width: 98%; *//* display: grid; *//* grid-template-columns: 780px 510px; */"><div style="/* margin-right: 120px; */overflow-wrap: break-word;"><div style="justify-content: center; align-items: center; top: 0px; position: relative;"><div style="font-family: OpenSans-Light; font-weight: normal; font-size: 18pt; color: rgb(0, 0, 0); text-align: justify;"></div><table class="MuiTable-root" aria-label="customized table"></table><table style="/* border-collapse: collapse; *//* width: 660px; */" border="0">
    <tbody>
    <tr>
    <td style="width: 98.9551%;text-align: justify;font-size: calc(1em + 1vw);">
    <p style="line-height: 1.3;margin: 6px 20px 10px 30px;"><span style="font-family: OpenSans-Light; font-weight: 300; font-style: normal;"><span style=""><span style="font-family: OpenSans-Bold; font-weight: bold; font-style: normal;"><strong>Бронхиальная астма</strong></span><strong> </strong><strong> </strong> — это хроническое неинфек</span><span style="">ционное заболевание дыхательных путей, которое </span><span style="">сопровождается сужением бронхов из-за мышечного </span><span style="">спазма, что приводит к одышке, удушью, кашлю.</span></span></p>
    </td>
    </tr>
    </tbody>
    </table></div></div><div style="justify-content: center; align-items: center; top: 0px; position: relative;"><div style="font-family: OpenSans-Light; font-weight: normal; font-size: 18pt; color: rgb(0, 0, 0); text-align: justify;"></div><table class="MuiTable-root" aria-label="customized table"></table><table style="" border="0">
    <tbody>
    <tr>
    <td style="width: 98.9551%;text-align: justify;font-size: calc(1em + 1vw);">
    <p style="line-height: 1.3;margin: 6px 20px 10px 30px;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;"><span style="font-family: OpenSans-Bold; font-weight: bold; font-style: normal;"><strong>Ген GSDMB</strong></span> кодирует белки на поверхности клеток, которые отвечают за чувствительность к раздражителям.</span></p>
    </td>
    </tr>
    </tbody>
    </table></div></div><div style="justify-content: center; align-items: center; top: 0px; position: relative;"><table class="MuiTable-root" aria-label="customized table"></table></div><div style="position: relative; margin-top: 16px; padding-left: 0px; padding-right: 2px; width: 100%;"></div><div style="justify-content: center; align-items: center; top: 0px; position: relative;"><div style="font-family: OpenSans-Regular_0;font-weight: normal;font-size: calc(1em + 1.5vw);color: rgb(0, 107, 163);background-color: rgb(255, 255, 255);text-align: center;">Факторы, влияющие на развитие  заболевания:</div><table class="MuiTable-root" aria-label="customized table"></table></div><div style="justify-content: center;align-items: center;top: 0px;position: relative;font-size: calc(1em + 1vw);"><div style="font-family: OpenSans-Light;font-weight: normal;color: rgb(0, 0, 0);text-align: justify;"></div><table class="MuiTable-root" aria-label="customized table"></table><table border="0" style="
    margin-left: 30px;">
    <tbody>
    <tr>
    <td style="width: 98.9551%;">
    <ul>
    <li style="color: #e41f26;">
    <div style="color: black;padding-left: 10px;line-height: 1.3;text-align: left;"><span style="font-family: OpenSans-Light; font-weight: 300; font-style: normal;"><span style="">бытовые аллергены (клещ домашней пыли, пер</span><span style="">хоть и шерсть животных);</span></span></div>
    </li>
    <li style="color: #e41f26; line-height: 1.3; text-align: left;">
    <div style="color: black; padding-left: 10px;"><span style="font-family: OpenSans-Light; font-weight: 300; font-style: normal;"><span style="">уличные аллергены (пыльца различных рас</span><span style="">тений);</span></span></div>
    </li>
    <li style="color: #e41f26; line-height: 1.3; text-align: left;">
    <div style="color: black; padding-left: 10px;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">профессиональные аллергены (формальдегид, соли хрома, никеля, марганца, синтетические полимерные материалы, компоненты лаков, смол, клеев, цементов;</span></div>
    </li>
    <li style="color: #e41f26;">
    <div style="color: black; padding-left: 10px; line-height: 1.3; text-align: left;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">табачный дым.</span></div>
    </li>
    </ul>
    </td>
    </tr>
    </tbody>
    </table></div><div style="overflow-wrap: break-word;"><div style="justify-content: center;align-items: center;top: 0px;position: relative;font-size: calc(1em + 1vw);"><div style="font-family: OpenSans-Light; font-weight: normal; font-size: 18pt; color: rgb(0, 0, 0); text-align: justify;"></div><table class="MuiTable-root" aria-label="customized table" style="
    margin-left: 30px;"></table><table style="/* border-collapse: collapse; *//* width: 510px; */margin-left: 30px;" border="0">
    <tbody>
    <tr>
    <td style="width: 98.8921%;">
    <ul>
    <li style="color: #e41f26;">
    <div style="color: black; padding-left: 10px; line-height: 1.3; text-align: left;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">затрудненное дыхания;</span></div>
    </li>
    <li style="color: #e41f26;">
    <div style="color: black; padding-left: 10px; line-height: 1.3; text-align: left;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">одышка.</span></div>
    </li>
    </ul>
    </td>
    </tr>
    </tbody>
    </table></div></div><div style="justify-content: center; align-items: center; top: 0px; position: relative;"><div style="font-family: OpenSans-Regular_0;font-weight: normal;font-size: calc(1em + 1.5vw);color: rgb(0, 107, 163);background-color: rgb(255, 255, 255);text-align: center;margin-top: 30px;">Предупреждающие меры  и профилактика:</div><table class="MuiTable-root" aria-label="customized table"></table></div><div style="justify-content: center; align-items: center; top: 0px; position: relative;"><div style="font-family: OpenSans-Light; font-weight: normal; font-size: 18pt; color: rgb(0, 0, 0); text-align: justify;"></div><table class="MuiTable-root" aria-label="customized table"></table><table style="margin-left: 30px;" border="0">
    <tbody>
    <tr>
    <td style="width: 98.9551%;">
    <ul style="font-size: calc(1em + 1vw);">
    <li style="color: #42ad49; text-align: left;">
    <div style="color: black; padding-left: 10px; line-height: 1.3; text-align: left;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">профилактика факторов риска (бытовые <br>и профессиональные аллергены);</span></div>
    </li>
    <li style="color: #42ad49; line-height: 1.3; text-align: left;">
    <div style="color: black; padding-left: 10px;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">вакцинация от гриппа и пневмонии;</span></div>
    </li>
    <li style="color: #42ad49;">
    <div style="color: black; padding-left: 10px; line-height: 1.3; text-align: left;"><span style="font-family: OpenSans-Light;font-weight: 300;font-style: normal;">лечения аллергического ринита.</span></div>
    </li>
    </ul>
    </td>
    </tr>
    </tbody>
    </table></div></div></div>` }


    // const text = "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 08:00-15:00 Вс: выходной"

    // const mutationText = []

    // text.split(" ").map( ( el, index ) => index % 2 === 0 && mutationText.push( `${el  } ${  text.split(" ")[ index + 1]}`   ) )


    const html = ( title, description ) => `<h1 style="text-align: center; color: blue"> ${title} </h1> <h2> ${description} </h2>`

    const listData = ( queue, arrNumberType, arrType, arrTitle, description ) => Array(arrTitle.length).fill("").map( ( el, idx ) =>  ({ id: `${queue}${idx}`, numberType: arrNumberType[idx], type: arrType[idx], title: arrTitle[idx],  description, content: html( description, arrTitle[idx] ) }) )

export const content = [
    { id: 111, title: "Здоровье",  chapter: "health",      blocks: [ health1, health2, health3 ] },
    { id: 222, title: "Лекарства", chapter: "medications", blocks: listData( 1, [ 1, 1, 2 ], [ "middle", "middle", "high" ], [ "Трамадол", "Метотрексат", "Варфарин" ], "medications" ) },
    { id: 333, title: "Питание",   chapter: "nutrition",   blocks: listData( 2, [ 2, 1, 2 ], [ "high", "middle", "high" ], [ "Мороженое", "Сникерс", "Банан" ], "nutrition" ) },
    { id: 444, title: "Спорт",     chapter: "sport",       blocks: listData( 3, [ 1, 1, 1 ], [ "middle", "middle", "middle" ], [ "Бег", "Отжимания", "Брусья" ], "sport" ) },
    { id: 555, title: "Красота",   chapter: "beauty",      blocks: listData( 4, [ 2, 1, 2 ], [ "high", "middle", "high" ], [ "Лицо", "Волосы", "Ногти" ], "beauty" ) },
    { id: 666, title: "Генетика",  chapter: "genetics",    blocks: listData( 5, [ 2, 1, 2 ], [ "middle", "middle", "middle" ], [ "Страна", "Раса", "Степь" ], "genetics" ) },
];




