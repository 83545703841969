/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable func-names */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/sort-comp */
/* eslint-disable react/prop-types */
import React, { Component, createRef } from 'react';
// import { inject, observer } from 'mobx-react';
// import { Menu } from 'semantic-ui-react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { BarcodeFormat, DecodeHintType, BrowserMultiFormatReader } from '@zxing/library';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import { TextField, Button, Stack } from '@material-ui/core';
// import Button from 'src/theme/overrides/Button';
// import { Button } from '@material-ui/core';
import './Scanner.css';
import { Button, IconButton, Typography } from '@material-ui/core';
// import ButtonBack from './ButtonBack';

const hints = new Map();
const formats = [BarcodeFormat.QR_CODE];
hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
const codeReader = new BrowserMultiFormatReader(hints);

// const navigate = useNavigate()
// @inject('actions')
// @observer
class LSScanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      track: null,
      facingBackDevices: [],
      decodeStream: null,

      code: ""
    };
    this.video = createRef();
  }

  componentDidMount() {
    this.scanBarcode();
  }

  componentDidUpdate(prevProps) {
    const { storageScanner, scannerActive } = this.props;
    if (prevProps.storageScanner !== storageScanner) {
      this.stop();
      this.scanBarcode();
    }
    if (prevProps.scannerActive !== scannerActive && !scannerActive) {
      this.stop();
    }
    if (prevProps.scannerActive !== scannerActive && scannerActive) {
      this.scanBarcode();
    }
  }

  componentWillUnmount() {
    this.stop();
  }

  setCamera = (deviceId) => {
    this.stop();
    this.scanBarcode(deviceId);
  };


  scanBarcode = async (deviceId) => {
    const videoInputDevices = await navigator?.mediaDevices.enumerateDevices();
    const facingBackDevices = videoInputDevices.filter((cam) => cam.label?.includes('back'));
    this.setState({ facingBackDevices: videoInputDevices });

    const chosenDeviceId = deviceId || facingBackDevices[facingBackDevices.length - 1]?.deviceId || videoInputDevices[0]?.deviceId;
    const constraints = {
      video: {
        zoom: true,
        deviceId: chosenDeviceId,
        width: { min: 720, ideal: 1000 },
        height: { min: 720, ideal: 1000 },
        frameRate: { ideal: 30 },
        facingMode: { ideal: 'environment' },
      },
      audio: false,

    };


    // navigator.mediaDevices.getUserMedia = (navigator?.mediaDevices?.getUserMedia ||
    //   navigator.mediaDevices.webkitGetUserMedia ||
    //   navigator.mediaDevices.mozGetUserMedia ||
    //   navigator.mediaDevices.msGetUserMedia);

    navigator?.mediaDevices?.getUserMedia(constraints).then((stream) => {
      const track = stream.getVideoTracks()[0];
      // permissiongiven = 1
      const capabilities = track.getCapabilities();

      if (capabilities.brightness) {
        const {min} = capabilities.brightness;
        track.applyConstraints({ brightness: { ideal: min / 2 + min / 4 } });
      }
      if (capabilities.contrast) {
        const {max} = capabilities.contrast;
        track.applyConstraints({ contrast: { ideal: max } });
      }
      if (capabilities.sharpness) {
        track.applyConstraints({ sharpness: { ideal: 3 } });
      }
      if (capabilities.zoom) {
        const {max} = capabilities.zoom;
        track.applyConstraints({ zoom: { ideal: max / 2 } });
      }

      this.setState({ track });

      if(!track){
     
        this.props.onOpen(false)
      }

      codeReader.decodeFromStream(stream, 'video', (res) => {
        if (res) {
          // if (accessFunc) {
          //   accessFunc && accessFunc(res);
          // } else {
            this.handleScanResult(res);
          // }

        }
      });
    });
  };


  handleScanResult = (result) => {
    const { shouldRequestData } = this.props;

    // const navigator = useNavigate()
    
    const modifiedResult = {
      ...result,
      text: result.text && result.text.replaceAll('\u001d', ' ').trim(),
    };

    if (shouldRequestData && !shouldRequestData(modifiedResult)) return;

    // alert(result.text)
    // navigator(result.text)
    
    this.stop();
    // this.props.db.getBarcodeInfo(modifiedResult.text, BarcodeFormat[result.format]).then(() => {
      // if(result.text.indexOf('socmedica')){
        this.props.onAccess(result.text);
      // }
      
      // if (!(res && res.torgName)) this.scanBarcode();
    // });
  };

  stop() {
    codeReader.reset();
    this.setState({ track: null });
  }

  close = () => {
    const { close } = this.props;
    // this.setState({ track: "5246546" });
    close && close();
  };

  continue = () => {
    const { onProceed } = this.props;
    onProceed && onProceed();
  };

  onAccess = (result) => {
    const { onAccess } = this.props;
    onAccess && result && onAccess(result);
  };
  
  handleSetFocus = (e, { value }) => {
    const { track } = this.state;
    track.applyConstraints({ advanced: [{ focusDistance: { ideal: value } }] });
  };


  render() {
    const { track, code } = this.state;
    // const navigate = useNavigate()
    const height = window.innerHeight;
    const width = window.innerWidth;
    const smallSize = width < height ? width : height;
    const size = smallSize * 0.9;

    // const cameras = (
    //   <div vertical basic color="blue" size="small">
    //     {facingBackDevices?.map((cam) => (
    //       <Button key={cam.deviceId} size="small" style={{ fontSize: '0.8rem' }} onClick={() => this.setCamera(cam.deviceId)}>
    //         {cam.label}
    //       </Button>
    //     ))}
    //   </div>
    // );
    // function ButtonBack2(deviceId) {
    //   console.log(deviceId, "123123");
    //   // navigate(deviceId)
    // }
    // this.props.onAccess("/");
    // console.log(track, code, navigator.mediaDevices.ondevicechange);
// alert(track)
    return (
      <div className="videoview">

        {/* scaner =555666 {!!track} */}
        <IconButton className="back"  // variant="contained"
          onClick={() => this.close("/")}
        >
          <ArrowBackIcon />
          {/* Назад */}
        </IconButton>
        {/* <ButtonBack link={-1} /> */}
        <div className="label" >
          Cканируйте QR код
        </div>
        {/* <Typography>Отсканируйте QR код</Typography> */}
        <div className="videocontainer" style={{ backgroundColor: !track && "black", transition: "0.5s linear" }} >
          {/*   , border: "solid aqua 1px" */}
          <video 
            id="video"
            autoPlay
            muted
            playsInline
            ref={(ref) => {
              this.video = ref;
            }}
          />
    
        </div>
        <div
          className="hoverer parent"
          style={{
            width: size,
            height: size,
            left: -(1400 + size / 2),
            top: -(1400 - height / 2 + size / 2),
          }}
        >

          <div className="corner-box-1" />
          <div className="a a1"/>
          <div className="a a2"/>
          <div className="a a3"/>
          <div className="a a4"/>
          {/* </div> */}
        </div>

      </div>
    );
  }
}

export default LSScanner;
