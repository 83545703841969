/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack, Container, Typography } from '@material-ui/core';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Service from './Service';
import "../index.css";
import Loader from '../components/_dashboard/BlockContent/Loader';

// import { requestPrice } from "../redux/GeneBook/requestAction";
  
  export default function ServiceСontainer() {
    const { loader } = useSelector( state => state.reducerGeneBook )
    // const dispatch = useDispatch()
    // useEffect(() => {
    //   requestPrice( dispatch )
    // }, [])
    return (
     <>
      { loader ? <Loader /> : 
        <Page title="Status | ImGenius">
          <Container maxWidth="xl" style={{ height: `100%` }}>
            <Scrollbar >
                <Service />
            </Scrollbar>
          </Container>
        </Page>
      }
     </>);
  }