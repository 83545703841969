/* eslint-disable import/no-unresolved */
import * as Yup from 'yup';
import { useState } from 'react';
// import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import DialogWindow from 'src/components/DialogWindow';
import { useDispatch } from 'react-redux';
import { asyncGetRecovery, asyncGetResetPassword } from 'src/redux/Logistic/requestAction';
import { isMobile } from 'src/_validations';
import { SERVICE, KEY_DEVELOPER, PARENT_ID_FOR_REGISTRATION, KEY_FOR_RESET_PASSWORD } from "../../../constants/keys";


export default function ResetPassForm() {
  const navigate = useNavigate();
  // const [showPassword, setShowPassword] = useState(false);
  // const [email, setEmail] = useState("");
  // const [code, setCode] = useSt/ate("");
  // const [password, setPassword] = useState("");
  // const [personKey, setPersonKey] = useState("");
  // const [getAuthKey, setGetAuthKey] = useState("");
  const [answerFromServer, setAnswerFromServer] = useState("");
  // const [AnswerFromServerAuthKey, setAnswerFromServerAuthKey] = useState("");
  const [open, setOpen] = useState(true);

  const RegisterSchema = Yup.object().shape({
    // firstName: Yup.string()
    //   .min(2, 'Слишком коротко!')
    //   .max(50, 'Слишком длинно!')
    //   .required('Требуется имя'),
    // lastName: Yup.string().min(2, 'Слишком коротко!').max(50, 'Слишком длинно!').required('Требуется фамилия'),
    email: Yup.string().email('Электронная почта должна быть действительна').required('Требуется электронная почта'),
    // password: Yup.string().min(6, 'Слишком коротко!').max(50, 'Слишком длинно!').required('Необходим пароль')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: (values, actions) => {
      sendDataForRegistration(values.email)
      actions.setSubmitting(false);
      // navigate('/dashboard', { replace: true });
    }
  });
  const dispatch = useDispatch();
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  let sendDataForRegistration = (email) => {
    // e.preventDefault();middleName,
    setOpen(true);
    asyncGetRecovery({ key: KEY_FOR_RESET_PASSWORD, service: SERVICE, email},
      dispatch, setAnswerFromServer, navigate);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>          

          <TextField variant= "outlined"
            style={{ backgroundColor: "#e8f0fe", borderRadius: "12px" }}
            InputLabelProps={{
              shrink: true, sx: { color: !isMobile && "#1e2028" }
            }}
            fullWidth
            autoComplete="username"
            type="email"
            placeholder="Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <LoadingButton
            style={{ backgroundColor: "white", color:  "#54827E", marginBottom: !isMobile && "2em" }}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Отправить
          </LoadingButton>
        </Stack>
      </Form>
      {answerFromServer && (<DialogWindow width="30%"
        // margin={"-7% 0% 0% 50%"}
        margin=""
        onClick={() => { setOpen(false) }}
        // AnswerFromServerAuthKey={AnswerFromServerAuthKey} 
        answerFromServer={answerFromServer}
        open={open} />)}
    </FormikProvider>
  );
}
