/* eslint-disable no-prototype-builtins */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-unresolved */
// /* eslint-disable react/jsx-no-undef */
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import {  useParams, useNavigate, } from 'react-router-dom';
import { useDispatch } from "react-redux";
// material
import { styled } from '@material-ui/core/styles';
import { Box,  Typography, Container,  CircularProgress, Fade } from '@material-ui/core';
// import { QrCode2 } from '@material-ui/icons';
// components
import { asyncQrLogin, asyncLogin } from 'src/redux/Logistic/requestAction';
import { getPlatform, isCordova, } from 'src/_validations';
import { KEY_DEVELOPER, SERVICE } from 'src/constants/keys';
// import { isCordova } from 'is-cordova';
import DashboardNavbar from 'src/layouts/dashboard/DashboardNavbar';
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';
// import { setChapterNow } from "../redux/GeneBook/action";

// import RegistrationMenu from "../components/_dashboard/authorizationMenu/RegistrationMenu";
// import Anonym from "../components/_dashboard/authorizationMenu/Anonym";
// import EntriesMenu from "../components/_dashboard/authorizationMenu/EntriesMenu";
// import QRScaner from "../components/_dashboard/authorizationMenu/QRScaner";

// ----------------------------------------------------------------------
const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));


export default function StartPage() {
  const dispatch = useDispatch()
  const navigate = useNavigate();

  useEffect(() => {
    // if ( localStorage.getItem("AUTHKEY") && localStorage.getItem("AUTHKEY").length > 0 ) {
    //    requestPrice( dispatch )
    //    requestOrderStatus( dispatch )
    // }
    setInterval(() => {

      if (!navigator.onLine) {
        navigate("/network")
      }
    }, 2000);

  }, [])
  
  const [open, setOpen] = useState(false);

  return (
    <RootStyle title="Start | ImGenius">
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
        <Container>
          {/* вход */}
        </Container>
    </RootStyle>
  );
}
