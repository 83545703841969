/* eslint-disable import/no-unresolved */
import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';

import { useFormik, Form, FormikProvider } from 'formik';
// import IconButton from 'src/theme/overrides/IconButton';
import { useNavigate, useParams } from 'react-router-dom';
// material
import { InputAdornment, Stack, TextField, IconButton } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import DialogWindow from 'src/components/DialogWindow';
import { useDispatch } from 'react-redux';
import { asyncGetResetPassword } from 'src/redux/Logistic/requestAction';
import { SERVICE, KEY_DEVELOPER, PARENT_ID_FOR_REGISTRATION, KEY_FOR_FORGET_PASSWORD } from "../../../constants/keys";

// ----------------------------------------------------------------------

export default function NewPassForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { code, email, service } = useParams();


  const [answerFromServer, setAnswerFromServer] = useState("");
  // const [AnswerFromServerAuthKey, setAnswerFromServerAuthKey] = useState("");
  const [open, setOpen] = useState(true);

  const RegisterSchema = Yup.object().shape({

    // lastName: Yup.string().min(2, 'Слишком коротко!').max(50, 'Слишком длинно!').required('Требуется фамилия'),
    email: Yup.string().email('Электронная почта должна быть действительна').required('Требуется электронная почта'),
    password: Yup.string().min(6, 'Слишком коротко!').max(50, 'Слишком длинно!').required('Необходим пароль')
  });

  const formik = useFormik({
    initialValues: {
      email: email || '',
      code: code || '',
      password: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: (values, actions) => {
    
      sendDataForRegistration(values.email, values.code, values.password)
      actions.setSubmitting(false);
      // navigate('/dashboard', { replace: true });
    }
  });
  const dispatch = useDispatch();
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  let sendDataForRegistration = (email, code, newPassword) => {

    setOpen(true);
    asyncGetResetPassword({ key: KEY_FOR_FORGET_PASSWORD, service: SERVICE, email, recoveryCode: code, newPassword },
      dispatch, setAnswerFromServer, navigate);
  };


  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>          
        <TextField color="success" variant="outlined" style={{backgroundColor:"#e8f0fe", borderRadius: "12px"}}
            fullWidth
            autoComplete="username"
            type="email"
            value={email}
            placeholder="Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField color="success" variant="outlined" style={{backgroundColor:"#e8f0fe", borderRadius: "12px"}}
            fullWidth
            autoComplete="username"
            // type="email"
            value={code}
            placeholder="Код для восстановления"
            {...getFieldProps('code')}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
          />
        <TextField
            fullWidth color="success" variant="outlined"
            // autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            placeholder="Новый пароль"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        {/* <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Подтверждение пароля"
            {...getFieldProps('confirm')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.confirm && errors.confirm)}
            helperText={touched.confirm && errors.confirm}
          /> */}
       

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Отправить
          </LoadingButton>
        </Stack>
      </Form>
      {answerFromServer && (<DialogWindow width="30%"
        // margin={"-7% 0% 0% 50%"}
        margin=""
        onClick={() => { setOpen(false) }}
        // AnswerFromServerAuthKey={AnswerFromServerAuthKey} 
        answerFromServer={answerFromServer}
        open={open} />)}
    </FormikProvider>
  );
}
