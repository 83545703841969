/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { MenuBook } from '@material-ui/icons';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, Typography, styled, Paper, Badge, TextField, IconButton, Fab, InputAdornment
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
// import {  } from '@material-ui/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SendIcon from '@material-ui/icons/Send';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { isMobile } from 'src/_validations';
import Message from 'src/components/_dashboard/modules/message';
import ButtonDownload from 'src/components/ButtonDownload';

const StyledBadge = withStyles((theme) => ({
  badge: { right: -3, top: 13, border: `2px solid ${theme.palette.background.paper}`, padding: '0 4px' },
}))(Badge);
const useStyles = makeStyles((theme) => ({
  root: { height: 380, transform: 'translateZ(0px)', flexGrow: 1 },
  speedDial: { position: 'absolute', bottom: theme.spacing(2), right: theme.spacing(2) },
}));

export default function ModalMailSend() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const classes = useStyles();
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  // const handleClose = () => {
  //   setOpen(false);
  //   // setOpenModal(false)
  // };

  // const Item = styled(Paper)(({ theme, position }) => ({
  //   ...theme.typography.body2,
  //   // padding: theme.spacing(1),
  //   textAlign: position,
  //   color: theme.palette.text.secondary,
  //   // fontWeight: position === "center" && "bold",
  //   // fontSize: isMobile ? "calc(0.5em + 1vw)" : "calc(0.1em + 1vw)",
  //   fontSize: isMobile && "calc(0.6em + 1vw)",
  //   // backgroundColor: position === "center" && "#ebf8f2", 
  //   // width: position === "center" && "7em",
  //   borderRadius: "20px"
  // }));

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return <>      <Fab className={classes.speedDial} color="primary" aria-label="edit" onClick={() => setOpen(true)}>
    <EditIcon />
  </Fab>
    <Dialog maxWidth="sm" fullScreen={!!isMobile} fullWidth
      open={open} onClose={() => setOpen(false)} scroll={scroll}
      aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description" >
      <DialogTitle style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} id="scroll-dialog-title">
        Служба поддержки
        <IconButton aria-label="close" onClick={() => setOpen(false)} style={{ float: "right" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={scroll === 'paper'} >
        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1} style={{ minHeight: "20em" }}>
          Ответм на ваши вопросы
          {/* <Message startsSequence="start" data={{ "userId": "qweqwe", "text": "dateTime", "dateTime": "10.11.2001" }} /> */}


          {/* <TextField color="success" variant="outlined"
            sx={{ mb: 2 }}

            fullWidth
            autoComplete="username"
            type="email"
            placeholder="Email"

          />
          <TextField fullWidth
            id="outlined-textarea"
            label="Описание"
            minRows={4}
            multiline
          /> */}


        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ display: "flex", background: "#f4f6f8" }} >
        <TextField color="success"
          variant="outlined"
          // sx={{ mb: 2 }}

          fullWidth
          autoComplete="username"
          type="text"
          placeholder="Введите сообщение"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" style={{
                marginRight: "8px",
              }}>
                {/* <IconButton onClick={() => console.log("sdfsdf")} edge="end">
                  <AttachFileIcon />
                </IconButton> */}
                <ButtonDownload type="icon" />

                <IconButton type="submit" edge="end" style={{ marginLeft: "8px" }}>
                  <SendIcon />
                </IconButton>


              </InputAdornment>
            )
          }}

        />
        {/* <Button variant="contained" onClick={() => setOpen(false)}> Отправить </Button> */}

      </DialogActions>
    </Dialog></>
}

