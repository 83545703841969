/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef } from 'react';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Divider, IconButton, Drawer, TextField, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { isMobile } from 'src/_validations';

export default function ModalDescription({ generationCSVFileToBase64, inputChoiceCSVFile, value, openModal, setOpenModal }) {
  const [open, setOpen] = React.useState(openModal);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenModal(false)
  };

  useEffect(() => {
    setOpen(openModal)
    // if ( serviceInBasket.length === 0 ) {
    //   handleClose()
    // }
  }, [openModal])

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const formData = ( caption ) => <><Grid style={{ paddingBottom: "5px ", marginTop: "1em" }}>
                                    <Grid style={{ paddingBottom: "5px" }}>
                                    { caption }
                                    </Grid>
                                    <Grid >
                                      <TextField style={{ paddingBottom: "5px" }} label="Телефон"  id="outlined-size-small" size="small" />
                                      <TextField style={{ paddingBottom: "5px" }} label="Адрес"  id="outlined-size-small" size="small" />
                                      <Button style={{ paddingBottom: "5px", marginBottom: "1em" }} size="small" variant="outlined" > Отправить заявку </Button>
                                    </Grid>
                                  </Grid>
                                  <Divider /></>
 
  return (
    // <Dialog  maxWidth="lg"  fullScreen={ !!isMobile }  open={open}  onClose={handleClose}  scroll={scroll}  aria-labelledby="scroll-dialog-title"  aria-describedby="scroll-dialog-description" >
    <Drawer anchor="right"  open={open}  onClose={handleClose}  style={{ width: isMobile ? '100vw' : '640px' }}
      // style={{ minWidth: "500px", maxWidth: "808px" }}
      // maxWidth="lg"
      // fullScreen={ !!isMobile }
      // scroll={scroll}
      // aria-labelledby="scroll-dialog-title"
      // aria-describedby="scroll-dialog-description"
    >
      <DialogTitle style={{ display: "flex",  justifyContent: "space-between",  alignItems: "center" }} id="scroll-dialog-title">
        {value && value?.Title}
          Выбор файла
        <IconButton aria-label="close" onClick={handleClose} style={{ float: "right" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers={scroll === 'paper'} style={{ width: isMobile ? '100vw' : '640px' }}>
          <DialogContentText id="scroll-dialog-description"  ref={descriptionElementRef}  tabIndex={-1} >
            <Typography style={{paddingTop: "5px"}} >
               {/* {value?.Description ? <div dangerouslySetInnerHTML={{ __html: value?.Description }} /> : "нет описания"} */}
               CSV-файл ....
            </Typography>  
            <input  style={{ margin: 15,  marginLeft: 50,  height: 30,  outline: "none" }}  ref={inputChoiceCSVFile}  
                //   style={{display: "none"}}  
                  onChange={(event) => generationCSVFileToBase64(event)}  accept=".csv, .tsv" name="image"  type="file"  id="file"  />
              <Button size="small" variant="outlined" onClick={ () => console.log()}> Оплатить </Button>
            <Typography style={{paddingTop: "5px"}} >
            <Divider />
                {formData( "VCF / gVCF-файл ...." )}
                {formData( "FASTq-файл ...." )}
                {/* {value?.Description ? <div dangerouslySetInnerHTML={{ __html: value?.Description }} /> : "нет описания"} */}
            </Typography> 
          </DialogContentText>
        </DialogContent>
      <DialogActions style={{ background: "#f4f6f8" }} >
        {/* { type === "service"  &&  <Button style={{ borderRadius: "20px" }} variant="contained" disabled={ serviceInBasket.length < 1} >  </Button> }  */}
        {/* <Button style={{ borderRadius: "20px" }} variant="outlined" color="error" onClick={handleClose}> Закрыть </Button> */}
        {/* <Button variant="contained" onClick={handleClose}>Добавить</Button> */}
      </DialogActions>
    </Drawer>
  );
}




