/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */

import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Link, Container, Typography, Button, Stack, } from '@material-ui/core';
// layouts
// import AuthLayout from '../layouts/AuthLayout';
// components
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { isMobile } from 'src/_validations';
import RegisterEmailForm from 'src/components/authentication/register/RegisterEmailForm';
import { asyncLogin } from 'src/redux/Logistic/requestAction';
import Page from '../components/Page';
// import { MHidden } from '../components/@material-extend';
import { RegisterForm } from '../components/authentication/register';
// import ModalWindows from "./ForService/ModalWindows";
import { infoCompany } from "../constants/constant";
import ModalMailSend from './ForService/ModalMailSend';
// import AuthSocial from '../components/authentication/AuthSocial';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: { display: 'flex',  flexDirection: "column",  color: "white",  width: "100%" }
}));


const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480, 
  // margin: 'auto', 
  display: 'flex', 
  // minHeight: isMobile && '100vh', 
  flexDirection: 'column', 
  justifyContent: 'end', 
  padding: theme.spacing(12, 0, 5)
}));

// function a11yProps(index) {
//   return {
//     id: `scrollable-force-tab-${index}`,
//     'aria-controls': `scrollable-force-tabpanel-${index}`
//   };
// }

export default function Register() {
  // const [value, setValue] = useState(0);
  // const [dataForModal, setDataForModal] = useState("")
  // const [openModal, setOpenModal] = useState( false )
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const [getAuthKey, setGetAuthKey] = useState("");
  const [answerFromServer, setAnswerFromServer] = useState("");
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { email } = useParams();
  // console.log(email, "email");
  return (
    <RootStyle title="Register | ImGenius">
      {/* <AuthLayout>
          <Typography sx={{ display: "contents", color: "white" }}> Уже есть аккаунт? &nbsp; </Typography>
          <Link style={{ color: "#fff" }}  underline="none" variant="subtitle2" component={RouterLink} to="/login">
            Войти
          </Link>
        </AuthLayout> */}
      <Button // startIcon={<ArrowBackIosIcon />}  
        size="small" variant="contained" style={{
          width: "max-content", position: "absolute",
          top: "1em",
          right: "1em"
        }}
        onClick={() =>
          asyncLogin({ email: "imgenius1@yopmail.com", password: "111111" }, dispatch, setAnswerFromServer, setGetAuthKey, value, navigate)}>
        Демо режим
      </Button>
      <div style={{
        background: 'url(/static/icons/logo.png) 5% 5% no-repeat fixed, linear-gradient(321.69deg, #54827E 0%, #A4B7B7 100%)',
        //  url(/example/image/animate-bg1.jpg) no-repeat fixed;
        backgroundSize: isMobile ? "35%, auto" : "20%, auto", /* Меняем масштаб */
        minHeight: "62vh",
        // background: isMobile ? 'url(/static/icons/fon.png) no-repeat 0% 0%, url(/static/icons/logo.png) no-repeat fixed' :
        //   'url(/static/icons/fon.png) no-repeat 0% 0%, url(/static/icons/logo.png) no-repeat fixed', minHeight: "65vh", backgroundSize: "cover, 20%", backgroundPosition: "center, center"
      }}>
        {/* <div style={{ background: isMobile ? 'url(/static/icons/backGroundMobileIcon.png) no-repeat 0% 0%' : 
        'url(/static/icons/backGroundDesignWeb.png) no-repeat 0% 0%', minHeight: "65vh", backgroundSize: "cover", backgroundPosition: "center" }}> */}
        {/* { isMobile && <img style={{ position: "absolute", top: 20, right: 20 }} width="80px" height="80px" alt="" src="/static/icons/imGeniusIcon.svg" /> } */}

        <Container  sx={{ minHeight: "inherit", display: "grid" }}>
          <ContentStyle>
            <Box sx={{ mb: 1 }}>
              <Typography style={{ color: "white" }} variant="h3" gutterBottom>
                Регистрация
              </Typography>
         
            </Box>
            
            <Stack spacing={2}>
            {email ? <Button startIcon={<ArrowBackIosIcon />}  
          size="small" variant="contained" style={{ width: "max-content" }} 
          onClick={() => navigate(-1)}>
            Назад
            </Button> : null}

            {email ? <RegisterForm /> : <RegisterEmailForm />}

          
              <Typography style={{ color: "white" }} variant="body2" align="left" sx={{ mt: 3 }}>
                Уже есть аккаунт?
                <Link style={{ color: "#fff" }} variant="subtitle2" component={RouterLink} to="/login">
                  &nbsp; Войти
                </Link>
              </Typography>
              </Stack>
            {/* </MHidden> */}
          </ContentStyle>
        </Container>
      </div>
      <ModalMailSend />
      <Container>
      {infoCompany}
      </Container>
    </RootStyle>
  );
}
