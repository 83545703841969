/* eslint-disable camelcase */
import { SERVER } from "./server"

const path_Back = SERVER === "dev" ? "https://service.socmedica.dev:9005/api/book/" : "https://service.socmedica.com:9005/api/book/"   // original
const path = SERVER === "dev" ? "https://cs.socmedica.com/api/" : "https://cs.socmedica.com/api/"
const path_New = "http://192.168.14.49:5000/api/"

const path_old_api = SERVER === "dev" ? "https://service.socmedica.dev:9055/api/": "https://service.socmedica.com:9055/api/"
const path_Dev = SERVER === "dev" ? "https://service.socmedica.dev:9060/" : "https://service.socmedica.com:9060/"
// const path_New = "https://service.socmedica.dev:9055/api/" https://service.socmedica.dev:9055/api/requestQRAuthorization   http://192.169.14.180:5001/
const path_search = "https://service.socmedica.dev:9018/"
const path_server = "http://192.168.14.200:9060/api/"
// ---------------------------------------------------------------------------------------------------------------------------------------------

// const path_Back = SERVER === "dev" ? "http://192.168.14.196:5000/api/book/" : "http://192.168.14.196:5000/api/book/"   // api Влад
// const path = SERVER === "dev" ? "http://192.168.14.196:5000/api/" : "http://192.168.14.196:5000/api/"

// const path_Back = SERVER === "dev" ? "https://service.socmedica.com:9005/api/book/" : "https://service.socmedica.com:9005/api/book/"
// const path = SERVER === "dev" ? "https://cs.socmedica.com/api/" : "https://cs.socmedica.com/api/"

// const path_Back = SERVER === "dev" ? "http://192.168.14.49:5000/api/book/" : "http://192.168.14.49:5000/api/book/"   // api Александр
// const path = SERVER === "dev" ? "http://192.168.14.49:5000/api/" : "http://192.168.14.49:5000/api/"

export const LIST_FUNCTIONAL =    path_Back;

export const ENTRIES_DATA =       `${path_Back  }options`;

/// /////////////////////////////////////////////////////////////////////////////////

export const DATA_OF_BOOK =       path_Back;   // original

// export const DATA_OF_BOOK =       "http://192.168.14.49:5000/api/book/"; // local

/// /////////////////////////////////////////////////////////////////////////////////
export const SEARCH =       `${path_search  }search`;


export const GUESTCREATE =       `${path  }guest/create`;
// export const ANONIMCREATE =       `${path_Dev  }requestRegistrationAnonymous`;

export const USER_INFO =          `${path  }user/getUserInfoByEmail`;
export const LAB_INFO = `${path_Dev}requestLaboratoryInformation`;


// export const QRLOGIN = `${path_DevQR}requestAccountAuthorizationQR`;

// export const REGISTRATION =       `${path  }registration/send`;


// export const CONFIRM_EMAIL =      `${path_Dev }AccountComfirmEmail`;

// export const GET_EMAIL_CONFIRM =  `${path_Dev }AccountComfirmEmailRepeat`;

export const CHECK_PERSON_KEY =   `${path  }umkb/checkRights`;

export const CHECK_AUTH =         `${path  }user/authcheck?authkey=`;

export const RECOVERY_PASSWORD =  `${path  }user/recovery`;

export const RESET_PASSWORD =     `${path  }user/resetPassword`;

export const EXIT_FROM_PROFILE =  `${path  }user/authexit?authkey=`;

///

// export const ANONIMCREATE = `${path_Dev}requestAccountRegistrationUserAnonymous`; // - Регистрация анонимуса

// export const REGISTRATION = `${path_Dev}requestAccountRegistrationUser`; // requestAccountRegistrationUser - Регистрация обычного пользователя

// requestAccountAuthorizationQR
// ------------------
// export const LOGIN = `${path_Dev}requestAccountAuthorization`;
export const LOGIN =              `${path_Dev  }Account/Authorization`;
export const QRLOGIN =              `${path_Dev  }Account/AuthorizationQr`;
// export const QRLOGIN = `${path_old_api}requestQRAuthorization`;
// export const ANONIMCREATE = `${path_Dev}AccountRegistrationUserAnonymous`;
export const ANONIMCREATE =       `${path_Dev}Account/RegistrationUserAnonymousQR`;
export const REGISTRATION =       `${path_Dev}Account/RegistrationUser`;
export const CONFIRM_EMAIL =      `${path_Dev}Account/ComfirmEmail`;
export const GET_EMAIL_CONFIRM =  `${path_Dev}Account/ComfirmEmailRepeat`;
export const CHECK_RIGHTS =       `${path_Dev}Account/CheckRights`;

// export const RESET_PASSWORD = `${path_Dev}responseAccountResetPassword`; // - создавалка нового пароля

// export const RECOVERY_PASSWORD = `${path_Dev}responseAccountRecovery`; // - ссылка на восстановление пароля

