/* eslint-disable import/named */
import { CHANGE_CURRENT_ACTION,  GET_DATA,  SET_SIZE_BOOK,  CHANGE_NOW_PAGE,  SET_NUMBER_PAGE,  SET_HEIGHT_CHAPTER,  SET_HEIGHT_ONE_TABLE,  ADD_VALUE_INTO_ARRAY_HEIGHT_BEFORE_TABLE,
  SET_COUNT_PAGES,  PLUS_ONE_COUNT,  SET_STATUS_ABOUT_TWO_PAGE_PATIENT,  SET_COUNT_OF_PAGE_TAKES_TABLE,  ADD_IMAGE_KAROTYPE,  ADD_LIST_NUMBERS_PAGE,
  ADD_IMAGE_LOCALIZATION,  GET_ENTRIES_DATA,  HEIGHT_BLOCK_FOR_DATA_PATIENT,  ADD_TEXT_DATA_FOR_DIAGNOSIS, CLEAR_DATA, SET_COUNT_FOR_GENERATION_PDF, SET_CHECK_DOWNLOAD_DATA,
  SET_QUANTITY_ALL_ELEMENTS_FOR_DOWNLOAD, SET_DATA_FOR_SOFT, SET_DATA_FOR_IN_WORK,
   CHECK_INTERVAL_FOR_STATUS, SET_DATA_FOR_IN_HISTORY_COMPLETED, SET_DATA_FOR_IN_HISTORY_CANCELED, SET_USER_INFO, GET_LAB_INFO, GET_SEARCH_LIB
} from "./actionTypes";

export function changeCurrentAction(current) {
  return {
    type: CHANGE_CURRENT_ACTION,
    payload: current,
  };
}

export function setUserInfo (data) {
  return {
    type: SET_USER_INFO,
    payload: data
  }
}
export function setSearch (data) {
  return {
    type: GET_SEARCH_LIB,
    payload: data
  }
}
export function setLabInfo (data) {
  return {
    type: GET_LAB_INFO,
    payload: data
  }
}


export function getData(data) {
  return {
    type: GET_DATA,
    payload: data,
  };
}


export function setStatusDownloadData(check) {
  return {
    type: SET_CHECK_DOWNLOAD_DATA,
    payload: check,
  };
}

export function clearData() {
  return {
    type: CLEAR_DATA,
  };
}

export function setCountForGeneration(count) {

  return {
    type: SET_COUNT_FOR_GENERATION_PDF,
    payload: count,
  };
}


export function getEntriesData(data) {
  return {
    type: GET_ENTRIES_DATA,
    payload: data,
  };
}

export function setSizeBook(size) {
  return {
    type: SET_SIZE_BOOK,
    payload: size,
  };
}

export function changeNowPage(count) {
  return {
    type: CHANGE_NOW_PAGE,
    payload: count,
  };
}

export function setNumberPage() {
  return {
    type: SET_NUMBER_PAGE,
  };
}

export function setHeightChapter (count) {
  return {
    type: SET_HEIGHT_CHAPTER,
    payload: count
  }
}

export function setHeightOneTable (count) {
  return {
    type: SET_HEIGHT_ONE_TABLE,
    payload: count
  }
}

export function addValueIntoArrayHeightBeforeTable (value) {
  return {
    type: ADD_VALUE_INTO_ARRAY_HEIGHT_BEFORE_TABLE,
    payload: value
  }
}

export function setCountPage (count) {
  return {
    type: SET_COUNT_PAGES,
    payload: count
  }
}

export function setCountOfPageTakesTable (count) {
  return {
    type: SET_COUNT_OF_PAGE_TAKES_TABLE,
    payload: count
  }
}

export function addImage (file) {
  return {
    type: ADD_IMAGE_KAROTYPE,
    payload: file
  }
}

export function addImageLocalization (file) {
  return {
    type: ADD_IMAGE_LOCALIZATION,
    payload: file
  }
}

export function addListNumbersPage (array) {
  return {
    type: ADD_LIST_NUMBERS_PAGE,
    payload: array
  }
}

export function addHeightOfBlockPatient (CountHeight) {
  return {
    type: HEIGHT_BLOCK_FOR_DATA_PATIENT,
    payload: CountHeight
  }
}

export function addTextDataForDiagnosis (dataDiagnosis) {
  return {
    type: ADD_TEXT_DATA_FOR_DIAGNOSIS,
    payload: dataDiagnosis
  }
}

export function plusOneCount () {
  return {
    type: PLUS_ONE_COUNT,
  }
}

export function setStatusAboutTwoPagePatient () {
  return {
    type: SET_STATUS_ABOUT_TWO_PAGE_PATIENT,
  }
}

export function setQuantityAllElementsForDownload (quantity) {
  return {
    type: SET_QUANTITY_ALL_ELEMENTS_FOR_DOWNLOAD,
    payload: quantity
  }
}

export function setDataForSoft ( array ) {
  return {
    type: SET_DATA_FOR_SOFT,
    payload: array
  }
}

export function setDataForHistoryInWork ( array ) {
  return {
    type: SET_DATA_FOR_IN_WORK,
    payload: array
  }
}

export function setDataForInHistoryCompleted ( array ) {
  return {
    type: SET_DATA_FOR_IN_HISTORY_COMPLETED,
    payload: array
  }
}

export function setDataForInHistoryCanceled ( array ) {
  return {
    type: SET_DATA_FOR_IN_HISTORY_CANCELED,
    payload: array
  }
}

export function setCheckIntervalForStatus ( ) {
  return {
    type: CHECK_INTERVAL_FOR_STATUS,
  }
}
