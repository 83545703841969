import { alpha } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};

// const PRIMARY  = {
//   lighter: '#C5CED6',
//   light: '#89B6DD',
//   main: '#364757',
//   dark: '#969DA3',
//   darker: '#505357',
//   contrastText: '#fff'
// };
// #dce0e4
// const PRIMARY  = {
//   lighter: '#eef2f5',
//   light: '#A3ADA3',
//   main: '#007B55',
//   dark: '#505357', 
//   darker: '#5B615B',
//   contrastText: '#fff'
// };

const PRIMARY  = {
  lighter: '#ebf8f2',
  light: '#A3ADA3',
  main: '#6EA1A5',
  dark: '#6EA1A5', 
  darker: '#0A465C',
  // main: '#00AB55',
  // dark: '#00AB55', 
  // darker: '#00AB55',
  contrastText: '#fff'
};

// const PRIMARY  = {
//   lighter: '#D3E0D3',
//   light: '#97A197',
//   main: '#5B615B',
//   dark: '#4E534E',
//   darker: '#373B37',
//   contrastText: '#fff'
// };

// const PRIMARY  = {
//   lighter: '#C8FACD',
//   light: '#5BE584',
//   main: '#00AB55',
//   dark: '#007B55',
//   darker: '#005249',
//   contrastText: '#fff'
// };
const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#fff'
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff'
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#00AB55', // #54827e // 54D62C
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800]
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800]
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#DA4545', // #DA4545 // FF4842
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff'
};

const BEAUTY = {
  lighter: '#fff',
  light: '#fff',
  main: '#fff',
  dark: '#e79df2c4',
  darker: '#fff',
  contrastText: GREY[800]
};

const GENETIC = {
  lighter: '#fff',
  light: '#fff',
  main: '#fff',
  dark: '#b46b455e',
  darker: '#fff',
  contrastText: GREY[800]
};
const BLACK = {
  lighter: '#000',
  light: '#000',
  main: '#000',
  dark: '#4E534E',
  darker: '#000',
  contrastText: GREY[800]
};




const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  beauty: createGradient(BEAUTY.light, BEAUTY.main),
  black: createGradient(BLACK.light, BLACK.main),
  genetic: createGradient(GENETIC.light, GENETIC.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  beauty: { ...BEAUTY },
  genetic: { ...GENETIC },
  black: { ...BLACK },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  divider: GREY[500_24],
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
  background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

export default palette;
