/* eslint-disable no-prototype-builtins */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-unresolved */
// /* eslint-disable react/jsx-no-undef */
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import {  useParams, useNavigate, } from 'react-router-dom';
import { useDispatch } from "react-redux";
// material
import { styled } from '@material-ui/core/styles';
import { Box,  Typography, Container,  CircularProgress, Fade } from '@material-ui/core';
// import { QrCode2 } from '@material-ui/icons';
// components
import { asyncQrLogin, asyncLogin } from 'src/redux/Logistic/requestAction';
import { getPlatform, isCordova, } from 'src/_validations';
import { KEY_DEVELOPER, SERVICE } from 'src/constants/keys';
// import { isCordova } from 'is-cordova';
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';
// import { setChapterNow } from "../redux/GeneBook/action";

// import RegistrationMenu from "../components/_dashboard/authorizationMenu/RegistrationMenu";
// import Anonym from "../components/_dashboard/authorizationMenu/Anonym";
// import EntriesMenu from "../components/_dashboard/authorizationMenu/EntriesMenu";
// import QRScaner from "../components/_dashboard/authorizationMenu/QRScaner";

 
// ----------------------------------------------------------------------
const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));


export default function Test() {

  const dispatch = useDispatch()

  const navigate = useNavigate();
  const { id, login, pass } = useParams();

  const [loading, setLoading] = useState(false);
  const url = document.location;

  useEffect(() => {
    console.log(url.pathname.split("/")[1], "url.pathname.split("/")[1]");
    
    if ( (getPlatform() === "iPhone" || getPlatform() === "iPad")) {
      // alert("ios");
      // window.open('http://apps.apple.com/ru/app/imgenius/id1586134223','_blank')
      window.location = 'itms-apps://itunes.apple.com/ru/app/imgenius/id1586134223'
      // window.open('imgenius.socmedica.com','_blank')
    } else if ( getPlatform() === "Android") {
      // alert("android");
      window.open("http://play.google.com/store/apps/details?id=com.socmedica.imgenius")
      // window.open('imgenius.socmedica.com','_blank')
    } else if ( url.pathname.split("/")[1] === "authorization") {
      setLoading(true)    
      asyncQrLogin({ linkHash: url.href.split("/authorization/").reverse()[0] }, navigate)
    } else if (id) {
      setLoading(true)
      asyncQrLogin({ linkHash: url.pathname.split("/passport/").reverse()[0] }, navigate)
    } else if (login && pass) {
      setLoading(true)
      asyncLogin({ key: KEY_DEVELOPER, service: SERVICE, email: login, password: pass }, dispatch, null, null, 0, navigate);
    }

    // if (id) {
    //   asyncQrLogin({ keyHash: url.pathname.split("/passport/").reverse()[0] }, navigate)
    // } else if (login && pass) {
    //   asyncLogin({ key: KEY_DEVELOPER, service: SERVICE, email: login, password: pass }, dispatch, null, null, 0, navigate);
    // }

  }, [dispatch, id, login, navigate, pass, url.href, url.pathname])

  return (
    <RootStyle title="Privacy | ImGenius">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                ImGenius  


                {/* // de */}
              </Typography>
            </motion.div>
            {/* <a href="googlechrome://navigate?url=https://ya.ru/" >46546</a> */}
            <Typography sx={{ color: 'text.secondary' }}>
              Добро пожаловать
       
              {/* Извините, мы не могли найти страницу, которую вы ищете. Возможно, вы опечатали URL?
              Обязательно проверьте свое правописание. */}
            </Typography>

            <motion.div variants={varBounceIn}>
              <Box
                component="img"
                src="/static/logoim.svg"
                sx={{ height: 100, mx: 'auto', my: { xs: 5, sm: 10 } }}
              />
            </motion.div>

            {/* <Box sx={{ display: 'flex' }}> */}
            {/* {loading ? <CircularProgress /> : null} */}
            <Fade
              in={loading}
              style={{
                transitionDelay: loading ? '800ms' : '0ms',
              }}
              unmountOnExit
            >
              <CircularProgress />
            </Fade>
            {/* </Box> */}
            {/* <Button to="/" size="large" variant="contained" component={RouterLink}>
              на Главную
            </Button> */}
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
