/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-unresolved */
import React from "react";
// import Breadcrumbs from "@material-ui/core/Breadcrumbs";
// import { useSelector } from "react-redux";
// import {headerSelector} from "../../ducks/propertiesReducer";
// import Link from "@material-ui/core/Link";
// import HomeIcon from '@material-ui/icons/Home';
// import {STORE_PATH} from "../documents/StoreRouter";
// import history from "../../history";
import { Link as RouterLink } from 'react-router-dom';
import {  Button,  Dialog, DialogActions, DialogContent, DialogTitle, IconButton,  Stack, TextField, Typography } from "@material-ui/core";
// import IconButton from "@material-ui/core/IconButton";
// import isMobile from "../../utils/isMobile";
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/close-fill';
// import { asyncSendLab } from "src/redux/Logistic/requestAction";
// import ButtonLink from "src/components/ButtonLink";

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Icon icon={menu2Fill} />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const Modals = (props) => {
    // const navigate = useNavigate();

    // const [open, setOpen] = React.useState(props.open);
    // const handleOpen = () => {
    //     // props.setOpen? 
    //     props.setOpen(true)
    //     // : setOpen(true);
    // };
    const handleClose = () => {
        // props.setOpen? 
        props.setOpen(false)
        // : setOpen(false);
        // setOpen(false);
    };
    const userName = localStorage.getItem("userName")
    const email = localStorage.getItem("email")
    const anonim = localStorage.getItem("anonim")
 
    return <>
        {/* <Button style={{ marginLeft: "auto" }} onClick={handleOpen} > Записаться </Button> */}
        {/* <Router> */}


        <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <BootstrapDialogTitle id="scroll-dialog-title" onClose={handleClose}>
                Записаться
            </BootstrapDialogTitle>
            {/* <Card sx={{...style }}> */}
            {/* <h2 id="parent-modal-title">Записаться</h2> */}
            <DialogContent >
                <div>
                    <h3>
                        {props?.marker?.Name}
                    </h3>

                    <div>
                        {props?.marker?.Address}
                    </div>
                    {/* <div onClick={ () => window.open('https://socmedica.com') }> АО “Соцмедика” </div> */}
                    {/* <Link>
                    </Link> */}
                    <a href={props?.marker?.Link}>
                        открыть сайт
                    </a>
                </div>
                <br />
                {anonim ?
                    <Typography >
                        В ближайшее время Вам прийдёт уведомление о записи с вашим QR-кодом, покажите его в Лаборатории
                    </Typography>
                    : <Stack spacing={3}>
                    <TextField label="ФИО" defaultValue={userName} />
                        <TextField label="Пол" />
                        <TextField label="Возвраст" />
                        <Typography >
                            {/* После записи ожидайте  */}
                            <span style={{color: "red", fontWeight: "bold"}}>*</span> В ближайшее время Вам прийдёт уведомление о записи с вашим QR-кодом, покажите его в Лаборатории
                        </Typography>
                    </Stack>}
                <br />
            </DialogContent>

            <DialogActions>
                {/* <ButtonLink/> */}
                <Button onClick={() => localStorage.setItem("status", new Date())}
                    to="/dashboard/status" component={RouterLink}
                >Записаться</Button>
                {/* <Button onClick={() => asyncSendLab(navigate)} >Готово</Button> */}
            </DialogActions>
            {/* <ChildModal /> */}
            {/* </Card> */}
        </Dialog>
        {/* </Router> */}
    </>

};

export default Modals
