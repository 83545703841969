/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/named */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Box,  Link, Container, Typography,  } from '@material-ui/core';
// layouts
// import AuthLayout from '../layouts/AuthLayout';
// components
import { isMobile } from 'src/_validations';
import Page from '../components/Page';
// import { MHidden } from '../components/@material-extend';
import { ResetPassForm } from '../components/authentication/resetPass';
// import AuthSocial from '../components/authentication/AuthSocial';
import { infoCompany } from "../constants/constant";

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: { display: 'flex',  flexDirection: "column",  color: "white",  width: "100%" }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  // margin: 'auto',
  display: 'flex', flexDirection: 'column', justifyContent: 'end', padding: theme.spacing(12, 0, 5), // minHeight: isMobile && '100vh',
}));

export default function Register() {

  return (
    <RootStyle title="ResetPass | ImGenius">
          {/* {isMobile && <img style={{ position: "absolute", top: 20, right: 20 }} width="80px" height="80px" alt="" src="/static/icons/imGeniusIcon.svg" />} */}
          {/* <div style={{ background: isMobile ? 'url(/static/icons/backGroundMobileIcon.png) no-repeat 0% 0%' : 
          'url(/static/icons/backGroundDesignWeb.png) no-repeat 0% 0%', minHeight: "60vh", backgroundSize: "cover" }} */}
<div style={{
        background: 'url(/static/icons/logo.png) 5% 5% no-repeat fixed, linear-gradient(321.69deg, #54827E 0%, #A4B7B7 100%)',
        //  url(/example/image/animate-bg1.jpg) no-repeat fixed;
        backgroundSize: isMobile ? "35%, auto" : "20%, auto", /* Меняем масштаб */
        minHeight: "65vh",}}
          >
        {/* <AuthLayout>
            <span style={{ color: "white" }}> Вспомнили пароль? &nbsp; </span>
            <Link  style={{ color: "#fff" }} underline="none" variant="subtitle2" component={RouterLink} to="/login">
              Войти
            </Link>
          </AuthLayout> */}

        <Container sx={{ minHeight: "inherit", display: "grid" }}>
            <ContentStyle>
              <Box sx={{ mb: 5 }}>
                <Typography style={{ color: "white" }} variant="h4" gutterBottom>
                  Введите Ваш email
                </Typography>
                <Typography style={{ color: "white" }}  sx={{ color: 'text.secondary' }}>
                  Для сброса пароля
                </Typography>
              </Box>

              <ResetPassForm />

            <Typography style={{ color: "white" }} variant="body2" align="center" sx={{ mt: 3 }}>
              Вспомнили пароль? &nbsp;
              <Link style={{ color: "#fff" }} underline="none" variant="subtitle2" component={RouterLink} to="/login">
                Войти
              </Link>
            </Typography>

              {/* <MHidden width="smUp">
                <Typography variant="subtitle2" sx={{ display: "contents", color: "white" , mt: 3, textAlign: 'center' }}>
                <span style={{ color: "white", paddingTop: "1em" }}> Вспомнили пароль?&nbsp; </span>
                  <Link style={{ color: "#fff" }} to="/login" component={RouterLink}>
                    Войти
                  </Link>
                </Typography>
              </MHidden> */}

            </ContentStyle>
          </Container>
          </div>
      <Container >
          { infoCompany }
      </Container>
    </RootStyle>
  );
}
