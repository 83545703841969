/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Link as RouterLink, useParams,useNavigate } from 'react-router-dom';
// import { useState } from 'react';
// import { KEY_DEVELOPER } from 'src/constants/keys';
import { asyncConfirmemail, asyncGetEmailConfirm } from 'src/redux/Logistic/requestAction';
// material
import { styled } from '@material-ui/core/styles';
import {
  Box,
  // Card,
  Link,
  Container,
  Typography,
} from '@material-ui/core';
// layouts
import { ConfirmForm } from 'src/components/authentication/confirm';
import { infoCompany } from 'src/constants/constant';
import { isMobile } from 'src/_validations';
// import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
// import { MHidden } from '../components/@material-extend';
// import { ResetPassForm } from '../components/authentication/resetPass';


// import AuthSocial from '../components/authentication/AuthSocial';

// ----------------------------------------------------------------------

// const RootStyle = styled(Page)(({ theme }) => ({
//   [theme.breakpoints.up('md')]: {
//     display: 'flex'
//   }
// }));
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: { display: 'flex', flexDirection: "column", color: "white", width: "100%" }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  // margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: isMobile ? 'center' : 'end', 
  // justifyContent: 'end',
  padding: theme.spacing(12, 0, 5), // minHeight: isMobile && '100vh',
}));

// ----------------------------------------------------------------------

export default function Confirm() {

  const dispatch = useDispatch();
  const { code, email, service } = useParams();

  const navigate = useNavigate();

  useEffect(() => {

    if (code && email && service) {

      asyncConfirmemail({ email,code}, dispatch, navigate)
    }
  },[])

  return (
    <RootStyle title="ResetPass | ImGenius">
 
      <div style={{
        background: 'url(/static/icons/logo.png) 5% 5% no-repeat fixed, linear-gradient(321.69deg, #54827E 0%, #A4B7B7 100%)',
        //  url(/example/image/animate-bg1.jpg) no-repeat fixed;
        backgroundSize: isMobile ? "35%, auto" : "20%, auto", /* Меняем масштаб */
        minHeight:  "65vh",
      }}
      >

        <Container sx={{ minHeight: "inherit", display: "grid" }}>
          <ContentStyle>
            <Box sx={{ mb: 5 }}>
              <Typography style={{ color: "white" }} variant="h4" gutterBottom>
              Введите код подтверждения
              </Typography>
              <Typography style={{ color: "white" }} sx={{ color: 'text.secondary' }}>
              Который мы прислали вам на почту
              </Typography>
            </Box>

            <ConfirmForm />

            <Typography style={{ color: "white" }} variant="body2" align="center" sx={{ mt: 3 }}>
              Вспомнили пароль? &nbsp;
              <Link style={{ color: "#fff" }} underline="none" variant="subtitle2" component={RouterLink} to="/login">
                Войти
              </Link>
            </Typography>

          </ContentStyle>
        </Container>
      </div>
      <Container >
        {infoCompany}
      </Container>
    </RootStyle>
  );
}
