import React, { useState } from 'react';
import { Button, Grid, Typography, Card, CardContent, CardActions } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import ModalOFStatus from "./ModalOFStatus";

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
);

const useStyles = makeStyles((theme) => ({
  cardContent: { borderRadius: '16px' },
  blockOfCardHeader: { display: 'flex',  flexFlow: 'row wrap-reverse',  justifyContent: 'space-between' },
  blockOfDescription: { paddingTop: '12px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
  card: { textDecoration: 'none' },
  cardActionArea: { textDecoration: 'none', borderRadius: '16px', background: "#f4f6f8" },
  cardAction: { textDecoration: 'none', borderRadius: '16px', padding: "12px" },
  cardHeader: { padding: 0, fontStyle: 'italic' },
  textColor: { color: theme.palette.primary.dark },
  titleChapter: { width: "100%", fontFamily: "OpenSans", fontStyle: "normal", fontWeight: "normal", lineHeight: "25px", color: "#262624", fontSize: isMobile ? "calc(0.8em + 1vw)" : "18px" },
  descriptionChapter: { width: "100%", fontFamily: "OpenSans", fontStyle: "normal", fontWeight: "normal", lineHeight: "18px", color: "#918975", fontSize: isMobile ? "calc(0.8em + 1vw)" : "13px"},
  blockButton: { display: "flex",  flexDirection: "row",  alignItems: "inherit",  width: "100%",  justifyContent: "flex-end", padding: "0px 15px" },
  CardActions: { padding: "24px",  justifyContent: "space-between",  bottom: 0,  position: "absolute",  width: "fit-content",  right: 0 },
  HelpOutline: { padding: "0px 1px", margin: "0px 5px" }
}));

const listHistory = [ { id: 3411, date: "21.09.2021"} ]

export default function ContentCardStatus({ block }) {
    const [openModal, setOpenModal] = useState(false)
    const classes = useStyles();
  return (
        <Card variant="outlined" style={{ height: "100%" }} >
            <CardContent style={{ paddingBottom: "5em" }}>
                  <Grid gridColumn="span 9" item style={{ display: "flex",  flexDirection: "column", alignItems: "center", textAlign: "left" }}>
                    <Typography className={ classes.titleChapter }> { block.caption } </Typography>
                    {openModal && <ModalOFStatus title={ block.caption } openModal={openModal} setOpenModal={setOpenModal} listHistory={listHistory} />}
                  </Grid>
            </CardContent> 
            <CardActions className={ classes.CardActions } >
              <Typography variant="subtitle2"> Образец передан для дальнейшего исследования </Typography>
              <Button fontSize="small" onClick={() => setOpenModal(true)}  variant="outlined" color="primary" position="center">
                  <HelpOutline className={ classes.HelpOutline } fontSize="small" />
              </Button>
            </CardActions>
        </Card>
  );
}