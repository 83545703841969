/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-unresolved */
import * as Yup from 'yup';
import { useState } from 'react';
// import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
// import eyeFill from '@iconify/icons-eva/eye-fill';
// import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, ButtonGroup, Button } from '@material-ui/core';
// import { LoadingButton } from '@material-ui/lab';
import DialogWindow from 'src/components/DialogWindow';
// import { useDispatch } from 'react-redux';
// import { asyncRegistration } from 'src/redux/Logistic/requestAction';
// import { SERVICE, KEY_DEVELOPER, PARENT_ID_FOR_REGISTRATION } from "../../../constants/keys";
// ----------------------------------------------------------------------

export default function ProfileForm() {
  // const navigate = useNavigate();
  // const [showPassword, setShowPassword] = useState(false);

  // const [email, setEmail] = useState("");
  // const [code, setCode] = useState("");
  // const [password, setPassword] = useState("");
  // const [personKey, setPersonKey] = useState("");
  // const [getAuthKey, setGetAuthKey] = useState("");
  const answerFromServer = "";
  // const [AnswerFromServerAuthKey, setAnswerFromServerAuthKey] = useState("");
  // const [s, setOpen] = useState(true);
  const [open, setOpen] = useState(true);

  const [firstName, setfirstName] = useState(localStorage.getItem('userName') ? localStorage.getItem('userName').split(" ")[0] : '');
  const [lastName, setlastName] = useState(localStorage.getItem('userName') ? localStorage.getItem('userName').split(" ")[1] : '');

  const [middleName, setmiddleName] = useState(localStorage.getItem('middleName'));
  const [age, setage] = useState(localStorage.getItem('age'));
  const [sex, setSex] = useState(localStorage.getItem('sex'));

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Слишком коротко!')
      .max(50, 'Слишком длинно!')
      .required('Требуется имя'),
    lastName: Yup.string().min(2, 'Слишком коротко!').max(50, 'Слишком длинно!').required('Требуется фамилия'),
    email: Yup.string().email('Электронная почта должна быть действительна').required('Требуется электронная почта'),
    password: Yup.string().min(6, 'Слишком коротко!').max(50, 'Слишком длинно!').required('Необходим пароль')
  });

  const formik = useFormik({
    initialValues: {
      firstName: localStorage.getItem('userName') ? localStorage.getItem('userName').split(" ")[0] : '' ,
      lastName: localStorage.getItem('userName') ? localStorage.getItem('userName').split(" ")[1] : '',
      middleName: localStorage.getItem('middleName') ? localStorage.getItem('middleName').split(" ")[1] : '',
      age: localStorage.getItem('age') || '',
      sex: localStorage.getItem('sex') || '',
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {

      localStorage.setItem("sex", values.sex || sex)
      localStorage.setItem("age", values.age)
      localStorage.setItem("middleName", values.middleName)
      localStorage.setItem("lastName", values.lastName)
      localStorage.setItem("firstName", values.firstName)

      // sendDataForRegistration(values.email, values.password, values.firstName, values.lastName)
      // actions.setSubmitting(false);
      // navigate('/dashboard', { replace: true });
    }
  });


  function onSubmit() {


    // const listUserState = JSON.parse(localStorage.getItem("listUser"))

    // const findUser = listUserState.findIndex(item => item.email === localStorage.getItem("email"))

    // if (!findUser) {
    //   listUserState.splice(findUser, 1, { firstName, lastName, middleName, sex, age, email: localStorage.getItem("email") })
    // } else {
    //   listUserState.push({ firstName, lastName, middleName, sex, age, email: localStorage.getItem("email") })
    // }

    // localStorage.setItem("listUser", JSON.stringify(listUserState))
    sex && localStorage.setItem("sex", sex)
    age && localStorage.setItem("age", age)
    middleName && localStorage.setItem("middleName", middleName)
    lastName && localStorage.setItem("lastName", lastName)
    firstName && localStorage.setItem("firstName", firstName)
  };

  // const dispatch = useDispatch();
  const { errors, touched, handleSubmit, } = formik;

  // let sendDataForRegistration = (email, password, firstName, lastName) => {
  //   // e.preventDefault();middleName,
  //   setOpen(true);
  //   // asyncRegistration({ key: KEY_DEVELOPER, service: SERVICE, email, password, firstName, lastName, parent_id: PARENT_ID_FOR_REGISTRATION }, 
  //   //   dispatch, setAnswerFromServer, navigate);
  // };


  const listUser = JSON.parse(localStorage.getItem("listUser"))
  const findUser = listUser?.find(item => item.email === localStorage.getItem("email"))


  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              label="Имя"
              value={firstName}
              onChange={(value) => setfirstName(value.target.value)}
              // {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              label="Фамилия"
              value={lastName}
              onChange={(value) => setlastName(value.target.value)}
              // {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />

            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              label="Отчество"
              value={middleName}
              onChange={(value) => setmiddleName(value.target.value)}
              // {...getFieldProps('middlename')}
              error={Boolean(touched.middlename && errors.middlename)}
              helperText={touched.middlename && errors.middlename}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              // fullWidth
              type="numeric"
              label="Возраст"
              value={age}
              onChange={(value) => setage(value.target.value)}
              // {...getFieldProps('age')}
              error={Boolean(touched.age && errors.age)}
              helperText={touched.age && errors.age}
            />

            <ButtonGroup variant="outlined" aria-label="outlined button group"  >

              <Button variant={(sex === "1" || sex === 1) ? "contained" : "outlined"}
                onClick={() => { setSex(1); localStorage.setItem("sex", 1) }} >Мужчина</Button>
              <Button variant={(sex === "0" || sex === 0) ? "contained" : "outlined"}
                onClick={() => { setSex(0); localStorage.setItem("sex", 0) }} >Женщина</Button>
            </ButtonGroup>

          </Stack>
          {/* <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          /> */}

          {/* <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Пароль"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          /> */}
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

            <Button variant="contained" onClick={onSubmit} style={{ marginLeft: 'auto' }}>Сохранить</Button>
            {/* <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          // disabled={(touched.email && errors.email) || (touched.password && errors.password)}
        >
          Войти
        </LoadingButton> */}

            {/* <LoadingButton
              // fullWidth
              // size="large"
              // loadingPositin="end"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Сохранить
            </LoadingButton> */}
          </Stack>
        </Stack>

      </Form>
      {answerFromServer && (<DialogWindow width="30%"
        // margin={"-7% 0% 0% 50%"}
        margin=""
        onClick={() => { setOpen(false) }}
        // AnswerFromServerAuthKey={AnswerFromServerAuthKey} 
        answerFromServer={answerFromServer}
        open={open} />)}
    </FormikProvider>
  );
}
