/* eslint-disable import/no-unresolved */
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate, NavLink } from 'react-router-dom';
import {  Container, Paper,  Typography,  createTheme,  responsiveFontSizes, Box, Stack, Grid, styled, Divider, Button } from '@material-ui/core';
import { Autorenew, MenuBook, History, Backup, HelpOutline } from '@material-ui/icons';
import Page from 'src/components/Page';
import { isMobile } from 'src/_validations';
import Modals from "../Modals";
import BlockCard from "./BlockCard";
import ModalInfoPay from "./ModalInfoPay";
import { ProductCartWidget } from '../../products';

let theme = createTheme();
theme = responsiveFontSizes(theme);

const listHistory = [
    // { id: 3258, date: "15.04.2020"},
    // { id: 3333, date: "1.06.2020"},
    { id: 3411, date: "21.09.2021"}
]

const Item = styled(Paper)(({ theme, position }) => ({
    // ...theme.typography.body2,
    // padding: theme.spacing(1),
    textAlign: position,
    color: theme.palette.text.secondary,
    fontWeight: position === "center" && "bold",
    // fontSize: isMobile ? "calc(0.5em + 1vw)" : "calc(0.1em + 1vw)",
    fontSize: isMobile && "calc(0.6em + 1vw)" ,
    backgroundColor: position === "center" && "#ebf8f2", 
    width: position === "center" && "7em",
    borderRadius: "20px"
}));

const ListPassports = () => {
    const dispatch = useDispatch()
    const { listService, servicesInBasket } = useSelector(state => state.reducerGeneBook)
    const [openModal, setOpenModal] = useState(false)
    const [openModalBucket, setOpenModalBucket] = useState(false)
    const vip = localStorage.getItem("vip")
    const list = [
        { id: 123, caption: "Генетический паспорт.Здоровье", organization: ["Гемотест", "Genotek", "Invitro"] },
        // { id: 20, caption: "Эффективность лекарственных средств", organization: "Genotek" },
        // { id: 21, caption: "Особенности питания", organization: "Invitro" },
        { id: 134, caption: "Эффективность лекарственных средств", organization: ["Соцмедика", "Invitro", "Genotek"] },
        // { id: 20, caption: "Эффективность лекарственных средств", organization: "Invitro" },
        // { id: 21, caption: "Особенности питания", organization: "Genotek" },
        { id: 145, caption: "Особенности питания", organization: ["Genotek", "Гемотест", "Соцмедика"] },
        // { id: 20, caption: "Эффективность лекарственных средств", organization: "Гемотест" },
        // { id: 21, caption: "Особенности питания", organization: "Соцмедика" },
    ]

    const [openModalDescription, setOpenModalDescription] = useState(false)
    const [nowDescription, setNowDescription] = useState(null)

    const inputChoiceCSVFile = useRef(null);
    const [imageLocalization, setCSVFileToBase64] = useState("");

    const onClickChoiceCSVFile = () => { inputChoiceCSVFile.current.click() };

    const generationCSVFileToBase64 = (event) => {
        const reader = new FileReader();
        reader.onload = function (event) { setCSVFileToBase64(reader.result) };
        console.log(event.target.files[0], "00000000");
        if ( event.target.files[0] ) {
            reader.readAsDataURL(event.target.files[0]);
            // console.log(event.target.files[0], "imageLocalization");
            console.log( imageLocalization , "after click");
        }
    };

    return <Page title="Dashboard: Products | ImGenius" style={vip ? {} : {
        display: 'flex',
        minHeight: '100%',
        alignItems: 'center'
    }} >
            <Container maxWidth="xl" style={{ height: `100%` }}>
            {/* <Typography variant="h4" sx={{ mb: 3 }}>
                    Список ваших паспортов
                </Typography> */}

                 {vip 
                    ?   
                <>
                    <Grid style={{
                        marginBottom: "1em",
                        padding: "1em", display: "flex", flexDirection: "column", justifyContent: "center",
                        backgroundColor: "#f9f9f9", backgroundImage: "url(../static/menu/backPhonePassport.svg)",
                        backgroundSize: "contain", borderRadius: "20px", backgroundRepeat: "no-repeat", backgroundPositionY: "bottom",
                        backgroundPositionX: "right", minHeight: "50px", maxHeight: "500px"
                    }}>
                        <Typography style={{
                            whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                            padding: "5px 8px", height: "25px", left: "20px", top: "20px", fontFamily: "OpenSans", fontStyle: "normal",
                            fontWeight: "normal", fontSize: "20px", lineHeight: "22px", color: "#262624"
                        }} >
                            Генетические заключения
                            {/* <br/> <i style={{ color: "#C4CDD5" }}> ( обновлен 21.09.2021 ) </i> */}
                        </Typography>

                        <Typography style={{ padding: "8px", marginTop: "5px", left: "20px", top: "50px", fontFamily: "OpenSans", fontStyle: "normal", fontWeight: "normal", fontSize: "16px", lineHeight: "22px", color: "#262624" }} >
                            {/* список ваших паспартов */}
                            Ознакомьтесь с вашими предрасположенностями
                            {/* В этом разделе мы оцениваем вашу наследственную предрасположенность к рискам заболеваний...  */}
                        </Typography>
                    </Grid>


                    <Typography style={{ display: "flex", justifyContent: "end",  borderRadius: "8px", paddingBottom: "0.5em" // , padding: "1em 24px"
                }}>
                        {/* <Typography style={{ margin: "auto", marginLeft: "15px" }}> Загрузить csv-файл </Typography> */}
                        <Button type="button"  variant="outlined" onClick={ () => {
                            // onClickChoiceCSVFile();  //  csv  файл в формате base64
                             setOpenModalDescription(true)
                            } 
                              } style={{ alignItems: "right", backgroundColor: "antiquewhite" }} > 
                            <span title="lhfjlanhsllasf a,ksfna aisfhpm" style={{ marginRight: "10px" }}> Загрузить файл </span> 
                            {/* <HelpOutline /> */}
                            <Backup />
                        </Button>
                        
                        {/* <Button onClick={ () => console.log(`${imageLocalization}`, "imageLocalization") }  type="button"  variant="contained" >
                            Оплатить
                        </Button> */}
                    </Typography>

                  <input  style={{  margin: 15,  marginLeft: 50,  height: 30,   outline: "none",  display: "none" }}  ref={inputChoiceCSVFile}  
                //   style={{display: "none"}}  
                  onChange={(event) => generationCSVFileToBase64(event)}   accept=".csv, .tsv" name="image"  type="file"  id="file"  />
                  
                    <Container maxWidth="xl"  style={{ padding: 0, margin: 0 }} >
                        <div >
                        
                        {/* <div style={{ display: "flex", justifyContent: "space-between",  flexWrap: "wrap" }} >
                            <Typography style={{ paddingTop: "10px" }} >
                                Название
                            </Typography>                                    
                            <Typography>
                                Организация
                            </Typography>
                            <Typography>
                                Обновить
                            </Typography>
                            <Typography>
                                Версии
                            </Typography>
                        </div>
                        <Divider /> */}

                        {/* <Grid style={{ padding: "1em 0px"}} container  justifyContent="center" spacing={3}> */}
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{ marginBottom: "1em"}}>
                            { list.map( block =>  
                                <Grid  key={ block.id }
                                
                                    // style={{ padding: 0, margin: 0 }}
                                    // onClick={ () => onHandlerChapter( block.chapter ) }
                                    // style={{ textDecoration: "none" }}
                                    // component={ NavLink } 
                                    // to={ block.path }
                                    // item xs={12} sm={6} md={4}
                                    Grid item xs={12} sm={8} md={4}
                                > 
                                    <BlockCard block={block} />
                                </Grid>
                            )}
                        </Grid>

                        {/* <div style={{ padding: "2.5em 0px", display: "flex", flexDirection: "row",flexWrap: "wrap", justifyContent: "space-between" }} container  justifyContent="center" spacing={3}>
                            { list.map( block =>  
                                <div key={ block.id }
                                    // onClick={ () => onHandlerChapter( block.chapter ) }
                                    style={{ textDecoration: "none", width: "33%", height:"100%",  flexGrow: 0.2 }}
                                    // component={ NavLink } 
                                    // to={ block.path }
                                    // item xs={12} sm={6} md={4}
                                > 
                                <BlockCard block={block} />
                                </div>
                            )}
                        </div> */}



                            {/* {list.map((item) =>
                                <>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap"
                                    }} >
                                        <Typography style={{ paddingTop: "10px" }} position="left">
                                            {item.caption}
                                        </Typography>                                    
                                        <Button fontSize="small" onClick={() => setOpenModal(true)}
                                            style={{ padding: "5px 9px", margin: "8px 0" }}
                                            variant="outlined" color="primary" position="center">
                                            {listHistory[0].date}  <History style={{ padding: "0px 1px", margin: "0px 5px" }} fontSize="small" />
                                        </Button>
                                    </div>
                                    <Divider />
                                    {openModal && <Modals title={item} openModal={openModal} setOpenModal={setOpenModal} listHistory={listHistory} />}
                                </>
                            )} */}
                            
                        </div>

                        {/* <Box >
                            {list.map( (item) =>
                                <>
                                    <Box  display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={3} mb={2}>
                                        <Box gridColumn="span 8">
                                            <Item style={{ paddingTop: "10px" }} position="left">
                                                {item.caption}
                                            </Item>

                                        </Box>

                                        <Box gridColumn="span 4" style={{
                                            display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center",
                                            width: isMobile && "max-content"
                                        }}>
                                            <Button  fontSize="small"  onClick={ () => setOpenModal( true ) } style={{ borderRadius: "20px", padding: "5px 9px", margin: "4px" }} variant="outlined"  color="primary"  position="center">
                                            {listHistory[0].date}  <History style={{ padding: "0px 1px", margin: "0px 5px" }} fontSize="small" />
                                            </Button>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    {openModal && <Modals title={ item }  openModal={openModal} setOpenModal={setOpenModal} listHistory={listHistory} />}
                                </>
                            )}
                        </Box> */}
                        {/* {servicesInBasket.length ? <ProductCartWidget badgeContent={servicesInBasket.length} openModal={openModalBucket} setOpenModal={setOpenModalBucket} /> : null} */}
                        
                        <ModalInfoPay generationCSVFileToBase64={generationCSVFileToBase64} inputChoiceCSVFile={inputChoiceCSVFile} value={nowDescription} openModal={openModalDescription} setOpenModal={setOpenModalDescription} />
                    </Container>
                </>
                :
                <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
                    <Typography variant="h4"> Нет данных </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                    Для получения Генетического заключения сдайте биоматериалы в лабораторию
                    </Typography>

                    <Button variant="contained" style={{ margin: "1em", textTransform: "none" }}
                    to="/dashboard/laboratories/map" component={RouterLink} >
                    Лаборатории
                    </Button>
                </Box>
                } 
            </Container>
        </Page>
};

export default ListPassports;