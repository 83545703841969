/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-key */
// material
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { asyncLaboratoryInfo, asyncSearch } from 'src/redux/Logistic/requestAction';
// import { useTheme } from '@material-ui/core/styles';
import { Link as RouterLink, useParams } from 'react-router-dom';
// import { isMobile } from 'src/_validations';
// import { Icon } from '@iconify/react';
// import plusFill from '@iconify/icons-eva/plus-fill';
import { Container, Typography, Button, Stack, Box, Accordion, AccordionSummary, AccordionDetails, Pagination, List, ListItem, ListItemText, Divider, Grid,
  // Paper, Tabs, Tab, CardActions, CardContent, Card, ListItemSecondaryAction, IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
// components
// eslint-disable-next-line import/no-unresolved
import { BlogPostsSearch, BlogPostsSort } from 'src/components/_dashboard/blog';
import Maps from 'src/layouts/dashboard/Maps';
import Modals from 'src/layouts/dashboard/Modals';

import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/arrow-ios-downward-fill';
import MapsYa from 'src/layouts/dashboard/MapsYa';
import Page from '../components/Page';
import USERLIST from '../_mocks_/copy';

const useStyles = makeStyles(() => ({
  cardContent: {
    // padding: '12px',
    // backgroundColor: '#ebf8f2',
    borderRadius: '16px',
    boxShadow: '0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)'
  },
  blockOfCardHeader: { display: 'flex', flexFlow: 'row wrap-reverse', justifyContent: 'space-between' },
  blockOfDescription: { paddingTop: '12px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
  card: { textDecoration: 'none' },
  cardActionArea: { textDecoration: 'none', borderRadius: '16px', background: "aliceblue" },
  cardAction: { textDecoration: 'none', borderRadius: '16px', },
  cardHeader: { padding: 0, fontStyle: 'italic' }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `scrollable-force-tab-${index}`,
//     'aria-controls': `scrollable-force-tabpanel-${index}`
//   };
// }

export default function DashboardApp() {
  const classes = useStyles();
  // const theme = useTheme();
  // const [value, setValue] = useState(0);
  const SORT_OPTIONS = [
    { value: 'all', label: 'Все' },
    { value: 'part', label: 'Партнеры' },
    // { value: 'high', label: 'Закрыты сейчас' }
  ];
  const {searchLib} = useSelector(state => state.reducerLogistic);
  const [choiceSearch, setChoiceSearch] = useState('');
  const [choiceSort, setChoiceSort] = useState('all');
  const dispatch = useDispatch();

  const { map } = useParams();

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  };
 const [openSearch, setOpenSearch] = useState({ lat: 55.683658, lng: 37.342434 });
 
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(success, error, options);
    // asyncLaboratoryInfo(dispatch, {
    //   "AuthKey": localStorage.getItem("AUTHKEY"),
    //   "Data": {}
    // });
  }, [])
  // 55.683658,37.342434
 
  // const [showInfo, setShowInfo] = useState(true)

  const [markerInfo, setMarker] = useState(null);
  const [open, setOpen] = React.useState(false);

  function success(pos) {
    const crd = pos.coords;

    setOpenSearch({ lat: crd?.latitude, lng: crd?.longitude })
  };

  function error(err) {
    // console.log(err, "err");
  };

  function handleMarker(targetMarker) {
    setOpen(true)
    setMarker(targetMarker)
  }

  function searchLibs(pos) {
    asyncSearch({
      "text": pos,
      "libs": [ 123, 25, 121 ],
      "levels": null,
      "permit": { "authkey": localStorage.getItem("AUTHKEY") }
    }, dispatch)
  };

  // const handleChange = ( newValue) => {
  //   setValue(newValue);
  // };
  const [page, setPage] = React.useState(1);
  const handleChangePage = (event, value) => {
  
    setPage(value || page + 1);
  };


  return (
    <Page title="Dashboard | ImGenius" style={{ height: `100%` }}>
      <Container maxWidth="xl" style={{ height: `100%`, padding: map && 0,  maxWidth: map && "100%"  }}>        
        {/* <TabPanel value={value} index={0} dir={theme.direction}> */}
        {map ? null : <Grid style={{
          padding: "1em", display: "flex", flexDirection: "column", justifyContent: "center",
          backgroundColor: "#f9f9f9", backgroundImage: "url(../static/menu/geoMetka.svg)",
          backgroundSize: "contain", borderRadius: "20px", backgroundRepeat: "no-repeat", backgroundPositionY: "bottom",
          backgroundPositionX: "right", minHeight: "50px", maxHeight: "500px"
        }}>
          <Typography style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', 
          // padding: "5px", 
          height: "25px", left: "20px", top: "20px", fontFamily: "OpenSans", fontStyle: "normal", 
          fontWeight: "normal", fontSize: "calc(1em + 0.4vw)", lineHeight: "22px", color: "#262624" }} >
            Медицинские учреждения
          </Typography>

          <Typography style={{ 
            // padding: "5px", 
            marginTop: "5px", left: "20px", top: "50px", fontFamily: "OpenSans", 
          fontStyle: "normal", fontWeight: "normal", fontSize: "calc(0.8em + 0.4vw)", lineHeight: "22px", color: "#262624" }} >
            Для сдачи биоматериала
          </Typography>
          <div >
            <Button style={{ float: "right" }} variant="contained" component={RouterLink} to="/dashboard/laboratories/map">
            Карта
          </Button>
          </div>
        </Grid>


        }

        {/* <Stack spacing={2} style={{ padding: 0, display: value === 1 && "none" }} > */}
        <Stack spacing={2} style={{ padding: 0, display: map && "none" }} >
          <Stack style={{ marginBottom: "0px" }} mb={2} mt={2} direction="row" alignItems="center" justifyContent="space-between">
            <BlogPostsSearch posts={[]} choiceSearch={choiceSearch} setSearchWord={
              // searchLibs
              setChoiceSearch
            } />
            <BlogPostsSort options={SORT_OPTIONS} choiceSort={choiceSort} setChoiceSort={(e) => setChoiceSort(e)} />
          </Stack>

          {/* {searchLib?.map(item => <span> {item.Value} </span>)} */}

          <List className={classes.root} style={{ margin: 0 }}>
            {USERLIST?.filter((e) => ((choiceSort === "part" && e?.License === true) || (choiceSort === "all" && !e.License)) && (e?.Name?.toUpperCase().indexOf(choiceSearch?.toUpperCase()) >= 0 ||
              e?.address?.toUpperCase().indexOf(choiceSearch?.toUpperCase()) >= 0)).slice(page * 10 - 10, page * 10).map((marker) => (
                <>
                  {/* <ListItem style={{ padding: "12px" }}> */}
                  {/* <ListItem style={{ padding: "0px 12px" }}> */}
                  <ListItem style={{ padding: "0px 0px 0px 2px", marginTop: "8px" }}>
                    <ListItemText className={classes.card}
                      primary={<Typography variant="h6">{marker.Name} </Typography>}
                      secondary={
                        <>
                          <Typography  component="span"  variant="body2"  className={classes.inline}  color="textPrimary" >
                            {marker.Address}
                          </Typography>

                          <Stack style={{ display: !marker?.TimeTable && "block", margin: !marker?.TimeTable && "9px 0px", alignItems: "baseline" }} direction="row-reverse" alignItems="center" justifyContent="space-between">
                            { marker?.TimeTable ? <Accordion style={{ width: "max-content" }}>
                              <AccordionSummary  expandIcon={<Icon icon={menu2Fill} />}  aria-controls="panel1a-content"  id="panel1a-header" >
                                <Typography>График работы</Typography>
                              </AccordionSummary>
                              <AccordionDetails >
                                <Typography style={{ display: "flex" }}>
                                  {
                                    marker?.TimeTable[0] === "с" ? marker?.TimeTable :
                                    marker?.TimeTable?.split(" ").map((el, index) => index % 2 === 0 ? <div>
                                        {`${el} ${marker?.TimeTable?.split(" ")[index + 1] !== undefined ? marker?.TimeTable?.split(" ")[index + 1] : '' }`}
                                      </div> : null
                                      )
                                  }
                                </Typography>
                              </AccordionDetails>
                            </Accordion> : null }
                            <Button size="small" variant="contained" onClick={() => handleMarker(marker)}>
                              Записаться
                            </Button>
                            {/* <Modals marker={marker} setMarker={setMarker} open={open} setOpen={setOpen}/> */}
                          </Stack>
                        </>
                      }
                    />
                  </ListItem>
                  <Divider />
                </>))}
          </List>
          <Modals marker={markerInfo} open={open} setOpen={setOpen} />

          <Pagination style={{marginBottom: "2em"}} 
          count={Math.ceil(USERLIST?.filter((e) => ((choiceSort === "part" && e?.License === true) || (choiceSort === "all" && !e.License)) && 
          (e?.Name?.toUpperCase().indexOf(choiceSearch?.toUpperCase()) >= 0 ||
            e?.Address?.toUpperCase().indexOf(choiceSearch?.toUpperCase()) >= 0)).length / 10)
          } color="primary" page={page} onChange={handleChangePage} siblingCount={0}  />
        </Stack>

        {/* </TabPanel> */}
        {/* <div style={{ display: value === 0 && 'none', height: '100%' }}> */}
        <div style={{ display: !map && 'none', height: '100%', maxWidth: "100%"  }}>
          {/* <Button style={{}}>Список</Button> */}
          <MapsYa openSearch={openSearch} />
          
        </div>
      </Container>
    </Page>
  );
}