/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
// import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';

import { Link, useNavigate, useParams } from 'react-router-dom';
// material
import { Stack, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import DialogWindow from 'src/components/DialogWindow';
import { useDispatch } from 'react-redux';
import { asyncConfirmemail, asyncGetResetPassword, asyncGetEmailConfirm } from 'src/redux/Logistic/requestAction';
import { isMobile } from 'src/_validations';
// import { SERVICE, KEY_DEVELOPER, PARENT_ID_FOR_REGISTRATION, KEY_CONFIRM_EMAIL } from "../../../constants/keys";
// ----------------------------------------------------------------------

export default function ConfirmForm() {
  const navigate = useNavigate();

  const { code, email, service } = useParams();

  const [answerFromServer, setAnswerFromServer] = useState("");
  // const [AnswerFromServerAuthKey, setAnswerFromServerAuthKey] = useState("");
  const [open, setOpen] = useState(true);

  // useEffect(() => {
  //   if (code && email && service) {

  //     asyncConfirmemail({Protocol: {Email: email, Code: code}}, dispatch, setAnswerFromServer, navigate)
  //   }
  // }, [])

  const RegisterSchema = Yup.object().shape({

    // lastName: Yup.string().min(2, 'Слишком коротко!').max(50, 'Слишком длинно!').required('Требуется фамилия'),
    email: Yup.string().email('Электронная почта должна быть действительна').required('Требуется электронная почта'),
    password: Yup.string().min(6, 'Слишком коротко!').max(50, 'Слишком длинно!').required('Необходим пароль')
  });

  const formik = useFormik({
    initialValues: {
      email: email || '',
      code: code || '',
    },
    validationSchema: RegisterSchema,
    onSubmit: (values, actions) => {
      sendDataForRegistration(values.code)
      actions.setSubmitting(false);
      // navigate('/dashboard', { replace: true });
    }
  });
  const dispatch = useDispatch();
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  let sendDataForRegistration = (email, code) => {
    // e.preventDefault();middleName,
    setOpen(true);
    asyncConfirmemail({email, code}, dispatch, setAnswerFromServer, navigate)
    // asyncGetResetPassword({ key: KEY_DEVELOPER, service: SERVICE, email, password, firstName, lastName, parent_id: PARENT_ID_FOR_REGISTRATION },
    //   dispatch, setAnswerFromServer, navigate);
  };

  // const sendGetDataForRegistration = (email) => {
  //   // e.preventDefault();middleName,
  //   setOpen(true);
  //   asyncGetEmailConfirm({Protocol: {Email: email}}, dispatch, setAnswerFromServer, navigate)
  //   // asyncGetResetPassword({ key: KEY_DEVELOPER, service: SERVICE, email, password, firstName, lastName, parent_id: PARENT_ID_FOR_REGISTRATION },
  //   //   dispatch, setAnswerFromServer, navigate);
  // };


  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>

          <TextField color="success" variant="outlined" style={{backgroundColor:"#e8f0fe", borderRadius: "12px"}}
            fullWidth
            InputLabelProps={{
              shrink: true, sx: { color: !isMobile && "#1e2028" }
            }}
            autoComplete="username"
            type="email"
            disabled={email}
            value={email}
            placeholder="Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField color="success" variant="outlined" style={{backgroundColor:"#e8f0fe", borderRadius: "12px"}}
            fullWidth

            autoComplete="username"
            // type="email"
            value={code}
            placeholder="Код"
            {...getFieldProps('code')}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
          />

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>

            <a onClick={() => { sendGetDataForRegistration(email) }}  style={{ textAlign: "end", color: "white", fontWeight: "100" }}>
              Прислать код повторно?
            </a>
          </Stack>

          <LoadingButton style={{ backgroundColor:"white", color: "#54827E" }}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Отправить
          </LoadingButton>
        </Stack>
      </Form>
      {answerFromServer && (<DialogWindow width="30%"
        // margin={"-7% 0% 0% 50%"}
        margin=""
        onClick={() => { setOpen(false) }}
        // AnswerFromServerAuthKey={AnswerFromServerAuthKey} 
        answerFromServer={answerFromServer}
        open={open} />)}
    </FormikProvider>
  );
}
