// /* eslint-disable react/jsx-no-undef */
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Card, Stack, Link, Container, Typography, Button } from '@material-ui/core';
// layouts
import { useEffect } from 'react';
import ModalWindows from "./ForService/ModalWindows";
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
// import AuthSocial from '../components/authentication/AuthSocial';
import { arrayPrivacyPolicy, arrayConsentToPersonalData } from "../constants/constant";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0, 4)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const navigate = useNavigate();
  useEffect(() => {

    localStorage.removeItem("AUTHKEY")
    localStorage.removeItem("userName")
    localStorage.removeItem("PersonalKey")
  }, [])
  // const navigate = useNavigate();

  // const openDemo = (event, newValue) => {
  //   localStorage.setItem("AUTHKEY", "123456789")
  //   localStorage.setItem("account", "0")
  //   localStorage.setItem("userId", "123123")
  //   localStorage.setItem("userName", "Тест Тестович")
  //   localStorage.setItem("PersonalKey", "123456789")
  //   navigate("/")
  // };

  return (
    <RootStyle title="Privacy | ImGenius">
      <ModalWindows openModal setOpenModal={() => navigate(-1)} content={arrayPrivacyPolicy} />
    </RootStyle>
  );
}