/* eslint-disable react/jsx-key */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { MenuBook } from '@material-ui/icons';
import { Button, Grid, TextField, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, styled, Paper, Badge, IconButton, Drawer, InputLabel, MenuItem, Dialog,
    FormControl, Select, Container, createTheme, CardActions, CardContent,  responsiveFontSizes, ThemeProvider, Box, Stack, Divider, 
    List, ListItem, ListItemText, ListItemSecondaryAction,  ListItemIcon, Checkbox, Card, CardHeader } from '@material-ui/core';
// import { Container, Paper, Typography, createTheme, CardActions, CardContent,  responsiveFontSizes, ThemeProvider, Box, Stack, Grid, styled, Divider, Button, List, ListItem, ListItemText, ListItemSecondaryAction,  ListItemIcon, Checkbox, IconButton, Card, CardHeader } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { NavLink } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { isMobile } from 'src/_validations';
import { Icon } from '@iconify/react';
import InfoIcon from '@material-ui/icons/Info';
import Page from 'src/components/Page';

import { setListServiceStore, setListServicesInBasket } from "../../redux/GeneBook/action";

const StyledBadge = withStyles((theme) => ({
  badge: { right: -3, top: 13, border: `2px solid ${theme.palette.background.paper}`, padding: '0 4px' },
}))(Badge);

const listPassport = [ 
    { id: 0, passport: "Риски развития заболевания" }, 
    { id: 1, passport: "Эффективность лекарственных средств" }, 
    { id: 2, passport: "Особенности питания" }, 
    { id: 3, passport: "Личностные особенности" }, 
    { id: 4, passport: "Полногеномный анализ" }, 
    { id: 5, passport: "Спорт" }
];

export default function ModalChoicePassport({ value, openModal, setOpenModal }) {
  const dispatch = useDispatch()
  const { listService, servicesInBasket } = useSelector(state => state.reducerGeneBook)

    const [open, setOpen] = React.useState(openModal);
    const [scroll, setScroll] = React.useState('paper');

    const [hhh, sethhh] = useState([])

    const [state, setstate] = useState([ { id: Date.parse(new Date()), company: "", typePassport: "", file: "" } ])
    
    const [captionPassport, setCaptionPassport] = React.useState('');
    const [company, setCompany] = useState("")
    
    const handleChange = (event, iiiii) => {
      console.log( event.target.value, iiiii, " event.target.value");
        setCaptionPassport(
          //  listPassport[
          event.target.value
        // ].passport
        );
    };
    
    const inputChoiceCSVFile = useRef(null);
    const [CSVFileBase64, setCSVFileToBase64] = useState("");

    const sampleCreateApplication = { id: Date.parse(new Date()), company: "", typePassport: "", file: "" }

    const newCard = () => {
        let createApplication = { id: Date.parse(new Date()), company, typePassport: captionPassport, file: CSVFileBase64 }
        if ( state.length === 1 ) {
          console.log(createApplication, "createApplication");
            setstate( [ createApplication ] ); 
            createApplication = sampleCreateApplication; 
            setstate([...state, {}])
            console.log(state, "kkkkkkk");
        } else {
          const kkk = state.splice(1,-1)
            setstate([...kkk, createApplication])
            setstate( [...state, createApplication ] ); 
            setstate([...state, createApplication ])
            createApplication = sampleCreateApplication; 
            console.log(state, "yyyyyy");
        }
        setCSVFileToBase64("");
        setCaptionPassport("");
        setCompany("");
        console.log(state,"pushhhh");

        // console.log(hhh,"hhh");
    }

    const newCard222 = () => {

     
        setCSVFileToBase64("");
        setCaptionPassport("");
        setCompany("");
        console.log(state,"pushhhh");
    }
    
  
    const generationCSVFileToBase64 = (event) => {
    const reader = new FileReader();
    reader.onload = function (event) { setCSVFileToBase64(reader.result) };
    console.log(event.target.files[0], "00000000");
    if ( event.target.files[0] ) {
        reader.readAsDataURL(event.target.files[0]);
        // console.log(event.target.files[0], "CSVFileBase64");
        console.log( CSVFileBase64 , "after click");
    }
  };

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenModal(false)
  };

  useEffect(() => {
    setOpen(openModal)
    // if ( serviceInBasket.length === 0 ) {
    //   handleClose()
    // }
  }, [openModal])


  const Item = styled(Paper)(({ theme, position }) => ({
    ...theme.typography.body2,
    // padding: theme.spacing(1),
    textAlign: position,
    color: theme.palette.text.secondary,
    // fontWeight: position === "center" && "bold",
    // fontSize: isMobile ? "calc(0.5em + 1vw)" : "calc(0.1em + 1vw)",
    fontSize: isMobile && "calc(0.6em + 1vw)" ,
    // backgroundColor: position === "center" && "#ebf8f2", 
    // width: position === "center" && "7em",
    borderRadius: "20px"
  }));

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const addToBasket = () => {
    if ( servicesInBasket.findIndex( el => el.captionPassport === `${listPassport[captionPassport].passport} (CSV)  -  ${company}` ) !== -1) {
      alert("В корзине есть уже такая услуга");
    } else {
      dispatch(setListServicesInBasket(
        { id: Date.parse(new Date()), Price: 100, company, captionPassport: `${listPassport[captionPassport].passport} (CSV)  -  ${company}`, typePassport: captionPassport, file: CSVFileBase64 }
      ));
      setOpenModal(false);
    }
    // console.log(servicesInBasket.findIndex( el => el.captionPassport === `${listPassport[captionPassport].passport} (CSV)  -  ${company}`), "4444");
    // servicesInBasket.findIndex( el => el.captionPassport === `${listPassport[captionPassport].passport} (CSV)  -  ${company}` )
  }
 
  return (
    <Dialog  maxWidth="lg"  fullScreen={ !!isMobile }  open={open}  onClose={handleClose}  scroll={scroll}  aria-labelledby="scroll-dialog-title"  aria-describedby="scroll-dialog-description" >
    {/* <Drawer anchor="right"  open={open}  onClose={handleClose}  style={{ width: isMobile ? '100vw' : '640px' }}  > */}
      <DialogTitle style={{ display: "flex", justifyContent: "space-between",  alignItems: "center" }} id="scroll-dialog-title">
        CSV   {/* {value} */}
        <IconButton aria-label="close" onClick={handleClose} style={{ float: "right" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

        <DialogContent  dividers={scroll === 'paper'} style={{ width: isMobile ? '100vw' : '640px' }}>
        {/* <DialogContentText id="scroll-dialog-description"  ref={descriptionElementRef}  tabIndex={-1} > */}
        {/* {state?.map( item => 
           ( <Grid direction="row" justifyContent="center" alignItems="center"   style={{ border: "solid 1px lightGrey", borderRadius: "20px", padding: "7px",  margin: "5px",
            // width: "fit-content", display: "flex", flexDirection: "column"
             }} >
              <input  style={{  margin: 15,  marginLeft: 30,  outline: "none", width: "fit-content" }}  ref={inputChoiceCSVFile}  
                  //   style={{display: "none"}}  
                    onChange={(event) => generationCSVFileToBase64(event)}   accept=".csv, .tsv" name="image"  type="file"  id="file"  />
                <TextField  label="Название компании"  id="outlined-size-small" size="small" />
                <FormControl sx={{ m: 1, minWidth: "150px", maxWidth: "80%" }}>
                    <InputLabel id="demo-simple-select-autowidth-label">Тип паспорта</InputLabel>
                    <Select value={captionPassport}  onChange={handleChange} autoWidth label="Тип паспорта" size="small" style={{ width: "100%" }} >
                        {listPassport.map( item => <MenuItem  value={ item.id }> { item.passport } </MenuItem> )}
                    </Select>
                </FormControl>
                <Button size="small" variant="outlined"  onClick={ () => { setstate( [...state.filter( el => el.id !== item.id ) ]); console.log( state.filter( el => el !== item.id), "7878787") } } >
                   удалить
                </Button>
            </Grid>)
        )} */}

    {/* <Page title="Dashboard: Products | ImGenius"> */}
        {/* <Container > */}
            {/* <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}> */}
                { state.map((item, index) =>
                    <Grid style={{ margin: "5px" }} direction="row" justifyContent="center" alignItems="center">
                        <Card disabled variant="outlined" style={{ height: "100%" }} >
                            <CardHeader style={{ padding: "13px 17px" }}
                                // action={
                                //     <IconButton color="error" aria-label="settings"  style={{ border: "1px solid", padding: "initial"}}
                                //         onClick={ () => { setstate( [...state.filter( el => el.id !== item.id ) ]); console.log( state.filter( el => el !== item.id), "7878787") } } >
                                //         <CloseIcon />
                                //     </IconButton>
                                // }
                                title={ <TextField error={!company} onChange={ (e) => setCompany(e.target.value) }  label="Название компании"  id="outlined-size-small" size="small" /> }
                            />
                            <CardContent style={{ padding: "0px 17px" }}>
                                <TextField error={!CSVFileBase64} style={{ outline: "none", width: "fit-content" }}  ref={inputChoiceCSVFile}  onChange={(event) => generationCSVFileToBase64(event)}   accept=".csv, .tsv" name="image"  type="file"  id="file"  />
                            </CardContent>
                            <CardActions style={{ padding: "10px 10px", justifyContent: "flex-start", }}>
                                <FormControl size="small" error={!String(captionPassport)}  FormControl sx={{ m: 1, minWidth: "150px", maxWidth: "65%" }}>
                                    <InputLabel disabled style={{ padding: "0px" }}id="demo-simple-select-autowidth-label">Тип паспорта</InputLabel>
                                    <Select value={item.typePassport !== "" ? item.typePassport : captionPassport }  onChange={ (e) => handleChange(e, item.typePassport)} autoWidth label="Тип паспорта" size="small" >
                                        {listPassport.map( item => <MenuItem  value={ item.id }> { item.passport } </MenuItem> )}
                                    </Select>
                                </FormControl>
                                {/* <Button variant={item.choice ? "outlined" : "contained"} >
                                    {item.choice ? "Отменить" : "Добавить"}
                                </Button> */}
                            </CardActions>
                        </Card>
                    </Grid>
                )}
            {/* </Grid> */}
        {/* </Container> */}
    {/* </Page> */}


            {/* <Button disabled={ state.length === 0 ? false : !(!!company && !!CSVFileBase64 && !!captionPassport) } style={{ width: "min-content", margin: "10px", float: "right"}} size="small" variant="outlined" 
              onClick={ () => newCard() } >
               <Icon icon="carbon:add-alt" width={24} height={24} />
            </Button> */}

            {/* </DialogContentText> */}
        </DialogContent>

      <DialogActions style={{ background: "#f4f6f8", display: "flex", justifyContent: "center" }} >
        <Button disabled={ !(!!company && !!CSVFileBase64 && !!String(captionPassport)) }
            onClick={ () => addToBasket() } 
            style={{ borderRadius: "20px" }} variant="outlined" color="secondary">
            В корзину &nbsp;  <Icon disabled icon="carbon:shopping-cart-plus" width={24} height={24} />
        </Button>
      </DialogActions>
    {/* </Drawer> */}
 </Dialog>
  );
}

