import React, { useState } from 'react';
import { Button, Grid, Typography, Card, CardContent, CardActionArea, CardActions } from '@material-ui/core';
import { Autorenew, History } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import Modals from "../Modals";

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
);

const useStyles = makeStyles((theme) => ({
  cardContent: { borderRadius: '16px' },
  blockOfCardHeader: { display: 'flex',  flexFlow: 'row wrap-reverse',  justifyContent: 'space-between' },
  blockOfDescription: { paddingTop: '12px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
  card: { textDecoration: 'none' },
  cardActionArea: { textDecoration: 'none', borderRadius: '16px', background: "#f4f6f8" },
  cardAction: { textDecoration: 'none', borderRadius: '16px', padding: "12px" },
  cardHeader: { padding: 0, fontStyle: 'italic' },
  parentGrid: {textAlign: 'center',  backgroundColor: theme.palette.primary.dark,  borderRadius: "50%",  boxShadow: "0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)" },
  textColor: { color: theme.palette.primary.dark },
  // descriptionChapter: { position: "absolute", width: "94px", height: "25px", left: "103px", top: "14px", fontFamily: "OpenSans", fontStyle: "normal", fontWeight: "normal", fontSize: "20px", lineHeight: "25px", color: "#262624" },
  // descriptionChapter: { fontFamily: 'OpenSans', fontStyle: "normal", fontWeight: "normal", fontSize: "14px", lineHeight: "19px",  position: "absolute" },
  titleChapter: { width: "100%", fontFamily: "OpenSans", fontStyle: "normal", fontWeight: "normal", lineHeight: "25px", color: "#262624", fontSize: isMobile ? "calc(0.8em + 1vw)" : "18px" },
  descriptionChapter: { width: "100%", fontFamily: "OpenSans", fontStyle: "normal", fontWeight: "normal", lineHeight: "18px", color: "#918975", fontSize: isMobile ? "calc(0.8em + 1vw)" : "13px"},

  // titleChapter: { position: "absolute", width: "94px", height: "25px", top: "14px", fontFamily: "OpenSans", fontStyle: "normal", fontWeight: "normal", fontSize: "18px", lineHeight: "25px", color: "#262624" },
  // descriptionChapter: { width: "70%", position: "absolute", height: "25px", fontFamily: "OpenSans", fontStyle: "normal", fontWeight: "normal", fontSize: "13px", lineHeight: "18px", color: "#262624" }
  blockButton: { display: "flex",  flexDirection: "row",  alignItems: "inherit",  width: "100%",  justifyContent: "flex-end", padding: "0px 15px" },
}));

const listHistory = [ { id: 3411, date: "21.09.2021"} ]

export default function ContentCard({ block }) {
    const [openModal, setOpenModal] = useState(false)
    const classes = useStyles();
  return (
    // #f4f6f8 #F3F6E0 #ebf8f2 #E9F3EF
    //   <Grid className={classes.parentGrid} >
        <Card variant="outlined" style={{ height: "100%" }} >
            {/* <CardActionArea >  */}
                <CardContent style={{ paddingBottom: "5em" }}>  {/* style={{ padding: "14px 8px"}} */}
                    {/* <Grid >    display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2} */}
                      {/* <Grid gridColumn="span 3" item>
                      <img style={{ maxWidth: "100%" }} src={pathImage} alt="" width="80px" height="80px" />
                      </Grid> */}
                      <Grid gridColumn="span 9" item style={{ display: "flex",  flexDirection: "column", alignItems: "center", textAlign: "left" }}>
                        <Typography className={ classes.titleChapter }> { block.caption } </Typography>
                        {/* <Typography className={ classes.descriptionChapter } > { block.organization } </Typography> */}
                        <Grid className={ classes.blockButton} gridColumn="span 3" item>
                            {/* <Button fontSize="small"  variant="outlined" color="primary"> <Autorenew fontSize="small"  /> </Button> */}
                        </Grid>
                        {openModal && <Modals block={block} openModal={openModal} setOpenModal={setOpenModal} listHistory={listHistory} />}
                      </Grid>
                    {/* </Grid> */}
                </CardContent> 
                <CardActions style={{ padding: "24px", justifyContent: "space-between", bottom: 0,  position: "absolute",  width: "fit-content",  right: 0  }}>
                  <Button fontSize="small" onClick={() => setOpenModal(true)} variant="outlined" color="primary" position="center">
                      {/* // style={{ padding: "5px", margin: "8px 0" }} */}
                      {/* {listHistory[0].date}  */}
                      Список
                      {/* <History style={{ padding: "0px 1px", margin: "0px 5px" }} fontSize="small" /> */}
                  </Button>
                </CardActions>
            {/* </CardActionArea> */}
        </Card>
    //  </Grid>
  );
}