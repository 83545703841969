import React from 'react';
import { Grid, Typography, Card, CardContent, CardActionArea } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  cardContent: { borderRadius: '16px' },
  blockOfCardHeader: { display: 'flex',  flexFlow: 'row wrap-reverse',  justifyContent: 'space-between' },
  blockOfDescription: { paddingTop: '12px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
  card: { textDecoration: 'none' },
  cardActionArea: { textDecoration: 'none', borderRadius: '16px', background: "#f4f6f8" },
  cardAction: { textDecoration: 'none', borderRadius: '16px', padding: "12px" },
  cardHeader: { padding: 0, fontStyle: 'italic' },
  parentBlock: {textAlign: 'center',  backgroundColor: theme.palette.primary.dark,  borderRadius: "50%",  boxShadow: "0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)" },
  textColor: { color: theme.palette.primary.dark },
  // descriptionChapter: { position: "absolute", width: "94px", height: "25px", left: "103px", top: "14px", fontFamily: "OpenSans", fontStyle: "normal", fontWeight: "normal", fontSize: "20px", lineHeight: "25px", color: "#262624" },
  // descriptionChapter: { fontFamily: 'OpenSans', fontStyle: "normal", fontWeight: "normal", fontSize: "14px", lineHeight: "19px",  position: "absolute" },
  titleChapter: { width: "100%", fontFamily: "OpenSans", fontStyle: "normal", fontWeight: "normal", fontSize: "18px", lineHeight: "25px", color: "#262624" },
  descriptionChapter: { width: "100%", fontFamily: "OpenSans", fontStyle: "normal", fontWeight: "normal", fontSize: "13px", lineHeight: "18px", color: "#262624" },
  // titleChapter: { position: "absolute", width: "94px", height: "25px", top: "14px", fontFamily: "OpenSans", fontStyle: "normal", fontWeight: "normal", fontSize: "18px", lineHeight: "25px", color: "#262624" },
  // descriptionChapter: { width: "70%", position: "absolute", height: "25px", fontFamily: "OpenSans", fontStyle: "normal", fontWeight: "normal", fontSize: "13px", lineHeight: "18px", color: "#262624" }
}));

export default function CardBlock({ title, description, pathImage }) {
  const classes = useStyles();
  return (
    // #f4f6f8 #F3F6E0 #ebf8f2 #E9F3EF
      <Grid className={classes.parentBlock} >
        <Card style={{ backgroundColor: "#F9F9F9", padding: 0 }} >
            <CardActionArea > 
                <CardContent style={{ padding: "14px 8px", border: "1px solid #919eab3d", borderRadius: "16px" }} >
                    <Grid display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                      <Grid gridColumn="span 3" item>
                      <img style={{ maxWidth: "100%" }} src={pathImage} alt="" width="80px" height="80px" />
                      </Grid>
                      <Grid gridColumn="span 9" item style={{ display: "flex",  flexDirection: "column", alignItems: "center", textAlign: "left" }}>
                        <Typography className={ classes.titleChapter }> { title } </Typography>
                        <Typography className={ classes.descriptionChapter } > { description } </Typography>
                      </Grid>
                    </Grid>
                </CardContent> 
            </CardActionArea>
        </Card>
     </Grid>
  );
}