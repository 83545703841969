export const CHANGE_CURRENT_ACTION = "CHANGE_CURRENT_ACTION";
export const GET_DATA = "GET_DATA";
export const SET_SIZE_BOOK = "SET_SIZE_BOOK";
export const CHANGE_NOW_PAGE = "CHANGE_NOW_PAGE";
export const SET_NUMBER_PAGE = "SET_NUMBER_PAGE";
export const SET_HEIGHT_CHAPTER = "SET_HEIGHT_CHAPTER";
export const SET_HEIGHT_ONE_TABLE = "SET_HEIGHT_ONE_TABLE";
export const ADD_VALUE_INTO_ARRAY_HEIGHT_BEFORE_TABLE = "ADD_VALUE_INTO_ARRAY_HEIGHT_BEFORE_TABLE";
export const SET_COUNT_PAGES = "SET_COUNT_PAGES";
export const SET_COUNT_OF_PAGE_TAKES_TABLE = "SET_COUNT_OF_PAGE_TAKES_TABLE";
export const ADD_IMAGE_KAROTYPE = "ADD_IMAGE_KAROTYPE";
export const ADD_LIST_NUMBERS_PAGE = "ADD_LIST_NUMBERS_PAGE";
export const ADD_IMAGE_LOCALIZATION = "ADD_IMAGE_LOCALIZATION";
export const GET_ENTRIES_DATA = "GET_ENTRIES_DATA";
export const HEIGHT_BLOCK_FOR_DATA_PATIENT = "HEIGHT_BLOCK_FOR_DATA_PATIENT";
export const SPLICE_TEST_TEXT_FOR_RENDER = "SPLICE_TEST_TEXT_FOR_RENDER";
export const ADD_TEXT_DATA_FOR_DIAGNOSIS = "ADD_TEXT_DATA_FOR_DIAGNOSIS";
export const PLUS_ONE_COUNT = "PLUS_ONE_COUNT";
export const SET_STATUS_ABOUT_TWO_PAGE_PATIENT = "SET_STATUS_ABOUT_TWO_PAGE_PATIENT";


export const SET_CHECK_DOWNLOAD_DATA = "SET_CHECK_DOWNLOAD_DATA";
export const CLEAR_DATA = "CLEAR_DATA";
export const SET_COUNT_FOR_GENERATION_PDF = "SET_COUNT_FOR_GENERATION_PDF";

export const SET_QUANTITY_ALL_ELEMENTS_FOR_DOWNLOAD = "SET_QUANTITY_ALL_ELEMENTS_FOR_DOWNLOAD";


export const SET_DATA_FOR_SOFT = "SET_DATA_FOR_SOFT";
export const SET_DATA_FOR_IN_WORK = "SET_DATA_FOR_IN_WORK";




export const CHECK_INTERVAL_FOR_STATUS = "CHECK_INTERVAL_FOR_STATUS";

export const SET_DATA_FOR_IN_HISTORY_COMPLETED = "SET_DATA_FOR_IN_HISTORY_COMPLETED";

export const SET_DATA_FOR_IN_HISTORY_CANCELED = "SET_DATA_FOR_IN_HISTORY_CANCELED";

export const SET_USER_INFO = "SET_USER_INFO";
export const GET_LAB_INFO = "GET_LAB_INFO";
export const GET_SEARCH_LIB = "GET_SEARCH_LIB";
