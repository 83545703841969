import { Button } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';

// import { Text, TouchableHighlight, View } from "react-native";
// import { styles } from "../../constants";
// import "../../../index.css"
// import {translations as t} from "../. ./constants/translations";

const CustomButton = (props) => {
    console.log('CustomButton props: ', props)

    return <Button {...props} onClick={() => props.onClick(props)} style={{ ...props.style }} />
   
}

// const mapStateToProps = (state) => ({
//    appRegion: state.appRegion,
// });

export default CustomButton;