/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import faker from 'faker';
import PropTypes from 'prop-types';
// material
import { Typography, Card } from '@material-ui/core';
// eslint-disable-next-line import/no-unresolved
import ModalDescription from "src/pages/ForService/ModalDescription";
import { Timeline, TimelineItem, TimelineContent, TimelineConnector, TimelineSeparator, TimelineDot, // TimelineOppositeContent
} from '@material-ui/lab';
import { HelpOutline } from '@material-ui/icons';
// utils
// import { nullFormat } from 'numeral';
import { isMobile } from "src/_validations";
import { fDateTime } from '../../../utils/formatTime';

// ----------------------------------------------------------------------

// const TIMELINES = [
  // {
  //   title: 'Сдача биоматериала',
  //   time: faker.date.past(),
  //   type: 'order1'
  // },    
  // {
  //   title: 'Подготовка образца',
  //   time: faker.date.past(),
  //   type: 'order2'
  // },
  // {
  //   title: 'Секвенирование гена',
  //   time: faker.date.past(),
  //   type: 'order3'
  // },
  // {
  //   title: 'Обработка данных',
  //   time: faker.date.past(),
  //   type: 'order4'
  // },
  // {
  //   title: 'Готово',
  //   time: faker.date.past(),
  //   type: 'order5'
  // },

//   {
//     title: 'Заявка на сдачу крови подана, мы вас оповестим о времени сдачи крови',
//     // time: ,
//     type: 'order0'
//   },
//   {
//     title: 'Произведена запись для сдачи биоматериала',
//     // time: ,
//     type: 'order1'
//   },    
//   {
//     title: 'Биоматериал сдан',
//     // time: faker.date.past(),
//     type: 'order2'
//   },
//   {
//     title: 'Биоматериал выдан',
//     // time: faker.date.past(),
//     type: 'order3'
//   },
//   {
//     title: 'Биоматериал принят в Центре Секвенирования',
//     // time: faker.date.past(),
//     type: 'order4'
//   },
//   {
//     title: 'Подготовка образца',
//     // time: faker.date.past(),
//     type: 'order5'
//   },
//   {
//     title: 'Проведение секвенирования',
//     // time: faker.date.past(),
//     type: 'order6'
//   },
//   {
//     title: 'Обработка данных, полученных в ходе секвенирования',
//     // time: faker.date.past(),
//     type: 'order7'
//   },
//   {
//     title: 'Завершено. Вы можете ознакомиться с результатом в личном кабинете в разделе «Генетическая карта».',
//     // time: faker.date.past(),
//     type: 'order8'
//   },

// ];

// ----------------------------------------------------------------------

OrderItem.propTypes = {
  item: PropTypes.object,
  isLast: PropTypes.bool
};

function OrderItem({ item, isLast }) {
  const [openModalDescription, setOpenModalDescription] = useState(false)
  const [nowDescription, setNowDescription] = useState(null)

  const { Type, Title, time, Description } = item;
  const status = localStorage.getItem("status");

  const vip = localStorage.getItem("vip")
  return (
    <TimelineItem>
      {/* <TimelineOppositeContent color="text.secondary">
        {fDateTime(time)}
      </TimelineOppositeContent> */}
      <TimelineSeparator>
        <TimelineDot title={ Type === 0 ? "Успешно" : Type === 1 ? "В процессе" : "Не начат" } 
          sx={{
            bgcolor:
              (Type === 0 && (vip || status ? 'success.main' : 'grey.main')) ||
              (Type === 1 && (vip ? 'success.main' : 'grey.main')) ||
              (Type === 2 && (vip ? 'success.main' : 'grey.main')) ||
              (Type === 3 && (vip ? 'success.main' : 'grey.main')) ||
              (Type === 4 && (vip ? 'success.main' : 'grey.main')) ||
              (Type === 5 && (vip ? 'success.main' : 'grey.main')) 
              // (Type === 'order7' && (vip ? 'success.main' : 'grey.main')) ||
              // (Type === 'order8' && (vip ? 'success.main' : 'grey.main'))
          }}
        />

        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent   style={{  display: "flex", justifyContent: "space-between" }}>
        <Typography style={{ cursor: "pointer", display: "grid", position: "relative", top: !isMobile && "-7px" }} 
        title={Title} variant="h5">
          {Title}
          
          {Type === 0 && status ? <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            {fDateTime(status)}
          </Typography> : vip ? <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            {fDateTime(faker.date.past())}
          </Typography> : null}

          <Typography  style={{ paddingTop: "5px" }} >
            {item?.Description ? <div dangerouslySetInnerHTML={{ __html: item?.Description }} /> : "нет описания"}
          </Typography>

        </Typography>

        {/* <HelpOutline onClick={() => { setOpenModalDescription(true); setNowDescription(Title) }}
          style={{ position: "relative", top: isMobile ? "4px" : "7px", marginLeft: "3px", fontSize: isMobile && "18px" }} /> */}

        {/* <ModalDescription value={item} openModal={openModalDescription} setOpenModal={setOpenModalDescription} /> */}
        

      </TimelineContent>
    </TimelineItem>
  );
}

export default function AppOrderTimeline() {
  const { listStatusOrders } = useSelector( state => state.reducerGeneBook )
  // console.log(listStatusOrders.sort((a, b) => a.Type - b.Type));
  return (
    <Card style={{ boxShadow: "none" }}
      sx={{
        '& .MuiTimelineItem-missingOppositeContent:before': {
          display: 'none'
        }
      }}
    >
      {/* //   <CardHeader title="Order Timeline" />
    //   <CardContent> */}
    <Timeline >
          {/* {TIMELINES.map((item, index) => (
            <OrderItem key={item.title} item={item} isLast={index === TIMELINES.length - 1} />
          ))} */}
        {listStatusOrders.sort((a, b) => a.Type - b.Type).map((item, index) => (
            <OrderItem key={item.Title} item={item} isLast={index === listStatusOrders.length - 1} />
          ))}
        </Timeline>
      {/* //   </CardContent> */}
    </Card>
  );
}
