import { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Button, Typography, Container } from '@material-ui/core';
// components
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';


// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  // paddingTop: theme.spacing(15),
  // paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

export default function PageNoNetwork() {

  const navigate = useNavigate();

  useEffect(() => {

    const timer = setInterval(() => {
      if (navigator.onLine) {

        navigate(-1)
      }
    }, 2000);
    return () => {
      clearInterval(timer);
    };

    // setInterval(() => {

    //   if (navigator.onLine) {
    //     navigate(-1)
    //   }
    // }, 2000);
  }, [])

  return (
    <RootStyle title="404 Page Not Found | ImGenius">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                Интернет соединение прервано!
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary', marginBottom: "1em" }}>
              Извините, мы не можем загрузить страницу. Пропало интернет соединение?
              Обязательно проверьте свою сеть.
            </Typography>

            {/* <motion.div variants={varBounceIn}>
              <Box
                component="img"
                src="/static/illustrations/illustration_404.svg"
                sx={{ height: 260, mx: 'auto' }}
              />
            </motion.div> */}

            <Button to="/" size="large" variant="contained" component={RouterLink}>
              Обновить
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
