/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Typography, Box, Divider, styled, Paper, Badge, IconButton, Drawer, FormControlLabel, Checkbox
} from '@material-ui/core';
import { ShoppingCart, Clear, Delete } from '@material-ui/icons';
// import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import { withStyles } from '@material-ui/styles';
// import IconButton from '@mui/material/IconButton';
// import DeleteIcon from '@mui/icons-material/Delete';
import { Icon } from '@iconify/react';
// import CurrencyRubleIcon from '@iconify/icons-ic/outline-currency-ruble';
// import deleteFill from '@iconify/icons-eva/trash-2-fill';
// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
// eslint-disable-next-line import/named
// import { arrayPrivacyPolicy, arrayConsentToPersonalData } from "../constants/constant";
import CloseIcon from '@material-ui/icons/Close';
import { formatCurrency, formatPrice, isMobile } from 'src/_validations';
import { removeServiceFromBasket } from 'src/redux/GeneBook/action';

const StyledBadge = withStyles((theme) => ({
  badge: { right: -3, top: 13, border: `2px solid ${theme.palette.background.paper}`, padding: '0 4px' },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))(Badge);

export default function ScrollDialog({ openModal, setOpenModal, content, type, listInvitro, serviceInBasket, setServiceInBasket, setListService }) {
  const dispatch = useDispatch()
  const [check, setCheck] = React.useState(false);

  //   useEffect(() => {
  //     setListService(listInvitro)
  // }, [serviceInBasket])

  // const allPrice = serviceInBasket.reduce( ( total, el ) => total + Number( el.Price ), 0 )

  let allPrice = []
  if ( type === "service" ) {
    allPrice = serviceInBasket.reduce( ( total, el ) => total + Number( el.Price ), 0 )
  }

  const [open, setOpen] = useState(openModal);
  const scroll = 'paper'

  // const handleClickOpen = (scrollType) => () => {
  //   setOpen(true);
  //   setScroll(scrollType);
  // };

  const handleClose = () => {
    setOpen(false);
    setOpenModal(false)
  };

  useEffect(() => {

    setOpen(openModal)

  }, [openModal])

  // const transformationList = ( idx, index, item ) => {
    const transformationList = ( item ) => {

    //  подсчет выбранных услуг

    //     let temp = serviceInBasket

    //         if ( serviceInBasket.find( e => e.Id === item.Id ) ) {
    //             temp = serviceInBasket.filter( elem => elem.Id !== item.Id )
            
    //         }
       
    //         dispatch(setServiceInBasket(temp))

    //     // логика для отображения выбранных и не выбранных элементов

    // const choiceObject = item
    // choiceObject.choice = !choiceObject.choice
    // const mutationObjectFromList = { ...listInvitro[idx] }
    // mutationObjectFromList.Items?.splice( index, 1, choiceObject )
    // const yyy =  [ mutationObjectFromList, ...listInvitro.filter( (el, num) => num !== idx ) ].sort(( a, b ) => a.Id - b.Id );
    dispatch(removeServiceFromBasket( item ))


    // const choiceObject = item
    // choiceObject.choice = !choiceObject.choice
    // const mutationObjectFromList = { ...listInvitro[idx  - 1] }
    // mutationObjectFromList.Items?.splice( index, 1, choiceObject )
    // const yyy =  [ mutationObjectFromList, ...listInvitro.filter( (el, num) => num !== idx  - 1  ) ].sort(( a, b ) => a.index - b.index );
    //     dispatch(setListService( yyy ))

    // dispatch(setListService([ mutationObjectFromList, ...listInvitro.filter( (el, num) => num !== idx  - 1 ) ]))
}


  const Item = styled(Paper)(({ theme, position }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: position,
    color: "black",
    fontWeight: position === "center" && "bold",
    // fontSize: isMobile ? "calc(0.5em + 1vw)" : "calc(0.1em + 1vw)",
    // fontSize: isMobile && "calc(0.6em + 1vw)" ,
    backgroundColor: position === "center" && "#ebf8f2", 
    width: position === "center" && "7em",
    // borderRadius: "20px"
  }));

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const show = () => listInvitro

  const findIndexElement = ( listElements, element ) => {

    let numIndex = null
    listElements.Items.map( ( el, i ) => el.Id === element.Id && ( numIndex = i ) ) 

    return numIndex
  }

  const creationOfStructureForBasket = () => (
    // <>
        // {/* {show().map( (items, idx) => */}
          // {/* <> */}
            <Box sx={{ width: 1, paddingBottom: "-80px" }}>
                {serviceInBasket.map( (item, index) =>
                  // item.ParentId === items.Id &&
                  <>
                    <Box key={index}  style={{ padding: isMobile ? "10px 0px" : "10px", display: "flex", justifyContent: "space-between" }}>
                      <Box style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        flexDirection: isMobile ? "column" : "row"

                      }}>
                        <Typography style={{ color: "black" }}> {item.captionPassport} </Typography>
                        <Typography style={{ color: "black", margin: !isMobile && "0px 8px", minWidth: isMobile && "fit-content" }}>
                          {item.Price} <span style={{fontFamily: "system-ui"}}> ₽</span>
                        </Typography>
                     
                      </Box>
                      <Box  style={{ display: "flex", flexDirection: "row", justifyContent: "end", alignItems:"center",  marginLeft: isMobile && "15px" }}>
                        
                        <IconButton
                         onClick={() => transformationList( item ) }
                          style={{ marginLeft: "10px" }} aria-label="delete" size="small">
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      </Box>
                    </Box>
                    <Divider /> 
                  </>
                )}
            </Box>

          // {/* </> */}
        // {/* )} */}
    // </>
  )
 
  return (

    <Drawer anchor="right"
        // style={{ minWidth: "500px", maxWidth: "808px" }}
        // maxWidth="lg"
        // fullScreen={ !!isMobile }
        open={open}
        onClose={handleClose}
      style={{ width: isMobile ? '100vw' : '640px' }}
        // scroll={scroll}
        // aria-labelledby="scroll-dialog-title"
        // aria-describedby="scroll-dialog-description"
      >


        <DialogTitle id="scroll-dialog-title" style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
          {type === "service" ? "Корзина" : content?.title}

            <IconButton aria-label="close" onClick={handleClose} style={{ float: "right" }}>
              <CloseIcon />
            </IconButton>

        </DialogTitle>


      <DialogContent dividers={scroll === 'paper'} style={{ width: isMobile ? '100vw' : '640px' }}>
        <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          style={{ margin: "1em 0" }}
          >

          {type === "service" && serviceInBasket
            ? (serviceInBasket.length > 0 ? creationOfStructureForBasket() : <Typography style={{ minWidth: !isMobile && "600px" }} >
              Вы ничего не выбрали
            </Typography>)
            : content?.list?.map(el => <Typography> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {el} </Typography>)
            }


          <Divider />

          {type === "service" && allPrice ? <div style={{
            padding: "10px 0", position: "sticky",
            backgroundColor: "white",
            bottom: 0
          }}>

            {/* <div style={{
            marginTop: "1em",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
            <Typography variant="h5">
              количество услуг:
            </Typography>
            <Typography variant="h5">
              {serviceInBasket.length}
            </Typography>
          </div> */}

            <div style={{
              
            marginTop: "1em",
            display: "flex",
            justifyContent: "space-between",
              alignItems: "center", color: "black"
            }}>
              <Typography variant="h3">
                Итого:
              </Typography>
              <Typography variant="h4">
                {/* {formatPrice(allPrice)} руб */}
                {allPrice} 
                <span style={{fontFamily: "system-ui"}}> ₽</span>
              </Typography>
            </div>
            <Typography style={{ fontSize: "14px" }}>
              Стоимость доставки будет рассчитана на следующем шаге
            </Typography>
            <Divider style={{ margin: "1em 0" }} />
            <FormControlLabel
              control={<Checkbox checked={check} onChange={() => setCheck(!check)} name="checkedA" />}
              label={<span style={{ fontSize: "12px" }}>Ознакомлен и полностью принимаю <a>условия Публичной оферты</a>, <a>условия
                Политики конфиденциальности и обработки персональных данных</a>, даю <a>согласие на обработку своих персональных
                данных</a> и получение информационных сообщений, связанных с оказанием услуг.</span>}
            />
            {/* <Checkbox>

            </Checkbox> */}
          </div> : null}


          </DialogContentText>
        </DialogContent>

      <DialogActions style={{ background: "#f4f6f8" }} >
        {type === "service" && <Button fullWidth variant="contained"
          // component={RouterLink} to="/dashboard/checkout"
          disabled={serviceInBasket.length < 1 || !check} size="large">
          К доставке и оплате
        </Button>}
        {/* <Button style={{ borderRadius: "20px" }} variant="outlined" color="error" onClick={handleClose}>Закрыть</Button> */}
          {/* <Button onClick={handleClose}>Subscribe</Button> */}
        </DialogActions>
    </Drawer>
  );
}