/* eslint-disable no-nested-ternary */
import axios from "axios";
// import { upperCase } from "lodash";
import { setListContentForChapters, setListContentForChaptersDemo, setListDescription, setListDescriptionDemo, setListStatusOrders, activateLoader, setListServiceStore } from "./action";
import { ucFirst } from "../../_validations";

const checkGlobalPath = true
const path = checkGlobalPath ? "https://service.socmedica.com:9055/api/" : "http://192.168.14.49:5000/api/";

// const authKey = localStorage.getItem("AUTHKEY");
// const userCheck = localStorage.getItem("account");

const userAccount = localStorage.getItem("account")==="3"?"1":"0"

export const postElement = ( elements )  => ({
  method: "POST",
  headers: { "Content-Type": "application/json" },
  body: JSON.stringify(elements),
});

// export async function requestListForHealth( dispatch, nowChapter ) {
    // axios.post("https://service.socmedica.dev:9055/api/requestHealth").then((res) => {  // send Data
//     axios.post("http://192.168.14.49:5000/api/requestHealth", { AuthKey: localStorage.getItem("AUTHKEY") }).then((res) => {  // send Data
//       const resp = res;
//       if ( resp.statusText === "OK" && resp.data.Data ) {
//         dispatch (setListContentForChapters( resp.data.Data, nowChapter ) )
//       } else {
//         alert("Ошибка, некорректная структура данных");
//       }
//     });
//   }

  export async function requestListForChapter( dispatch, nowChapter ) {
    dispatch( activateLoader( true ) )

    // const pathForRequest = `request${ ucFirst(nowChapter) }`

    const pathForRequest =  nowChapter === "health" ? "requestHealth" :  nowChapter === "medications" ? "requestMedicament" :  nowChapter === "nutrition" ? "requestFood" :
    nowChapter === "sport" ? "requestSport" : "requestBeauty"

    axios.post( path + pathForRequest, { AuthKey: localStorage.getItem("AUTHKEY"), User: userAccount }).then((res) => {  // send Data
    // axios.post( `${path}request${ ucFirst(nowChapter) }`, { AuthKey: localStorage.getItem("AUTHKEY"), User: localStorage.getItem("account") } ).then((res) => {  // send Data
        // axios.post("http://192.168.14.49:5000/api/responseHealth", { AuthKey: localStorage.getItem("AUTHKEY") } ).then((res) => {  // send Data
      const resp = res;
      if (resp.data.Message === "OK" && resp.data.Data) {
          dispatch ( setListContentForChapters( resp.data.Data, nowChapter ) )
          dispatch( activateLoader( false ) )
        } else {
          alert("Ошибка, некорректная структура данных");
          dispatch( activateLoader( false ) )
        }
    });
  }


  export async function requestListForChapterDemo( dispatch, nowChapter ) {
    console.log("don`t work");
    dispatch( activateLoader( true ) )

    // const pathForRequest = `request${ ucFirst(nowChapter) }`

    const pathForRequest =  nowChapter === "healthDemo" ? "requestHealthDemo" :  nowChapter === "medicationsDemo" ? "requestMedicamentDemo" :  nowChapter === "nutritionDemo" ? "requestFoodDemo" :
    nowChapter === "sportDemo" ? "requestSportDemo" : "requestBeautyDemo"

    axios.post( path + pathForRequest, { AuthKey: localStorage.getItem("AUTHKEY"), User: userAccount }).then((res) => {  // send Data
    // axios.post( `${path}request${ ucFirst(nowChapter) }`, { AuthKey: localStorage.getItem("AUTHKEY"), User: localStorage.getItem("account") } ).then((res) => {  // send Data
        // axios.post("http://192.168.14.49:5000/api/responseHealth", { AuthKey: localStorage.getItem("AUTHKEY") } ).then((res) => {  // send Data

        const resp = res;
        console.log(resp, "vvvvvv");

      if (resp.data.Message === "OK" && resp.data.Data) {
          dispatch ( setListContentForChaptersDemo( resp.data.Data, nowChapter ) )
          dispatch( activateLoader( false ) )
        } else {
          alert("Ошибка, некорректная структура данных");
          dispatch( activateLoader( false ) )
        }
    });
  }


  
  // export async function asyncGetFilesFromDirectory( ) {
  //   axios.get( "http://192.168.14.49:5010/api/requestDirectory" ).then((res) => {
  //     const resp = res.data;
  //     console.log(resp, "resp asyncGetFilesFromDirectory");
  //     console.log(res, "res ");
  //     // if (resp.IsSuccess) {
  //     //   dispatch(getEntriesData(resp.Data));
  //     // } else {
  //     //   console.log(resp.Error, messageOfBagForBack);
  //     //   alert(messageForAlert);
  //     // }
  //     // return  res.IsSuccess
  //   });
  // }




  // export async function requestDescription( dispatch, item ) {

  //   // axios.get("https://service.socmedica.dev:9055/api/responseHealth").then((res) => {  // send Data
  //   axios.post("http://192.168.14.49:5000/api/responseHealth", { AuthKey: localStorage.getItem("AUTHKEY"), Data: item } ).then((res) => {  // send Data
  //     const resp = res;
  //     if (resp.statusText === "OK" && resp.data.Data ) {
  //       localStorage.setItem( "ObjectForDescription", JSON.stringify( resp.data.Data[0] ) )
  //       dispatch (setListDescription( resp.data.Data ) )
  //     } else {
  //       alert("Ошибка, некорректная структура данных");
  //     }
  //   });
  // }

  
  export async function requestDescription( dispatch, item, nowChapter ) {
    dispatch( activateLoader( true ) )

    // console.log(nowChapter, "descripppttiioonn");

    // const nowChapter = window.location.href.split("/").reverse()[0]

    // const pathForResponse = `response${ ucFirst( nowChapter )}`

    const pathForResponse =  nowChapter === "health" ? "responseHealth" :  nowChapter === "medications" ? "responseMedicament" :  nowChapter === "nutrition" ? "responseFood" :
    nowChapter === "sport" ? "responseSport" : "responseBeauty"
    
    // axios.post( `${path}response${ ucFirst(nowChapter) }`, { AuthKey: localStorage.getItem("AUTHKEY"), Data: item, User: localStorage.getItem("account") } ).then((res) => {  // send Data
    axios.post( path + pathForResponse , { AuthKey: localStorage.getItem("AUTHKEY"), Data: item, User: userAccount } ).then((res) => {  // send Data
      const resp = res;
      if (resp.data.Message === "OK" && resp.data.Data) {
          // localStorage.setItem( "ObjectForDescription", JSON.stringify( resp.data.Data[0] ) )
          localStorage.setItem( "ObjectForDescription", JSON.stringify( resp.data.Data ) )
          dispatch (setListDescription( resp.data.Data ) )
          dispatch( activateLoader( false ) )
        } else {
          alert("Ошибка, некорректная структура данных");
          dispatch( activateLoader( false ) )
        }
    });
  }




  export async function requestDescriptionDemo( dispatch, item, nowChapter ) {
    dispatch( activateLoader( true ) )

    console.log(nowChapter, "descripppttiioonn");

    // const nowChapter = window.location.href.split("/").reverse()[0]

    // const pathForResponse = `response${ ucFirst( nowChapter )}`

    const pathForResponse =  nowChapter === "healthDemo" ? "responseHealthDemo" :  nowChapter === "medicationsDemo" ? "responseMedicamentDemo" :  nowChapter === "nutritionDemo" ? "responseFoodDemo" :
    nowChapter === "sportDemo" ? "responseSportDemo" : "responseBeautyDemo"
    
    // axios.post( `${path}response${ ucFirst(nowChapter) }`, { AuthKey: localStorage.getItem("AUTHKEY"), Data: item, User: localStorage.getItem("account") } ).then((res) => {  // send Data
    axios.post( path + pathForResponse , { AuthKey: localStorage.getItem("AUTHKEY"), Data: item, User: userAccount } ).then((res) => {  // send Data
      const resp = res;
      if (resp.data.Message === "OK" && resp.data.Data) {
          // localStorage.setItem( "ObjectForDescription", JSON.stringify( resp.data.Data[0] ) )
          localStorage.setItem( "ObjectForDescription", JSON.stringify( resp.data.Data ) )
          dispatch (setListDescriptionDemo( resp.data.Data ) )
          dispatch( activateLoader( false ) )
        } else {
          alert("Ошибка, некорректная структура данных");
          dispatch( activateLoader( false ) )
        }
    });
  }



  // export async function requestDescription( dispatch, item ) {
  //   const nowChapter = window.location.href.split("/").reverse()[0]

  //   const pathForResponse =  nowChapter === "health" ? "responseHealth" :  nowChapter === "medications" ? "responseMedicament" :  nowChapter === "nutrition" ? "responseFood" :
  //   nowChapter === "sport" ? "responseSport" : "responseBeauty"

  //   axios.post(`http://192.168.14.49:5000/api/${pathForResponse}`, { AuthKey: localStorage.getItem("AUTHKEY"), Data: item,  User: localStorage.getItem("account") } ).then((res) => {  // send Data
  //   // axios.post(`https://service.socmedica.dev:9055/api/${pathForResponse}`, { AuthKey: localStorage.getItem("AUTHKEY"), Data: item,  User: localStorage.getItem("account") } ).then((res) => {  // send Data
  //   const resp = res;
  //     if (resp.statusText === "OK" && resp.data.Data ) {
  //       // localStorage.setItem( "ObjectForDescription", JSON.stringify( resp.data.Data[0] ) )
  //       localStorage.setItem( "ObjectForDescription", JSON.stringify( resp.data.Data ) )
  //       dispatch (setListDescription( resp.data.Data ) )
  //     } else {
  //       alert("Ошибка, некорректная структура данных");
  //     }
  //   });
  // }


  const uuu = {
    levels: null,
    text: "сах",
    libs: [ 25, 39, 50, 52, 125, 131 ],
    permit: {authkey: localStorage.getItem("AUTHKEY")},
    authkey: localStorage.getItem("AUTHKEY")
  }


export async function asyncSearch(data, dispatch) {
  const response = await fetch("https://service.socmedica.dev:9018/search", postElement(uuu));
  const answer = await response.json();  
  console.log(answer.result, "9018/search" );
  // dispatch(setSearch(answer.result));
  // setAnswerFromServer(answer.alerts && answer.alerts[0].message || answer.alert.message)
  // setAnswerFromServer(answer)  {
  //  "text": "Хир",
  //  "libs": [ 123,  25,  121 ],
  //  "levels": null,
  //  "permit": {
  //    "authkey": "uvwEg09uYxNoQE6wcviiE7Di"
  //  }
  // }
  // await answer.Alert.level === "success" && navigate(`/confirm/${data.email}`)
}

export async function requestPrice( dispatch ) {
  dispatch( activateLoader( true ) )
    axios.post( `${path}requestPrice`, { AuthKey: localStorage.getItem("AUTHKEY"), User: userAccount, Data:[] }).then((res) => {  // send Data
      const resp = res;
      // console.log( resp, "resp" );
      // if ( resp.statusText === "OK" && resp.data.Data ) {
      if (resp.data.Data) {
        dispatch( setListServiceStore( resp.data.Data ) )
        dispatch( activateLoader( false ) )
      } else {
        alert("Ошибка, некорректная структура данных");
        dispatch( activateLoader( false ) )
      }
    });
}

export async function requestOrderStatus( dispatch ) {

  dispatch( activateLoader( true ) )

  axios.post( `${path}requestOrderStatus`, { AuthKey: localStorage.getItem("AUTHKEY"), User: userAccount, Data:[] }).then((res) => {  // send Data
    const resp = res;
    // console.log( resp, "resp" );
    // if ( resp.statusText === "OK" && resp.data.Data ) {
    if (resp.data.Data) {
      dispatch( setListStatusOrders( resp.data.Data ) )
      dispatch( activateLoader( false ) )
    } else {
      alert("Ошибка, некорректная структура данных");
      dispatch( activateLoader( false ) )
    }
  });
}


// setListStatusOrders








export async function requestAccountAuthorization( ) {
    axios.post( `http://192.168.14.21:5001/api/requestAccountAuthorization`, {
       Protocol: {
         "Email": "adgem@yopmail.com",
         "Password": "111111"
       } 
    }).then((res) => {  // send Data
      const answer = res;
      console.log( answer, "answer" );

      // if ( resp.statusText === "OK" && resp.data.Data ) {
      //   dispatch( setListServiceStore( resp.data.Data ) )
      //   dispatch( activateLoader( false ) )
      // } else {
      //   alert("Ошибка, некорректная структура данных");
      //   dispatch( activateLoader( false ) )
      // }
    });
}

export async function requestAccountRegistrationUserAnonymous( ) {
  axios.post( `http://192.168.14.21:5001/api/requestAccountRegistrationUserAnonymous`, {} ).then((res) => {  // send Data
      const answer = res;
      console.log( answer, "answer" );

    // if ( resp.statusText === "OK" && resp.data.Data ) {
    //   dispatch( setListServiceStore( resp.data.Data ) )
    //   dispatch( activateLoader( false ) )
    // } else {
    //   alert("Ошибка, некорректная структура данных");
    //   dispatch( activateLoader( false ) )
    // }
  });
}