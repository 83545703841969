/* eslint-disable no-plusplus */
/* eslint-disable import/no-unresolved */
import React, { useState,  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  // Stack,
  Container, Button,
  // Typography,
  Card,
  CardContent,
  CardActions, CardHeader, Grid, IconButton, Avatar, Accordion, AccordionDetails, Typography, AccordionSummary
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import ButtonDownload from 'src/components/ButtonDownload';
// import menu2Fill from '@iconify/icons-eva/arrow-ios-downward-fill';
import qrcode from '@iconify/icons-ant-design/qrcode-outlined';
import { NavLink, useNavigate } from 'react-router-dom';
import ProfileForm from 'src/components/authentication/profile/ProfileForm';
// import { AppOrderTimeline } from 'src/components/_dashboard/app';
import { styled } from '@material-ui/core/styles';
import { asyncExit } from 'src/redux/Logistic/requestAction';
import { device, view } from 'src/_validations';
import Page from '../components/Page';
// import Scrollbar from '../components/Scrollbar';


// import Button from 'src/theme/overrides/Button';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));


// ----------------------------------------------------------------------

export default function Profile() {
  const { account } = useSelector(state => state.reducerLogistic)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const userName = localStorage.getItem("userName")

  const lastName = localStorage.getItem("lastName")
  const firstName = localStorage.getItem("firstName")

  
  const [dataBase64, setBase64] = useState([]);
  const [editor, setEditor] = useState("");
  const [fileList, setFileList] = useState([]);
  const loadingFile = () => (value) => {
    let index = 0;
    // if (cycle && (dataBase64.length + value.target.files.length) > cycle) { // 40000000
    //   alert('Загрузить можно только ' + cycle + " файлов")
    // }
    Object.keys(value.target.files).map(key => {
      const reader = new FileReader();
      const file = value.target.files[key];
      if (file.size > 40000000) { // 40000000
        alert(`Допустимый размер файла 5Мб, размер текущего файла ${  Math.round(file.size / 8000000)  }Mб`)
      }
      if (file.size > 40000000) { // 40000000
        alert(`Допустимый размер файла 5Мб, размер текущего файла ${  Math.round(file.size / 8000000)  }Mб`)
      }

      reader.readAsDataURL(file);
      reader.onload = function () {
        index += 1;

        console.log(reader.result);
        // if (cycle) {
        //   dataBase64.push(reader.result);
        //   setBase64(dataBase64);
        // } else {
          setBase64([reader.result]);
        // }

        // result = reader.result;

        setEditor(++key)
        return null;
      };
      reader.onerror = function (error) {
      }; 
      return null;
    })
  };
  return (
    <RootStyle title="Profile | ImGenius">
      <Container maxWidth="xl" style={{ height: `100%` }}>
  
        <Grid container justifyContent="center"  spacing={2}>
          <Grid item xs={12} sm={4} md={4}>
            <Card >
              <CardHeader
                avatar={
                  <Avatar sx={{ width: 56, height: 56 }} aria-label="recipe"/>
                }
                action={
                  <IconButton aria-label="settings" component={NavLink} to="/dashboard/scaner" >
                    <Icon icon={qrcode} />
                  </IconButton>
                }
                title={((firstName && lastName)? `${firstName} ${lastName}`: userName)}
                subheader={account === 1 ? "Врач" : "Пациент"}
              />
           
              <CardActions>
              <ButtonDownload accept=".jpg,.jepg,.png" onDowenload={loadingFile()}/>
                <Button onClick={() => asyncExit(dispatch, navigate)} size="small" color="primary" style={{ marginLeft: 'auto' }}>
                  Выйти
                </Button>
                {/* <Button size="big" color="primary">
                  
                  <Icon icon={qrcode}/>
                </Button> */}

              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={8} md={8} >

            <Card  style={{ marginBottom: "2em" }}>
              <CardHeader 
              title="Редактирование профиля"
              subheader="можете изменить данные ниже"
              />

              <CardContent>
                <ProfileForm />
              </CardContent>
       
            </Card>

            {/* <Accordion>
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography >Конфигурации</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {device}
                </Typography>
                <Typography>
                  {view}
                </Typography>
                <Typography>
                  {`${window.innerWidth}x${window.innerHeight}` }
                </Typography>
              
              </AccordionDetails>
            </Accordion> */}

          </Grid>
        </Grid>

        {/* <Button size="small" color="primary">
          Выйти
        </Button> */}

      </Container>
    </RootStyle>
  );
}
