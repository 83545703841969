/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { Collapse, IconButton } from "@material-ui/core";
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { Alert } from "@material-ui/lab";
// import { Close } from "@material-ui/icons";

const DialogWindow = ({ onClick, AnswerFromServerAuthKey, answerFromServer, open }) => (
  // <div style={{ width, margin, position: "absolute", bottom: "3em", right: "5em" }}>
    <Collapse in={open}>
    <Alert 
    // style={{ marginTop: "16px" }}
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={onClick}  >
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        }
        severity={
          AnswerFromServerAuthKey
            ? AnswerFromServerAuthKey.level
            : answerFromServer[0]
              ? answerFromServer[0].level
              : answerFromServer.alerts
                ? answerFromServer.alerts[0].level
                : answerFromServer.alert
                  ? answerFromServer.alert.level
                  : "error"
        }
      >
        {AnswerFromServerAuthKey
          ? AnswerFromServerAuthKey.message
          : answerFromServer[0]
            ? answerFromServer[0].title
            : answerFromServer.alerts
              ? answerFromServer.alerts[0].message
              : answerFromServer.alert.level === "success"
                ? answerFromServer.alert.message
                : answerFromServer.alert.level !== "success" && answerFromServer.alert.message !== ""
                  ? answerFromServer.alert.message
                  : answerFromServer.alert.level !== "success" && answerFromServer.alert.message === ""
                    ? answerFromServer.alert.message
                    : "ошибка"}
      </Alert>
    </Collapse>
  // </div>
);

export default DialogWindow;