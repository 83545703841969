/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// import logout from '@iconify/icons-eva/log-out-fill';
import backFill from '@iconify/icons-eva/arrow-back-fill';
import homeFill from '@iconify/icons-eva/home-fill';
// import arrowFill from '@iconify/icons-eva/arrow-back-fill';
// import { Link as RouterLink, useNavigate, } from 'react-router-dom';
// material 
import { alpha, styled } from '@material-ui/core/styles';
import { Box, Stack, AppBar, Toolbar, IconButton,  Tooltip, Button } from '@material-ui/core';
// components
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { asyncExit } from 'src/redux/Logistic/requestAction';
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from 'src/_validations';
import Logo from 'src/components/Logo';
import { ProductCartWidget } from 'src/components/_dashboard/products';
import { MHidden } from '../../components/@material-extend';
//
// import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
// import LanguagePopover from './LanguagePopover';
// import HeaderLink from './HeaderLink';
import HeaderLinkText from './HeaderLinkText';
import NotificationsPopover from './NotificationsPopover';

// import { Icon } from '@iconify/react';
// import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
// import { Icon } from '@iconify/react';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};


export default function DashboardNavbar({ onOpenSidebar }) {
  const { servicesInBasket } = useSelector(state => state.reducerGeneBook)

  // const history = useHistory();
  const header = window.location.pathname;
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const email = localStorage.getItem("email")
  const anonim = localStorage.getItem("anonim")
  const vip = localStorage.getItem("vip")
//   const { index, routes } = dangerouslyGetState()
console.log(header);
  return (
    // <RootStyle style={{ backgroundColor: "#c4cdd54a" }}> 
    <RootStyle style={{ backgroundColor: "#eaedef", width: header === "/" && "100%" }}>
      <ToolbarStyle>
        <Stack spacing={2}   direction="row" >
          {/* {isMobile ? <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'primary' }}>
              <Icon icon={menu2Fill} />
            </IconButton>: null} */}
          {header !== "/dashboard/menu" ? (
            header === "/"? <Logo/>:<>{isMobile ? <IconButton onClick={onOpenSidebar} sx={{ mr: 0, color: 'primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>: null}
          <Tooltip title="Назад" style={{marginLeft: "0"}}>
              
            <IconButton onClick={() => navigate(-1)} color="primary">
              <Icon icon={backFill} />
            </IconButton>
          </Tooltip></>) : 
            <MHidden width="lgUp">
              <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'primary' }}>
              <Icon icon={menu2Fill} />
              </IconButton>
            </MHidden>
          }
          <HeaderLinkText />
        </Stack>

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}
          {/* <AccountPopover /> */}

          {/* {servicesInBasket.length ?  */}
          {/* <ProductCartWidget badgeContent={servicesInBasket.length} /> */}
          {/* // openModal={openModal} setOpenModal={setOpenModal} /> : null} */}

          <AccountPopover />
   
          {/* <MHidden width="lgUp">
            <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
              <Icon icon={menu2Fill} />
            </IconButton>
          </MHidden> */}
      
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}