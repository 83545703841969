/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate,  } from 'react-router-dom';
// material
import playFill from '@iconify/icons-eva/play-circle-outline';
import { Stack, TextField, IconButton, InputAdornment, Typography, Link, Divider } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import DialogWindow from 'src/components/DialogWindow';
import { useDispatch } from 'react-redux';
import { asyncRegistration, asyncRegistrationEmail } from 'src/redux/Logistic/requestAction';
import { isMobile } from 'src/_validations';
import TemporaryDrawer from 'src/components/_dashboard/BlockContent/BlockDrawer';
import { SERVICE, KEY_DEVELOPER, PARENT_ID_FOR_REGISTRATION, INFO_BY_EMAIL } from "../../../constants/keys";
// ----------------------------------------------------------------------
import ModalWindows from "../../../pages/ForService/ModalWindows";
import { arrayPrivacyPolicy, arrayConsentToPersonalData } from "../../../constants/constant";


export default function RegisterEmailForm() {
  const navigate = useNavigate();
  // const { email } = useParams();
  // const [dataForModal, setDataForModal] = useState("")
  // const [showPassword, setShowPassword] = useState(false);
  // const [openModal, setOpenModal] = useState( false )
  // const [email, setEmail] = useState("");
  // const [code, setCode] = useState("");
  // const [password, setPassword] = useState("");
  // const [personKey, setPersonKey] = useState("");
  // const [getAuthKey, setGetAuthKey] = useState("");
  const [answerFromServer, setAnswerFromServer] = useState("");
  // const [AnswerFromServerAuthKey, setAnswerFromServerAuthKey] = useState("");
  const [open, setOpen] = useState(true);

  const RegisterSchema = Yup.object().shape({
    // firstName: Yup.string()
    //   .min(2, 'Слишком коротко!')
    //   .max(50, 'Слишком длинно!')
    //   .required('Требуется имя'),
    // lastName: Yup.string().min(2, 'Слишком коротко!').max(50, 'Слишком длинно!').required('Требуется фамилия'),
    email: Yup.string().email('Электронная почта должна быть действительна').required('Требуется электронная почта'),
    // password: Yup.string().min(6, 'Слишком коротко!').max(50, 'Слишком длинно!').required('Необходим пароль'),
    // confirm: Yup.string().min(6, 'Слишком коротко!').max(50, 'Слишком длинно!').required('Необходим пароль')
  });


  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: (values, actions) => {
      sendDataForRegistration(values.email)
      actions.setSubmitting(false);
      // navigate('/dashboard', { replace: true });
    }
  });
  const dispatch = useDispatch();
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  let sendDataForRegistration = (email) => {
    // e.preventDefault();middleName,
   
    // if (confirm === password) {
    //   setOpen(true);
    //   if (!firstName) {
    //     firstName="firstName"
    //   }
    //   if (!lastName) {
    //     lastName="lastName"
    //   }
      // console.log("htubcnhfwbz");
      asyncRegistrationEmail({ email, key: INFO_BY_EMAIL }, 
      dispatch, setAnswerFromServer, navigate);
    // }else{
    //   setAnswerFromServer({ alert: {level: "error", message: "Пароли не совпадают" } })
    // }

  };


  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          
          <TextField variant="outlined"
            style={{ backgroundColor: "#e8f0fe", borderRadius: "12px" }}
            fullWidth
            // InputLabelProps={{
            //   shrink: true, sx: { color: !isMobile && "#1e2028" }
            // }}
            // autoComplete="username"
            type="email"
            placeholder="Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}

            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{
                  marginRight: "8px",
                  // width: "45%",
                  // justifyContent: "space-around"
                }}>
               
                  <IconButton type="submit" edge="end" style={{ marginLeft: "8px" }}>
                    <Icon icon={playFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}

          />
          <div style={{ textAlign: "center", color: "white" }}>
            {/* или */}
            {/* <Divider style={{ backgroundColor: "white" }} /> */}
            {/* или <Divider style={{ backgroundColor: "white" }} /> */}
          </div>
          {/* <Divider variant="middle" /> */}
          {/* <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            style={{ backgroundColor: "white", color: "#54827E" }}
          >
            Далее
          </LoadingButton> */}

          <TemporaryDrawer block={{ "Content": [{ "Type": "comment", "Value": " " }] }}
           title="Анонимно пинкод"  />
           
        </Stack>
      </Form>
      {answerFromServer && (<DialogWindow width="30%"
        // margin={"-7% 0% 0% 50%"}
        margin=""
        onClick={() => { setOpen(false) }}
        // AnswerFromServerAuthKey={AnswerFromServerAuthKey} 
        answerFromServer={answerFromServer}
        open={open} />)}
    </FormikProvider>
  );
}
