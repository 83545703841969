/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Card, Stack, Link, Container, Typography, Button } from '@material-ui/core';
// layouts
import { useEffect } from 'react';
import { asyncResponseAnonimCreate, asyncResponseGuestCreate } from 'src/redux/Logistic/requestAction';
import { useDispatch } from 'react-redux';
// import AuthLayout from '../layouts/AuthLayout';
// components
import TemporaryDrawer from 'src/components/_dashboard/BlockContent/BlockDrawer';
import { isMobile } from 'src/_validations';
import Page from '../components/Page';
// import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
// import AuthSocial from '../components/authentication/AuthSocial';
import { infoCompany } from "../constants/constant";
import PinCode from './PinCode';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: { display: 'flex',  flexDirection: "column",  color: "white",  width: "100%" }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,

  // margin: 'auto',
  display: 'flex', flexDirection: 'column', justifyContent: isMobile ? 'center' : 'end', 
  padding: theme.spacing(12, 0, 5), // minHeight: isMobile && '100vh',
}));

export default function Pin() {
  useEffect(() => {
    localStorage.removeItem("AUTHKEY")
    localStorage.removeItem("PersonalKey")
    localStorage.removeItem("email")
    localStorage.removeItem("userName")
    localStorage.removeItem("userId")
    localStorage.removeItem("account")
  }, [])

  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const openDemo = (pin) => {
    if (!localStorage.getItem("AUTHKEY")) {
      asyncResponseAnonimCreate({ Billing: { Pin: pin } }, dispatch, navigate)
    }
  };

  return (
    <RootStyle title="Login | ImGenius">

    <PinCode/>
    </RootStyle>
  );
}

