/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import playFill from '@iconify/icons-eva/play-circle-outline';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel, Tabs, Tab, Paper, Typography, Button } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import DialogWindow from 'src/components/DialogWindow';
import TemporaryDrawer from 'src/components/_dashboard/BlockContent/BlockDrawer';
import { isMobile } from 'src/_validations';
import { asyncLogin, asyncCheckPersonKey, asyncConfirmemail, asyncResponseAnonimCreate } from "../../../redux/Logistic/requestAction";
import { titleMainPage, descriptionOfMainPage, buttonEntries, buttonConfirm, buttonRegistration, titleForConfirmEmail, buttonResetPassword } from "../../../constants/constant";
import { SERVICE, KEY_DEVELOPER } from "../../../constants/keys";
import { back_Phone, caption_Email, text_Error_Format, text_Password, text_alert_for_password, text_Person_key, text_Insert_key } from "../../../constants/constantForAuthorization";
// import { isEmail } from "../../../constants/functions";
// material
// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  ttt: { borderRadius: "12px" }
}));

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`
  };
}

export default function LoginForm() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [value, setValue] = useState(0);
  const [openPass, setOpenPass] = useState(!isMobile); // false
  const [openCamera, setOpenCamera] = useState(false);
  
  // const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  // const [password, setPassword] = useState("");
  const [personKey, setPersonKey] = useState("");
  const [getAuthKey, setGetAuthKey] = useState("");
  const [answerFromServer, setAnswerFromServer] = useState("");
  const [AnswerFromServerAuthKey, setAnswerFromServerAuthKey] = useState("");
  const [open, setOpen] = useState(true);
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Электронная почта должна быть действительна').required('Электронная почта обязательна'),
    password: Yup.string().min(6, 'Слишком коротко!').max(50, 'Слишком длинно!').required('Необходим пароль')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      key: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      
      !answerFromServer.authkey ? show(values.email, values.password): sendAuthKey(values.key)
      localStorage.setItem("remember", values.remember)      
      actions.setSubmitting(false);
      // navigate('/dashboard', { replace: true });
    }
  });
  const dispatch = useDispatch();
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  useEffect(() => {
  
    if (value === 0 && localStorage.getItem("AUTHKEY")) {
      navigate('/', { replace: true })
      // asyncConfirmemail( { key: KEY_DEVELOPER, email, code, service: SERVICE}, dispatch, setAnswerFromServer );
    }
  }, [getAuthKey, AnswerFromServerAuthKey, navigate, value]);

  const show = (email, password) => {

    const result = asyncLogin({ key: KEY_DEVELOPER, service: SERVICE, email, password }, dispatch, setAnswerFromServer, setGetAuthKey, value, navigate );

    setOpen(true);
  };

  const sendAuthKey = (personKey) => {
    // e.preventDefault();
    asyncCheckPersonKey({ personKey }, dispatch, setAnswerFromServerAuthKey, navigate);
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  // const openDemo = (pin) => {
  //   if (!localStorage.getItem("AUTHKEY")) {
  //     asyncResponseAnonimCreate({Biling: { Pin: pin}},dispatch, navigate)
  //   }
  // };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>


        <Stack spacing={3}>
          {openPass && isMobile ? <Button startIcon={<ArrowBackIosIcon />}  
          size="small" variant="contained" style={{ width: "max-content" }} 
          onClick={() => setOpenPass(false)}>
            Назад
          </Button> : null}

          <TextField color="success" variant="outlined"
            style={{ backgroundColor: "#e8f0fe" }}

            fullWidth
            autoComplete="username"
            type="email"
            placeholder="Email / Логин"
            onClick={() => setOpenPass(true)}
   
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            className={classes.ttt}
          />

          {openPass ? <TextField color="success" variant="outlined"
            style={{
              backgroundColor: "#e8f0fe", borderRadius: "12px"
            }}
    
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            placeholder="Пароль"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{
                  marginRight: "8px",
                }}>
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                  <IconButton type="submit" edge="end" style={{ marginLeft: "8px" }}>
                    <Icon icon={playFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          /> :  (isMobile ? <Button size="large" 
          style={{ color: "white", backgroundColor: "#68938F", border: "solid 1px white", justifyContent: "start" }}
          variant="outlined"
          onClick={() => navigate('/scaner/qr')} 
          sx={{ mt: 3 }}>
          QR код
        </Button>:null)

          }

        </Stack>

        <Stack direction="column" sx={{ my: 2 }}>
          <FormControlLabel style={{ color: "white" }}
            control={<Checkbox style={{ color: "white" }} {...getFieldProps('remember')} checked={values.remember} />}
            label="Запомнить меня"
          />

          <Link component={RouterLink} to="/resetpass" style={{ color: "white", fontWeight: "100", marginTop: "1em" }}>
            Забыли пароль?
          </Link>

          <Link component={RouterLink} to="/register" style={{ color: "white", fontWeight: "100", marginTop: "1em" }}>
            Регистрация?
          </Link>

          <Typography style={{
            color: "white", fontWeight: "100", display: "flex",
            flexFlow: "wrap", marginTop: "1em"
          }}>
            Телефон техподдержки:
            <a href='tel:+7(499)991-1988' style={{ color: "white", cursor: "pointer", marginLeft: "8px" }}> +7 (499) 991-1988 </a>
          </Typography>
        </Stack>

      </Form>
      {answerFromServer && ( <DialogWindow width="30%" 
          margin=""
           onClick={() => { setOpen(false)}} 
           AnswerFromServerAuthKey={AnswerFromServerAuthKey} answerFromServer={answerFromServer} 
           open={open} /> )}
    </FormikProvider>
  );
}
