import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
// import { useSelector } from "react-redux";
// import {headerSelector} from "../../ducks/propertiesReducer";
import Link from "@material-ui/core/Link";
// import HomeIcon from '@material-ui/icons/Home';
// import {STORE_PATH} from "../documents/StoreRouter";
// import history from "../../history";
import { Link as RouterLink } from 'react-router-dom';
import { Stack, Typography } from "@material-ui/core";
// import IconButton from "@material-ui/core/IconButton";
// import isMobile from "../../utils/isMobile";
// import { Icon } from '@iconify/react';
// import menu2Fill from '@iconify/icons-eva/home-fill';

export const LOCATIONS = {
    "camera": "Сканер",
    "app": "Генпаспорт",

    "health": "Здоровье",
    "medications": "Лекарства",
    "nutrition": "Питание",
    "sport": "Спорт",
    "beauty": "Красота",

    "healthDemo": "Здоровье",
    "medicationsDemo": "Лекарства",
    "nutritionDemo": "Питание",
    "sportDemo": "Спорт",
    "beautyDemo": "Красота",

    "historyPay": "История платежей",

    "genetics": "Генетика",
    // "content": "Описание",
    "status": "Статус заказа",
    "scaner": "QR-код",
    "demo": "Демо версия",
    "profile": "Профиль",
    "laboratories": "Лаборатории",
    "map": "Лаборатории",

    "checkout": "Оплата",
    // "storage": "Место хранения",
    "service": "Услуги",
    "listPassports": "Список паспортов"
};

const HeaderLinkText = () => {

    const header = window.location.pathname; // useSelector(headerSelector);
    // const navigate = useNavigate();
    const LinkRouter = props => <Link {...props} component={RouterLink} />;
    //
    const links = header.split("/");

    const renderLinks = () => {
// console.log(links, "links");
        const href = "";

        return <Typography variant="h6" style={{ color: "#000" }}>
            { (links[links.length - 2] === 'demo' && `${LOCATIONS[links[links.length - 1]]}-демо`) || LOCATIONS[links[links.length - 1]] || LOCATIONS[links[links.length - 2]] || "" }
        </Typography>
        // <LinkRouter key={href} to={href}>
        //     {LOCATIONS[link] || link}
        // </LinkRouter>


    };

    if (links.length === 1) {
        return <div>ImGenius</div>
    }

    return <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        {/* <Typography variant="h4">Меню</Typography> */}
        {renderLinks()}
    </Stack>
    // <Breadcrumbs maxItems={isMobile ? 2 : 5} aria-label="breadcrumb" >
    // {/* <IconButton onClick={() => navigate('/')}>
    //     <Icon icon={menu2Fill} />
    // </IconButton> */}



    // </Breadcrumbs>

};

export default HeaderLinkText
