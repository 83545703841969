const data = 
[
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Красного Маяка, д. 1, корп. 1.",
    "Lat": 55.610969159426,
    "Lng": 37.600170986972,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=393"
  },
  // {
  //   title: "Placemark 1",
  //   descr: "Some description",
  //   coords: [55.831903, 37.411961]
  // },
  // [Lat, Lng]
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Совхозная, д. 10, корп. 1.",
    "Lat": 55.671086528411,
    "Lng": 37.752597755697,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=402"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Каширское шоссе, д. 68, корп. 2;",
    "Lat": 55.643124632615,
    "Lng": 37.675517636648,
    "TimeTable": "07:00 - 23:55",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка; Бесплатный Wi-Fi; Результат за 4 часа; С 7.00 утра",
    "MedicalServices": "Взятие урологического материала; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; Эхокардиография (ЭХО-КГ)",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Результаты за 4 часа; Выдача результатов; Взятие урологического материала; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Эхокардиография (ЭХО-КГ); График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=403"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Профсоюзная, д. 43, корп. 2",
    "Lat": 55.668231673654,
    "Lng": 37.55503671031,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=412"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. 9-я Парковая, д. 8 А",
    "Lat": 55.790773608168,
    "Lng": 37.800297145881,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=419"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Генерала Кузнецова, д. 18, корп. 2.",
    "Lat": 55.688334536625,
    "Lng": 37.855320483146,
    "TimeTable": "07:30 - 15:00",
    "Comment": "",
    "MedicalServices": "Взятие урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=435"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Ломоносовский пр-т, д. 18.",
    "Lat": 55.692724917968,
    "Lng": 37.54009234696,
    "TimeTable": "07:00 - 20:00",
    "Comment": "",
    "MedicalServices": "Взятие гинекологического и урологического материала; Внутривенное капельное вливание; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Внутривенное капельное вливание; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=438"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Вельяминовская, д. 6",
    "Lat": 55.780830884176,
    "Lng": 37.722269982803,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; Внутривенное капельное вливание; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; Внутривенное капельное вливание; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=441"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Бибиревская, д. 19.",
    "Lat": 55.882980770518,
    "Lng": 37.600736755697,
    "TimeTable": "06:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=454"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Хорошёвское ш., д. 22",
    "Lat": 55.774415976311,
    "Lng": 37.543830906746,
    "TimeTable": "06:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=463"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Ленинградский пр-т, д. 45, корп. 1.",
    "Lat": 55.799082421002,
    "Lng": 37.533506809062,
    "TimeTable": "06:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Ускоренный результат",
    "MedicalServices": "Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=478"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Ратная, д. 8, корп. 2.",
    "Lat": 55.573923340369,
    "Lng": 37.582038992468,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; Запись результатов исследований на электронный носитель - USB - flash drive; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=479"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Осенний б-р, д. 8, корп. 2.",
    "Lat": 55.755808846233,
    "Lng": 37.410875431284,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=481"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Товарищеский пер., д. 7, стр. 1. (Товарищеский пер., д. 9/11)",
    "Lat": 55.742359851783,
    "Lng": 37.661000394381,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Запись результатов исследований на электронный носитель - USB - flash drive; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=483"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Новочеркасский б-р, д. 55.",
    "Lat": 55.649922035714,
    "Lng": 37.740774810321,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=484"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Народного Ополчения, д. 42, корп. 1.",
    "Lat": 55.790627007584,
    "Lng": 37.489475750201,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; УЗИ; Запись результатов исследований на электронный носитель - USB - flash drive; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=485"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Шипиловский пр-д, д. 39, корп. 1.",
    "Lat": 55.611581660582,
    "Lng": 37.696562931562,
    "TimeTable": "00:00 - 23:55",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Внутривенное капельное вливание; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Внутривенное капельное вливание; УЗИ; ЭКГ; Запись результатов исследований на электронный носитель - USB - flash drive; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=486"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Большая Никитская, д. 12/1, стр. 3",
    "Lat": 55.756563,
    "Lng": 37.606209,
    "TimeTable": "07:30 - 15:00",
    "Comment": "Оплата банковской картой; Без очереди",
    "MedicalServices": "Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=487"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Ново-Переделкино, Боровское шоссе, д. 20",
    "Lat": 55.648890348818,
    "Lng": 37.368983555095,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=490"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Нагатинская, д. 1, стр. 33.",
    "Lat": 55.682635348956,
    "Lng": 37.62933130712,
    "TimeTable": "",
    "Comment": "",
    "MedicalServices": "",
    "NotMedicalServices": "",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=492"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, пр-т Мира, д. 61",
    "Lat": 55.785928381282,
    "Lng": 37.634460687392,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=496"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Бауманская, д. 50/12, стр. 1",
    "Lat": 55.769545887825,
    "Lng": 37.679001860652,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=504"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Адмирала Лазарева, д. 68.",
    "Lat": 55.538546129128,
    "Lng": 37.507453671041,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=522"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Ялтинская, д. 2",
    "Lat": 55.651378155191,
    "Lng": 37.610678365484,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=530"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Широкая, д. 7, корп. 1",
    "Lat": 55.888932275173,
    "Lng": 37.656719805959,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического материала; Кольпоскопия; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; Кольпоскопия; Врач-консультант; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=539"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Химкинский б-р, д. 9",
    "Lat": 55.850177663698,
    "Lng": 37.448982685852,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Ускоренный результат; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Врач-терапевт; УЗИ; Эхокардиография (ЭХО-КГ)",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Врач-терапевт; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Эхокардиография (ЭХО-КГ); Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=550"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Бескудниковский бульвар, д. 6, корп. 2",
    "Lat": 55.85799,
    "Lng": 37.564826,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=590"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Тимирязевская, д. 17, корп. 1",
    "Lat": 55.812274081915,
    "Lng": 37.566519144583,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Видеокольпоскопия",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём мочи; Выдача результатов; Видеокольпоскопия; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=612"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Зелёный пр-т, д. 45/31",
    "Lat": 55.754142019894,
    "Lng": 37.800074093173,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Ускоренное получение результатов; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=623"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Никитский бульвар, д. 9, стр. 1",
    "Lat": 55.754687495717,
    "Lng": 37.599834814558,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без очереди; Результат за 4 часа",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=641"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, 3-й Митинский пер., д. 7",
    "Lat": 55.846158150727,
    "Lng": 37.347460088824,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без очереди; Ускоренный результат; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=642"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Усачева, д. 29, корп. 1.",
    "Lat": 55.72447945931,
    "Lng": 37.563747619451,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=643"
  },
  {
    "Name": "Invitro",
    "City": "Химки",
    "Address": "Московская обл., г. Химки, ул. Панфилова, д. 3",
    "Lat": 55.885801821445,
    "Lng": 37.427605626918,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Врач-консультант; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=705"
  },
  {
    "Name": "Invitro",
    "City": "Голицыно",
    "Address": "Московская обл., г. Голицыно, Коммунистический пр-т, д. 1",
    "Lat": 55.614912138544,
    "Lng": 36.987637750215,
    "TimeTable": "07:30 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=715"
  },
  {
    "Name": "Invitro",
    "City": "Железнодорожный",
    "Address": "г. Железнодорожный, ул. Советская, д. 18",
    "Lat": 55.750256,
    "Lng": 38.018248,
    "TimeTable": "07:00 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Врач-консультант; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=719"
  },
  {
    "Name": "Invitro",
    "City": "Зеленоград",
    "Address": "г. Зеленоград, пл. Юности, д. 2, стр. 1",
    "Lat": 56.001825999993,
    "Lng": 37.211147949738,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Ускоренный результат; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; Эхокардиография (ЭХО-КГ)",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; Эхокардиография (ЭХО-КГ); График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=720"
  },
  {
    "Name": "Invitro",
    "City": "Королёв",
    "Address": "г. Королёв, пр-т Космонавтов, д. 27",
    "Lat": 55.914392710116,
    "Lng": 37.868905212303,
    "TimeTable": "07:00 - 16:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=725"
  },
  {
    "Name": "Invitro",
    "City": "Мытищи",
    "Address": "г. Мытищи, ул. Белобородова, д. 2, корп. 3",
    "Lat": 55.924321276616,
    "Lng": 37.753889669061,
    "TimeTable": "07:00 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=730"
  },
  {
    "Name": "Invitro",
    "City": "Одинцово",
    "Address": "г. Одинцово, б-р Маршала Крылова, д. 15",
    "Lat": 55.6875276676,
    "Lng": 37.29839195645,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём кала; Приём мочи; Выдача результатов; Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Врач-консультант; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=735"
  },
  {
    "Name": "Invitro",
    "City": "Серпухов",
    "Address": "Московская обл., г. Серпухов, ул. 1-я Московская, д. 7",
    "Lat": 54.914622600535,
    "Lng": 37.414633954216,
    "TimeTable": "07:30 - 17:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=739"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Менжинского, д. 32, корп. 3",
    "Lat": 55.870172535885,
    "Lng": 37.665892739184,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие гинекологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Взятие гинекологического материала; Кольпоскопия; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=767"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, пр-т Мира, д. 146",
    "Lat": 55.82066346762,
    "Lng": 37.64205099114,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=945"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, мкр-н Кожухово, ул. Святоозерская, д. 26",
    "Lat": 55.712098254983,
    "Lng": 37.893286457672,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=946"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Генерала Кузнецова, д. 19, корп. 1.",
    "Lat": 55.683965064849,
    "Lng": 37.856910501199,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-терапевт; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; Эхокардиография (ЭХО-КГ)",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-терапевт; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Эхокардиография (ЭХО-КГ); График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=949"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва,  Щёлковское шоссе, д. 72",
    "Lat": 55.810206445688,
    "Lng": 37.809180967739,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Врач-гинеколог; Врач-терапевт; УЗИ; Эхокардиография (ЭХО-КГ)",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Врач-гинеколог; Врач-терапевт; УЗИ; ЭКГ; Эхокардиография (ЭХО-КГ); График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=966"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Новоясеневский пр-т, д. 16, корп. 1",
    "Lat": 55.608222122643,
    "Lng": 37.52150438268,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=967"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Профсоюзная, д. 104",
    "Lat": 55.641408170058,
    "Lng": 37.52362764418,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=3083"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Ленинградское шоссе, д. 88",
    "Lat": 55.849823708538,
    "Lng": 37.475241626984,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-терапевт; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Выдача результатов; Взятие гинекологического и урологического материала; Врач-терапевт; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=3117"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, пр-т Вернадского, д. 92, корп. 1",
    "Lat": 55.654883668262,
    "Lng": 37.476285332165,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=3118"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул.Свободы, д.12/8",
    "Lat": 55.830524167255,
    "Lng": 37.453626365484,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=3303"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Новокосинская, д. 17, корп. 7",
    "Lat": 55.739873501157,
    "Lng": 37.853928094456,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; Врач-гинеколог; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; Врач-гинеколог; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=4249"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Малая Тульская, д. 2/1, корп. 4.",
    "Lat": 55.710443343273,
    "Lng": 37.619144411798,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; УЗИ; Запись результатов исследований на электронный носитель - USB - flash drive; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=4620"
  },
  {
    "Name": "Invitro",
    "City": "Чехов",
    "Address": "Московская обл., г. Чехов, ул. Почтовая, д. 107",
    "Lat": 55.152783283478,
    "Lng": 37.458283093871,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём кала; Приём мочи; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=4662"
  },
  {
    "Name": "Invitro",
    "City": "Ногинск",
    "Address": "Московская обл., г. Ногинск, ул. Декабристов, д. 1 Б",
    "Lat": 55.861358431447,
    "Lng": 38.438983128099,
    "TimeTable": "07:00 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём кала; Приём мочи; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=4664"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, шоссе Энтузиастов, д. 22/18",
    "Lat": 55.751246191186,
    "Lng": 37.716816499336,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Запись результатов исследований на электронный носитель - USB - flash drive; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=4666"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Профсоюзная, д. 5/9",
    "Lat": 55.685088532032,
    "Lng": 37.571928655172,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=4667"
  },
  {
    "Name": "Invitro",
    "City": "Сергиев Посад",
    "Address": "г. Сергиев Посад, ул. Вознесенская, д. 78",
    "Lat": 56.299168234466,
    "Lng": 38.132494555219,
    "TimeTable": "06:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Кольпоскопия; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Кольпоскопия; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=4716"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Зои и Александра Космодемьянских, д. 4, корп. 1",
    "Lat": 55.818070784002,
    "Lng": 37.502313103984,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Врач-терапевт; Вакцинация; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Врач-терапевт; Вакцинация; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=4727"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Мичуринский пр-т, д. 26",
    "Lat": 55.700323124769,
    "Lng": 37.502115267701,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; ЭКГ; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=4733"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Олеко Дундича, д. 21, корп. 3",
    "Lat": 55.738705194057,
    "Lng": 37.488477798427,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=4736"
  },
  {
    "Name": "Invitro",
    "City": "Малаховка",
    "Address": "Московская обл., п. Малаховка, ул. Комсомольская, 7а",
    "Lat": 55.644399,
    "Lng": 38.002644,
    "TimeTable": "07:00 - 18:00",
    "Comment": "Оплата банковской картой; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=5511"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Зорге, д. 2/92",
    "Lat": 55.778329772807,
    "Lng": 37.513772028971,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=5518"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Большая Татарская, д. 3",
    "Lat": 55.743000254897,
    "Lng": 37.632044219278,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=5616"
  },
  {
    "Name": "Invitro",
    "City": "Наро-Фоминск",
    "Address": "Московская обл., г. Наро-Фоминск, Площадь Свободы 17а",
    "Lat": 55.385246671474,
    "Lng": 36.728668005943,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Вакцинация; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Вакцинация; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=5622"
  },
  {
    "Name": "Invitro",
    "City": "Лобня",
    "Address": "г. Лобня, ул. Дружбы, д. 1",
    "Lat": 56.008396913459,
    "Lng": 37.46769764418,
    "TimeTable": "07:00 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие урологического материала",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Взятие урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=5726"
  },
  {
    "Name": "Invitro",
    "City": "Люберцы",
    "Address": "г. Люберцы, ул. Красноармейская, д. 6",
    "Lat": 55.679329016425,
    "Lng": 37.888806496072,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=5847"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Генерала Белова, д. 51, корп. 1",
    "Lat": 55.605520564264,
    "Lng": 37.726151728443,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Врач-консультант; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=6134"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Чертановская, д. 7, корп. 1а.",
    "Lat": 55.630207381237,
    "Lng": 37.600980476753,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=6139"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Долгоруковская, д. 35.",
    "Lat": 55.77802600003,
    "Lng": 37.600666066404,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=6176"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Ореховый бульвар, д. 45, корп. 1",
    "Lat": 55.614586750899,
    "Lng": 37.743570061802,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=6188"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Зеленодольская, д. 41, корп. 1",
    "Lat": 55.702956773941,
    "Lng": 37.766432185782,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=6210"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Нахимовский пр-т, д. 11, корп. 1",
    "Lat": 55.663893057031,
    "Lng": 37.605246517599,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=6275"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Митинская, д. 10",
    "Lat": 55.84015268894,
    "Lng": 37.378136237484,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-терапевт; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; Эхокардиография (ЭХО-КГ)",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-терапевт; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Эхокардиография (ЭХО-КГ); График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=6368"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Земляной Вал, д. 1/4, стр. 2",
    "Lat": 55.764008195799,
    "Lng": 37.657638906746,
    "TimeTable": "07:00 - 18:00",
    "Comment": "Оплата банковской картой; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=6539"
  },
  {
    "Name": "Invitro",
    "City": "Красногорск",
    "Address": "г. Красногорск, ул. Ленина, д. 41",
    "Lat": 55.832692079207,
    "Lng": 37.304500500383,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=6578"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Строгинский б-р, д. 7, корп. 1",
    "Lat": 55.805356,
    "Lng": 37.400809,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=6667"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Веерная, д. 1, корп. 2",
    "Lat": 55.707136373283,
    "Lng": 37.481936185867,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Ускоренный результат; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=6690"
  },
  {
    "Name": "Invitro",
    "City": "Реутов",
    "Address": "г. Реутов, ул. Октября, д. 20",
    "Lat": 55.752525927445,
    "Lng": 37.868633515657,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=6695"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Северный б-р, д. 2",
    "Lat": 55.865025356112,
    "Lng": 37.607358515271,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Ускоренный результат; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; Эхокардиография (ЭХО-КГ)",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Эхокардиография (ЭХО-КГ); График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=6727"
  },
  {
    "Name": "Invitro",
    "City": "Долгопрудный",
    "Address": "г. Долгопрудный, ул. Первомайская, д. 9/4",
    "Lat": 55.931675589606,
    "Lng": 37.520192381615,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Видеокольпоскопия; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Видеокольпоскопия; Врач-консультант; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=6912"
  },
  {
    "Name": "Invitro",
    "City": "Орехово-Зуево",
    "Address": "Московская обл., г. Орехово-Зуево, ул. Мадонская, д. 2",
    "Lat": 55.802750383206,
    "Lng": 38.973246904774,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=7096"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Ленинградский пр-т, д. 74, корп. 6.",
    "Lat": 55.806610721646,
    "Lng": 37.517401222719,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=7209"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Люблинская, д. 27/2",
    "Lat": 55.700805894181,
    "Lng": 37.73338316648,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; Кольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=7216"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Большая Дорогомиловская, д. 6",
    "Lat": 55.745452832148,
    "Lng": 37.569341812447,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=7354"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Алтуфьевское шоссе, д. 78",
    "Lat": 55.892369790123,
    "Lng": 37.588772372042,
    "TimeTable": "",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Ускоренный результат",
    "MedicalServices": "",
    "NotMedicalServices": "",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=7356"
  },
  {
    "Name": "Invitro",
    "City": "Дубна",
    "Address": "г. Дубна, ул. Понтекорво, д. 10",
    "Lat": 56.740245315294,
    "Lng": 37.168082341336,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Видеокольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Выдача результатов; Взятие гинекологического и урологического материала; Видеокольпоскопия; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=7403"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Рязанский пр-т, д. 93, корп. 2",
    "Lat": 55.715606004991,
    "Lng": 37.809209959612,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=7457"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Скобелевская, д. 5",
    "Lat": 55.549571828306,
    "Lng": 37.555615554905,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Результаты за 4 часа; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=7803"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Багратионовский пр-д, д. 1, стр. 1.",
    "Lat": 55.745498436457,
    "Lng": 37.511562173372,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=7817"
  },
  {
    "Name": "Invitro",
    "City": "Раменское",
    "Address": "г. Раменское, ул. Чугунова, д. 15/1",
    "Lat": 55.574853839188,
    "Lng": 38.208780597989,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=7836"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Гурьянова, д. 4, корп. 1",
    "Lat": 55.691674305254,
    "Lng": 37.721126728195,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=7862"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Нагатинская, д. 1, стр. 1",
    "Lat": 55.682673938373,
    "Lng": 37.625242271164,
    "TimeTable": "00:00 - 23:55",
    "Comment": "Оплата банковской картой; Без выходных; Срочные анализы (2 часа); Платная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Врач-терапевт; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; CITO; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Врач-терапевт; УЗИ; ЭКГ; Приём биоматериала на исследование \"Спермограмма\"; Запись результатов исследований на электронный носитель - USB - flash drive; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=7877"
  },
  {
    "Name": "Invitro",
    "City": "Апрелевка",
    "Address": "г. Апрелевка, ул. Горького, д. 25",
    "Lat": 55.539991350493,
    "Lng": 37.079445572252,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=7901"
  },
  {
    "Name": "Invitro",
    "City": "Домодедово",
    "Address": "г. Домодедово, мкр-н Центральный, ул. Кирова, д. 7, корп. 1",
    "Lat": 55.439354282232,
    "Lng": 37.750145729681,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=8041"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Покрышкина, д. 1, корп. 1",
    "Lat": 55.66329858721,
    "Lng": 37.478998244323,
    "TimeTable": "07:00 - 21:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Врач-гинеколог; Врач-терапевт; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Врач-гинеколог; Врач-терапевт; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Оформление санаторно-курортной карты; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=8048"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Бутырская, д. 9, корп. 1",
    "Lat": 55.795194693856,
    "Lng": 37.583035703691,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Результаты за 4 часа; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=8049"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Планерная, д. 7, корп. 4",
    "Lat": 55.862049882864,
    "Lng": 37.432600259898,
    "TimeTable": "06:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Видеокольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Видеокольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=8069"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Большая Черкизовская, д. 32, корп. 1",
    "Lat": 55.799993523613,
    "Lng": 37.743889660736,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=8161"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Крымский Вал, д. 4, стр. 1",
    "Lat": 55.730728335979,
    "Lng": 37.610839753913,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Видеокольпоскопия; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Видеокольпоскопия; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=8192"
  },
  {
    "Name": "Invitro",
    "City": "Можайск",
    "Address": "Московская обл. г. Можайск, ул. Амбулаторная, д. 1",
    "Lat": 55.50311089029,
    "Lng": 36.024646861351,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=8218"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Адмирала Макарова, д. 45",
    "Lat": 55.838973304414,
    "Lng": 37.485335761261,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Ускоренный результат; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; Эхокардиография (ЭХО-КГ)",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; Эхокардиография (ЭХО-КГ); Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=8375"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Братиславская. д. 10",
    "Lat": 55.661221687971,
    "Lng": 37.751798629804,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=8403"
  },
  {
    "Name": "Invitro",
    "City": "Клин",
    "Address": "Московская обл., Клинский район, г. Клин, ул. Литейная, д. 4.",
    "Lat": 56.334836665836,
    "Lng": 36.730701612651,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=8486"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Большая Академическая, д. 63",
    "Lat": 55.84002612208,
    "Lng": 37.546487101211,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=8534"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Б. Черкизовская, д. 6",
    "Lat": 55.796212734298,
    "Lng": 37.719558094257,
    "TimeTable": "07:15 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=9055"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Толбухина, д. 8, корп. 1",
    "Lat": 55.719630288454,
    "Lng": 37.401816439193,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=9212"
  },
  {
    "Name": "Invitro",
    "City": "Ступино",
    "Address": "г. Ступино, ул. Горького, д. 24А, корп. 1",
    "Lat": 54.891514822765,
    "Lng": 38.065050152529,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=9229"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Конюшковская, д. 30.",
    "Lat": 55.759757897089,
    "Lng": 37.578864541687,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Запись результатов исследований на электронный носитель - USB - flash drive; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=9295"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Профсоюзная, д. 109",
    "Lat": 55.633024398764,
    "Lng": 37.521220583671,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=9298"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Трофимова, д. 31",
    "Lat": 55.702964052368,
    "Lng": 37.682588830688,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=9302"
  },
  {
    "Name": "Invitro",
    "City": "Красногорск",
    "Address": "Московская обл., г. Красногорск, Павшинский б-р, д. 1",
    "Lat": 55.824593605855,
    "Lng": 37.365714746033,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=9317"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Можайское шоссе, д. 11",
    "Lat": 55.72089427506,
    "Lng": 37.436543534416,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=9357"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Орджоникидзе, д. 9, корп. 1",
    "Lat": 55.709937845939,
    "Lng": 37.598123116439,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=9364"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Новощукинская, д. 5",
    "Lat": 55.806713442174,
    "Lng": 37.459103626342,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; ЭКГ; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=9367"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Главмосстроя, д. 14",
    "Lat": 55.65468050956,
    "Lng": 37.408426595602,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Взятие урологического материала; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=9381"
  },
  {
    "Name": "Invitro",
    "City": "Троицк (Моск.обл.)",
    "Address": "г. Москва, Троицк, Октябрьский проспект, д. 17А",
    "Lat": 55.477689132952,
    "Lng": 37.297996697725,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; Врач-консультант; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=9619"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, пр-т Вернадского, д. 39",
    "Lat": 55.675504667194,
    "Lng": 37.505426828078,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; УЗИ; ЭКГ; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=9631"
  },
  {
    "Name": "Invitro",
    "City": "Кубинка",
    "Address": "Одинцовский р-н, п. Кубинка-8",
    "Lat": 55.578431506875,
    "Lng": 36.701443483847,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=9714"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Ангарская, д. 22, корп.1",
    "Lat": 55.876081603125,
    "Lng": 37.518074959182,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Видеокольпоскопия; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Видеокольпоскопия; Врач-консультант; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=9722"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Родионовская, д. 10, корп. 1",
    "Lat": 55.89483312155,
    "Lng": 37.389301463203,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=9826"
  },
  {
    "Name": "Invitro",
    "City": "Мытищи",
    "Address": "Московская обл., г. Мытищи, ул. Летная, д. 36, кор.1",
    "Lat": 55.904710108674,
    "Lng": 37.715533641784,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=9898"
  },
  {
    "Name": "Invitro",
    "City": "Люберцы",
    "Address": "г. Люберцы, Комсомольский пр-т, д. 18/1",
    "Lat": 55.692352734739,
    "Lng": 37.908637157327,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Ускоренный результат; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=9907"
  },
  {
    "Name": "Invitro",
    "City": "Фрязино",
    "Address": "Московская обл., г. Фрязино, ул. Московская, д. 8",
    "Lat": 55.958420558306,
    "Lng": 38.053982780861,
    "TimeTable": "07:00 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; Видеокольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём кала; Приём мочи; Выдача результатов; Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; Видеокольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=10067"
  },
  {
    "Name": "Invitro",
    "City": "Ивантеевка",
    "Address": "г. Ивантеевка, Центральный проезд, д. 7",
    "Lat": 55.967108328483,
    "Lng": 37.903290519318,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=10076"
  },
  {
    "Name": "Invitro",
    "City": "Кашира",
    "Address": "Московская обл., г. Кашира, ул. Советская, дом 9. ",
    "Lat": 54.833269745953,
    "Lng": 38.151081844764,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=10109"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Ключевая, д. 18",
    "Lat": 55.633942596501,
    "Lng": 37.761105176148,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=10112"
  },
  {
    "Name": "Invitro",
    "City": "Солнечногорск",
    "Address": "Московская обл., г. Солнечногорск, ул. Красная, д. 64",
    "Lat": 56.185236762222,
    "Lng": 36.982822780292,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=10271"
  },
  {
    "Name": "Invitro",
    "City": "Пушкино",
    "Address": "г. Пушкино, Московский пр-т, д. 14",
    "Lat": 56.009833772464,
    "Lng": 37.847577005397,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=10385"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Тёплый стан, д. 11, корп. 1",
    "Lat": 55.62309169622,
    "Lng": 37.493002979602,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=10497"
  },
  {
    "Name": "Invitro",
    "City": "Коломна",
    "Address": "г. Коломна, ул. Малышева, д. 13",
    "Lat": 55.084900338016,
    "Lng": 38.7951244774,
    "TimeTable": "06:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=10622"
  },
  {
    "Name": "Invitro",
    "City": "Павловский Посад",
    "Address": "Московская обл., г. Павловский Посад, ул. Кирова, д. 8",
    "Lat": 55.779706487253,
    "Lng": 38.65436716332,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=10769"
  },
  {
    "Name": "Invitro",
    "City": "Котельники",
    "Address": "г. Котельники, 2-й Покровский проезд, д. 14, корп. 2",
    "Lat": 55.66351693449,
    "Lng": 37.856811686518,
    "TimeTable": "06:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=10975"
  },
  {
    "Name": "Invitro",
    "City": "Королёв",
    "Address": "Московская обл., г. Королёв, ул. Пушкинская, д. 15",
    "Lat": 55.93237442757,
    "Lng": 37.837677570966,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=10991"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Балаклавский проспект, д. 12, корп. 3.",
    "Lat": 55.641660908285,
    "Lng": 37.607358515271,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; Врач-терапевт; Вакцинация; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; Врач-терапевт; Вакцинация; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=11139"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Профсоюзная, д. 17, корп. 1",
    "Lat": 55.678686535689,
    "Lng": 37.565613804018,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Результаты за 4 часа; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=11241"
  },
  {
    "Name": "Invitro",
    "City": "Щербинка",
    "Address": "г. Щербинка, ул. 40 лет Октября, д. 15/1",
    "Lat": 55.511939315815,
    "Lng": 37.573554911129,
    "TimeTable": "07:00 - 20:00",
    "Comment": "",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=11252"
  },
  {
    "Name": "Invitro",
    "City": "Шатура",
    "Address": "г. Шатура, ул. Жарова, д. 37",
    "Lat": 55.576970962313,
    "Lng": 39.527723030744,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=11446"
  },
  {
    "Name": "Invitro",
    "City": "Московский",
    "Address": "г. Московский, 3-й микрорайон, д. 10",
    "Lat": 55.595674020447,
    "Lng": 37.343370602726,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-терапевт; УЗИ; Эхокардиография (ЭХО-КГ)",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-терапевт; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Эхокардиография (ЭХО-КГ); График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=11739"
  },
  {
    "Name": "Invitro",
    "City": "Одинцово",
    "Address": "г. Одинцово, ул. Маковского, д. 20",
    "Lat": 55.664471554743,
    "Lng": 37.284486035852,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Врач-консультант; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=11857"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Пролетарский пр-т, д. 27",
    "Lat": 55.635782122116,
    "Lng": 37.657556373347,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=11954"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Митинская, д. 27",
    "Lat": 55.845051451695,
    "Lng": 37.361705217675,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=12678"
  },
  {
    "Name": "Invitro",
    "City": "Нахабино",
    "Address": "Cityской округ Красногорск, поселок Нахабино, ул. Институтская, д. 15А",
    "Lat": 55.839456101878,
    "Lng": 37.180928249899,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=12810"
  },
  {
    "Name": "Invitro",
    "City": "Климовск",
    "Address": "г. Климовск, ул. Заводская, д. 11/2",
    "Lat": 55.379490461495,
    "Lng": 37.541538954403,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём кала; Приём мочи; Выдача результатов; Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=12894"
  },
  {
    "Name": "Invitro",
    "City": "Егорьевск",
    "Address": "г. Егорьевск, ул. Советская, д. 133",
    "Lat": 55.379884297289,
    "Lng": 39.041375135922,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Врач-гинеколог; Врач-терапевт; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Врач-гинеколог; Врач-терапевт; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=13141"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Староалексеевская, д. 8",
    "Lat": 55.809246459281,
    "Lng": 37.63973379811,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Ускоренный результат; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Видеокольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Видеокольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=13160"
  },
  {
    "Name": "Invitro",
    "City": "Зеленоград",
    "Address": "г. Зеленоград, корпус 2024",
    "Lat": 55.975596765494,
    "Lng": 37.168657263118,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Ускоренный результат; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; Эхокардиография (ЭХО-КГ)",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Эхокардиография (ЭХО-КГ); График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=13220"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Сретенка, д. 9.",
    "Lat": 55.767969779017,
    "Lng": 37.631478280649,
    "TimeTable": "",
    "Comment": "Без выходных; Без очереди; Ускоренный результат",
    "MedicalServices": "",
    "NotMedicalServices": "",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=13270"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Рабочая, д. 13",
    "Lat": 55.744586340103,
    "Lng": 37.682915813818,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; Видеокольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; Видеокольпоскопия; УЗИ; ЭКГ; Приём биоматериала на исследование \"Спермограмма\"; Запись результатов исследований на электронный носитель - USB - flash drive; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=13278"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Люблинская, д. 163/1",
    "Lat": 55.651323158696,
    "Lng": 37.745869748929,
    "TimeTable": "06:30 - 20:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=13450"
  },
  {
    "Name": "Invitro",
    "City": "Дзержинский",
    "Address": "г. Дзержинский, ул. Ленина, д. 2А",
    "Lat": 55.637260793909,
    "Lng": 37.841100152198,
    "TimeTable": "06:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=13645"
  },
  {
    "Name": "Invitro",
    "City": "Балашиха",
    "Address": "МО, г. Балашиха, ул. Заречная, 22",
    "Lat": 55.827479234763,
    "Lng": 37.966028858761,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; Видеокольпоскопия; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём кала; Приём мочи; Выдача результатов; Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; Видеокольпоскопия; Врач-консультант; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=13790"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Славянский бульвар, д. 13, к. 1",
    "Lat": 55.725462858331,
    "Lng": 37.462666872457,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=14072"
  },
  {
    "Name": "Invitro",
    "City": "Балашиха",
    "Address": "г. Балашиха, ул. Твардовского, д. 34,",
    "Lat": 55.79526640432,
    "Lng": 37.983411259509,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём кала; Приём мочи; Выдача результатов; Взятие гинекологического материала; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=14167"
  },
  {
    "Name": "Invitro",
    "City": "Долгопрудный",
    "Address": "г. Долгопрудный, Лихачёвское шоссе, д. 6, корп. 4",
    "Lat": 55.939529417402,
    "Lng": 37.492670602947,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Видеокольпоскопия; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Видеокольпоскопия; Врач-консультант; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=14353"
  },
  {
    "Name": "Invitro",
    "City": "Химки",
    "Address": "Московская обл., г. Химки, ул. Московская, д. 20/2",
    "Lat": 55.89222339868,
    "Lng": 37.447359580016,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=14442"
  },
  {
    "Name": "Invitro",
    "City": "Истра",
    "Address": "Московская обл., г. Истра, ул. Морозова, д. 1",
    "Lat": 55.912576581012,
    "Lng": 36.858199500926,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=14671"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. 2-я Брестская, д. 39, стр. 2",
    "Lat": 55.774156818925,
    "Lng": 37.584591542328,
    "TimeTable": "00:00 - 23:55",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-терапевт; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-терапевт; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=14687"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Ярцевская, д. 29, корп. 3",
    "Lat": 55.742863433012,
    "Lng": 37.41850569309,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=14845"
  },
  {
    "Name": "Invitro",
    "City": "Железнодорожный",
    "Address": "Московская обл., г. Железнодорожный, ул. Некрасова д. 10",
    "Lat": 55.750240992349,
    "Lng": 37.996724292019,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=14894"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Зеленодольская, д. 30",
    "Lat": 55.7105194189,
    "Lng": 37.769953581696,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Видеокольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Видеокольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=15438"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Большая Декабрьская, д. 1",
    "Lat": 55.764622190962,
    "Lng": 37.559720855754,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=15517"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Гризодубовой, д. 2",
    "Lat": 55.78254184354,
    "Lng": 37.531909014557,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=15736"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Тимура Фрунзе, д. 20",
    "Lat": 55.73476858432,
    "Lng": 37.588742223428,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=15828"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Шереметьевская, д. 1, корп. 1",
    "Lat": 55.794021464472,
    "Lng": 37.615551150662,
    "TimeTable": "07:00 - 20:00",
    "Comment": "",
    "MedicalServices": "Взятие гинекологического и урологического материала; Видеокольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Видеокольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=16042"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, поселение Сосенское, п. Коммунарка, ул. Лазурная, д. 10",
    "Lat": 55.567880772277,
    "Lng": 37.471802738897,
    "TimeTable": "08:00 - 15:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=16049"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Стремянный пер., д. 33.",
    "Lat": 55.726770833183,
    "Lng": 37.628325194002,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; Запись результатов исследований на электронный носитель - USB - flash drive; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=16633"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Старокачаловская, д. 5А",
    "Lat": 55.569840960381,
    "Lng": 37.578714635582,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=16637"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, 3-й Крутицкий пер., д. 11",
    "Lat": 55.731186594603,
    "Lng": 37.665350991401,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=17601"
  },
  {
    "Name": "Invitro",
    "City": "Подольск",
    "Address": "г. Подольск, ул. Ленинградская, д. 11",
    "Lat": 55.42541991883,
    "Lng": 37.497332859272,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=17887"
  },
  {
    "Name": "Invitro",
    "City": "Подольск",
    "Address": "г. Подольск, пр-т Ленина, д. 107/49",
    "Lat": 55.433072171226,
    "Lng": 37.548518864161,
    "TimeTable": "06:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Видеокольпоскопия; Врач-гинеколог; Врач-терапевт; Вакцинация; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Видеокольпоскопия; Врач-гинеколог; Врач-терапевт; Вакцинация; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Оформление санаторно-курортной карты; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=17889"
  },
  {
    "Name": "Invitro",
    "City": "Королёв",
    "Address": "Московская область, г. Королёв, ул. Циолковского, д. 27",
    "Lat": 55.920345609095,
    "Lng": 37.8267181245,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; Эхокардиография (ЭХО-КГ)",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Эхокардиография (ЭХО-КГ); График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=19128"
  },
  {
    "Name": "Invitro",
    "City": "Жуковский",
    "Address": "г. Жуковский, ул. Ломоносова, д. 18/11",
    "Lat": 55.596567943694,
    "Lng": 38.118532742757,
    "TimeTable": "06:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=19132"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Ивантеевская, д. 19",
    "Lat": 55.816176430417,
    "Lng": 37.730249791821,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Видеокольпоскопия; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; Эхокардиография (ЭХО-КГ)",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Видеокольпоскопия; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Эхокардиография (ЭХО-КГ); График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=19234"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Малая Дмитровка, д. 5/9",
    "Lat": 55.767898879993,
    "Lng": 37.605669682536,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие гинекологического материала; Кольпоскопия; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Взятие гинекологического материала; Кольпоскопия; Врач-консультант; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=19669"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Жулебинский бульвар, д. 5",
    "Lat": 55.69882877902,
    "Lng": 37.844589991078,
    "TimeTable": "06:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=21052"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Академика Янгеля, д. 1",
    "Lat": 55.594895752839,
    "Lng": 37.597009923198,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; УЗИ; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=21517"
  },
  {
    "Name": "Invitro",
    "City": "Щёлково",
    "Address": "г. Щёлково, ул. Комарова, д. 7А",
    "Lat": 55.916975859047,
    "Lng": 37.992627974324,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Видеокольпоскопия; Врач-гинеколог; Врач-терапевт; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; Эхокардиография (ЭХО-КГ)",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Видеокольпоскопия; Врач-гинеколог; Врач-терапевт; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Эхокардиография (ЭХО-КГ); Оформление санаторно-курортной карты; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=21678"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г.Москва, ул.Наташи Ковшовой, д. 27",
    "Lat": 55.678417514564,
    "Lng": 37.445859393492,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=21987"
  },
  {
    "Name": "Invitro",
    "City": "Химки",
    "Address": "г. Химки, ул. Совхозная, д. 2",
    "Lat": 55.896701729208,
    "Lng": 37.481727159839,
    "TimeTable": "06:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Ускоренный результат; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Результаты за 4 часа; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=22376"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Обручева, д. 34/63, стр. 1",
    "Lat": 55.65426403091,
    "Lng": 37.539463846097,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=22380"
  },
  {
    "Name": "Invitro",
    "City": "Запрудня",
    "Address": "Московская обл., Талдомский р-н, п. Запрудня, улица Ленина, д. 1",
    "Lat": 56.561688902923,
    "Lng": 37.434656788299,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём кала; Приём мочи; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=23068"
  },
  {
    "Name": "Invitro",
    "City": "Озёры",
    "Address": "г. Озёры, ул. Ленина, д. 53Б",
    "Lat": 54.853324690028,
    "Lng": 38.555287752532,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём кала; Приём мочи; Выдача результатов; Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=23073"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, 1 – ый Кожуховский проезд, д. 9",
    "Lat": 55.706285753156,
    "Lng": 37.660789982687,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=23850"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Карманицкий пер., д.5.",
    "Lat": 55.748759153973,
    "Lng": 37.585078271164,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=24304"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Лукинская, д. 7.",
    "Lat": 55.644533135097,
    "Lng": 37.339820626984,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-терапевт; Вакцинация; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-терапевт; Вакцинация; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=24474"
  },
  {
    "Name": "Invitro",
    "City": "Дедовск",
    "Address": "Московская область, Истринский р-он, г. Дедовск, ул. Клубная, д. 2",
    "Lat": 55.860955813905,
    "Lng": 37.12167482209,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; Врач-гинеколог; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; Врач-гинеколог; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=26035"
  },
  {
    "Name": "Invitro",
    "City": "Лобня",
    "Address": "г. Лобня, ул. Ленина, д. 71",
    "Lat": 56.008010989113,
    "Lng": 37.445006,
    "TimeTable": "07:00 - 18:00",
    "Comment": "Оплата банковской картой; Без очереди; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=26165"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Саянская, д. 7А",
    "Lat": 55.768144386465,
    "Lng": 37.831884067459,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём кала; Приём мочи; Выдача результатов; Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=26222"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, пос. Внуковское, ул. Авиаконструктора Петлякова, д. 31",
    "Lat": 55.626518296509,
    "Lng": 37.313512728836,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Врач-терапевт; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Врач-терапевт; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=26314"
  },
  {
    "Name": "Invitro",
    "City": "Протвино",
    "Address": "г. Протвино, пр-т Академика Сахарова, д 2, корп 2",
    "Lat": 54.877674084127,
    "Lng": 37.232439931823,
    "TimeTable": "07:30 - 17:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=26537"
  },
  {
    "Name": "Invitro",
    "City": "Дмитров",
    "Address": "г. Дмитров, Торговая площадь, д. 3",
    "Lat": 56.345136659683,
    "Lng": 37.517382932541,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=26971"
  },
  {
    "Name": "Invitro",
    "City": "Одинцово",
    "Address": "г. Одинцово, мкр-н. Трёхгорка, ул. Чистяковой, д. 42.",
    "Lat": 55.701465275138,
    "Lng": 37.321543318857,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=27291",
    "License": true
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Алтайская, д. 4",
    "Lat": 55.819719329237,
    "Lng": 37.802890998672,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=28424"
  },
  {
    "Name": "Invitro",
    "City": "Химки",
    "Address": "г. Химки, пр-кт Мельникова, д. 17",
    "Lat": 55.90356278889,
    "Lng": 37.40122164418,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=28426"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, пр-т Андропова, д. 28",
    "Lat": 55.680091178494,
    "Lng": 37.662665915344,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Ускоренный результат; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Видеокольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Видеокольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=29278"
  },
  {
    "Name": "Invitro",
    "City": "Электросталь",
    "Address": "г. Электросталь, пр-т Ленина, д. 18",
    "Lat": 55.796819175348,
    "Lng": 38.438899915344,
    "TimeTable": "06:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=29712"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Яблочкова, д. 16",
    "Lat": 55.82024403055,
    "Lng": 37.581535457672,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=29716"
  },
  {
    "Name": "Invitro",
    "City": "Балашиха",
    "Address": "г. Балашиха, Балашихинское шоссе, д. 16.",
    "Lat": 55.823246191934,
    "Lng": 37.930045034393,
    "TimeTable": "06:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=29728"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Петрозаводская, д. 9, корп.1",
    "Lat": 55.865255321286,
    "Lng": 37.496306928741,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие гинекологического материала; Кольпоскопия; УЗИ; Эхокардиография (ЭХО-КГ)",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Взятие гинекологического материала; Кольпоскопия; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Эхокардиография (ЭХО-КГ); График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=29883"
  },
  {
    "Name": "Invitro",
    "City": "Химки",
    "Address": "г. Химки, мкр. Сходня, 2-й  Мичуринский  тупик,  д. 7 корп. 1",
    "Lat": 55.946434389624,
    "Lng": 37.304757186508,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=30002"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Полярная, д. 16, корп. 1",
    "Lat": 55.876744957336,
    "Lng": 37.637322542328,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=30300"
  },
  {
    "Name": "Invitro",
    "City": "Красногорск",
    "Address": "г. Красногорск, Красногорский бульвар, д. 28",
    "Lat": 55.82198387833,
    "Lng": 37.376955542328,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=30592"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, пр-т Защитников Москвы, д. 15",
    "Lat": 55.704030524649,
    "Lng": 37.923627338623,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=31181"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва,  ул. Нагорная, д. 27, корп. 1",
    "Lat": 55.675511874536,
    "Lng": 37.598618,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=32122"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Снежная, д. 27, корп.1",
    "Lat": 55.856131787903,
    "Lng": 37.652474322754,
    "TimeTable": "06:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=32126"
  },
  {
    "Name": "Invitro",
    "City": "Селятино",
    "Address": "р.п. Селятино, ул. Спортивная, 55, корп. 1",
    "Lat": 55.514646878685,
    "Lng": 36.982491135808,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=33530"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Вешняковская, д. 2",
    "Lat": 55.737763424326,
    "Lng": 37.820512728836,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём кала; Приём мочи; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=33536"
  },
  {
    "Name": "Invitro",
    "City": "Путилково",
    "Address": "д. Путилково, Путилковское ш., д. 4, корп. 1",
    "Lat": 55.863887634375,
    "Lng": 37.390807186508,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём кала; Приём мочи; Выдача результатов; Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=33774"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Сокольническая площадь, д. 9",
    "Lat": 55.789974307481,
    "Lng": 37.681054451729,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант; УЗИ; Эхокардиография (ЭХО-КГ)",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; УЗИ; Суточное мониторирование ЭКГ по Холтеру; Эхокардиография (ЭХО-КГ); График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=33861"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Ленинский пр-т, д. 45",
    "Lat": 55.703178402427,
    "Lng": 37.57336564418,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Ускоренный результат; Бесплатная парковка; Бесплатный Wi-Fi",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; Врач-терапевт; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; Эхокардиография (ЭХО-КГ)",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; Врач-терапевт; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Эхокардиография (ЭХО-КГ); График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=33876"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Волгоградский пр-т, д. 51",
    "Lat": 55.709850739731,
    "Lng": 37.731914620867,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; Врач-консультант; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=34022"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Зацепский Вал, д. 4, стр.1",
    "Lat": 55.733252429947,
    "Lng": 37.639994,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=34235"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. НовCityская, д. 37",
    "Lat": 55.905522134425,
    "Lng": 37.57859464418,
    "TimeTable": "",
    "Comment": "Оплата банковской картой; Без выходных; Ускоренный результат",
    "MedicalServices": "",
    "NotMedicalServices": "",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=34240"
  },
  {
    "Name": "Invitro",
    "City": "Дубна",
    "Address": "г. Дубна, ул. Володарского, д. 2б",
    "Lat": 56.756215615394,
    "Lng": 37.141081272491,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=36791"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Новоясеневский пр-т, д. 9",
    "Lat": 55.609107507665,
    "Lng": 37.536368595245,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=37157"
  },
  {
    "Name": "Invitro",
    "City": "Московский",
    "Address": "г. Московский, ул. Атласова, д. 3",
    "Lat": 55.602873148057,
    "Lng": 37.357727550926,
    "TimeTable": "06:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-терапевт; УЗИ; Эхокардиография (ЭХО-КГ)",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-терапевт; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Эхокардиография (ЭХО-КГ); График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=37281"
  },
  {
    "Name": "Invitro",
    "City": "Видное",
    "Address": "г. Видное, ул. Завидная, д. 4",
    "Lat": 55.540190617039,
    "Lng": 37.725202247713,
    "TimeTable": "07:00 - 17:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Ускоренный результат; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; Врач-консультант; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=38085"
  },
  {
    "Name": "Invitro",
    "City": "Подольск",
    "Address": "г. Подольск, ул. Подольская, д.14",
    "Lat": 55.470538810808,
    "Lng": 37.546205830688,
    "TimeTable": "06:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=43829"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Ленинский пр-т, д. 89/2",
    "Lat": 55.677910750575,
    "Lng": 37.53156835582,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=43847"
  },
  {
    "Name": "Invitro",
    "City": "Щёлково",
    "Address": "г. Щёлково, ул. Талсинская, д. 26",
    "Lat": 55.929939369282,
    "Lng": 37.982851139881,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=44436"
  },
  {
    "Name": "Invitro",
    "City": "Электросталь",
    "Address": "г. Электросталь, ул. Мира, д. 2",
    "Lat": 55.784138309148,
    "Lng": 38.441906764684,
    "TimeTable": "06:25 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Врач-терапевт; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Врач-терапевт; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=45272"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Малыгина, д. 22, корп.1",
    "Lat": 55.884741225968,
    "Lng": 37.689050790178,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови; Выдача результатов; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=45282"
  },
  {
    "Name": "Invitro",
    "City": "Сергиев Посад",
    "Address": "г. Сергиев Посад, Новоугличское ш., д. 40А",
    "Lat": 56.324901940197,
    "Lng": 38.134614102019,
    "TimeTable": "06:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Кольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=45368"
  },
  {
    "Name": "Invitro",
    "City": "Раменское",
    "Address": "г. Раменское, ул. Красноармейская, д. 8",
    "Lat": 55.571531402167,
    "Lng": 38.232503411741,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=45376"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Верхняя Красносельская, д. 34",
    "Lat": 55.780100953552,
    "Lng": 37.664801620867,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Взятие гинекологического и урологического материала; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=45393"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Борисовские пруды, д. 10, корп. 6",
    "Lat": 55.63284941911,
    "Lng": 37.742130527612,
    "TimeTable": "06:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=45394"
  },
  {
    "Name": "Invitro",
    "City": "Серпухов",
    "Address": "г. Серпухов ул. Советская д. 89",
    "Lat": 54.929755349223,
    "Lng": 37.441813492065,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=45401"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Солдатская, д .10, корп. 2",
    "Lat": 55.764138050508,
    "Lng": 37.703612498182,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=45409"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Мосфильмовская, д. 17/25",
    "Lat": 55.715661564839,
    "Lng": 37.516423728836,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Ускоренный результат; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=45410"
  },
  {
    "Name": "Invitro",
    "City": "Пушкино",
    "Address": "г. Пушкино, ул. Тургенева, д. 6",
    "Lat": 56.012530142533,
    "Lng": 37.84513939633,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=45446"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. 800-летия Москвы, д. 14",
    "Lat": 55.878162255726,
    "Lng": 37.548891379133,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=46829"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Новаторов, д. 36, корп. 1",
    "Lat": 55.661881748556,
    "Lng": 37.523640510416,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; УЗИ; ЭКГ; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=47189"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Луганская, д.11",
    "Lat": 55.622106872964,
    "Lng": 37.669430271164,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Ускоренный результат; Платная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=47457"
  },
  {
    "Name": "Invitro",
    "City": "Реутов",
    "Address": "г. Реутов, ул. Новая, д. 10",
    "Lat": 55.757648958633,
    "Lng": 37.855048256107,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=48133"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Ферганская, д. 13, к. 5",
    "Lat": 55.707352884449,
    "Lng": 37.813273746033,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=48615"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Ярославское ш., д. 124",
    "Lat": 55.870693137056,
    "Lng": 37.713888220814,
    "TimeTable": "06:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-терапевт; УЗИ; Эхокардиография (ЭХО-КГ)",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Врач-терапевт; УЗИ; ЭКГ; Эхокардиография (ЭХО-КГ); График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=49517"
  },
  {
    "Name": "Invitro",
    "City": "Красково",
    "Address": "п. Красково, ул. Карла Маркса, д. 61",
    "Lat": 55.658075687203,
    "Lng": 37.982810665012,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=48678"
  },
  {
    "Name": "Invitro",
    "City": "Ликино-Дулево",
    "Address": "г. Ликино-Дулево, ул. 1 Мая, д. 12",
    "Lat": 55.73384206065,
    "Lng": 38.95754014818,
    "TimeTable": "07:30 - 17:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=49064"
  },
  {
    "Name": "Invitro",
    "City": "Руза",
    "Address": "г. Руза, ул. Ульяновская, д.11",
    "Lat": 55.702998058446,
    "Lng": 36.200117122684,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=49831"
  },
  {
    "Name": "Invitro",
    "City": "Жуковский",
    "Address": "г. Жуковский, ул. Солнечная, д. 9",
    "Lat": 55.60447790106,
    "Lng": 38.087149090223,
    "TimeTable": "07:00 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Внутривенное капельное вливание; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Внутривенное капельное вливание; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=49582"
  },
  {
    "Name": "Invitro",
    "City": "Михнево",
    "Address": "Московская область, Ступинский район, поселок Михнево, ул. Советская, владение 8",
    "Lat": 55.121125790768,
    "Lng": 37.957175971174,
    "TimeTable": "07:15 - 18:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=50655"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, п. Коммунарка, ул. Липовый Парк, д. 9",
    "Lat": 55.567619524247,
    "Lng": 37.4871662926,
    "TimeTable": "08:00 - 15:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=51641"
  },
  {
    "Name": "Invitro",
    "City": "Мытищи",
    "Address": "г. Мытищи, ул. Веры Волошиной, д. 12/30",
    "Lat": 55.893233777884,
    "Lng": 37.725330122684,
    "TimeTable": "07:00 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=51884"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Беломорская, д. 22",
    "Lat": 55.863060871532,
    "Lng": 37.474861766865,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=51894"
  },
  {
    "Name": "Invitro",
    "City": "Мытищи",
    "Address": "г. Мытищи, ул. Борисовка, д. 26",
    "Lat": 55.919519086338,
    "Lng": 37.711225813492,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Ускоренный результат; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=52861"
  },
  {
    "Name": "Invitro",
    "City": "Долгопрудный",
    "Address": "г. Долгопрудный, Лихачевское шоссе, д. 14, корп. 1",
    "Lat": 55.948041458272,
    "Lng": 37.493504610106,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=52925"
  },
  {
    "Name": "Invitro",
    "City": "Бронницы",
    "Address": "г. Бронницы, ул. Советская, д. 63",
    "Lat": 55.425483203119,
    "Lng": 38.26611982209,
    "TimeTable": "08:00 - 18:00",
    "Comment": "Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=48048"
  },
  {
    "Name": "Invitro",
    "City": "ЗвениCity",
    "Address": "г. ЗвениCity, ул. Василия Фабричнова, д. 26",
    "Lat": 55.728487762136,
    "Lng": 36.860148279642,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=50426"
  },
  {
    "Name": "Invitro",
    "City": "Волоколамск",
    "Address": "г. Волоколамск, ул. Парковая, д. 15",
    "Lat": 56.029654861893,
    "Lng": 35.958108054838,
    "TimeTable": "07:00 - 18:00",
    "Comment": "Оплата банковской картой; Без очереди; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=50467"
  },
  {
    "Name": "Invitro",
    "City": "Старая Купавна",
    "Address": "г. Старая Купавна, ул. Кирова, д. 2",
    "Lat": 55.809293065944,
    "Lng": 38.176199148092,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=51883"
  },
  {
    "Name": "Invitro",
    "City": "Красногорск",
    "Address": "г. Красногорск, бульвар Космонавтов, д. 1 ",
    "Lat": 55.834556769673,
    "Lng": 37.270469741586,
    "TimeTable": "07:00 - 18:00",
    "Comment": "Оплата банковской картой; Без очереди; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=51917"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, пр-кт Маршала Жукова, д. 25, к. 1",
    "Lat": 55.775210999994,
    "Lng": 37.48428756316,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Ускоренный результат",
    "MedicalServices": "Взятие урологического материала; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие урологического материала; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53280"
  },
  {
    "Name": "Invitro",
    "City": "Электроугли",
    "Address": "Московская область, Ногинский район, г. Электроугли, ул. Школьная, д. 12",
    "Lat": 55.716208846018,
    "Lng": 38.214999860119,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=50431"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, п. Первомайское, ул. Центральная, д. 28",
    "Lat": 55.537069674102,
    "Lng": 37.157159587627,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=51644"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Никулинская, д. 27, корп. 3  ",
    "Lat": 55.670197384052,
    "Lng": 37.4508132295,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического материала; Кольпоскопия; Врач-гинеколог",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; Кольпоскопия; Врач-гинеколог; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=51900"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Киевская, д. 18",
    "Lat": 55.73969081538,
    "Lng": 37.549490728836,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Платная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53173"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. 6-я Нововатутинская, д. 1",
    "Lat": 55.515289928383,
    "Lng": 37.352376169312,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53172"
  },
  {
    "Name": "Invitro",
    "City": "Домодедово",
    "Address": "г. Домодедово, ул. Курыжова, д. 16",
    "Lat": 55.396772197199,
    "Lng": 37.772748067459,
    "TimeTable": "07:00 - 15:30",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=51899"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Дмитровское шоссе, д. 131к2",
    "Lat": 55.889118368111,
    "Lng": 37.540617813492,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ; Эхокардиография (ЭХО-КГ)",
    "NotMedicalServices": "Взятие крови, мочи, кала; Результаты за 4 часа; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; Эхокардиография (ЭХО-КГ); График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53132"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва,  ул. Раменки, д. 17, корп. 2",
    "Lat": 55.69166790333,
    "Lng": 37.493286364418,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка; С 7.00 утра",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53554"
  },
  {
    "Name": "Invitro",
    "City": "Бутово",
    "Address": "Московская область, дер. Бутово, д. 22, корп. 1",
    "Lat": 55.545302150121,
    "Lng": 37.585925245369,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Бесплатная парковка; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53192"
  },
  {
    "Name": "Invitro",
    "City": "Дмитров",
    "Address": "г. Дмитров, ул. Минина, д. 46 ",
    "Lat": 56.34681010034,
    "Lng": 37.533144022758,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53503"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Шарикоподшипниковская, д. 36/18",
    "Lat": 55.714440179298,
    "Lng": 37.675722912048,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=54769"
  },
  {
    "Name": "Invitro",
    "City": "Лыткарино",
    "Address": "г. Лыткарино, ул. Первомайская, д. 23",
    "Lat": 55.585616071632,
    "Lng": 37.900581194786,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=54799"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, пос. Сосенское, п. Коммунарка, ул. Александры Монаховой, д. 92, корп. 2",
    "Lat": 55.542518913375,
    "Lng": 37.487023222228,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Ускоренный результат; Бесплатная парковка; Бесплатный Wi-Fi",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Врач-консультант; УЗИ; Эхокардиография (ЭХО-КГ)",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Врач-консультант; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Эхокардиография (ЭХО-КГ); Запись результатов исследований на электронный носитель - USB - flash drive; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=54895"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Маршала Тухачевского, 37/21",
    "Lat": 55.784324382182,
    "Lng": 37.470732172683,
    "TimeTable": "00:00 - 23:55",
    "Comment": "Оплата банковской картой; Без выходных; Круглосуточно; Вход с колясками; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие гинекологического материала; Кольпоскопия; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; CITO; Выдача результатов; Взятие гинекологического и урологического материала; Взятие гинекологического материала; Кольпоскопия; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57180"
  },
  {
    "Name": "Invitro",
    "City": "Ногинск",
    "Address": "г. Ногинск, ул. Советская, д. 64",
    "Lat": 55.853766916886,
    "Lng": 38.438961542328,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=56340"
  },
  {
    "Name": "Invitro",
    "City": "Яхрома",
    "Address": "г. Яхрома, ул. Конярова, д. 5 ",
    "Lat": 56.287959736599,
    "Lng": 37.48256517791,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=56343"
  },
  {
    "Name": "Invitro",
    "City": "Химки",
    "Address": "г. Химки, Ленинский пр-т, д. 1, корп. 1",
    "Lat": 55.895539569001,
    "Lng": 37.452287542328,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; Кольпоскопия; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=56020"
  },
  {
    "Name": "Invitro",
    "City": "Хотьково",
    "Address": "г. Хотьково, ул. Ленина, д. 5А",
    "Lat": 56.251194918479,
    "Lng": 37.977135635582,
    "TimeTable": "06:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57299"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Электролитный проезд, д. 16, корп. 2 ",
    "Lat": 55.671563326868,
    "Lng": 37.610977449074,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=56336"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Партизанская, д. 22 ",
    "Lat": 55.734377303595,
    "Lng": 37.412720771827,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Внутривенное капельное вливание; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Результаты за 4 часа; Взятие гинекологического и урологического материала; Кольпоскопия; Внутривенное капельное вливание; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=56339"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Большая Филевская, д. 4",
    "Lat": 55.753701126215,
    "Lng": 37.511995014542,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=56875"
  },
  {
    "Name": "Invitro",
    "City": "Пущино",
    "Address": "г. Пущино, мкр. Д, д. 20А",
    "Lat": 54.829639439552,
    "Lng": 37.630046176582,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=56344"
  },
  {
    "Name": "Invitro",
    "City": "Люберцы",
    "Address": "г. Люберцы, ул. Вертолетная, д. 42",
    "Lat": 55.698030480872,
    "Lng": 37.961836084656,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57001"
  },
  {
    "Name": "Invitro",
    "City": "Лосино-Петровский",
    "Address": "г. Лосино-Петровский, ул. Октябрьская, д. 1",
    "Lat": 55.869249276574,
    "Lng": 38.20242035582,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=56862"
  },
  {
    "Name": "Invitro",
    "City": "Пушкино",
    "Address": "г. Пушкино, ул. Набережная, д. 35, корп. 7",
    "Lat": 56.020203004481,
    "Lng": 37.86036917791,
    "TimeTable": "06:30 - 15:00",
    "Comment": "Оплата банковской картой; Без выходных; С 6.30 утра",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=54961"
  },
  {
    "Name": "Invitro",
    "City": "Видное",
    "Address": "г. Видное, пр-т Ленинского Комсомола, д. 46В",
    "Lat": 55.546510124184,
    "Lng": 37.70849117791,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Ускоренный результат; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57725"
  },
  {
    "Name": "Invitro",
    "City": "Дзержинский",
    "Address": "г. Дзержинский, ул. Лесная, д. 11",
    "Lat": 55.630598104567,
    "Lng": 37.858414347221,
    "TimeTable": "07:00 - 18:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "УЗИ",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Выдача результатов; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57554"
  },
  {
    "Name": "Invitro",
    "City": "Октябрьский (Моск.обл.)",
    "Address": "Московская область, Cityской округ Люберцы, рабочий поселок Октябрьский, ул. Ленина, д. 25",
    "Lat": 55.608208133497,
    "Lng": 37.976344034393,
    "TimeTable": "07:00 - 18:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Внутривенное капельное вливание; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Внутривенное капельное вливание; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=56429"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Осенний бульвар, д. 5, корп. 1",
    "Lat": 55.756904709379,
    "Lng": 37.407090390213,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Платная парковка; Бесплатный Wi-Fi",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57379"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Лестева, д. 21/61, корп.1",
    "Lat": 55.714468510003,
    "Lng": 37.608504542328,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57499"
  },
  {
    "Name": "Invitro",
    "City": "Железнодорожный",
    "Address": "г. Балашиха, микрорайон Саввино, ул. Калинина, д. 18",
    "Lat": 55.741539917937,
    "Lng": 38.03155164418,
    "TimeTable": "07:30 - 17:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка; Бесплатный Wi-Fi",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Приём кала; Приём мочи; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=59038"
  },
  {
    "Name": "Invitro",
    "City": "Зарайск",
    "Address": "г. Зарайск, ул. Карла Маркса, д. 48/9",
    "Lat": 54.759148818351,
    "Lng": 38.877516635582,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58365"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. М. Филёвская, д. 18 ",
    "Lat": 55.737088238959,
    "Lng": 37.468397576721,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57726"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, пр-т Комсомольский, д. 25к1 ",
    "Lat": 55.726623821359,
    "Lng": 37.582229186508,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Ускоренный результат; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57803"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Габричевского, 10 к1",
    "Lat": 55.816410040771,
    "Lng": 37.459795813492,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без очереди; Ускоренный результат; Бесплатная парковка; С 7.00 утра",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=59335"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Кашёнкин Луг, д. 6, к. 1",
    "Lat": 55.825101861964,
    "Lng": 37.597889160713,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "Взятие гинекологического материала; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Взятие гинекологического материала; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=59338"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Коломенская, 12 к2",
    "Lat": 55.681495648908,
    "Lng": 37.69577135582,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка; Результат за 4 часа",
    "MedicalServices": "УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=59021"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, 22 км Киевского шоссе, вл. 4",
    "Lat": 55.634272382969,
    "Lng": 37.439942387558,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие гинекологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Взятие гинекологического материала; Кольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=59042"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Кировоградская, д. 9к2",
    "Lat": 55.623890504665,
    "Lng": 37.611070898148,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58995"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, пр-д Стратонавтов, д. 9",
    "Lat": 55.826087196339,
    "Lng": 37.435887991402,
    "TimeTable": "07:00 - 20:00",
    "Comment": "",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57500"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, пр-т Ленинградский, д. 60А",
    "Lat": 55.800251126127,
    "Lng": 37.535882390213,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Ускоренный результат; Платная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-терапевт; Вакцинация; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-терапевт; Вакцинация; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Запись результатов исследований на электронный носитель - USB - flash drive; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=59156"
  },
  {
    "Name": "Invitro",
    "City": "Одинцово",
    "Address": "г. Одинцово,  ул. Маршала Неделина, д. 9А",
    "Lat": 55.674264999994,
    "Lng": 37.27148682209,
    "TimeTable": "07:00 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=56335"
  },
  {
    "Name": "Invitro",
    "City": "Клин",
    "Address": "г. Клин, ул. Карла Маркса, д. 37",
    "Lat": 56.331083702347,
    "Lng": 36.71500817791,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58957"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ш. Боровское , д. 2, корп. 5 ",
    "Lat": 55.661455138712,
    "Lng": 37.418430056205,
    "TimeTable": "07:00 - 20:00",
    "Comment": "",
    "MedicalServices": "Врач-терапевт; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Врач-терапевт; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58121"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Михайлова, д.31А",
    "Lat": 55.725196577481,
    "Lng": 37.775354195106,
    "TimeTable": "07:00 - 20:00",
    "Comment": "",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-терапевт; Вакцинация; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-терапевт; Вакцинация; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=59191"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Дмитровское шоссе, д. 165Е, корп.1 ",
    "Lat": 55.924815,
    "Lng": 37.543695,
    "TimeTable": "08:00 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Внутривенное капельное вливание",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Внутривенное капельное вливание; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=61443"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, Свободный проспект, д. 37/18",
    "Lat": 55.74824,
    "Lng": 37.816396,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=62082"
  },
  {
    "Name": "Invitro",
    "City": "Краснознаменск",
    "Address": "г. Краснознаменск, ул. Молодежная, д. 1",
    "Lat": 55.599886,
    "Lng": 37.041447,
    "TimeTable": "07:00 - 20:00",
    "Comment": "",
    "MedicalServices": "Взятие гинекологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=62757"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, поселение Внуковское, ул. Бориса Пастернака, д. 23",
    "Lat": 55.636606412628,
    "Lng": 37.321766771828,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Ускоренный результат; Бесплатная парковка; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие гинекологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Взятие гинекологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=62721"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Верхняя Масловка, д. 22",
    "Lat": 55.796377131696,
    "Lng": 37.561071635582,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Ускоренный результат; Бесплатная парковка; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=62727"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, п. Сосенское, б-р Веласкеса, д. 2",
    "Lat": 55.591505658704,
    "Lng": 37.455724728836,
    "TimeTable": "",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Ускоренный результат; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического материала; Кольпоскопия; Врач-гинеколог; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; Кольпоскопия; Врач-гинеколог; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=62726"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Олонецкая, д. 4",
    "Lat": 55.85296,
    "Lng": 37.619647,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=61957"
  },
  {
    "Name": "Invitro",
    "City": "Мисайлово",
    "Address": "Московская обл., д. Мисайлово, мкр. ПриCity Лесное, Литературный б-р, д. 7",
    "Lat": 55.560693,
    "Lng": 37.812165,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатный Wi-Fi",
    "MedicalServices": "Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=60751"
  },
  {
    "Name": "Invitro",
    "City": "Воскресенск",
    "Address": "г. Воскресенск, ул. Октябрьская, д. 2",
    "Lat": 55.322189,
    "Lng": 38.674341,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=63324"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, п. Воскресенское, д. 39, пом. 7/2.",
    "Lat": 55.531647,
    "Lng": 37.446731,
    "TimeTable": "",
    "Comment": "",
    "MedicalServices": "",
    "NotMedicalServices": "",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=64392"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, пр-т Ленинский, д. 72/2",
    "Lat": 55.686066,
    "Lng": 37.541243,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=64107"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Каховка, д. 25",
    "Lat": 55.655219,
    "Lng": 37.573474,
    "TimeTable": "07:30 - 20:00",
    "Comment": "",
    "MedicalServices": "Взятие гинекологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=64108"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Южнобутовская, д. 50",
    "Lat": 55.538524,
    "Lng": 37.532709,
    "TimeTable": "",
    "Comment": "",
    "MedicalServices": "",
    "NotMedicalServices": "",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=63478"
  },
  {
    "Name": "Invitro",
    "City": "Домодедово",
    "Address": "Московская область, г.о. Домодедово, тер. Аэропорт Домодедово, стр. 1",
    "Lat": 55.415538,
    "Lng": 37.901985,
    "TimeTable": "00:00 - 23:55",
    "Comment": "",
    "MedicalServices": "",
    "NotMedicalServices": "График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=64646"
  },
  {
    "Name": "Invitro",
    "City": "Дрожжино",
    "Address": "Московская область, Ленинский район, деревня Дрожжино, Новое шоссе, д.9, пом.3",
    "Lat": 55.527667,
    "Lng": 37.586113,
    "TimeTable": "",
    "Comment": "",
    "MedicalServices": "",
    "NotMedicalServices": "",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=63648"
  },
  {
    "Name": "Invitro",
    "City": "Москва",
    "Address": "г. Москва, ул. Цимлянская, д. 3к2",
    "Lat": 55.669986,
    "Lng": 37.773143,
    "TimeTable": "",
    "Comment": "",
    "MedicalServices": "",
    "NotMedicalServices": "",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=63654"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Кронштадтская, д. 4, литера А",
    "Lat": 59.87692225602,
    "Lng": 30.25485352449,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=133"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Композиторов, д. 4, литера А",
    "Lat": 60.04602311306,
    "Lng": 30.318428299925,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=134"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Благодатная, д. 18,  литера А",
    "Lat": 59.877118470695,
    "Lng": 30.315668863111,
    "TimeTable": "08:00 - 16:00",
    "Comment": "Оплата банковской картой; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=564"
  },
  {
    "Name": "Invitro",
    "City": "Выборг",
    "Address": "г. Выборг, ул. Ленинградское шоссе, д. 47",
    "Lat": 60.703987891087,
    "Lng": 28.776667518138,
    "TimeTable": "07:30 - 18:30",
    "Comment": "Оплата банковской картой; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=714"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Невский р-н, ул. Ивановская, д. 6, литера А",
    "Lat": 59.875508243464,
    "Lng": 30.445692759933,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=4626"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Яблочкова, д. 22/3, литера А",
    "Lat": 59.951078075289,
    "Lng": 30.29710229508,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Ускоренный результат; Ускоренный результат (до 6 часов)",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=4925"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Комендантский пр-т, д. 10, корп. 1, лит. а",
    "Lat": 60.005207129886,
    "Lng": 30.26150206037,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=4963"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Ленсовета, д. 89, литера А",
    "Lat": 59.834483565712,
    "Lng": 30.345388083373,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=7197"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Типанова, д. 3, литера А",
    "Lat": 59.853916218617,
    "Lng": 30.324994984652,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "Взятие гинекологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=7198"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Дыбенко, д. 27, корп. 1, литера Х",
    "Lat": 59.905601463755,
    "Lng": 30.479657591949,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=7242"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Транспортный пер., д. 2А, литера А",
    "Lat": 59.920989386176,
    "Lng": 30.357171576721,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=7822"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Дачный пр-т, д. 17, корп. 4, литера А",
    "Lat": 59.842621150963,
    "Lng": 30.252204497179,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=7823"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Кантемировская, д. 31, литера Е",
    "Lat": 59.985322557084,
    "Lng": 30.348288299969,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=8013"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Гражданская, д. 3, литера А",
    "Lat": 59.928733376613,
    "Lng": 30.314623710998,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=8359"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, пр-т Просвещения, д. 24/2.",
    "Lat": 60.05604516202,
    "Lng": 30.322183257812,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=8709"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Наличная, д. 49, литера А",
    "Lat": 59.949194589348,
    "Lng": 30.233115297392,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=9060"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Бухарестская, д. 78, литера А",
    "Lat": 59.868617997677,
    "Lng": 30.383762770539,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Взятие гинекологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Взятие гинекологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=9492"
  },
  {
    "Name": "Invitro",
    "City": "Колпино",
    "Address": "г. Колпино, ул. Пролетарская, д. 17/42",
    "Lat": 59.741608530974,
    "Lng": 30.582209599954,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=14594"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Гражданский пр-т, д. 113, корп. 3, литера А",
    "Lat": 60.03108545026,
    "Lng": 30.417512475763,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=14913"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Ленинский пр-т, д. 77, корп. 1, литера А",
    "Lat": 59.857974091117,
    "Lng": 30.191433468209,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=15607"
  },
  {
    "Name": "Invitro",
    "City": "Пушкин",
    "Address": "г. Санкт-Петербург, г. Пушкин, ул. Магазейная, д. 47.",
    "Lat": 59.719400899147,
    "Lng": 30.417512475763,
    "TimeTable": "07:30 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=15727"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, пр-т Елизарова, д. 18, литера А",
    "Lat": 59.895086438855,
    "Lng": 30.421033871677,
    "TimeTable": "07:30 - 17:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди; Ускоренный результат; Ускоренный результат (до 6 часов)",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=16095"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Гражданский пр-т, д. 49, корп. 1, литера А",
    "Lat": 60.01429271321,
    "Lng": 30.400561266352,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=16713"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Савушкина, д. 16, литера А",
    "Lat": 59.985480096853,
    "Lng": 30.294335484005,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=23140"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Светлановский пр-т, д. 66, литера А",
    "Lat": 60.040445391572,
    "Lng": 30.398574626984,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Ускоренный результат; Ускоренный результат (до 6 часов)",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=23684"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, пр-т Пятилеток, д. 4, корп. 1, литера А",
    "Lat": 59.921275475516,
    "Lng": 30.46319391667,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=24348"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Коллонтай, д. 6, корп. 1, литера А",
    "Lat": 59.915400451041,
    "Lng": 30.449845084656,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=24648"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, пр-т Ветеранов, д. 158, литера А",
    "Lat": 59.834798597846,
    "Lng": 30.148978288361,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=25315"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Я. Гашека, д. 4, корп. 1, литера А",
    "Lat": 59.83205972024,
    "Lng": 30.38676091539,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=27358"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Коломяжский пр-т, д. 28, корп. 2, литера А",
    "Lat": 60.008106255695,
    "Lng": 30.295194542328,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=28449"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, пр-т Просвещения, д. 27, литера А",
    "Lat": 60.051242651059,
    "Lng": 30.339836373016,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=29497"
  },
  {
    "Name": "Invitro",
    "City": "Всеволожск",
    "Address": "г. Всеволожск, Колтушское шоссе, д. 44, к.1.",
    "Lat": 60.03283998854,
    "Lng": 30.635791253967,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=29585"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, пос. Парголово, ул. Михаила Дудина, д. 25, корп. 1, литера А",
    "Lat": 60.071592939328,
    "Lng": 30.326483223608,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=30210"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Туристская, д.24/42, литера А",
    "Lat": 60.000941081013,
    "Lng": 30.207604846557,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=30301"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Савушкина, д. 124, корп. 1",
    "Lat": 59.987834769203,
    "Lng": 30.207635777771,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=31678"
  },
  {
    "Name": "Invitro",
    "City": "Шушары",
    "Address": "г. Шушары, ул. Вишерская, д.22",
    "Lat": 59.804104728739,
    "Lng": 30.375478203705,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=32235"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, пр. Наставников, д. 45, корп. 1",
    "Lat": 59.95578404531,
    "Lng": 30.482065,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=32241"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Яблочкова, д. 22/3, литера А",
    "Lat": 59.951260865302,
    "Lng": 30.297262093254,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Ускоренный результат; Ускоренный результат (до 6 часов)",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=33800"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Заневский пр-т, д. 31, литера А",
    "Lat": 59.929702617009,
    "Lng": 30.416581457672,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=33802"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, пр-т Энгельса, д. 68, литера А",
    "Lat": 60.01800511156,
    "Lng": 30.32133864418,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=34190"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Сикейроса, д. 5, корп. 1, литера А",
    "Lat": 60.034381046414,
    "Lng": 30.32442,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=35437"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, 6-я линия ВО, д. 43, литера А",
    "Lat": 59.946082367944,
    "Lng": 30.276474682603,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=35835"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, пос. Шушары, территория Славянка, ул. Ростовская, д. 19/3",
    "Lat": 59.742166192875,
    "Lng": 30.479812627564,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=36189"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Будапештская, д. 67",
    "Lat": 59.847614533197,
    "Lng": 30.386954392868,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=36254"
  },
  {
    "Name": "Invitro",
    "City": "Мурино",
    "Address": "пос. Мурино, пл. Привокзальная, д. 3/1",
    "Lat": 60.052558521619,
    "Lng": 30.44663302845,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=36392"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Варшавская, д. 23, корп. 3, литера А",
    "Lat": 59.866587089516,
    "Lng": 30.313313186508,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=36820"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Боткинская, д. 1",
    "Lat": 59.958454423149,
    "Lng": 30.355769023138,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Ускоренный результат; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=36832"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Адмирала Коновалова, д. 2-4, литера А",
    "Lat": 59.850073549229,
    "Lng": 30.150647985458,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=36884"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Комендантский пр-т, д. 34, корп. 1, литера А",
    "Lat": 60.019866777039,
    "Lng": 30.244133853195,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=43808"
  },
  {
    "Name": "Invitro",
    "City": "Гатчина",
    "Address": "г. Гатчина, пр-т 25 Октября,  д. 31",
    "Lat": 59.570107278304,
    "Lng": 30.123506635582,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=44244"
  },
  {
    "Name": "Invitro",
    "City": "Ломоносов",
    "Address": "г. Ломоносов, ул. Еленинская, д.4",
    "Lat": 59.914608555148,
    "Lng": 29.766816804756,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=45406"
  },
  {
    "Name": "Invitro",
    "City": "Мурино",
    "Address": "п. Мурино, б-р Менделеева, д. 14",
    "Lat": 60.053232664692,
    "Lng": 30.429904474321,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=45407"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, дорога Муринская, д. 84, литера А",
    "Lat": 60.037194822615,
    "Lng": 30.449411505967,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=45664"
  },
  {
    "Name": "Invitro",
    "City": "Кудрово",
    "Address": "д. Кудрово, Европейский пр-т, д. 14, корп. 3",
    "Lat": 59.904022376382,
    "Lng": 30.513805358301,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=46737"
  },
  {
    "Name": "Invitro",
    "City": "Сертолово",
    "Address": "г. Сертолово, мкр Сертолово-1, ул. Ларина, д.15, корп.1",
    "Lat": 60.145428534278,
    "Lng": 30.214188387732,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=46759"
  },
  {
    "Name": "Invitro",
    "City": "Кириши",
    "Address": "г. Кириши, пр-т Героев, д. 34А",
    "Lat": 59.444065415571,
    "Lng": 32.033718567642,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=46849"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, пр-т Маршала Жукова, д. 28, корп. 1, литера А",
    "Lat": 59.858001546717,
    "Lng": 30.231847748669,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=48050"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Богатырский пр-т, д. 7, корп. 2, литера А",
    "Lat": 60.000424467865,
    "Lng": 30.287860440475,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=48664"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Индустриальный пр-т, д. 15, литера А",
    "Lat": 59.942075843984,
    "Lng": 30.478432784061,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=49534"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, пр-т Науки, д. 8, корп. 1",
    "Lat": 60.015574761255,
    "Lng": 30.388753364418,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=49540"
  },
  {
    "Name": "Invitro",
    "City": "Кудрово",
    "Address": "д. Кудрово, ул.Ленинградская, д. 7",
    "Lat": 59.914893746548,
    "Lng": 30.516178527786,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=49632"
  },
  {
    "Name": "Invitro",
    "City": "Пушкин",
    "Address": "г. Пушкин, ул. Архитектора Данини, д. 5, литера 5",
    "Lat": 59.695759090146,
    "Lng": 30.417912109123,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=50085"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Южное шоссе, д. 51, корп. 3, стр.1",
    "Lat": 59.858048762836,
    "Lng": 30.411665305557,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=50087"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, пос. Парголово, ул. Федора Абрамова, д. 23, корп.1",
    "Lat": 60.081689429744,
    "Lng": 30.345384817401,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=50190"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, пр-т Металлистов, д. 111, литера А",
    "Lat": 59.974030287717,
    "Lng": 30.390925457672,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=50192"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Кременчугская, д. 11, корп. 2, литера А",
    "Lat": 59.922620994637,
    "Lng": 30.36942208347,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=50851"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Капитанская, д. 4, литера А",
    "Lat": 59.954774174617,
    "Lng": 30.213543668071,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=50963"
  },
  {
    "Name": "Invitro",
    "City": "Волхов",
    "Address": "г. Волхов, ул. Александра Лукьянова, д. 9",
    "Lat": 59.899749687662,
    "Lng": 32.353161798777,
    "TimeTable": "08:00 - 18:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=50984"
  },
  {
    "Name": "Invitro",
    "City": "Новоселье",
    "Address": "п. Новоселье, пр-т Питерский, д. 1",
    "Lat": 59.81209837616,
    "Lng": 30.078044094408,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=50988"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Дыбенко, д. 22, корп. 1 лит. Ю",
    "Lat": 59.905762246966,
    "Lng": 30.47585165238,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди",
    "MedicalServices": "УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=51055"
  },
  {
    "Name": "Invitro",
    "City": "Волхов",
    "Address": "г. Волхов, ул. Профсоюзов, д. 4",
    "Lat": 59.923435974511,
    "Lng": 32.298553233135,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=51281"
  },
  {
    "Name": "Invitro",
    "City": "Всеволожск",
    "Address": "г. Всеволожск, ул. Центральная, д. 8",
    "Lat": 59.993363159231,
    "Lng": 30.644986412788,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=51329"
  },
  {
    "Name": "Invitro",
    "City": "Кудрово",
    "Address": "д. Кудрово, ул. Столичная, д. 4, корп. 3",
    "Lat": 59.900724326627,
    "Lng": 30.516472496798,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=51330"
  },
  {
    "Name": "Invitro",
    "City": "Старая",
    "Address": "д. Старая, Школьный пер., д. 1",
    "Lat": 59.932397223318,
    "Lng": 30.645064583992,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=51328"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург,  Горелово, Красносельское шоссе, д. 54, корп. 3",
    "Lat": 59.791269682585,
    "Lng": 30.14883445369,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=51584"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Русановская, д. 19/3, литера А",
    "Lat": 59.86399162294,
    "Lng": 30.514981440475,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=51585"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Лабораторный пр-т, д. 20, корп. 3",
    "Lat": 59.983747750328,
    "Lng": 30.385336603724,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=51723"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Фурштатская, д. 3, литера А",
    "Lat": 59.945078693382,
    "Lng": 30.350046224149,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=52008"
  },
  {
    "Name": "Invitro",
    "City": "Сестрорецк",
    "Address": "г. Сестрорецк, ул. Токарева, д. 18, литера А",
    "Lat": 60.090547301731,
    "Lng": 29.953609128975,
    "TimeTable": "08:00 - 18:00",
    "Comment": "Оплата банковской картой; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=52588"
  },
  {
    "Name": "Invitro",
    "City": "Мурино",
    "Address": "пос. Мурино, ул. Шувалова, д. 23",
    "Lat": 60.063390536318,
    "Lng": 30.432766,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53134"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, пр-кт Юнтоловский, 43, корп. 14, стр. 1",
    "Lat": 60.030770684439,
    "Lng": 30.137144186508,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53302"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, пр-т Непокоренных, д. 14/2, литера А",
    "Lat": 59.996739715838,
    "Lng": 30.379519096829,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53294"
  },
  {
    "Name": "Invitro",
    "City": "Гатчина",
    "Address": "г. Гатчина,  ул. Рощинская, д. 13/1",
    "Lat": 59.577916509502,
    "Lng": 30.132912249944,
    "TimeTable": "07:30 - 16:30",
    "Comment": "Оплата банковской картой; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53637"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Ленинский пр-т, д. 56, стр.1",
    "Lat": 59.866881912965,
    "Lng": 30.159625355579,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53455"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, пр-т Маршала Блюхера, 7, корп. 2, стр. 1",
    "Lat": 59.979698144117,
    "Lng": 30.370975533729,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53978"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, бульвар Новаторов, д. 13, литер А, пом. 8Н",
    "Lat": 59.84973059672,
    "Lng": 30.267278836172,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без очереди; Ускоренный результат; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=54060"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Смольный пр., д. 17, стр.1",
    "Lat": 59.943285449356,
    "Lng": 30.39345764614,
    "TimeTable": "07:30 - 20:00",
    "Comment": "",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53979"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Московский пр-т, д. 79, литера Б",
    "Lat": 59.902414222729,
    "Lng": 30.317106914749,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53974"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Генерала Симоняка, д. 4, корп. 1, литера А",
    "Lat": 59.831469216305,
    "Lng": 30.204931364418,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53975"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Парашютная, д. 38/3, стр. 1",
    "Lat": 60.028013911656,
    "Lng": 30.252693813492,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53976"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, пр-т Луначарского, д. 76, литера А",
    "Lat": 60.037747871902,
    "Lng": 30.360767528676,
    "TimeTable": "07:30 - 20:00",
    "Comment": "",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=54947"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Дальневосточный пр-т, д. 25, корп. 1, стр. 1",
    "Lat": 59.90429511891,
    "Lng": 30.453349457672,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=54571"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Коллонтай, д. 24/1, литера А",
    "Lat": 59.921515121999,
    "Lng": 30.473947215939,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=54602"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": " г. Санкт-Петербург, пр-т Пятилеток, д. 10, корп. 2, литера А",
    "Lat": 59.92277154187,
    "Lng": 30.456274462095,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=54819"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, пр-т Солидарности, д. 14, корп. 1, литера А",
    "Lat": 59.918430069668,
    "Lng": 30.496714801258,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без очереди; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=55110"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Кронверкский пр-т, д. 29, литера А",
    "Lat": 59.957298874718,
    "Lng": 30.317000953373,
    "TimeTable": "07:30 - 22:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Ускоренный результат; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=55568"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, пр-т Просвещения, д. 53, корп. 1, литера А",
    "Lat": 60.043009711094,
    "Lng": 30.380470079305,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=55603"
  },
  {
    "Name": "Invitro",
    "City": "Кронштадт",
    "Address": "г. Санкт-Петербург, г. Кронштадт, ул. Гражданская, д. 8, литера А ",
    "Lat": 59.997988890635,
    "Lng": 29.764795844187,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=55054"
  },
  {
    "Name": "Invitro",
    "City": "Кировск",
    "Address": "г. Кировск, ул. Набережная, д. 19",
    "Lat": 59.87845978652,
    "Lng": 30.982264721827,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53977"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Днепропетровская, д. 57, литера А",
    "Lat": 59.91215782826,
    "Lng": 30.357978580296,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Срочные анализы (2 часа); Без очереди; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; CITO; Выдача результатов; ЭКГ; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=55592"
  },
  {
    "Name": "Invitro",
    "City": "Новое Девяткино",
    "Address": "Ленинградская область, д. Новое Девяткино, ул. Арсенальная, д. 7",
    "Lat": 60.059540311779,
    "Lng": 30.469780331693,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=55580"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Среднерогатская, дом 11, строение 1",
    "Lat": 59.823447143103,
    "Lng": 30.331715979828,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=55803"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Заневский пр., д.42, строение 1",
    "Lat": 59.934603834689,
    "Lng": 30.434617185867,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=56378"
  },
  {
    "Name": "Invitro",
    "City": "Кингисепп",
    "Address": "г. Кингисепп, ул. Иванова, д. 23",
    "Lat": 59.374532149495,
    "Lng": 28.606541542328,
    "TimeTable": "07:30 - 20:00",
    "Comment": "",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=56426"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, пр-т Ветеранов, д. 171 /2, стр. 1",
    "Lat": 59.837129799747,
    "Lng": 30.110274457672,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=56618"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, мун. округ Пулковский меридиан, 5-й Предпортовый проезд, д. 2, стр. 1",
    "Lat": 59.840956987291,
    "Lng": 30.312297169312,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=56619"
  },
  {
    "Name": "Invitro",
    "City": "Янино",
    "Address": "Ленинградская обл., п. Янино, ул. Голландская, д. 5",
    "Lat": 59.950509072273,
    "Lng": 30.577218813492,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57267"
  },
  {
    "Name": "Invitro",
    "City": "Никольское",
    "Address": "г. Никольское, Советский пр., д. 142",
    "Lat": 59.694314307172,
    "Lng": 30.785532093254,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без очереди; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57270"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург,  ул. Бабушкина, д. 84, к. 1",
    "Lat": 59.862495479871,
    "Lng": 30.464595746032,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58009"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Бульвар Александра Грина, д. 2",
    "Lat": 59.936108066086,
    "Lng": 30.204823720238,
    "TimeTable": "07:30 - 20:00",
    "Comment": "",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58012"
  },
  {
    "Name": "Invitro",
    "City": "Мурино",
    "Address": "г. Мурино, ул. шоссе в Лаврики, д. 76",
    "Lat": 60.062344899062,
    "Lng": 30.45592417791,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58443"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, 26-я линия В.О., д. 7, стр. 1",
    "Lat": 59.929761525978,
    "Lng": 30.259542328697,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58600"
  },
  {
    "Name": "Invitro",
    "City": "Сертолово",
    "Address": "г. Сертолово, мкр. Сертолово-2, ул. Тихвинская, д. 8, корп. 4",
    "Lat": 60.164098020017,
    "Lng": 30.196457084656,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58621"
  },
  {
    "Name": "Invitro",
    "City": "Бугры",
    "Address": "пос. Бугры, бульвар Петровский, д. 25",
    "Lat": 60.063220492035,
    "Lng": 30.404420008598,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58364"
  },
  {
    "Name": "Invitro",
    "City": "Колпино",
    "Address": "г. Колпино, ул.Тверская, д. 38, лит. А",
    "Lat": 59.739200784618,
    "Lng": 30.611199124992,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57716"
  },
  {
    "Name": "Invitro",
    "City": "Шушары",
    "Address": "п. Шушары, территория Пулковское, ул. Переведенская, д. 4, корп. 2, стр. 2",
    "Lat": 59.753339240816,
    "Lng": 30.372914813492,
    "TimeTable": "07:30 - 20:00",
    "Comment": "",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58705"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Каменноостровский проспект, д.34 ",
    "Lat": 59.964271863454,
    "Lng": 30.311959516533,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=59077"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Ижорская, д. 13/39",
    "Lat": 59.959449830212,
    "Lng": 30.296999542328,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=59078"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Советский проспект, д. 39, к. 1",
    "Lat": 59.829500593906,
    "Lng": 30.532698693115,
    "TimeTable": "07:30 - 20:00",
    "Comment": "",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=59192"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, пос. Парголово, ул. Валерия Гаврилина, д. 15, стр. 1",
    "Lat": 60.080163504022,
    "Lng": 30.33447794841,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=59203"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, пр-кт Героев, д. 30, стр. 1",
    "Lat": 59.85767603361,
    "Lng": 30.15350635582,
    "TimeTable": "07:30 - 17:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58883"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, улица Звездная, д. 9, корп.1, лит. А",
    "Lat": 59.834020189395,
    "Lng": 30.357371480812,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58884"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Петергофское шоссе, д. 84, корп. 19, стр. 1",
    "Lat": 59.845962,
    "Lng": 30.108079,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58882"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, муниципальный округ Полюстрово, Васнецовский проспект, д. 22, стр. 1",
    "Lat": 60.00751,
    "Lng": 30.482676,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=60573"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "Санкт-Петербург, пр-кт Авиаконструкторов, д. 47, лит. А",
    "Lat": 60.027107,
    "Lng": 30.22539,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без очереди; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=60452"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, пр-кт Новоколомяжский, д. 13, литера А",
    "Lat": 60.030601579262,
    "Lng": 30.293191542328,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=60871"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Богатырский пр-т, д. 49, к. 1, литера А",
    "Lat": 60.00594405866,
    "Lng": 30.223258499336,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=60852"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Конторская, д. 20, литера А",
    "Lat": 59.946636382125,
    "Lng": 30.413708898147,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=61388"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт- Петербург, Кушелевская дорога, д. 6, корп. 1, стр. 1",
    "Lat": 59.988141381678,
    "Lng": 30.365844212303,
    "TimeTable": "07:30 - 20:00",
    "Comment": "",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=62141"
  },
  {
    "Name": "Invitro",
    "City": "Шушары",
    "Address": "п. Шушары, ул. Школьная, д. 17, стр. 1",
    "Lat": 59.812214616065,
    "Lng": 30.37361253373,
    "TimeTable": "07:30 - 20:00",
    "Comment": "",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=62337"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Комендантский проспект, д. 66, корп. 1, стр. 1 ",
    "Lat": 60.040501,
    "Lng": 30.219605,
    "TimeTable": "07:30 - 20:00",
    "Comment": "",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=63301"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Лиговский проспект, д.109",
    "Lat": 59.919734,
    "Lng": 30.352951,
    "TimeTable": "09:00 - 21:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка; Бесплатный Wi-Fi",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-терапевт; Вакцинация; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие гинекологического и урологического материала; Врач-терапевт; Вакцинация; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=63484"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Орлово-Денисовский пр-кт, д.19. к. 1, стр.1",
    "Lat": 60.056916046258,
    "Lng": 30.267975542328,
    "TimeTable": "07:30 - 20:00",
    "Comment": "",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=63401"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Парфеновская, д.9, корп. 1, стр. 1",
    "Lat": 59.901324879128,
    "Lng": 30.308936079345,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=63409"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. 2-я Советская, д. 10",
    "Lat": 59.931819,
    "Lng": 30.368968,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=63408"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Лидии Зверевой, д. 9, корп. 2 , стр. 1",
    "Lat": 60.039588999982,
    "Lng": 30.257617720238,
    "TimeTable": "07:30 - 20:00",
    "Comment": "",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=63267"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "Ленинградская область, Ломоносовский муниципальный р-он, Вилозское Cityское поселение,  поселок Новогорелово, бульвар Десантника Вадима Чугунова, д. 7, п. 12Н ",
    "Lat": 59.790367,
    "Lng": 30.169218,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=64161"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, пр. Лиговский, д.271. стр.1, пом 78Н",
    "Lat": 59.905357,
    "Lng": 30.342404,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=64194"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Малая Бухарестская , д. 12, стр.1, пом. 54Н",
    "Lat": 59.848366,
    "Lng": 30.416587,
    "TimeTable": "",
    "Comment": "",
    "MedicalServices": "",
    "NotMedicalServices": "",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=64548"
  },
  {
    "Name": "Invitro",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Чайковского, д. 44, лит А, пом 3-Н",
    "Lat": 59.946676,
    "Lng": 30.361413,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=64904"
  },
  {
    "Name": "Invitro",
    "City": "Шушары",
    "Address": "г. Санкт-Петербург, внутриCityское муниципальное образование Санкт-Петербурга поселок Шушары, Московское шоссе, дом 250, корпус 2, строение 1, помещение 22-Н",
    "Lat": 59.758318,
    "Lng": 30.477053,
    "TimeTable": "07:30 - 18:00",
    "Comment": "",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=64917"
  },
  {
    "Name": "Invitro",
    "City": "Краснодар",
    "Address": "г. Краснодар, ул. Ставропольская, д. 127",
    "Lat": 45.021022656789,
    "Lng": 39.022312885593,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=10283"
  },
  {
    "Name": "Invitro",
    "City": "Краснодар",
    "Address": "г. Краснодар, ул. Красная, д. 196",
    "Lat": 45.054257035576,
    "Lng": 38.982255093254,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=32770"
  },
  {
    "Name": "Invitro",
    "City": "Краснодар",
    "Address": "г. Краснодар, ул. им. 40-летия Победы, д. 18",
    "Lat": 45.054652000019,
    "Lng": 39.000679271164,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=33623"
  },
  {
    "Name": "Invitro",
    "City": "Краснодар",
    "Address": "г. Краснодар, ул. Карякина, д. 22",
    "Lat": 45.065228619646,
    "Lng": 39.010928847885,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=35575"
  },
  {
    "Name": "Invitro",
    "City": "Краснодар",
    "Address": "г. Краснодар, ул. Атарбекова, д. 9",
    "Lat": 45.059685812055,
    "Lng": 38.950156364418,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=36976"
  },
  {
    "Name": "Invitro",
    "City": "Краснодар",
    "Address": "г. Краснодар, ул. Тюляева, д. 21",
    "Lat": 45.036720976429,
    "Lng": 39.096263486828,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=38167"
  },
  {
    "Name": "Invitro",
    "City": "Краснодар",
    "Address": "г. Краснодар, ул. Агрохимическая, д. 117",
    "Lat": 45.085659608681,
    "Lng": 39.003521550926,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=43826"
  },
  {
    "Name": "Invitro",
    "City": "Краснодар",
    "Address": "г. Краснодар, ул. 2-я Целиноградская, д. 44, корп. 2",
    "Lat": 45.146703777078,
    "Lng": 38.998580728836,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-гинеколог",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-гинеколог; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=47213"
  },
  {
    "Name": "Invitro",
    "City": "Краснодар",
    "Address": "г. Краснодар, ул. Игнатова, д. 55",
    "Lat": 45.006539691557,
    "Lng": 39.072583586528,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; С 7.00 утра",
    "MedicalServices": "Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=51636"
  },
  {
    "Name": "Invitro",
    "City": "Краснодар",
    "Address": "г. Краснодар, пр-т Чекистов, 28/1",
    "Lat": 45.030797856559,
    "Lng": 38.914372453649,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=52855"
  },
  {
    "Name": "Invitro",
    "City": "Краснодар",
    "Address": "г. Краснодар, ул. Восточно-Кругликовская, д. 22",
    "Lat": 45.0480564211,
    "Lng": 39.027300728836,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; С 7.00 утра",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=54010"
  },
  {
    "Name": "Invitro",
    "City": "Краснодар",
    "Address": "г. Краснодар, ул. Октябрьская, 179",
    "Lat": 45.038681453358,
    "Lng": 38.970373373016,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53466"
  },
  {
    "Name": "Invitro",
    "City": "Краснодар",
    "Address": "г. Краснодар, ул. Кубанская Набережная, д. 64",
    "Lat": 45.021749,
    "Lng": 38.960284,
    "TimeTable": "",
    "Comment": "",
    "MedicalServices": "",
    "NotMedicalServices": "",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=64291"
  },
  {
    "Name": "Invitro",
    "City": "Волгоград",
    "Address": "г. Волгоград, ул. Невская, д. 11А",
    "Lat": 48.717035229013,
    "Lng": 44.508668094582,
    "TimeTable": "07:00 - 18:30",
    "Comment": "Оплата банковской картой; Без выходных; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=713"
  },
  {
    "Name": "Invitro",
    "City": "Волгоград",
    "Address": "г. Волгоград, ул. Генерала Штеменко, д. 43",
    "Lat": 48.77545861743,
    "Lng": 44.554731293288,
    "TimeTable": "07:00 - 18:30",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=17257"
  },
  {
    "Name": "Invitro",
    "City": "Волгоград",
    "Address": "г. Волгоград, ул. Огарёва, д. 18",
    "Lat": 48.695516844704,
    "Lng": 44.493563271164,
    "TimeTable": "07:00 - 18:30",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=29722"
  },
  {
    "Name": "Invitro",
    "City": "Волгоград",
    "Address": "г. Волгоград, ул. Николая Отрады, д. 20Б",
    "Lat": 48.816702961286,
    "Lng": 44.629157,
    "TimeTable": "07:00 - 18:30",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=31519"
  },
  {
    "Name": "Invitro",
    "City": "Волгоград",
    "Address": "г. Волгоград, ул. 8-ой Воздушной Армии, д. 33",
    "Lat": 48.754016394659,
    "Lng": 44.50134417791,
    "TimeTable": "07:00 - 18:30",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=32534"
  },
  {
    "Name": "Invitro",
    "City": "Волгоград",
    "Address": "г. Волгоград, ул. им. Грибанова, д. 5",
    "Lat": 48.661851678272,
    "Lng": 44.413316737434,
    "TimeTable": "07:00 - 18:30",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57315"
  },
  {
    "Name": "Invitro",
    "City": "Волгоград",
    "Address": "г. Волгоград , ул. 64-й Армии, д. 6",
    "Lat": 48.601219347606,
    "Lng": 44.422071635582,
    "TimeTable": "07:00 - 18:30",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57730"
  },
  {
    "Name": "Invitro",
    "City": "Волгоград",
    "Address": "г. Волгоград, пр-кт Героев Сталинграда, д. 48",
    "Lat": 48.512625707697,
    "Lng": 44.545142116394,
    "TimeTable": "07:00 - 18:30",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58619"
  },
  {
    "Name": "Invitro",
    "City": "Волгоград",
    "Address": "г. Волгоград, ул. 50 лет Октября, д. 4",
    "Lat": 48.518888563856,
    "Lng": 44.564528542328,
    "TimeTable": "07:00 - 18:30",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58713"
  },
  {
    "Name": "Invitro",
    "City": "Волгоград",
    "Address": "г. Волгоград , пр-кт Ленина, д. 213",
    "Lat": 48.799770227506,
    "Lng": 44.602275542328,
    "TimeTable": "07:00 - 18:30",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=59281"
  },
  {
    "Name": "Invitro",
    "City": "Волгоград",
    "Address": "г. Волгоград, пр-т Ленина, д.48",
    "Lat": 48.721417,
    "Lng": 44.53451,
    "TimeTable": "07:00 - 18:30",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=64429"
  },
  {
    "Name": "Invitro",
    "City": "Уфа",
    "Address": "г. Уфа, пр-т Октября, д. 48",
    "Lat": 54.753253305553,
    "Lng": 56.002762459426,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=707"
  },
  {
    "Name": "Invitro",
    "City": "Уфа",
    "Address": "г. Уфа, ул. Маршала Жукова, д. 21",
    "Lat": 54.769305139499,
    "Lng": 56.054918644822,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=7165"
  },
  {
    "Name": "Invitro",
    "City": "Уфа",
    "Address": "Республика Башкортостан, г. Уфа, ул. Софьи Перовской, 54",
    "Lat": 54.697083954188,
    "Lng": 55.992503698882,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=19326"
  },
  {
    "Name": "Invitro",
    "City": "Уфа",
    "Address": "Республика Башкортостан, г. Уфа,  ул. Цюрупы, д.  91",
    "Lat": 54.733142165636,
    "Lng": 55.956762084656,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=24302"
  },
  {
    "Name": "Invitro",
    "City": "Уфа",
    "Address": "г. Уфа, ул. Ульяновых, д. 31",
    "Lat": 54.818390781858,
    "Lng": 56.075005,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=33559"
  },
  {
    "Name": "Invitro",
    "City": "Уфа",
    "Address": "г. Уфа ул. Менделеева, д. 140/1",
    "Lat": 54.720786664254,
    "Lng": 56.011012635034,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-гинеколог; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-гинеколог; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=37451"
  },
  {
    "Name": "Invitro",
    "City": "Уфа",
    "Address": "г. Уфа, ул. Ухтомского, д.17",
    "Lat": 54.705896319173,
    "Lng": 55.828474101852,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-гинеколог; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-гинеколог; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=52497"
  },
  {
    "Name": "Invitro",
    "City": "Уфа",
    "Address": "г. Уфа, ул. Аксакова, д. 56",
    "Lat": 54.729875779071,
    "Lng": 55.937201775076,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=48883"
  },
  {
    "Name": "Invitro",
    "City": "Уфа",
    "Address": "г. Уфа, пр-т Октября, д. 125",
    "Lat": 54.790717897359,
    "Lng": 56.035271952985,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=49066"
  },
  {
    "Name": "Invitro",
    "City": "Уфа",
    "Address": "г. Уфа, ул. Ферина, д. 1",
    "Lat": 54.787126894266,
    "Lng": 56.131671363777,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=51174"
  },
  {
    "Name": "Invitro",
    "City": "Уфа",
    "Address": "г. Уфа, ул. Маршала Жукова, д. 20",
    "Lat": 54.777384535499,
    "Lng": 56.069562576401,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53291"
  },
  {
    "Name": "Invitro",
    "City": "Уфа",
    "Address": "г. Уфа, ул. Лесотехникума, д. 20",
    "Lat": 54.758765465118,
    "Lng": 56.023418279762,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=61463"
  },
  {
    "Name": "Invitro",
    "City": "Уфа",
    "Address": "г. Уфа, ул. Летчиков, д. 11",
    "Lat": 54.794741,
    "Lng": 55.870665,
    "TimeTable": "07:00 - 16:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=63325"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Тургенева, д. 3",
    "Lat": 56.840713165464,
    "Lng": 60.614433457031,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=717"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Фрунзе, д. 67",
    "Lat": 56.812589988162,
    "Lng": 60.601728304916,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=718"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Заводская, д. 12",
    "Lat": 56.837957316049,
    "Lng": 60.553205480592,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=6907"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Академика Бардина, д. 40, корп. 1",
    "Lat": 56.800448862589,
    "Lng": 60.573040282066,
    "TimeTable": "08:00 - 17:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=9110"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Родонитовая, д. 8",
    "Lat": 56.791196501144,
    "Lng": 60.6227889825,
    "TimeTable": "07:00 - 18:30",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=10587"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Краснолесья, д. 109",
    "Lat": 56.790072456681,
    "Lng": 60.526157207387,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=10994"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Машиностроителей,  д. 14",
    "Lat": 56.884875939827,
    "Lng": 60.575241154512,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=11267"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Надеждинская, д. 8",
    "Lat": 56.86624842186,
    "Lng": 60.534781034115,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=16577"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "Свердловская область, г. Екатеринбург, ул. Уральская, 70",
    "Lat": 56.854434487986,
    "Lng": 60.635054457672,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=26129"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, Восточная, 164",
    "Lat": 56.828260458952,
    "Lng": 60.636454681935,
    "TimeTable": "07:00 - 18:30",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=28429"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Грибоедова, д.15",
    "Lat": 56.753724938836,
    "Lng": 60.696924915344,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=29201"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Сыромолотова, д. 25",
    "Lat": 56.835333513445,
    "Lng": 60.687389567575,
    "TimeTable": "08:00 - 16:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=38880"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Белореченская, д. 29",
    "Lat": 56.812887730156,
    "Lng": 60.559308991402,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=45374"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Патриса Лумумбы, д. 63",
    "Lat": 56.766278147643,
    "Lng": 60.604085273303,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=45448"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Юмашева, д. 10",
    "Lat": 56.843560065497,
    "Lng": 60.573796189144,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=46833"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Трактовая, д. 2",
    "Lat": 56.778018780098,
    "Lng": 60.798451250332,
    "TimeTable": "07:00 - 17:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=50245"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Гагарина, д. 22",
    "Lat": 56.840187065859,
    "Lng": 60.645938635462,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=49648"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Азина, д. 39",
    "Lat": 56.853839756189,
    "Lng": 60.606661495701,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=51189"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Краснофлотцев, д. 22",
    "Lat": 56.88961021441,
    "Lng": 60.63148446627,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=50833"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Щербакова, д. 20",
    "Lat": 56.783259990238,
    "Lng": 60.654481701713,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53293"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Победы, д. 16",
    "Lat": 56.897147462881,
    "Lng": 60.605402852785,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53292"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Радищева, д. 33",
    "Lat": 56.82827203285,
    "Lng": 60.588726071746,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53804"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Белинского, д. 200А",
    "Lat": 56.804042494207,
    "Lng": 60.626258159662,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=54834"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Евгения Савкова, д. 4",
    "Lat": 56.796248057184,
    "Lng": 60.493752332681,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=56029"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Бебеля,  д. 128",
    "Lat": 56.853166833105,
    "Lng": 60.555663660049,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=56861"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Викулова,  д. 37, корп. 1",
    "Lat": 56.831865703895,
    "Lng": 60.545597,
    "TimeTable": "07:00 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58852"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург,  пр-т Академика Сахарова, д. 51",
    "Lat": 56.784645003445,
    "Lng": 60.516172347221,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=60453"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Техническая, д. 20а",
    "Lat": 56.862873876635,
    "Lng": 60.543455364418,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Вход с колясками",
    "MedicalServices": "Взятие гинекологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=60576"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Ирбитская,  д. 13",
    "Lat": 56.862460260419,
    "Lng": 60.65575717791,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=61253"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, бульвар Золотистый, д. 4",
    "Lat": 56.746942048529,
    "Lng": 60.565151862427,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=61401"
  },
  {
    "Name": "Invitro",
    "City": "Екатеринбург",
    "Address": "г. Екатеринбург, ул. Билимбаевская, д. 17",
    "Lat": 56.881119,
    "Lng": 60.516231,
    "TimeTable": "07:00 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=63297"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Зыряновская, д. 55",
    "Lat": 55.011565667479,
    "Lng": 82.937870643363,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Ускоренный результат",
    "MedicalServices": "",
    "NotMedicalServices": "График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=3112"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Советская, д. 35",
    "Lat": 55.030543021837,
    "Lng": 82.915203608459,
    "TimeTable": "07:30 - 15:00",
    "Comment": "Оплата банковской картой; Без выходных; Результат за 4 часа",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Результаты за 4 часа; Выдача результатов; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=3114"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, Красный пр-т, д. 218/2.",
    "Lat": 55.066499014989,
    "Lng": 82.911572216406,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Видеокольпоскопия; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; Эхокардиография (ЭХО-КГ)",
    "NotMedicalServices": "Взятие крови, мочи, кала; Результаты за 4 часа; Выдача результатов; Взятие гинекологического и урологического материала; Видеокольпоскопия; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Эхокардиография (ЭХО-КГ); График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=4827"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Богдана Хмельницкого, д. 33",
    "Lat": 55.07397415602,
    "Lng": 82.949693186508,
    "TimeTable": "07:30 - 15:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=8310"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Станиславского, д. 16",
    "Lat": 54.979996489664,
    "Lng": 82.871543287345,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Ускоренный результат",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=8557"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Гребенщикова, д. 9",
    "Lat": 55.111592363338,
    "Lng": 82.940152332312,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=9729"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Гоголя, д. 43/1",
    "Lat": 55.044061779767,
    "Lng": 82.937991668912,
    "TimeTable": "07:30 - 15:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Результаты за 4 часа; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=9731"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, Красный пр-т, д. 188",
    "Lat": 55.059451404393,
    "Lng": 82.914123686549,
    "TimeTable": "07:00 - 15:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=10603"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Высоцкого, д. 43",
    "Lat": 55.030379751426,
    "Lng": 83.020295315243,
    "TimeTable": "07:30 - 15:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "Взятие урологического материала; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие урологического материала; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=11100"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, проспект Дзержинского, д. 22",
    "Lat": 55.053201153144,
    "Lng": 82.970528648502,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=11710"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, Морской проспект, д. 21",
    "Lat": 54.838275925522,
    "Lng": 83.106300020544,
    "TimeTable": "",
    "Comment": "Оплата банковской картой; Вход с колясками",
    "MedicalServices": "",
    "NotMedicalServices": "",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=12266"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Большевистская, д. 151",
    "Lat": 54.996784201745,
    "Lng": 82.966108937304,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=16613"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Киевская, д. 3/1",
    "Lat": 54.989801370238,
    "Lng": 82.850810170588,
    "TimeTable": "07:30 - 15:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Ускоренный результат",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=17254"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Кирова, д 27, строение 1",
    "Lat": 55.018071203827,
    "Lng": 82.944315808512,
    "TimeTable": "07:30 - 15:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Результаты за 4 часа; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=18856"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск ул. Громова 17/1",
    "Lat": 54.934688355062,
    "Lng": 82.909810271164,
    "TimeTable": "07:00 - 17:00",
    "Comment": "Оплата банковской картой; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=24464"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Ватутина, д. 33",
    "Lat": 54.981957931528,
    "Lng": 82.899204084656,
    "TimeTable": "07:30 - 15:00",
    "Comment": "Оплата банковской картой; Срочные анализы (2 часа); Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; CITO; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=25403"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, Кубовая, 99",
    "Lat": 55.127688255655,
    "Lng": 82.89652564418,
    "TimeTable": "07:30 - 15:00",
    "Comment": "Оплата банковской картой; Вход с колясками",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=28479"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Нарымская, д.19",
    "Lat": 55.042025943828,
    "Lng": 82.906631,
    "TimeTable": "07:30 - 15:00",
    "Comment": "Оплата банковской картой; Ускоренный результат",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=31140"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Выборная, д.115\\2",
    "Lat": 55.002781334042,
    "Lng": 83.017452830688,
    "TimeTable": "07:30 - 15:00",
    "Comment": "Оплата банковской картой; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=31200"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Демакова, д. 1",
    "Lat": 54.864417609485,
    "Lng": 83.099555373016,
    "TimeTable": "07:30 - 15:00",
    "Comment": "Оплата банковской картой; Вход с колясками",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=36026"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "Пашино, Новоуральская,33",
    "Lat": 55.17910541738,
    "Lng": 82.954862209685,
    "TimeTable": "07:30 - 17:00",
    "Comment": "Оплата банковской картой; Вход с колясками",
    "MedicalServices": "Взятие гинекологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=37449"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Б. Богаткова, д. 208\\1",
    "Lat": 55.035676096055,
    "Lng": 82.976016643084,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант; Врач-терапевт; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Результаты за 4 часа; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; Врач-терапевт; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=38303"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Римского-Корсакова, д. 9",
    "Lat": 54.979741758383,
    "Lng": 82.885956510222,
    "TimeTable": "00:00 - 23:55",
    "Comment": "Оплата банковской картой; Без выходных; Срочные анализы (2 часа); Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; Врач-терапевт; Вакцинация; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; Эхокардиография (ЭХО-КГ)",
    "NotMedicalServices": "Взятие крови, мочи, кала; CITO; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; Врач-терапевт; Вакцинация; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Эхокардиография (ЭХО-КГ); График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=45268"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Столетова, д. 5",
    "Lat": 55.098285933417,
    "Lng": 82.965050194184,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=46811"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Бориса Богаткова, д. 256",
    "Lat": 55.03918065433,
    "Lng": 82.980837660049,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Результат за 4 часа",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=8311"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Динамовцев, д. 1",
    "Lat": 54.8618309056,
    "Lng": 82.989638504299,
    "TimeTable": "07:30 - 15:00",
    "Comment": "Оплата банковской картой; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=50605"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Оловозаводская, д. 13",
    "Lat": 54.962322697678,
    "Lng": 82.952287540861,
    "TimeTable": "07:30 - 15:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53198"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Ватутина, д. 19",
    "Lat": 54.986039653191,
    "Lng": 82.89213880781,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Срочные анализы (2 часа); Вход с колясками; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=54041"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Кропоткина, д. 126/1",
    "Lat": 55.056460262263,
    "Lng": 82.933288254901,
    "TimeTable": "07:30 - 19:30",
    "Comment": "Оплата банковской картой; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=54954"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, мкр-н Горский, д. 43",
    "Lat": 54.99303957856,
    "Lng": 82.895821898147,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=56079"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Д. Ковальчук, д.75/1",
    "Lat": 55.053616069254,
    "Lng": 82.893467542328,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=56413"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Спортивная, д. 4",
    "Lat": 54.985382933402,
    "Lng": 82.808411906746,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического материала; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57300"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Трикотажная, д. 60/3",
    "Lat": 55.068093490878,
    "Lng": 82.964494440475,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58620"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Гоголя, д. 180",
    "Lat": 55.044052160643,
    "Lng": 82.95461982209,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=60571"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Гоголя, д. 4",
    "Lat": 55.041599288225,
    "Lng": 82.914424169311,
    "TimeTable": "07:00 - 17:00",
    "Comment": "",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=60572"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Заречная, д.4",
    "Lat": 54.977305453137,
    "Lng": 83.044938923942,
    "TimeTable": "",
    "Comment": "Оплата банковской картой; Вход с колясками; Бесплатная парковка; Платная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=59340"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Мясниковой, д. 26",
    "Lat": 55.108471472534,
    "Lng": 82.93041828836,
    "TimeTable": "07:00 - 17:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=60847"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. 9-й Гвардейской Дивизии, д. 24",
    "Lat": 54.969148238842,
    "Lng": 82.844538804894,
    "TimeTable": "07:30 - 15:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=61146"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Заровного, д. 22",
    "Lat": 54.99153891561,
    "Lng": 82.967221449074,
    "TimeTable": "07:30 - 15:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=62136"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Виктора Уса, д. 1/2",
    "Lat": 54.941286293938,
    "Lng": 82.886272542328,
    "TimeTable": "07:30 - 15:00",
    "Comment": "",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=63049"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Геодезическая, д.13",
    "Lat": 54.988376,
    "Lng": 82.907153,
    "TimeTable": "07:30 - 15:00",
    "Comment": "Оплата банковской картой; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=63268"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, пр. Морской, д.7",
    "Lat": 54.839925,
    "Lng": 83.109678,
    "TimeTable": "07:30 - 15:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=63865"
  },
  {
    "Name": "Invitro",
    "City": "Новосибирск",
    "Address": "г. Новосибирск, ул. Героев Революции, д. 25, пом.3",
    "Lat": 54.975134,
    "Lng": 83.086393,
    "TimeTable": "07:30 - 18:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Бесплатная парковка; Бесплатный Wi-Fi",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=63647"
  },
  {
    "Name": "Invitro",
    "City": "Казань",
    "Address": "г. Казань, ул. Вишневского, д. 29/48",
    "Lat": 55.787639255061,
    "Lng": 49.152012473368,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=8023"
  },
  {
    "Name": "Invitro",
    "City": "Казань",
    "Address": "г. Казань, ул. Академика Сахарова, д. 12",
    "Lat": 55.780010605857,
    "Lng": 49.230704892257,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=22810"
  },
  {
    "Name": "Invitro",
    "City": "Казань",
    "Address": "г. Казань, ул. Декабристов д. 160/40",
    "Lat": 55.834257905007,
    "Lng": 49.081092260036,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=22811"
  },
  {
    "Name": "Invitro",
    "City": "Казань",
    "Address": "г. Казань, ул. Адоратского, д. 27",
    "Lat": 55.836999351364,
    "Lng": 49.14686512679,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=22812"
  },
  {
    "Name": "Invitro",
    "City": "Казань",
    "Address": "г. Казань, ул. Четаева, д. 35",
    "Lat": 55.824678632016,
    "Lng": 49.12146064418,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=28522"
  },
  {
    "Name": "Invitro",
    "City": "Казань",
    "Address": "г. Казань, пр-кт Победы, д. 56",
    "Lat": 55.750717675879,
    "Lng": 49.212181170639,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=28523"
  },
  {
    "Name": "Invitro",
    "City": "Казань",
    "Address": "г. Казань, ул. Фрунзе, д. 3",
    "Lat": 55.824853046179,
    "Lng": 49.044694880951,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=51906"
  },
  {
    "Name": "Invitro",
    "City": "Казань",
    "Address": "г. Казань, ул. Николая Ершова, д. 78",
    "Lat": 55.801157949751,
    "Lng": 49.183601474869,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=51909"
  },
  {
    "Name": "Invitro",
    "City": "Казань",
    "Address": "г. Казань, р-н Вахитовский, ул. Марселя Салимжанова, д. 15/8Б",
    "Lat": 55.776641093857,
    "Lng": 49.132648813491,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58006"
  },
  {
    "Name": "Invitro",
    "City": "Казань",
    "Address": "г. Казань, ул. Академика Парина, д. 6",
    "Lat": 55.745624702669,
    "Lng": 49.189278642853,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58007"
  },
  {
    "Name": "Invitro",
    "City": "Казань",
    "Address": "г. Казань, ул. Нигматуллина, д. 11/84",
    "Lat": 55.818813868512,
    "Lng": 49.142148704368,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58008"
  },
  {
    "Name": "Invitro",
    "City": "Казань",
    "Address": "г. Казань, ул. Даурская, д. 25",
    "Lat": 55.766371097146,
    "Lng": 49.179820720238,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=61380"
  },
  {
    "Name": "Invitro",
    "City": "Казань",
    "Address": "г. Казань, ул. Беломорская, д. 6",
    "Lat": 55.864545,
    "Lng": 49.085923,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=63046"
  },
  {
    "Name": "Invitro",
    "City": "Казань",
    "Address": "г. Казань, ул. Татарстан, д. 16/78",
    "Lat": 55.782584174655,
    "Lng": 49.11500128836,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Бесплатная парковка; Платная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=63410"
  },
  {
    "Name": "Invitro",
    "City": "Сочи",
    "Address": "г. Сочи, Центральный район, ул. Туапсинская, д. 13",
    "Lat": 43.592367661648,
    "Lng": 39.737578464267,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Без очереди",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=6205"
  },
  {
    "Name": "Invitro",
    "City": "Сочи",
    "Address": "г. Сочи, Адлер, ул. Кирова, д. 30",
    "Lat": 43.423834752844,
    "Lng": 39.923035654674,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=13497"
  },
  {
    "Name": "Invitro",
    "City": "Сочи",
    "Address": "г. Сочи, ул. Островского, д. 67",
    "Lat": 43.590963873037,
    "Lng": 39.724678656787,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=14386"
  },
  {
    "Name": "Invitro",
    "City": "Сочи",
    "Address": "г. Сочи, п. Лазаревское, ул. Победы, д. 44",
    "Lat": 43.909211852221,
    "Lng": 39.331835434359,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Без очереди; Бесплатная парковка; Бесплатный Wi-Fi",
    "MedicalServices": "Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; Взятие урологического материала; Кольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=49857"
  },
  {
    "Name": "Invitro",
    "City": "Сочи",
    "Address": "г. Сочи, Центральный район, ул. Донская, д. 88",
    "Lat": 43.62222512492,
    "Lng": 39.721673411045,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Кольпоскопия; Врач-гинеколог; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Кольпоскопия; Врач-гинеколог; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57166"
  },
  {
    "Name": "Invitro",
    "City": "Сочи",
    "Address": "г. Сочи, Адлерский район, ул. Ленина, д. 209",
    "Lat": 43.459515676786,
    "Lng": 39.902488317791,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57167"
  },
  {
    "Name": "Invitro",
    "City": "Воронеж",
    "Address": "г. Воронеж, ул. Плехановская, д. 50",
    "Lat": 51.671159664334,
    "Lng": 39.189183932771,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Видеокольпоскопия; Врач-терапевт; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Видеокольпоскопия; Врач-терапевт; УЗИ; ЭКГ; Оформление санаторно-курортной карты; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=4685"
  },
  {
    "Name": "Invitro",
    "City": "Воронеж",
    "Address": "г. Воронеж, ул. Генерала Лизюкова, д. 66А",
    "Lat": 51.705873444743,
    "Lng": 39.146284712967,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Видеокольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Видеокольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=26348"
  },
  {
    "Name": "Invitro",
    "City": "Воронеж",
    "Address": "г. Воронеж, Ленинский пр-кт, д. 124Б",
    "Lat": 51.6735041198,
    "Lng": 39.254387457672,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=30000"
  },
  {
    "Name": "Invitro",
    "City": "Воронеж",
    "Address": "г. Воронеж, Жилой массив Олимпийский д. 2",
    "Lat": 51.71449864566,
    "Lng": 39.193941681146,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Видеокольпоскопия; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Видеокольпоскопия; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=48112"
  },
  {
    "Name": "Invitro",
    "City": "Воронеж",
    "Address": "г. Воронеж, ул. Ростовская, д. 58/22",
    "Lat": 51.602570915507,
    "Lng": 39.238942599692,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=48113"
  },
  {
    "Name": "Invitro",
    "City": "Воронеж",
    "Address": "г. Воронеж, Ленинский проспект, д.22 ",
    "Lat": 51.641890469437,
    "Lng": 39.237225228172,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=56369"
  },
  {
    "Name": "Invitro",
    "City": "Воронеж",
    "Address": "г. Воронеж, ул. Космонавтов, д.10 ",
    "Lat": 51.659706533095,
    "Lng": 39.153930288243,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=56368"
  },
  {
    "Name": "Invitro",
    "City": "Воронеж",
    "Address": "г. Воронеж, Московский проспект, д.124 ",
    "Lat": 51.716874620418,
    "Lng": 39.181767813492,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Видеокольпоскопия; Врач-терапевт; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; Эхокардиография (ЭХО-КГ)",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Видеокольпоскопия; Врач-терапевт; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Эхокардиография (ЭХО-КГ); Оформление санаторно-курортной карты; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=56370"
  },
  {
    "Name": "Invitro",
    "City": "Воронеж",
    "Address": "г. Воронеж, ул. Кольцовская, д. 4",
    "Lat": 51.678677000002,
    "Lng": 39.204622186508,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без очереди; Платная парковка; Бесплатный Wi-Fi",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57982"
  },
  {
    "Name": "Invitro",
    "City": "Воронеж",
    "Address": "г. Воронеж, ул. Курчатова, д. 32/1",
    "Lat": 51.561601938417,
    "Lng": 39.115707253967,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58994"
  },
  {
    "Name": "Invitro",
    "City": "Воронеж",
    "Address": "г. Воронеж, ул. Карла Маркса, д. 38",
    "Lat": 51.659841453558,
    "Lng": 39.208864626984,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Платная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=59054"
  },
  {
    "Name": "Invitro",
    "City": "Воронеж",
    "Address": "г. Воронеж, ул. Средне-московская, д. 29",
    "Lat": 51.668512,
    "Lng": 39.195894,
    "TimeTable": "",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "",
    "NotMedicalServices": "",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=64550"
  },
  {
    "Name": "Invitro",
    "City": "Нижний НовCity",
    "Address": "г. Нижний НовCity, ул. Горького, д. 184",
    "Lat": 56.318998848829,
    "Lng": 44.017955101852,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=3095"
  },
  {
    "Name": "Invitro",
    "City": "Нижний НовCity",
    "Address": "г. Нижний НовCity, пр-т Кирова, д. 1А",
    "Lat": 56.248084053352,
    "Lng": 43.865994728836,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=5886"
  },
  {
    "Name": "Invitro",
    "City": "Нижний НовCity",
    "Address": "г. Нижний НовCity, ул. Родионова, д.17",
    "Lat": 56.319509223812,
    "Lng": 44.054181033824,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=22422"
  },
  {
    "Name": "Invitro",
    "City": "Нижний НовCity",
    "Address": "г. Нижний НовCity, пр-т Ленина, д. 48",
    "Lat": 56.279882968332,
    "Lng": 43.924588271164,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=23287"
  },
  {
    "Name": "Invitro",
    "City": "Нижний НовCity",
    "Address": "г. Нижний НовCity, ул. Казанское шоссе, д. 5",
    "Lat": 56.295223408848,
    "Lng": 44.075280915344,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Врач-консультант; Врач-терапевт; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Врач-консультант; Врач-терапевт; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=34357"
  },
  {
    "Name": "Invitro",
    "City": "Нижний НовCity",
    "Address": "г. Нижний НовCity, Мещерский б-р, д. 7, корп. 3",
    "Lat": 56.338465509916,
    "Lng": 43.933936347221,
    "TimeTable": "07:00 - 18:00",
    "Comment": "Оплата банковской картой; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=36307"
  },
  {
    "Name": "Invitro",
    "City": "Нижний НовCity",
    "Address": "г. Нижний НовCity, пр-т Гагарина, д. 104",
    "Lat": 56.27357134746,
    "Lng": 43.977943708608,
    "TimeTable": "07:00 - 15:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=49830"
  },
  {
    "Name": "Invitro",
    "City": "Нижний НовCity",
    "Address": "г. Нижний НовCity, ул. Культуры, д. 7, корп. 1",
    "Lat": 56.349291383145,
    "Lng": 43.856600347221,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53332"
  },
  {
    "Name": "Invitro",
    "City": "Нижний НовCity",
    "Address": "г. Нижний НовCity, ул. Коминтерна, д. 6/1",
    "Lat": 56.337676106752,
    "Lng": 43.882940240013,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=54966"
  },
  {
    "Name": "Invitro",
    "City": "Нижний НовCity",
    "Address": "г. Нижний НовCity, ул. Веденяпина, 14",
    "Lat": 56.233267841408,
    "Lng": 43.872767017196,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка; С 7.00 утра",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=56345"
  },
  {
    "Name": "Invitro",
    "City": "Нижний НовCity",
    "Address": "г. Нижний НовCity, ул. М. Горького, д. 80/1",
    "Lat": 56.314158314998,
    "Lng": 43.993069245369,
    "TimeTable": "07:00 - 15:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; С 7.00 утра",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57039"
  },
  {
    "Name": "Invitro",
    "City": "Нижний НовCity",
    "Address": "г. Нижний НовCity, ул. Бекетова, д. 11/1",
    "Lat": 56.290352094168,
    "Lng": 43.995061274483,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57038"
  },
  {
    "Name": "Invitro",
    "City": "Нижний НовCity",
    "Address": "г. Нижний НовCity, пр-кт Ленина, д. 76",
    "Lat": 56.266012467854,
    "Lng": 43.913534144844,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58017"
  },
  {
    "Name": "Invitro",
    "City": "Нижний НовCity",
    "Address": "г. Нижний НовCity, Московское шоссе, д. 179",
    "Lat": 56.314646721656,
    "Lng": 43.88351017791,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58016"
  },
  {
    "Name": "Invitro",
    "City": "Нижний НовCity",
    "Address": "г. Нижний НовCity, ул. Ульянова, д. 12",
    "Lat": 56.326038280441,
    "Lng": 44.011241761902,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=60781"
  },
  {
    "Name": "Invitro",
    "City": "Ростов-на-Дону",
    "Address": "г. Ростов-на-Дону, ул. Станиславского, д. 54 ",
    "Lat": 47.216630980619,
    "Lng": 39.708733560494,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=3092"
  },
  {
    "Name": "Invitro",
    "City": "Ростов-на-Дону",
    "Address": "г. Ростов-на-Дону, пр-т Ленина, д. 44/6",
    "Lat": 47.250136636524,
    "Lng": 39.706011665183,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического материала; Вакцинация",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; Вакцинация; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=4964"
  },
  {
    "Name": "Invitro",
    "City": "Ростов-на-Дону",
    "Address": "г. Ростов-на-Дону, пр-т Космонавтов, д. 6/13",
    "Lat": 47.284085549101,
    "Lng": 39.717123825248,
    "TimeTable": "06:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Вакцинация; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Вакцинация; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=8551"
  },
  {
    "Name": "Invitro",
    "City": "Ростов-на-Дону",
    "Address": "г. Ростов-на-дону, улица 1-й Конной Армии, д. 29А",
    "Lat": 47.256075608163,
    "Lng": 39.768264914373,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=10929"
  },
  {
    "Name": "Invitro",
    "City": "Ростов-на-Дону",
    "Address": "г. Ростов-на-Дону, ул. Зорге, д. 52",
    "Lat": 47.220798932951,
    "Lng": 39.632125233065,
    "TimeTable": "06:30 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=13116"
  },
  {
    "Name": "Invitro",
    "City": "Ростов-на-Дону",
    "Address": "г. Ростов-на-Дону, ул. Таганрогская, д. 143",
    "Lat": 47.263065732541,
    "Lng": 39.659407068243,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=13119"
  },
  {
    "Name": "Invitro",
    "City": "Ростов-на-Дону",
    "Address": "г. Ростов-на-Дону, ул. Текучева 143",
    "Lat": 47.235711503672,
    "Lng": 39.709461195874,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=22955"
  },
  {
    "Name": "Invitro",
    "City": "Ростов-на-Дону",
    "Address": "г. Ростов-на-Дону, пр-т 40 лет Победы, д. 89",
    "Lat": 47.239111317664,
    "Lng": 39.828904457672,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=23175"
  },
  {
    "Name": "Invitro",
    "City": "Ростов-на-Дону",
    "Address": "г. Ростов-на-Дону, проспект Стачки, д. 26",
    "Lat": 47.211318000013,
    "Lng": 39.670441457672,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=23176"
  },
  {
    "Name": "Invitro",
    "City": "Ростов-на-Дону",
    "Address": "г. Ростов-на-Дону, пер. Днепровский, д. 105",
    "Lat": 47.282743601639,
    "Lng": 39.756297203705,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=32430"
  },
  {
    "Name": "Invitro",
    "City": "Ростов-на-Дону",
    "Address": "г. Ростов-на-Дону, ул. Еременко, д. 97/29",
    "Lat": 47.232985485808,
    "Lng": 39.599228783881,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=52856"
  },
  {
    "Name": "Invitro",
    "City": "Ростов-на-Дону",
    "Address": "г. Ростов-на-Дону, микрорайон Красный Аксай, ул. Богданова, 79",
    "Lat": 47.226455518383,
    "Lng": 39.777313796295,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=56318"
  },
  {
    "Name": "Invitro",
    "City": "Ростов-на-Дону",
    "Address": "г. Ростов-на-Дону, ул. Миронова, д. 10",
    "Lat": 47.297264039875,
    "Lng": 39.746717457672,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57266"
  },
  {
    "Name": "Invitro",
    "City": "Ростов-на-Дону",
    "Address": "г. Ростов-на-Дону, ул. Нагибина, 49",
    "Lat": 47.26482756158,
    "Lng": 39.719946728836,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58617"
  },
  {
    "Name": "Invitro",
    "City": "Ростов-на-Дону",
    "Address": "г. Ростов-на-Дону, ул. Еременко, д. 25/36",
    "Lat": 47.232885,
    "Lng": 39.634641,
    "TimeTable": "08:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=63411"
  },
  {
    "Name": "Invitro",
    "City": "Ростов-на-Дону",
    "Address": "г. Ростов-на-Дону, ул. Большая Садовая, д. 130/33",
    "Lat": 47.224679,
    "Lng": 39.730356,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=64268"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, ул. Российская, д. 275",
    "Lat": 55.156493931942,
    "Lng": 61.418284914703,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных; Срочные анализы (2 часа)",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-терапевт; Вакцинация; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; Эхокардиография (ЭХО-КГ)",
    "NotMedicalServices": "Взятие крови, мочи, кала; CITO; Выдача результатов; Взятие гинекологического и урологического материала; Врач-терапевт; Вакцинация; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Эхокардиография (ЭХО-КГ); Оформление санаторно-курортной карты; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=135"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, пр-т Свердловский, д. 86",
    "Lat": 55.153588832709,
    "Lng": 61.388957355178,
    "TimeTable": "06:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-гинеколог; Врач-терапевт; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Врач-гинеколог; Врач-терапевт; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=392"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, пр-т Комсомольский, д. 43В",
    "Lat": 55.193341059165,
    "Lng": 61.336172710998,
    "TimeTable": "07:30 - 20:30",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; УЗИ; Эхокардиография (ЭХО-КГ)",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Результаты за 4 часа; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; Эхокардиография (ЭХО-КГ); График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=740"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, пр-т Ленина, д. 16",
    "Lat": 55.160999785463,
    "Lng": 61.444552270523,
    "TimeTable": "06:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=741"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, пр-т Победы, д. 388Б",
    "Lat": 55.184181218851,
    "Lng": 61.292024886016,
    "TimeTable": "06:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=6466"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, ул. Братьев Кашириных, д. 157.",
    "Lat": 55.165214816925,
    "Lng": 61.295600180847,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=9868"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, ул. Гагарина, д. 11.",
    "Lat": 55.140903599064,
    "Lng": 61.442447780342,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Результаты за 4 часа; Выдача результатов; Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=10316"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "Челябинская область, г. Челябинск, ул. Калинина, д. 11",
    "Lat": 55.179369783109,
    "Lng": 61.401269007718,
    "TimeTable": "07:00 - 17:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=18871"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, ул. Сталеваров, д. 50",
    "Lat": 55.255374603256,
    "Lng": 61.40263444695,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=22416"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, улица Братьев Кашириных, д. 105",
    "Lat": 55.178102720152,
    "Lng": 61.338516457672,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=23320"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, ул. Воровского, д. 62",
    "Lat": 55.141776382318,
    "Lng": 61.374847937791,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Видеокольпоскопия; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Видеокольпоскопия; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=28493"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, ул. Машиностроителей, д. 32",
    "Lat": 55.117834895478,
    "Lng": 61.469927,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического материала; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови; Приём кала; Выдача результатов; Взятие гинекологического материала; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=28722"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г.Челябинск, ул. Горького, д. 36",
    "Lat": 55.177263398674,
    "Lng": 61.438517880951,
    "TimeTable": "07:00 - 18:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-гинеколог; Врач-терапевт; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Врач-гинеколог; Врач-терапевт; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=30098"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, ул. Скульптора Головницкого, д. 16",
    "Lat": 55.207814811954,
    "Lng": 61.283190542328,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=31027"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, ул. Зальцмана, д. 28",
    "Lat": 55.168066254172,
    "Lng": 61.523926728836,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=31661"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, ул. Шоссе Металлургов, д.70",
    "Lat": 55.24727850143,
    "Lng": 61.378469050262,
    "TimeTable": "06:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=34254"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, ул.Тимирязева, д. 8",
    "Lat": 55.158903857102,
    "Lng": 61.417909101852,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Срочные анализы (2 часа)",
    "MedicalServices": "Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; CITO; Выдача результатов; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=35455"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, ул. Братьев Кашириных, д.131",
    "Lat": 55.175094429166,
    "Lng": 61.31001262962,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=46821"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, ул. 40 Лет Победы, д. 38",
    "Lat": 55.178765866477,
    "Lng": 61.30819742576,
    "TimeTable": "07:00 - 18:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди; Бесплатная парковка; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Выдача результатов; Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=46845"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, ул. Рылеева, д. 2, корп. Б",
    "Lat": 55.131890600406,
    "Lng": 61.371518511712,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Врач-терапевт; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови; Приём кала; Приём мочи; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-гинеколог; Врач-терапевт; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; Оформление санаторно-курортной карты; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=50873"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, ул. Мамина, д. 29а",
    "Lat": 55.187746992847,
    "Lng": 61.48500046627,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Бесплатная парковка; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Результаты за 4 часа; Выдача результатов; Взятие гинекологического и урологического материала; Врач-гинеколог; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=52637"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, пос. Новосинеглазово, ул. Советская, д. 13",
    "Lat": 55.036640537072,
    "Lng": 61.368810915344,
    "TimeTable": "07:00 - 17:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=52653"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, проспект Героя России Родионова Е.Н.,  д. 17",
    "Lat": 55.169738977115,
    "Lng": 61.280688,
    "TimeTable": "07:00 - 19:30",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=51173"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, ул. Цинковая, д. 1",
    "Lat": 55.191300587155,
    "Lng": 61.382024139881,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=54772"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, ул. Гагарина, д. 51",
    "Lat": 55.119413086076,
    "Lng": 61.435641991402,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Результаты за 4 часа; Выдача результатов; Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57750"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, проспект Ленина, д. 77",
    "Lat": 55.158979571064,
    "Lng": 61.375977542328,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Ускоренный результат; Бесплатная парковка; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Ускоренное получение результатов; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57558"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, проспект Комсомольский, д. 85",
    "Lat": 55.194100855541,
    "Lng": 61.308714754631,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58074"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, ул. Гагарина, д. 24",
    "Lat": 55.135487805833,
    "Lng": 61.438040635582,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Результаты за 4 часа; Выдача результатов; Взятие гинекологического и урологического материала; Внутривенное капельное вливание; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=59357"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, Краснопольский пр., д. 5",
    "Lat": 55.208389,
    "Lng": 61.301547,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=63653"
  },
  {
    "Name": "Invitro",
    "City": "Челябинск",
    "Address": "г. Челябинск, ул. Комсомольский проспект, д. 109",
    "Lat": 55.187743,
    "Lng": 61.289339,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=63652"
  },
  {
    "Name": "Invitro",
    "City": "Омск",
    "Address": "г. Омск, бульвар Архитекторов, д. 14/1",
    "Lat": 54.989980710992,
    "Lng": 73.304957145734,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=14303"
  },
  {
    "Name": "Invitro",
    "City": "Омск",
    "Address": "г. Омск, ул. Красный путь, д. 70",
    "Lat": 55.004462894202,
    "Lng": 73.348194355051,
    "TimeTable": "07:30 - 19:00",
    "Comment": "Оплата банковской картой; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=14305"
  },
  {
    "Name": "Invitro",
    "City": "Омск",
    "Address": "г. Омск, ул. Мира, д. 40",
    "Lat": 55.028035652491,
    "Lng": 73.289156186508,
    "TimeTable": "07:30 - 15:00",
    "Comment": "Оплата банковской картой; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=45416"
  },
  {
    "Name": "Invitro",
    "City": "Омск",
    "Address": "г. Омск, ул. Лукашевича, д. 6\\1",
    "Lat": 54.993033741859,
    "Lng": 73.26726116683,
    "TimeTable": "07:30 - 15:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=45418"
  },
  {
    "Name": "Invitro",
    "City": "Омск",
    "Address": "г. Омск, ул. Кирова, д. 7",
    "Lat": 54.949026069791,
    "Lng": 73.418384519014,
    "TimeTable": "07:30 - 15:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=45426"
  },
  {
    "Name": "Invitro",
    "City": "Красноярск",
    "Address": "г. Красноярск, пр-т Свободный, д. 64Г",
    "Lat": 56.015942882176,
    "Lng": 92.78823435495,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=726"
  },
  {
    "Name": "Invitro",
    "City": "Красноярск",
    "Address": "г. Красноярск, ул. 9 Мая, д. 43",
    "Lat": 56.06346415623,
    "Lng": 92.92580235756,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=3104"
  },
  {
    "Name": "Invitro",
    "City": "Красноярск",
    "Address": "г. Красноярск, пр-т имени газеты «Красноярский рабочий», д. 81",
    "Lat": 56.004544130282,
    "Lng": 92.941163548919,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=4707"
  },
  {
    "Name": "Invitro",
    "City": "Красноярск",
    "Address": "г. Красноярск, ул. Александра Матросова, д. 4",
    "Lat": 55.990332480504,
    "Lng": 92.886168687225,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=10219"
  },
  {
    "Name": "Invitro",
    "City": "Красноярск",
    "Address": "г. Красноярск, ул. Декабристов, д. 36",
    "Lat": 56.012742514071,
    "Lng": 92.846373320139,
    "TimeTable": "07:00 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=10221"
  },
  {
    "Name": "Invitro",
    "City": "Красноярск",
    "Address": "г. Красноярск, пр. Металлургов, д. 38",
    "Lat": 56.044844831003,
    "Lng": 92.942466106081,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=10351"
  },
  {
    "Name": "Invitro",
    "City": "Красноярск",
    "Address": "г. Красноярск, ул. Партизана Железняка, д. 12А",
    "Lat": 56.027594802585,
    "Lng": 92.909785396045,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=10542"
  },
  {
    "Name": "Invitro",
    "City": "Красноярск",
    "Address": "г. Красноярск, ул. 78-ой Добровольческой бригады, д. 11",
    "Lat": 56.042566507873,
    "Lng": 92.909037251311,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=27378"
  },
  {
    "Name": "Invitro",
    "City": "Красноярск",
    "Address": "г. Красноярск, ул. Чернышевского, д. 98",
    "Lat": 56.034026129662,
    "Lng": 92.872582186508,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=32676"
  },
  {
    "Name": "Invitro",
    "City": "Красноярск",
    "Address": "г. Красноярск, ул. Щорса, д. 50;",
    "Lat": 55.991123135231,
    "Lng": 92.950863626984,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=34238"
  },
  {
    "Name": "Invitro",
    "City": "Красноярск",
    "Address": "г. Красноярск, пр-т Газеты Красноярский Рабочий, д. 62",
    "Lat": 56.012376643611,
    "Lng": 92.970555150787,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=35586"
  },
  {
    "Name": "Invitro",
    "City": "Красноярск",
    "Address": "г. Красноярск, проспект Мира, д. 39",
    "Lat": 56.012281148191,
    "Lng": 92.877458069941,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-гинеколог; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-гинеколог; Врач-консультант; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=45417"
  },
  {
    "Name": "Invitro",
    "City": "Красноярск",
    "Address": "г. Красноярск, ул. Новосибирская, д. 48",
    "Lat": 56.018484469921,
    "Lng": 92.812637204859,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=45420"
  },
  {
    "Name": "Invitro",
    "City": "Красноярск",
    "Address": "г. Красноярск, ул. Высотная, д. 2с1",
    "Lat": 56.025455236888,
    "Lng": 92.78328616683,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=45422"
  },
  {
    "Name": "Invitro",
    "City": "Красноярск",
    "Address": "г. Красноярск, ул. Шевченко, 44",
    "Lat": 55.999047184318,
    "Lng": 93.03287282209,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=45425"
  },
  {
    "Name": "Invitro",
    "City": "Красноярск",
    "Address": "г. Красноярск, пр-т 60 лет образования СССР, д. 19",
    "Lat": 56.113037479206,
    "Lng": 92.918824262566,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Без очереди",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=46842"
  },
  {
    "Name": "Invitro",
    "City": "Красноярск",
    "Address": "г. Красноярск, ул. 9 Мая, д. 44А",
    "Lat": 56.059434197012,
    "Lng": 92.913709151473,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Вход с колясками; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Кольпоскопия; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=53971"
  },
  {
    "Name": "Invitro",
    "City": "Красноярск",
    "Address": "г. Красноярск, ул. Карамзина, д. 18",
    "Lat": 55.988190041916,
    "Lng": 92.855143262566,
    "TimeTable": "07:30 - 20:00",
    "Comment": "Оплата банковской картой; Бесплатная парковка",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57129"
  },
  {
    "Name": "Invitro",
    "City": "Красноярск",
    "Address": "г. Красноярск, ул. Ястынская, д.10А",
    "Lat": 56.061803013608,
    "Lng": 92.94952717791,
    "TimeTable": "07:30 - 20:00",
    "Comment": "",
    "MedicalServices": "Взятие гинекологического и урологического материала; Врач-консультант",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; Врач-консультант; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58622"
  },
  {
    "Name": "Invitro",
    "City": "Красноярск",
    "Address": "г. Красноярск, ул. Тельмана, д.15",
    "Lat": 56.054228,
    "Lng": 92.977144,
    "TimeTable": "",
    "Comment": "",
    "MedicalServices": "",
    "NotMedicalServices": "",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=64498"
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "г. Самара, Октябрьский р-н, ул. Московское шоссе, д. 12",
    "Lat": 53.206299214587,
    "Lng": 50.164809057296,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=132"
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "г. Самара, Советский р-н, ул. Победы, д. 18",
    "Lat": 53.207313647597,
    "Lng": 50.235797101852,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Результаты за 4 часа; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=3157"
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "г. Самара,  ул. Георгия Димитрова, д. 104",
    "Lat": 53.258329335602,
    "Lng": 50.225242457672,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=9112"
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "г. Самара, проспект Металлургов, д. 56",
    "Lat": 53.231785900349,
    "Lng": 50.272346379958,
    "TimeTable": "06:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=10625"
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "г. Самара, ул. 22 Партсъезда, д. 182",
    "Lat": 53.237143336408,
    "Lng": 50.190276295601,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Результаты за 4 часа; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=11071"
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "г. Самара, ул. Мичурина, д. 76А",
    "Lat": 53.205846171851,
    "Lng": 50.143141692643,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Срочные анализы (2 часа); Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; CITO; Результаты за 4 часа; Выдача результатов; Взятие гинекологического и урологического материала; Приём биоматериала на исследование \"Спермограмма\"; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=14046"
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "г. Самара, ул. Самарская, д. 38",
    "Lat": 53.184952438792,
    "Lng": 50.099546451905,
    "TimeTable": "07:00 - 15:00",
    "Comment": "Оплата банковской картой; Результат за 4 часа; С 7.00 утра",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Результаты за 4 часа; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=14838"
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "г. Самара, ул. Красноармейская,  д. 121",
    "Lat": 53.187920155683,
    "Lng": 50.121645007894,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Результаты за 4 часа; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=14841"
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "г. Самара, пр-т Кирова, д. 50",
    "Lat": 53.219410497662,
    "Lng": 50.262075017526,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Видеокольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Выдача результатов; Взятие гинекологического и урологического материала; Видеокольпоскопия; Врач-гинеколог; Инъекции: внутривенные, внутримышечные, подкожные; капельницы; УЗИ; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=15738"
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "г. Самара, Железнодорожный р-н, ул. Гагарина, д. 21",
    "Lat": 53.199432943582,
    "Lng": 50.166884165602,
    "TimeTable": "",
    "Comment": "Оплата банковской картой; Без выходных; Результат за 4 часа",
    "MedicalServices": "",
    "NotMedicalServices": "",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=18822"
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "г. Самара, Кировский р-н, пр-т Кирова, 285",
    "Lat": 53.243787992502,
    "Lng": 50.232946271597,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=23017"
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "г. Самара, ул. Куйбышева, д. 75/77",
    "Lat": 53.184836105204,
    "Lng": 50.088359371688,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Результаты за 4 часа; Выдача результатов; Взятие гинекологического и урологического материала; Суточное мониторирование ЭКГ по Холтеру; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=28502"
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "Самарская область, мкр. Южный City, Николаевский пр-т, д. 44",
    "Lat": 53.113022309671,
    "Lng": 50.144311589053,
    "TimeTable": "06:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=37965"
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "г. Самара, мкр. Крутые Ключи, б-р Ивана Финютина, д. 39",
    "Lat": 53.320536635505,
    "Lng": 50.311916968088,
    "TimeTable": "06:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=45408"
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "г. Самара, ул. Демократическая, д.20/ул. Г. Димитрова, д. 129",
    "Lat": 53.265919175692,
    "Lng": 50.217714667494,
    "TimeTable": "06:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=47401"
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "г. Самара, ул. Ново-Садовая, д. 9",
    "Lat": 53.206976596252,
    "Lng": 50.128877209822,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала; УЗИ",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Ускоренное получение результатов; Результаты за 4 часа; Выдача результатов; Взятие гинекологического и урологического материала; УЗИ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=48014"
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "г. Самара, ул. Ульяновская, д. 21",
    "Lat": 53.199453298088,
    "Lng": 50.106669168573,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Результаты за 4 часа; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=48122"
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "г. Самара, ул. Авроры, д. 94",
    "Lat": 53.192715702173,
    "Lng": 50.190369457672,
    "TimeTable": "06:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Результаты за 4 часа; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=48581"
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "г. Самара, ул. Победы, д. 95",
    "Lat": 53.21317167885,
    "Lng": 50.250132906746,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=50471"
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "г. Самара, ул. Пензенская, д. 71",
    "Lat": 53.193899059294,
    "Lng": 50.148097641699,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Результат за 4 часа",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Результаты за 4 часа; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=50558"
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "г. Самара, Пугачевский тракт, д. 66",
    "Lat": 53.117629638419,
    "Lng": 50.085001626983,
    "TimeTable": "06:30 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Выдача результатов; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=51910",
    "License": true
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "г. Самара, ул. Лукачева, д. 10",
    "Lat": 53.21721695266,
    "Lng": 50.166277703041,
    "TimeTable": "07:00 - 15:00",
    "Comment": "Оплата банковской картой; Без выходных; Результат за 4 часа; С 7.00 утра",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Результаты за 4 часа; Выдача результатов; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=52851",
    "License": true
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "г. Самара, п. Мехзавод, мкр. Новая Самара, квартал 1, д. 51",
    "Lat": 53.307398474489,
    "Lng": 50.286846550926,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57282",
    "License": true
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "п. Придорожный,  ул. Дмитрия Донского, д. 15",
    "Lat": 53.094721310991,
    "Lng": 50.163750550926,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57283",
    "License": true
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "г. Самара, Кировский р-он, ул. Ташкентская, д. 97",
    "Lat": 53.24662717663,
    "Lng": 50.251947550926,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Выдача результатов; Взятие гинекологического и урологического материала; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57373",
    "License": true
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "г. Самара, Промышленный р-он, ул. Ново-Вокзальная, д. 217",
    "Lat": 53.241889744165,
    "Lng": 50.212279635582,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных",
    "MedicalServices": "Взятие гинекологического и урологического материала; Видеокольпоскопия",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие крови; Выдача результатов; Взятие гинекологического и урологического материала; Видеокольпоскопия; ЭКГ; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=57377",
    "License": true
  },
  {
    "Name": "Invitro",
    "City": "Самара",
    "Address": "г. Самара, ул. Санфировой, д. 112б",
    "Lat": 53.220269658956,
    "Lng": 50.193208813492,
    "TimeTable": "07:00 - 19:00",
    "Comment": "Оплата банковской картой; Без выходных; Вход с колясками; Без очереди; Бесплатная парковка",
    "MedicalServices": "Взятие гинекологического и урологического материала",
    "NotMedicalServices": "Взятие крови, мочи, кала; Взятие гинекологического и урологического материала; График работы МО",
    "Link": "https://invitro.ru/offices/moscow/clinic.php?ID=58979",
    "License": true
  },
  // ============================================

  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, 3-й Автозаводский проезд, д. 4",
    "Lat": 55.70669006902935,
    "Lng": 37.65498699999997,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 08:00-19:00 Вс: 08:00-18:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-avtozavodskaya-3-y-avtozavodskiy-proezd/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Профсоюзная, д. 5/9",
    "Lat": 55.68441377722776,
    "Lng": 37.57115095767206,
    "TimeTable": "Пн: 00:00-23:59 Вт: 00:00-23:59 Ср: 00:00-23:59 Чт: 00:00-23:59 Пт: 00:00-23:59 Сб: 00:00-23:59 Вс: 00:00-23:59",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-akademicheskaya-ul-profsoyuznaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Проспект Мира, д. 114",
    "Lat": 55.808511,
    "Lng": 37.6378979,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-19:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-alekseevskaya-prospekt-mira/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, п. Северный, Дмитровское шоссе, д. 165Д, корп. 6",
    "Lat": 55.923513068811175,
    "Lng": 37.54322749999996,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 08:00-16:00 Вс: 08:00-15:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-p-severnyy-dmitrovskoe-shosse/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Лескова, д. 5",
    "Lat": 55.898699568836975,
    "Lng": 37.59373099999992,
    "TimeTable": "Пн: 07:00-19:30 Вт: 07:00-19:30 Ср: 07:00-19:30 Чт: 07:00-19:30 Пт: 07:00-19:30 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-altufevo-ul-leskova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Варшавское шоссе, д. 158, корп. 2",
    "Lat": 55.583871069130446,
    "Lng": 37.59566249999999,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-anino-varshavskoe-shosse/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Ленинградский проспект, д. 66",
    "Lat": 55.802725068918534,
    "Lng": 37.52597999999999,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-19:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-aeroport-leningradskiy-prospekt/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Менжинского, д. 29",
    "Lat": 55.87039,
    "Lng": 37.656029,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-17:00 Вс: 08:00-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-babushkinskaya-ul-menzhinskogo/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. 3-я Филёвская, д. 8, корп. 2",
    "Lat": 55.739819,
    "Lng": 37.499884,
    "TimeTable": "Пн: 07:30-19:30 Вт: 07:30-19:30 Ср: 07:30-19:30 Чт: 07:30-19:30 Пт: 07:30-19:30 Сб: 08:00-17:00 Вс: 08:00-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-bagrationovskaya-3-ya-filyevskaya-ul/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Бауманская, д. 33/2, стр. 8",
    "Lat": 55.772487,
    "Lng": 37.67996,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 08:00-19:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-baumanskaya-ul-baumanskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Хорошевское шоссе, д. 22",
    "Lat": 55.7746039,
    "Lng": 37.5432379,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 08:00-19:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-begovaya-khoroshevskoe-shosse/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Петрозаводская, д. 24, корп. 2",
    "Lat": 55.868167,
    "Lng": 37.494072,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-19:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-belomorskaya-ul-petrozavodskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Профсоюзная, д. 104",
    "Lat": 55.64192006910112,
    "Lng": 37.52345599999998,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 07:30-19:00 Вс: 07:30-19:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-belyaevo-ul-profsoyuznaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Плещеева, д. 8",
    "Lat": 55.884878,
    "Lng": 37.608414,
    "TimeTable": "Пн: 07:00-19:30 Вт: 07:00-19:30 Ср: 07:00-19:30 Чт: 07:00-19:30 Пт: 07:00-19:30 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-bibirevo-ul-pleshcheeva/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Лазоревый проезд, д. 1",
    "Lat": 55.84874056888765,
    "Lng": 37.6387365,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-botanicheskiy-sad-lazorevyy-proezd/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Перерва, д. 49",
    "Lat": 55.660668069089745,
    "Lng": 37.74994799999999,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-bratislavskaya-ul-pererva/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Перервинский бульвар, д. 1",
    "Lat": 55.663725,
    "Lng": 37.763899,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-19:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-bratislavskaya-perervinskiy-bulvar/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Адмирала Лазарева, д. 6",
    "Lat": 55.54617456918221,
    "Lng": 37.54132349999993,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-bulvar-admirala-ushakova-ul-admirala-lazareva/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Грина, д. 30",
    "Lat": 55.56809,
    "Lng": 37.569702,
    "TimeTable": "Пн: 07:30-19:30 Вт: 07:30-19:30 Ср: 07:30-19:30 Чт: 07:30-19:30 Пт: 07:30-19:30 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-bulvar-dmitriya-donskogo-ul-grina/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Ивантеевская, д. 32, корп. 3",
    "Lat": 55.81440606894856,
    "Lng": 37.730292999999904,
    "TimeTable": "Пн: 07:00-19:30 Вт: 07:00-19:30 Ср: 07:00-19:30 Чт: 07:00-19:30 Пт: 07:00-19:30 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-bulvar-rokossovskogo-ul-ivanteevskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Южнобутовская, д. 80",
    "Lat": 55.534352,
    "Lng": 37.517536,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-19:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-buninskaya-alleya-ul-yuzhnobutovskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ЖК Бунинские луга, ул. Александры Монаховой, д. 88, корп. 3",
    "Lat": 55.54386015575187,
    "Lng": 37.48481348413081,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 07:30-17:30 Вс: 08:00-15:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-sosenskoe-zhk-buninskie-luga-ul-aleksandry-monakhovoy/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Ярославская, д. 15, стр. 2",
    "Lat": 55.819984,
    "Lng": 37.64711299999999,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-17:00 Вс: 08:00-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/md-vdnkh-ul-yaroslavskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Варшавское шоссе, д. 76, корп. 2",
    "Lat": 55.654493,
    "Lng": 37.617878,
    "TimeTable": "Пн: 07:30-17:00 Вт: 07:30-17:00 Ср: 07:30-17:00 Чт: 07:30-17:00 Пт: 07:30-17:00 Сб: 08:00-16:00 Вс: выходной",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-varshavskaya-varshavskoe-shosse/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Дубнинская, д. 27, корп. 1",
    "Lat": 55.863707,
    "Lng": 37.565003,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 07:30-18:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-verkhnie-likhobory-ul-dubninskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Головинское шоссе, д. 7",
    "Lat": 55.839572068893894,
    "Lng": 37.49318249999998,
    "TimeTable": "Пн: 07:00-20:00 Вт: 07:00-20:00 Ср: 07:00-20:00 Чт: 07:00-20:00 Пт: 07:00-20:00 Сб: 07:30-20:00 Вс: 07:30-20:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-vodnyy-stadion-golovinskoe-shosse/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Ленинградское шоссе, д. 13, корп. 1",
    "Lat": 55.818684,
    "Lng": 37.497153,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-17:00 Вс: 08:00-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-voykovskaya-leningradskoe-shosse/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Большая Академическая, д. 39",
    "Lat": 55.832908,
    "Lng": 37.53233399999999,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 08:00-19:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-koptevo-ul-bolshaya-akademicheskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Новотушинский проезд, д. 6, корп. 1",
    "Lat": 55.83702956891726,
    "Lng": 37.38319299999997,
    "TimeTable": "Пн: 07:30-17:00 Вт: 07:30-17:00 Ср: 07:30-17:00 Чт: 07:30-17:00 Пт: 07:30-17:00 Сб: 07:30-15:00 Вс: 07:30-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-volokolamskaya-novotushinskiy-proezd/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Боровское шоссе, д. 2, корп. 7",
    "Lat": 55.66227899966765,
    "Lng": 37.41597778042602,
    "TimeTable": "Пн: 07:30-19:30 Вт: 07:30-19:30 Ср: 07:30-19:30 Чт: 07:30-19:30 Пт: 07:30-19:30 Сб: 08:00-17:00 Вс: 08:00-16:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-govorovo-borovskoe-shosse/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Школьная, д. 1, корп. 1",
    "Lat": 55.52064506920582,
    "Lng": 37.21611549999992,
    "TimeTable": "Пн: 07:00-13:00 Вт: 07:00-13:00 Ср: 07:00-13:00 Чт: 07:00-13:00 Пт: 07:00-13:00 Сб: 08:00-13:00 Вс: 08:00-13:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-gorchakovo-ul-shkolnaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Люсиновская, д. 36/50",
    "Lat": 55.72229406900985,
    "Lng": 37.62153400000001,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 07:30-19:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-dobryninskaya-ul-lyusinovskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Генерала Белова, д. 28, корп. 1",
    "Lat": 55.60567022879331,
    "Lng": 37.724602652778565,
    "TimeTable": "Пн: 07:00-19:30 Вт: 07:00-19:30 Ср: 07:00-19:30 Чт: 07:00-19:30 Пт: 07:00-19:30 Сб: 07:30-19:30 Вс: 07:30-19:30",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-domodedovskaya-ul-generala-belova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Трифоновская, д. 11",
    "Lat": 55.78888406894255,
    "Lng": 37.611841000000005,
    "TimeTable": "График работы офиса временно изменен",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-dostoevskaya-ul-trifonovskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Генерала Кузнецова, д. 20",
    "Lat": 55.68633993006583,
    "Lng": 37.85589036742902,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-zhulebino-ul-generala-kuznetsova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, корпус 1801Б",
    "Lat": 55.97803456874222,
    "Lng": 37.165234500000004,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 08:00-19:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-zelenograd-korpus-1801b/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, корпус 250",
    "Lat": 56.00595306872433,
    "Lng": 37.20322449999991,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 08:00-19:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-zelenograd-korpus-250/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Парковая 1-я, д. 11",
    "Lat": 55.7907959,
    "Lng": 37.77756690000001,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-izmaylovskaya-ul-parkovaya-1-ya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Ленинский проспект, д. 111 корп. 1",
    "Lat": 55.661557,
    "Lng": 37.509343,
    "TimeTable": "Пн: 08:00-21:00 Вт: 08:00-21:00 Ср: 08:00-21:00 Чт: 08:00-21:00 Пт: 08:00-21:00 Сб: 08:00-21:00 Вс: 08:00-21:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-kids-kaluzhskaya-leninskiy-prospekt/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Херсонская, д. 17",
    "Lat": 55.65274424695543,
    "Lng": 37.563458417825615,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-kaluzhskaya-ul-khersonskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Кантемировская, д. 29, корп. 2",
    "Lat": 55.6373644,
    "Lng": 37.66390639999997,
    "TimeTable": "Пн: 07:00-16:00 Вт: 07:00-16:00 Ср: 07:00-16:00 Чт: 07:00-16:00 Пт: 07:00-16:00 Сб: 07:30-16:00 Вс: 07:30-16:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-kantemirovskaya-ul-kantemirovskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Маросейка, д. 6-8",
    "Lat": 55.757455,
    "Lng": 37.634371,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-19:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-kitay-gorod-ul-maroseyka/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Петра Романова, д. 6",
    "Lat": 55.70830806903346,
    "Lng": 37.68066999999998,
    "TimeTable": "Пн: 07:00-18:30 Вт: 07:00-18:30 Ср: 07:00-18:30 Чт: 07:00-18:30 Пт: 07:00-18:30 Сб: 07:30-18:30 Вс: 07:30-16:30",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-kozhukhovskaya-ul-petra-romanova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, проспект Андропова, д. 29",
    "Lat": 55.675687,
    "Lng": 37.664824,
    "TimeTable": "Пн: 00:00-23:59 Вт: 00:00-23:59 Ср: 00:00-23:59 Чт: 00:00-23:59 Пт: 00:00-23:59 Сб: 00:00-23:59 Вс: 00:00-23:59",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-kolomenskaya-prospekt-andropova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Коломенская, д. 12, корп. 2",
    "Lat": 55.68191144898524,
    "Lng": 37.695330973541225,
    "TimeTable": "Пн: 07:00-19:30 Вт: 07:00-19:30 Ср: 07:00-19:30 Чт: 07:00-19:30 Пт: 07:00-19:30 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-kolomenskaya-ul-kolomenskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Липовый парк, д. 7, корп. 2",
    "Lat": 55.56762207714185,
    "Lng": 37.4901626269836,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-kommunarka-ul-lipovyy-park/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Русаковская, д. 1",
    "Lat": 55.781920,
    "Lng": 37.670158,
    "TimeTable": "Пн: 07:00-17:00 Вт: 07:00-17:00 Ср: 07:00-17:00 Чт: 07:00-17:00 Пт: 07:00-17:00 Сб: 08:00-15:00 Вс: 08:00-13:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-krasnoselskaya-ul-rusakovskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Воронцовская, д. 26",
    "Lat": 55.73580906898496,
    "Lng": 37.659388999999926,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 07:30-18:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-krestyanskaya-zastava-ul-vorontsovskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Осенний бульвар, д. 20, корп. 1",
    "Lat": 55.76363911068241,
    "Lng": 37.40805454232782,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-17:00 Вс: 08:00-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-krylatskoe-osenniy-bulvar/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Юных Ленинцев, д. 59, корп. 1",
    "Lat": 55.701171069015196,
    "Lng": 37.76217399999999,
    "TimeTable": "Пн: 07:00-20:00 Вт: 07:00-20:00 Ср: 07:00-20:00 Чт: 07:00-20:00 Пт: 07:00-20:00 Сб: 07:30-20:00 Вс: 07:30-20:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-kuzminki-ul-yunykh-lenintsev/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Молодогвардейская, д. 2, корп. 1",
    "Lat": 55.733901,
    "Lng": 37.44254799999999,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 08:00-19:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-kuntsevskaya-ul-molodogvardeyskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Куркинское шоссе, д. 17",
    "Lat": 55.88482228,
    "Lng": 37.39241850,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-khimki-kurkinskoe-shosse/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Донецкая, д. 30, корп. 1",
    "Lat": 55.645476569080444,
    "Lng": 37.70721549999994,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-marino-ul-donetskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Кутузовский проспект, д. 43",
    "Lat": 55.738145,
    "Lng": 37.528981,
    "TimeTable": "Пн: 07:30-21:00 Вт: 07:30-21:00 Ср: 07:30-21:00 Чт: 07:30-21:00 Пт: 07:30-21:00 Сб: 08:00-19:00 Вс: 08:00-19:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-kutuzovskaya-kutuzovskiy-prospekt/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Жулебинский бульвар, д. 10/6",
    "Lat": 55.69853856903821,
    "Lng": 37.849643,
    "TimeTable": "Пн: 07:00-20:00 Вт: 07:00-20:00 Ср: 07:00-20:00 Чт: 07:00-20:00 Пт: 07:00-20:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-lermontovskiy-prospekt-zhulebinskiy-bulvar/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Солдатская, д. 3, пом. 1",
    "Lat": 55.765200,
    "Lng": 37.703191,
    "TimeTable": "Скоро открытие",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/meditsinskaya-klinika-cmd-lefortovo-ul-soldatskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Мосфильмовская, д. 17/25",
    "Lat": 55.715166013490055,
    "Lng": 37.51533475197603,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 07:00-19:00 Вс: 08:00-18:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-lomonosovskiy-prospekt-ul-mosfilmovskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Дмитриевского, д. 23, корп. 1",
    "Lat": 55.71112806904079,
    "Lng": 37.89689449999998,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-lukhmanovskaya-ul-dmitrievskogo/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Краснодарская, д. 57, корп. 2",
    "Lat": 55.67663056904142,
    "Lng": 37.76863299999996,
    "TimeTable": "Пн: 07:00-19:30 Вт: 07:00-19:30 Ср: 07:00-19:30 Чт: 07:00-19:30 Пт: 07:00-19:30 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-lyublino-ul-krasnodarskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Люблинская, д. 165, корп. 2",
    "Lat": 55.649683,
    "Lng": 37.745945000000006,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-marino-ul-lyublinskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Грекова, д. 14, корп. 1",
    "Lat": 55.88981527662934,
    "Lng": 37.66332349999997,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-17:00 Вс: 08:00-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-medvedkovo-ul-grekova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Новослободская, д. 31, стр. 1",
    "Lat": 55.781955,
    "Lng": 37.597836,
    "TimeTable": "Пн: 00:00-23:59 Вт: 00:00-23:59 Ср: 00:00-23:59 Чт: 00:00-23:59 Пт: 00:00-23:59 Сб: 00:00-23:59 Вс: 00:00-23:59",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-mendeleevskaya-ul-novoslobodskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Митинская, д. 44",
    "Lat": 55.848356568886665,
    "Lng": 37.35771649999998,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-mitino-ul-mitinskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Озерная, д. 2, корп. 2",
    "Lat": 55.689076,
    "Lng": 37.4700159,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-michurinskiy-prospekt-ul-ozernaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Ельнинская, д. 20, корп. 1",
    "Lat": 55.739003703196694,
    "Lng": 37.42198827777093,
    "TimeTable": "Пн: 07:00-19:30 Вт: 07:00-19:30 Ср: 07:00-19:30 Чт: 07:00-19:30 Пт: 07:00-19:30 Сб: 07:00-17:00 Вс: 07:30-16:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-molodyezhnaya-ul-elninskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Радужная, д. 11",
    "Lat": 55.592271,
    "Lng":  37.37085,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-moskovskiy-ul-raduzhnaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Народного Ополчения, д. 21, корп. 1",
    "Lat": 55.77753006897304,
    "Lng": 37.47728249999999,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-polezhaevskaya-ul-narodnogo-opolcheniya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Рождественская, д. 37",
    "Lat": 55.70086656904419,
    "Lng": 37.928703999999975,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-nekrasovka-ul-rozhdestvenskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Носовихинское шоссе, д. 5",
    "Lat": 55.74562006901024,
    "Lng": 37.857494499999966,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-reutov-nosovikhinskoe-shosse/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Боровское шоссе, д. 46",
    "Lat": 55.636524069087244,
    "Lng": 37.34839199999997,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 08:00-19:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-novoperedelkino-borovskoe-shosse/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, пос. Десеновское, ул. 2-я Нововатутинская, д. 5",
    "Lat": 55.518442131770854,
    "Lng": 37.351322752639746,
    "TimeTable": "Пн: 07:30-18:00 Вт: 07:30-18:00 Ср: 07:30-18:00 Чт: 07:30-18:00 Пт: 07:30-18:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-novye-vatutinki-pos-desenovskoe-2-ya-novovatutinskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, проспект Ленинский, д. 89/2",
    "Lat": 55.67768701178491,
    "Lng": 37.533166452392514,
    "TimeTable": "Пн: 07:30-19:30 Вт: 07:30-19:30 Ср: 07:30-19:30 Чт: 07:30-19:30 Пт: 07:30-19:30 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-novye-cheremushki-prospekt-leninskiy/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Большая Очаковская, д. 28",
    "Lat": 55.681525633115584,
    "Lng": 37.45285507328006,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-ozyernaya-ul-bolshaya-ochakovskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Маршала Бирюзова, д. 41",
    "Lat": 55.79905128,
    "Lng": 37.48212450,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-oktyabrskoe-pole-ul-marshala-biryuzova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Декабристов, д. 20 корп. 1",
    "Lat": 55.86320291124247,
    "Lng": 37.60732394231925,
    "TimeTable": "Пн: 07:00-19:30 Вт: 07:00-19:30 Ср: 07:00-19:30 Чт: 07:00-19:30 Пт: 07:00-19:30 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-otradnoe-ul-dekabristov/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Кожевническая, д. 7, стр. 1",
    "Lat": 55.730339160273,
    "Lng": 37.64526714418031,
    "TimeTable": "Пн: 08:30-19:00 Вт: 08:30-19:00 Ср: 08:30-19:00 Чт: 08:30-19:00 Пт: 08:30-19:00 Сб: 09:00-14:00 Вс: выходной",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-paveletskaya-ul-kozhevnicheskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Измайловский бульвар, д. 58",
    "Lat": 55.796405,
    "Lng": 37.811878,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-pervomayskaya-izmaylovskiy-bulvar/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Новогиреевская, д. 3А (при лаборатории)",
    "Lat": 55.76273850887033,
    "Lng": 37.79482961408183,
    "TimeTable": "Пн: 6:30-20:00 Вт: 6:30-20:00 Ср: 6:30-20:00 Чт: 6:30-20:00 Пт: 6:30-20:00 Сб: 6:30-19:00 Вс: 6:30-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-perovo-ul-novogireevskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Планерная, д. 3, корп. 1",
    "Lat": 55.86000906888679,
    "Lng": 37.432663000000005,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-planernaya-ul-planernaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Кировоградская, д. 28, корп. 1",
    "Lat": 55.609477,
    "Lng": 37.601771,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 08:00-19:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-prazhskaya-ul-kirovogradskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Большая Черкизовская, д. 3, корп. 2",
    "Lat": 55.79788256893589,
    "Lng": 37.716683499999974,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 08:00-15:00 Вс: выходной",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-preobrazhenskaya-ploshchad-ul-bolshaya-cherkizovskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, бульвар Веласкеса, д. 6",
    "Lat": 55.59226056912223,
    "Lng": 37.45936099999998,
    "TimeTable": "Скоро открытие",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-prokshino-bulvar-velaskesa/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Новаторов, д. 4",
    "Lat": 55.669285,
    "Lng": 37.521686,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-19:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-prospekt-vernadskogo-ul-novatorov/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Мичуринский проспект, д. 9",
    "Lat": 55.70132828,
    "Lng": 37.50741200,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 07:30-19:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-ramenki-michurinskiy-prospekt/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, п. Внуковское, ул. Лётчика Грицевца, д. 6",
    "Lat": 55.622736069111305,
    "Lng": 37.304815000000005,
    "TimeTable": "Пн: 07:00-20:00 Вт: 07:00-20:00 Ср: 07:00-20:00 Чт: 07:00-20:00 Пт: 07:00-20:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-rasskazovka-p-vnukovskoe-ul-lyetchika-gritsevtsa/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, п. Внуковское, ул. Корнея Чуковского, д. 1",
    "Lat": 55.63589906908563,
    "Lng": 37.32978799999999,
    "TimeTable": "Пн: 07:00-20:00 Вт: 07:00-20:00 Ср: 07:00-20:00 Чт: 07:00-20:00 Пт: 07:00-20:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-rasskazovka-p-vnukovskoe-ul-korneya-chukovskogo/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, проспект Мира, д. 188Б, корп. 4",
    "Lat": 55.83499626113305,
    "Lng": 37.66015894180286,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 08:00-18:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-rostokino-prospekt-mira/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Рязанский проспект, д. 38",
    "Lat": 55.717325,
    "Lng": 37.788440,
    "TimeTable": "Пн: 07:00-20:00 Вт: 07:00-20:00 Ср: 07:00-20:00 Чт: 07:00-20:00 Пт: 07:00-20:00 Сб: 07:30-19:00 Вс: 07:30-18:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-ryazanskiy-prospekt-ryazanskiy-prospekt/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Малая Юшуньская, д. 3",
    "Lat": 55.65512545,
    "Lng": 37.58995101,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-sevastopolskaya-ul-malaya-yushunskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Талдомская, д. 17, корп. 1",
    "Lat": 55.874329,
    "Lng": 37.523501,
    "TimeTable": "Пн: 08:00-18:00 Вт: 08:00-18:00 Ср: 08:00-18:00 Чт: 08:00-18:00 Пт: 08:00-18:00 Сб: 08:00-14:00 Вс: 08:00-14:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-seligerskaya-ul-taldomskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Ткацкая, д. 28/14",
    "Lat": 55.78629806893591,
    "Lng": 37.73397599999989,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 08:00-18:00 Вс: 08:00-18:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-semyenovskaya-ul-tkatskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Нежинская, д. 8, корп. 4",
    "Lat": 55.71008328,
    "Lng": 37.47146150,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-16:00 Вс: 08:00-16:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-slavyanskiy-bulvar-ul-nezhinskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Троилинский переулок, д. 4/7",
    "Lat": 55.748217,
    "Lng": 37.58457199999998,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 08:00-16:00 Вс: 08:00-16:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-smolenskaya-troilinskiy-pereulok/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Солнцевский проспект, д. 10",
    "Lat": 55.65164828,
    "Lng": 37.40160850,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 08:00-19:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-solntsevo-solntsevskiy-prospekt/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, 2-й Грайвороновский проезд, д. 44, корп. 1",
    "Lat": 55.72535080845713,
    "Lng": 37.74715603704824,
    "TimeTable": "Пн: 07:00-19:30 Вт: 07:00-19:30 Ср: 07:00-19:30 Чт: 07:00-19:30 Пт: 07:00-19:30 Сб: 07:00-17:00 Вс: 07:30-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-stakhanovskaya-2-y-proezd-grayvoronovskiy/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Кулакова, д. 19",
    "Lat": 55.806216,
    "Lng": 37.396704,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-17:00 Вс: 08:00-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-strogino-ul-kulakova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Ананьевский переулок, д. 5, стр. 9",
    "Lat": 55.771505,
    "Lng": 37.63451299999997,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-sukharevskaya-ananevskiy-pereulok/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, бульвар Яна Райниса, д. 31",
    "Lat": 55.8488169,
    "Lng": 37.4192554,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-skhodnenskaya-bulvar-yana-raynisa/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Николоямская, д. 31",
    "Lat": 55.747312568984796,
    "Lng": 37.65714299999998,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-taganskaya-ul-nikoloyamskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Люблинская, д. 9, корп. 1",
    "Lat": 55.71160192868713,
    "Lng": 37.73062413682832,
    "TimeTable": "Пн: 07:00-20:00 Вт: 07:00-20:00 Ср: 07:00-20:00 Чт: 07:00-20:00 Пт: 07:00-20:00 Сб: 07:30-19:00 Вс: 07:30-18:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-tekstilshchiki-ul-lyublinskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Красностуденческий проезд, д. 1",
    "Lat": 55.82571438169359,
    "Lng": 37.56986602885334,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-19:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-timiryazevskaya-krasnostudencheskiy-proezd/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Парковый переулок, д. 4",
    "Lat": 55.49115003576946,
    "Lng": 37.302733417791465,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 07:00-19:00 Вс: 07:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-troitsk-parkovyy-pereulok/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Милютинский переулок, д. 15, стр. 1",
    "Lat": 55.76508306900067,
    "Lng": 37.63191849999999,
    "TimeTable": "Пн: 08:00-20:00 Вт: 08:00-20:00 Ср: 08:00-20:00 Чт: 08:00-20:00 Пт: 08:00-20:00 Сб: 08:00-16:00 Вс: 08:00-16:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-turgenevskaya-milyutinskiy-pereulok/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Тушинская, д. 12",
    "Lat": 55.82702906892135,
    "Lng": 37.44828499999997,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-18:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-tushinskaya-ul-tushinskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Профсоюзная, д. 146, корп. 1",
    "Lat": 55.623625,
    "Lng": 37.507008 ,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-teplyy-stan-ul-profsoyuznaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. 1905 года, д. 7, корп. 1",
    "Lat": 55.761441568961494,
    "Lng": 37.55873250000002,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-15:00 Вс: выходной",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-ulitsa-1905-goda-ul-1905-goda-d-7-k-1/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Поляны, д. 5А, корп. 1",
    "Lat": 55.555183069175655,
    "Lng": 37.55400749999991,
    "TimeTable": "Скоро открытие",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-ulitsa-skobelevskaya-ul-polyany/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Ломоносовский проспект, д. 23",
    "Lat": 55.690469,
    "Lng": 37.536510,
    "TimeTable": "Пн: 07:30-19:30 Вт: 07:30-19:30 Ср: 07:30-19:30 Чт: 07:30-19:30 Пт: 07:30-19:30 Сб: 07:30-18:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-universitet-lomonosovskiy-prospekt/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Милашенкова, д. 8",
    "Lat": 55.82525989659267,
    "Lng": 37.58534791534425,
    "TimeTable": "Пн: 07:30-19:30 Вт: 07:30-19:30 Ср: 07:30-19:30 Чт: 07:30-19:30 Пт: 07:30-19:30 Сб: 08:00-19:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-fonvizinskaya-ul-milashenkova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Комсомольский проспект, д. 28",
    "Lat": 55.72687706902164,
    "Lng": 37.57964549999999,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-frunzenskaya-komsomolskiy-prospekt/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Бирюлевская, д. 1, корп. 1",
    "Lat": 55.60195056914715,
    "Lng": 37.66475200000001,
    "TimeTable": "Пн: 07:30-19:30 Вт: 07:30-19:30 Ср: 07:30-19:30 Чт: 07:30-19:30 Пт: 07:30-19:30 Сб: 08:00-19:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-tsaritsyno-ul-biryulevskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Липецкая, д. 48",
    "Lat": 55.581083,
    "Lng": 37.680886,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-tsaritsyno-ul-lipetskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, проспект Балаклавский, д. 16, корп. 2",
    "Lat": 55.64213306910162,
    "Lng": 37.5993455,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 07:30-19:00 Вс: 07:30-19:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-chertanovskaya-prospekt-balaklavskiy/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Шаболовка, д. 50",
    "Lat": 55.713676,
    "Lng": 37.60605499999997,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-shabolovskaya-ul-shabolovka/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Шмитовский проезд, д. 39, корп. 2",
    "Lat": 55.754061068972334,
    "Lng": 37.52472249999994,
    "TimeTable": "Пн: 07:30-22:00 Вт: 07:30-22:00 Ср: 07:30-22:00 Чт: 07:30-22:00 Пт: 07:30-22:00 Сб: 07:30-19:00 Вс: 07:30-19:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-shelepikha-shmitovskiy-proezd/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Мусы Джалиля, д. 4, корп. 6",
    "Lat": 55.62869278,
    "Lng": 37.74067750,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 07:30-16:00 Вс: 07:30-16:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-shipilovskaya-ul-musy-dzhalilya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, проспект Буденного, д. 30А",
    "Lat": 55.76150970400313,
    "Lng": 37.73469637301628,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-shosse-entuziastov-prospekt-budennogo/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Барышевская Роща, д. 1",
    "Lat": 55.50031506921299,
    "Lng": 37.54119749999992,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-shcherbinka-ul-baryshevskaya-roshcha/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Маршала Савицкого, д. 26, корп. 2",
    "Lat": 55.503038,
    "Lng": 37.594549,
    "TimeTable": "Пн: 08:00-19:00 Вт: 08:00-19:00 Ср: 08:00-19:00 Чт: 08:00-19:00 Пт: 08:00-19:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-shcherbinka-ul-marshala-savitskogo/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Авиационная, д. 63",
    "Lat": 55.80963257828561,
    "Lng": 37.455311134918205,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-shchukinskaya-ul-aviatsionnaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Уральская, д. 6, корп. 1",
    "Lat": 55.813894,
    "Lng": 37.799289,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 07:00-18:00 Вс: 07:00-16:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-shchelkovskaya-ul-uralskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, Проспект Вернадского, д. 127",
    "Lat": 55.653138,
    "Lng": 37.481194,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-18:00 Вс: выходной",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-yugo-zapadnaya-prospekt-vernadskogo/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Кировоградская, д. 8 корп. 4",
    "Lat": 55.62685806912186,
    "Lng": 37.610062499999955,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-yuzhnaya-ul-kirovogradskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Москва",
    "Address": "г. Москва, ул. Ясногорская, д. 17/1",
    "Lat": 55.60213856914762,
    "Lng": 37.5332385,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-yasenevo-ul-yasnogorskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Алексин",
    "Address": "г. Алексин, ул. Тульская, д. 129",
    "Lat": 54.51023907015442,
    "Lng": 37.11407549999995,
    "TimeTable": "Пн: 07:00-13:00 Вт: 07:00-13:00 Ср: 08:00-13:00 Чт: 07:00-13:00 Пт: 07:00-13:00 Сб: 08:00-13:00 Вс: 08:00-13:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-aleksin-ul-tulskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Ангарск",
    "Address": "г. Ангарск, ул. 40 лет Октября, д. 119",
    "Lat": 52.52341607172051,
    "Lng": 103.87931099999984,
    "TimeTable": "Скоро открытие",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-angarsk-ul-40-let-oktyabrya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Архангельск",
    "Address": "г. Архангельск, проспект Обводный канал, д. 9, корп. 3",
    "Lat": 64.536292,
    "Lng": 40.558863,
    "TimeTable": "Пн: 07:30-19:30 Вт: 07:30-19:30 Ср: 07:30-19:30 Чт: 07:30-19:30 Пт: 07:30-19:30 Сб: 09:00-15:00 Вс: 09:00-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-arkhangelsk-prospekt-obvodnyy-kanal/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Баксан",
    "Address": "г. Баксан, ул. Карачаева, д. 2Б",
    "Lat": 43.67686080,
    "Lng": 43.53735350,
    "TimeTable": "Пн: 07:30-18:00 Вт: 07:30-18:00 Ср: 07:30-18:00 Чт: 07:30-18:00 Пт: 07:30-18:00 Сб: 07:30-17:00 Вс: выходной",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-baksan-ul-karachaeva/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Балашиха",
    "Address": "г. Балашиха, ул. Ситникова, д. 4",
    "Lat": 55.789183068943366,
    "Lng": 37.88051849999989,
    "TimeTable": "Пн: 07:00-18:00 Вт: 07:00-18:00 Ср: 07:00-18:00 Чт: 07:00-18:00 Пт: 07:00-18:00 Сб: 08:00-16:30 Вс: 08:00-16:30",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-balashikha-zhk-novoe-izmaylovo-ul-sitnikova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Балашиха",
    "Address": "г. Балашиха, мкр. Дзержинского, д. 46",
    "Lat": 55.77681106897122,
    "Lng": 37.89840350000002,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 07:30-16:00 Вс: 07:30-16:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-balashikha-mkr-dzerzhinskogo/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Балашиха",
    "Address": "г. Балашиха, ул. Колхозная, д. 12, корп. 2",
    "Lat": 55.74399356897626,
    "Lng": 38.02040399999992,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 07:30-19:30 Вс: 07:30-19:30",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-balashikha-mkr-zheleznodorozhnyy-ul-kolkhoznaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Балашиха",
    "Address": "г. Балашиха, ул. Советская, д. 15а",
    "Lat": 55.75665506897899,
    "Lng": 38.082145000000004,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 08:00-17:00 Вс: 08:00-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-balashikha-mkr-zarya-ul-sovetskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Балашиха",
    "Address": "г. Балашиха, ул. Жилcityок, д. 7а",
    "Lat": 55.750758,
    "Lng": 37.988783,
    "TimeTable": "Пн: 07:00-19:30 Вт: 07:00-19:30 Ср: 07:00-19:30 Чт: 07:00-19:30 Пт: 07:00-19:30 Сб: 07:30-19:30 Вс: 07:30-19:30",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-balashikha-mkr-olgino-ul-zhilgorodok-d-7a/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Балашиха",
    "Address": "г. Балашиха, ул. Кожедуба, д. 10",
    "Lat": 55.82527928,
    "Lng": 37.94940100,
    "TimeTable": "Пн: 07:00-19:30 Вт: 07:00-19:30 Ср: 07:00-19:30 Чт: 07:00-19:30 Пт: 07:00-19:30 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-balashikha-mkrn-aviatorov-ul-kozheduba/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Балашиха",
    "Address": "г. Балашиха, проспект Ленина, д. 7/1",
    "Lat": 55.79779178,
    "Lng": 37.93090450,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 07:30-17:00 Вс: 08:00-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-balashikha-prospekt-lenina-d-7-1/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Боброво",
    "Address": "г. Боброво, ЖК Восточное Бутово, ул. Лесная, д. 20, корп. 1",
    "Lat": 55.53355206917952,
    "Lng": 37.618955500000006,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-bobrovo-zhk-vostochnoe-butovo-ul-lesnaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Брянск",
    "Address": "г. Брянск, Московский проспект, д. 140",
    "Lat": 53.208094,
    "Lng": 34.4585449,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-bryansk-moskovskiy-prospekt/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Видное",
    "Address": "г. Видное, ул. Березовая, д. 9",
    "Lat": 55.541585569170444,
    "Lng": 37.72233399999999,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-vidnoe-ul-berezovaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Владикавказ",
    "Address": "г. Владикавказ, проспект Коста, д. 139",
    "Lat": 43.02356857451617,
    "Lng": 44.671422499999984,
    "TimeTable": "Скоро открытие",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-vladikavkaz-prospekt-kosta/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Волгодонск",
    "Address": "г. Волгодонск, ул. Ленина, д. 100",
    "Lat": 47.51151730,
    "Lng": 42.16182600,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-19:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-volgodonsk-ul-lenina/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Волгодонск",
    "Address": "г. Волгодонск, ул. Энтузиастов, д. 32А",
    "Lat": 47.52169011385691,
    "Lng": 42.19760081831743,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-19:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-volgodonsk-ul-entuziastov/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Воскресенск",
    "Address": "г. Воскресенск, ул. Победы, д. 14А",
    "Lat": 55.32471906941473,
    "Lng": 38.6797225,
    "TimeTable": "Пн: 07:30-18:00 Вт: 07:30-18:00 Ср: 07:30-18:00 Чт: 07:30-18:00 Пт: 07:30-18:00 Сб: 08:00-17:00 Вс: 08:00-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-voskresensk-ul-pobedy/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Вязьма",
    "Address": "г. Вязьма, ул. Ленина, д. 38",
    "Lat": 55.21577,
    "Lng": 34.304231,
    "TimeTable": "Пн: выходной Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 07:30-17:00 Вс: выходной",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-vyazma-ul-lenina/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Дзержинский",
    "Address": "г. Дзержинский, ул. Угрешская, д. 24",
    "Lat": 55.63211356910565,
    "Lng": 37.858401499999985,
    "TimeTable": "Пн: 07:00-20:00 Вт: 07:00-20:00 Ср: 07:00-20:00 Чт: 07:00-20:00 Пт: 07:00-20:00 Сб: 07:30-15:00 Вс: 07:30-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-dzerzhinskiy-ul-ugreshskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Дмитров",
    "Address": "г. Дмитров, ул. Пионерская, д. 2",
    "Lat": 56.34216138452308,
    "Lng": 37.53568426256552,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-16:00 Вс: 08:00-14:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-dmitrov-ul-pionerskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Долгопрудный",
    "Address": "г. Долгопрудный, ул. Дирижабельная, д. 11",
    "Lat": 55.937845,
    "Lng": 37.51002900000003,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-dolgoprudnyy-ul-dirizhabelnaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Домодедово",
    "Address": "г. Домодедово, ул. Курыжова, д. 21",
    "Lat": 55.39791456933587,
    "Lng": 37.772872999999976,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-domodedovo-mkrn-yuzhnyy-ul-kuryzhova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Домодедово",
    "Address": "г. Домодедово, ул. Кирова, д. 7, корп. 1",
    "Lat": 55.43935428,
    "Lng": 37.75014550,
    "TimeTable": "Пн: 07:00-19:30 Вт: 07:00-19:30 Ср: 07:00-19:30 Чт: 07:00-19:30 Пт: 07:00-19:30 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-domodedovo-ul-kirova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Дрожжино",
    "Address": "г. Дрожжино, Новое шоссе, д. 13",
    "Lat": 55.52694856919228,
    "Lng": 37.59065899999999,
    "TimeTable": "Пн: 07:00-17:30 Вт: 07:00-17:30 Ср: 07:00-17:30 Чт: 07:00-17:30 Пт: 07:00-17:30 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-drozhzhino-novoe-shosse/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Дубна",
    "Address": "г. Дубна, проспект Боголюбова, д. 16А",
    "Lat": 56.73911006796738,
    "Lng": 37.16478549999998,
    "TimeTable": "Скоро открытие",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-dubna-prospekt-bogolyubova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Жуковский",
    "Address": "г. Жуковский, ул. Королева, д. 6, стр. 2",
    "Lat": 55.60909,
    "Lng": 38.092539,
    "TimeTable": "Пн: 07:30-18:00 Вт: 07:30-18:00 Ср: 07:30-18:00 Чт: 07:30-18:00 Пт: 07:30-18:00 Сб: 08:00-17:00 Вс: 08:00-15:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-zhukovskiy-ul-koroleva/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Звениcity",
    "Address": "г. Звениcity, мкр. Пронина, д. 5",
    "Lat": 55.741895568970875,
    "Lng": 36.856366999999956,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-zvenigorod-mkr-pronina/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Ивантеевка",
    "Address": "г. Ивантеевка, ул. Толмачева, д. 1а",
    "Lat": 55.969134013685235,
    "Lng": 37.91201317790972,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 09:00-17:00 Вс: 09:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-ivanteevka-ul-tolmacheva/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Истра",
    "Address": "г. Истра, ул. 9-й Гвардейской дивизии, д. 42",
    "Lat": 55.91265756881317,
    "Lng": 36.8641555,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-istra-ul-9-y-gvardeyskoy-divizii/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Калуга",
    "Address": "г. Калуга, ул. Петра Тарасова, д. 8",
    "Lat": 54.50102757016019,
    "Lng": 36.183492999999984,
    "TimeTable": "Скоро открытие",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-kaluga-ul-petra-tarasova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Калуга",
    "Address": "г. Калуга, ул. Труда, д. 4, корп. 1",
    "Lat": 54.520863101010576,
    "Lng": 36.25921510185239,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 07:00-16:00 Вс: 07:00-16:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/meditsinskaya-klinika-cmd-kaluga-ul-truda/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Кашира",
    "Address": "г. Кашира, ул. Юбилейная, д. 11",
    "Lat": 54.839981569854324,
    "Lng": 38.247552,
    "TimeTable": "Пн: 07:00-20:00 Вт: 07:00-20:00 Ср: 07:00-20:00 Чт: 07:00-20:00 Пт: 07:00-20:00 Сб: 07:30-15:00 Вс: 07:30-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-kashira-ul-yubileynaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Керчь",
    "Address": "г. Керчь, ул. К. Маркса, д. 16Б",
    "Lat": 45.358385074554185,
    "Lng": 36.4698399999999,
    "TimeTable": "Пн: 07:00-16:00 Вт: 07:00-16:00 Ср: 07:00-16:00 Чт: 07:00-16:00 Пт: 07:00-16:00 Сб: 07:00-16:00 Вс: выходной",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-kerch-ul-k-marksa/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Киржач",
    "Address": "г. Киржач, ул. Серегина, д. 5",
    "Lat": 56.162404,
    "Lng": 38.8716269,
    "TimeTable": "Пн: 08:00-19:00 Вт: 08:00-19:00 Ср: 08:00-19:00 Чт: 08:00-19:00 Пт: 08:00-19:00 Сб: 08:00-16:00 Вс: 08:00-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-kirzhach-ul-seregina/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Клин",
    "Address": "г. Клин, ул. Гагарина, д. 4/10",
    "Lat": 56.333392,
    "Lng": 36.731414,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 07:30-17:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-klin-ul-gagarina/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Коломна",
    "Address": "г. Коломна, ул. Октябрьской революции, д. 318а",
    "Lat": 55.09188078,
    "Lng": 38.77449450,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 07:30-15:00 Вс: 08:30-14:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-kolomna-ul-oktyabrskoy-revolyutsii/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Королёв",
    "Address": "г. Королёв, проспект Королева, д. 5Д, ТЦ “Статус”, 3 этаж",
    "Lat": 55.921802,
    "Lng": 37.84325899999999,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 08:00-19:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-korolyev-prospekt-koroleva/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Кострома",
    "Address": "г. Кострома, ул. Советская, д. 97",
    "Lat": 57.76072656686556,
    "Lng": 40.9560265,
    "TimeTable": "Скоро открытие",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-kostroma-ul-sovetskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Котельники",
    "Address": "г. Котельники, ул. Сосновая, д. 2, корп. 4",
    "Lat": 55.67471706906632,
    "Lng": 37.85452999999996,
    "TimeTable": "Пн: 07:00-20:00 Вт: 07:00-20:00 Ср: 07:00-20:00 Чт: 07:00-20:00 Пт: 07:00-20:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-kotelniki-ul-sosnovaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Красково",
    "Address": "г. Красково, ул. Карла Маркса, д. 61",
    "Lat": 55.658143992472134,
    "Lng": 37.98227422320928,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-kraskovo-ul-karla-marksa/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Красногорск",
    "Address": "г. Красногорск, Ильинский бульвар, д. 4",
    "Lat": 55.81771428,
    "Lng": 37.36654750,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-krasnogorsk-ilinskiy-bulvar/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Красногорск",
    "Address": "г. Красногорск, Ильинское шоссе, автобусная остановка Бецема",
    "Lat": 55.806529,
    "Lng": 37.330704,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-krasnogorsk-ilinskoe-shosse/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Красногорск",
    "Address": "г. Красногорск, ул. Народного Ополчения, д. 5А",
    "Lat": 55.83480556891149,
    "Lng": 37.31332199999998,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-16:00 Вс: 08:00-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-krasnogorsk-ul-narodnogo-opolcheniya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Краснознаменск",
    "Address": "г. Краснознаменск, ул. Победы, д. 15г",
    "Lat": 55.59536721,
    "Lng": 37.03606484,
    "TimeTable": "Пн: 07:00-19:30 Вт: 07:00-19:30 Ср: 07:00-19:30 Чт: 07:00-19:30 Пт: 07:00-19:30 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-krasnozNamensk-ul-pobedy/"
  },  
  {
   "Name": "Центр молекулярной диагностики (CMD)",
   "City": "Лобня",
   "Address": "г. Лобня, ул. Физкультурная, д. 4",
   "Lat": 56.007166,
   "Lng": 37.45027,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-lobnya-ul-fizkulturnaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Лопатино",
    "Address": "г. Лопатино, ул. Сухановская, д. 19",
    "Lat": 55.53253306917689,
    "Lng": 37.64022799999994,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 07:30-16:00 Вс: 07:30-16:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-lopatino-ul-sukhanovskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Луховицы",
    "Address": "г. Луховицы, ул. 40 лет Октября, д. 36/7",
    "Lat": 54.952234,
    "Lng": 39.022714,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 07:30-16:00 Вс: 08:00-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-lukhovitsy-ul-40-let-oktyabrya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Люберцы",
    "Address": "г. Люберцы, ул. Дружбы, д. 7, корп. 1",
    "Lat": 55.70269806901913,
    "Lng": 37.963504500000006,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-lyubertsy-zhk-samolyet-ul-druzhby/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Люберцы",
    "Address": "г. Люберцы, Комсомольский проспект, д. 16/2",
    "Lat": 55.691196,
    "Lng": 37.906597,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-lyubertsy-komsomolskiy-prospekt/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Люберцы",
    "Address": "г. Люберцы, пос. ВУГИ, д. 18",
    "Lat": 55.66019578,
    "Lng": 37.93140750,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-lyubertsy-pos-vugi/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Люберцы",
    "Address": "г. Люберцы, ул. Кирова, д. 51",
    "Lat": 55.679605,
    "Lng": 37.884992,
    "TimeTable": "Пн: 07:00-20:00 Вт: 07:00-20:00 Ср: 07:00-20:00 Чт: 07:00-20:00 Пт: 07:00-20:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-lyubertsy-ul-kirova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Малино",
    "Address": "г. Малино, р.п. Малино, ул. Донская, д. 3",
    "Lat": 55.11230906957967,
    "Lng": 38.174518999999975,
    "TimeTable": "Пн: 07:45-17:00 Вт: 07:45-17:00 Ср: 07:45-17:00 Чт: 07:45-17:00 Пт: 07:45-17:00 Сб: 07:45-15:00 Вс: выходной",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-malino-ul-donskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Махачкала",
    "Address": "г. Махачкала, Проспект Петра I, д. 125",
    "Lat": 42.960559074477025,
    "Lng": 47.5514395,
    "TimeTable": "Пн: 07:30-17:00 Вт: 07:30-17:00 Ср: 07:30-17:00 Чт: 07:30-17:00 Пт: 07:30-17:00 Сб: 07:30-17:00 Вс: выходной",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-makhachkala-prospekt-petra-1/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Махачкала",
    "Address": "г. Махачкала, ул. Магомеда Гаджиева, д. 27А",
    "Lat": 42.983724,
    "Lng": 47.498751,
    "TimeTable": "Пн: 07:30-17:00 Вт: 07:30-17:00 Ср: 07:30-17:00 Чт: 07:30-17:00 Пт: 07:30-17:00 Сб: 07:30-17:00 Вс: выходной",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-ul-magomeda-gadzhieva/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Мисайлово",
    "Address": "г. Мисайлово, Литературный бульвар, д. 2",
    "Lat": 55.55719506918082,
    "Lng": 37.813880999999995,
    "TimeTable": "Пн: 07:00-19:30 Вт: 07:00-19:30 Ср: 07:00-19:30 Чт: 07:00-19:30 Пт: 07:00-19:30 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-misaylovo-literaturnyy-bulvar/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Монино",
    "Address": "г. Монино, Новинское шоссе, д. 4",
    "Lat": 55.8445460689067,
    "Lng": 38.19964449999997,
    "TimeTable": "Пн: 07:00-21:00 Вт: 07:00-21:00 Ср: 07:00-21:00 Чт: 07:00-21:00 Пт: 07:00-21:00 Сб: 08:00-21:00 Вс: 08:00-21:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-monino-novinskoe-shosse/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Мурманск",
    "Address": "г. Мурманск, ул. Беринга, д. 2",
    "Lat": 68.91566504951425,
    "Lng": 33.095066999999965,
    "TimeTable": "Скоро открытие",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-murmansk-ul-beringa/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Мурманск",
    "Address": "г. Мурманск, пр-т Кольский, д. 61",
    "Lat": 68.925693,
    "Lng": 33.107832,
    "TimeTable": "Пн: 08:00-17:00 Вт: 08:00-17:00 Ср: 08:00-17:00 Чт: 08:00-17:00 Пт: 08:00-17:00 Сб: 09:00-16:00 Вс: 09:00-16:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-murmansk-prospekt-kolskiy/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Мурманск",
    "Address": "г. Мурманск, ул. Лобова, д. 4",
    "Lat": 69.01505770,
    "Lng": 33.10355600,
    "TimeTable": "Пн: 08:00-18:00 Вт: 08:00-18:00 Ср: 08:00-18:00 Чт: 08:00-18:00 Пт: 08:00-18:00 Сб: 09:00-16:00 Вс: 09:00-16:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-murmansk-ul-lobova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Мытищи",
    "Address": "г. Мытищи, 2-й Щелковский проезд, д. 3",
    "Lat": 55.922978568839696,
    "Lng": 37.78377849999998,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-mytishchi-2-y-shchelkovskiy-proezd/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Мытищи",
    "Address": "г. Мытищи, ул. Веры Волошиной, д. 9/24",
    "Lat": 55.89296877184399,
    "Lng": 37.72395577967546,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 08:00-16:00 Вс: 08:00-16:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-mytishchi-ul-very-voloshinoy/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Мытищи",
    "Address": "г. Мытищи, ул. Воровского, д. 1",
    "Lat": 55.9187263,
    "Lng": 37.7616626,
    "TimeTable": "График работы офиса временно изменен",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-mytishchi-ul-vorovskogo/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Мытищи",
    "Address": "г. Мытищи, ул. Институтская 2-я, д. 26",
    "Lat": 55.92971727624197,
    "Lng": 37.79676653306575,
    "TimeTable": "Пн: 07:00-18:00 Вт: 07:00-18:00 Ср: 07:00-18:00 Чт: 07:00-18:00 Пт: 07:00-18:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-mytishchi-ul-institutskaya-2-ya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Мытищи",
    "Address": "г. Мытищи, ул. Колпакова, д. 25",
    "Lat": 55.918646,
    "Lng": 37.729134,
    "TimeTable": "Пн: 07:30-17:00 Вт: 07:30-17:00 Ср: 07:30-17:00 Чт: 07:30-17:00 Пт: 07:30-17:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-mytishchi-ul-kolpakova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Мытищи",
    "Address": "г. Мытищи, ул. Юбилейная, д. 30",
    "Lat": 55.90784406883066,
    "Lng": 37.70911049999998,
    "TimeTable": "Пн: 07:30-14:30 Вт: 07:30-14:30 Ср: 07:30-14:30 Чт: 07:30-14:30 Пт: 07:30-14:30 Сб: 07:30-14:30 Вс: 07:30-14:30",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-mytishchi-ul-yubileynaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Назрань",
    "Address": "г. Назрань, ул. Чеченская, д. 8а",
    "Lat": 43.219420574526424,
    "Lng": 44.75436399999997,
    "TimeTable": "Пн: 08:00-17:00 Вт: 08:00-17:00 Ср: 08:00-17:00 Чт: 08:00-17:00 Пт: 08:00-17:00 Сб: 09:00-13:00 Вс: 09:00-13:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-nazran-ul-chechenskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Нальчик",
    "Address": "г. Нальчик, ул. Тургенева, д. 54",
    "Lat": 43.49271158704025,
    "Lng": 43.59889779521484,
    "TimeTable": "Пн: 07:30-18:00 Вт: 07:30-18:00 Ср: 07:30-18:00 Чт: 07:30-18:00 Пт: 07:30-18:00 Сб: 07:30-18:00 Вс: 08:00-14:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-nalchik-ul-turgeneva/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Наро-Фоминск",
    "Address": "г. Наро-Фоминск, ул. Ефремова, д. 9В",
    "Lat": 55.38712606933785,
    "Lng": 36.7418675,
    "TimeTable": "Пн: 07:30-18:00 Вт: 07:30-18:00 Ср: 07:30-18:00 Чт: 07:30-18:00 Пт: 07:30-18:00 Сб: 07:30-18:00 Вс: 07:30-18:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-naro-fominsk-ul-efremova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Нахабино",
    "Address": "г. Нахабино, ул. Чкалова, д. 5",
    "Lat": 55.83849056892099,
    "Lng": 37.183614499999955,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-nakhabino-ul-chkalova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Нахабино",
    "Address": "г. Нахабино, ул. Королева, д. 5",
    "Lat": 55.85109043402812,
    "Lng": 37.193189338754735,
    "TimeTable": "Пн: 07:30-19:30 Вт: 07:30-19:30 Ср: 07:30-19:30 Чт: 07:30-19:30 Пт: 07:30-19:30 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-nakhabino-ul-koroleva/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Невинномысск",
    "Address": "г. Невинномысск, ул. Павлова, д. 1Г",
    "Lat": 44.64026207459793,
    "Lng": 41.930931999999956,
    "TimeTable": "Скоро открытие",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-nevinnomyssk-ul-pavlova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Нижневартовск",
    "Address": "г. Нижневартовск, ул. Нефтяников, д. 15",
    "Lat": 60.93937106279973,
    "Lng": 76.576653,
    "TimeTable": "Скоро открытие",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-nizhnevartovsk-ul-neftyanikov/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Новомосковск",
    "Address": "г. Новомосковск, ул. Свердлова/Садовского, д. 38/40",
    "Lat": 54.015766,
    "Lng": 38.300876,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 07:30-15:00 Вс: 07:30-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-novomoskovsk-ul-sverdlova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Новый Уренгой",
    "Address": "г. Новый Уренгой, Тундровый микрорайон, д. 2",
    "Lat": 66.08106455474243,
    "Lng": 76.63840299999998,
    "TimeTable": "Пн: 08:00-20:00 Вт: 08:00-20:00 Ср: 08:00-20:00 Чт: 08:00-18:00 Пт: 08:00-20:00 Сб: 08:00-20:00 Вс: выходной",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-novyy-urengoy-tundrovyy-mikrorayon/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Ногинск",
    "Address": "г. Ногинск, ул. 3-его Интернационала, д. 39",
    "Lat": 55.85708507049973,
    "Lng": 38.4502863200988,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 08:00-16:00 Вс: 08:00-16:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-noginsk-ul-3-ego-internatsionala/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Ногинск",
    "Address": "г. Ногинск, ул. Белякова, д. 35А",
    "Lat": 55.86113056885984,
    "Lng": 38.440949999999965,
    "TimeTable": "Пн: 06:30-19:00 Вт: 06:30-19:00 Ср: 06:30-19:00 Чт: 06:30-19:00 Пт: 06:30-19:00 Сб: 08:00-16:00 Вс: 08:00-16:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-noginsk-ul-belyakova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Одинцово",
    "Address": "г. Одинцово, Дачный поселок Лесной cityок, ул. Фасадная, д. 2, корп. 2",
    "Lat": 55.634496,
    "Lng": 37.215594,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 07:00-17:00 Вс: 07:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-odintsovo-dachnyy-poselok-lesnoy-gorodok-ul-fasadnaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Одинцово",
    "Address": "г. Одинцово, ул. Гвардейская, д. 11",
    "Lat": 55.65588160862743,
    "Lng": 37.22945981772272,
    "TimeTable": "Пн: 07:00-19:30 Вт: 07:00-19:30 Ср: 07:00-19:30 Чт: 07:00-19:30 Пт: 07:00-19:30 Сб: 07:30-18:00 Вс: 07:30-18:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-odintsovo-zhk-gusarskaya-ballada-ul-gvardeyskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Одинцово",
    "Address": "г. Одинцово, ул. Сколковская, д. 1Б",
    "Lat": 55.69512356902938,
    "Lng": 37.32451500000001,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 07:30-19:30 Вс: 07:30-19:30",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-odintsovo-trekhgorka-ul-skolkovskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Одинцово",
    "Address": "г. Одинцово, Можайское шоссе, д. 165",
    "Lat": 55.688649,
    "Lng": 37.307088,
    "TimeTable": "Пн: 07:30-19:30 Вт: 07:30-19:30 Ср: 07:30-19:30 Чт: 07:30-19:30 Пт: 07:30-19:30 Сб: 07:30-18:00 Вс: 07:30-16:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-odintsovo-mozhayskoe-shosse/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Омск",
    "Address": "г. Омск, ул. Заозерная, д. 21",
    "Lat": 55.039310569658156,
    "Lng": 73.31310049999985,
    "TimeTable": "Пн: 07:30-18:00 Вт: 07:30-18:00 Ср: 07:30-18:00 Чт: 07:30-18:00 Пт: 07:30-18:00 Сб: 08:00-15:00 Вс: 08:00-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-omsk-ul-zaozernaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Орел",
    "Address": "г. Орел, Бульвар Победы, д. 2А",
    "Lat": 52.97245307138066,
    "Lng": 36.06160049999995,
    "TimeTable": "Пн: 08:00-16:00 Вт: 08:00-16:00 Ср: 08:00-16:00 Чт: 08:00-16:00 Пт: 08:00-16:00 Сб: 08:00-14:00 Вс: 08:00-14:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-orel-bulvar-pobedy/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Оренбург",
    "Address": "г. Оренбург, ул. Джангильдина, д. 3",
    "Lat": 51.828336072215095,
    "Lng": 55.155965499999965,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 08:00-17:00 Вс: 08:00-14:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-orenburg-ul-dzhangildina/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Орск",
    "Address": "г. Орск, проспект Ленина, д. 36",
    "Lat": 51.232959,
    "Lng": 58.473952199999985,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-orsk-prospekt-lenina/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Печора",
    "Address": "г. Печора, Печорский проспект, д. 90ж",
    "Lat": 65.1292309,
    "Lng": 57.1634371,
    "TimeTable": "Пн: 08:00-20:00 Вт: 08:00-20:00 Ср: 08:00-20:00 Чт: 08:00-20:00 Пт: 08:00-20:00 Сб: 08:00-19:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-pechora-pechorskiy-prospekt/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Подольск",
    "Address": "г. Подольск, проспект 50 лет Октября, д. 11",
    "Lat": 55.375869069368264,
    "Lng": 37.540802499999934,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-podolsk-prospekt-50-let-oktyabrya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Подольск",
    "Address": "г. Подольск, ул. Рабочая, д. 16/33",
    "Lat": 55.43207606927522,
    "Lng": 37.556378999999986,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-podolsk-ul-rabochaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Подольск",
    "Address": "г. Подольск, ул. Свердлова, д. 21",
    "Lat": 55.423775,
    "Lng": 37.539185,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-podolsk-ul-sverdlova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Подольск",
    "Address": "г. Подольск, ул. Ленинградская, д. 11",
    "Lat": 55.42546078,
    "Lng": 37.49727900,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-podolsk-ul-leningradskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Путилково",
    "Address": "г. Путилково, ул. Новотушинская, д. 2",
    "Lat": 55.86848774298146,
    "Lng": 37.39695571163937,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-putilkovo-ul-novotushinskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Пушкино",
    "Address": "г. Пушкино, Московский проспект, д. 57, корп. 1",
    "Lat": 55.997927619445356,
    "Lng": 37.86076626074789,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-pushkino-moskovskiy-prospekt/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Пушкино",
    "Address": "г. Пушкино, ул. Просвещения, д. 13, корп. 3",
    "Lat": 56.02777606872066,
    "Lng": 37.85527549999989,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 07:30-17:00 Вс: 08:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-pushkino-ul-prosveshcheniya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Раменское",
    "Address": "г. Раменское, ул. Приборостроителей, д. 1а",
    "Lat": 55.57701678,
    "Lng": 38.25144150,
    "TimeTable": "Пн: 07:30-18:00 Вт: 07:30-18:00 Ср: 07:30-18:00 Чт: 07:30-18:00 Пт: 07:30-18:00 Сб: 08:00-17:00 Вс: 08:00-15:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-ramenskoe-ul-priborostroiteley/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Раменское",
    "Address": "г. Раменское, ул.Чугунова, д. 11/1",
    "Lat": 55.575042069167225,
    "Lng": 38.21007399999998,
    "TimeTable": "Пн: 07:00-18:00 Вт: 07:00-18:00 Ср: 07:00-18:00 Чт: 07:00-18:00 Пт: 07:00-18:00 Сб: 08:00-17:00 Вс: 08:00-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-ramenskoe-ul-chugunova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Реутов",
    "Address": "г. Реутов, ул. Новая, д. 3",
    "Lat": 55.757506,
    "Lng": 37.852006,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-18:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-reutov-ul-novaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Реутов",
    "Address": "г. Реутов, Юбилейный проспект, д. 61",
    "Lat": 55.753995469790894,
    "Lng": 37.88273177976229,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-18:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-reutov-yubileynyy-prospekt/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Рыбинск",
    "Address": "г. Рыбинск, ул. Лизы Чайкиной, д. 1",
    "Lat": 58.05700256652829,
    "Lng": 38.81645499999998,
    "TimeTable": "Скоро открытие",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-rybinsk-ul-lizy-chaykinoy/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Рязань",
    "Address": "г. Рязань, ул. Интернациональная, д. 16",
    "Lat": 54.667634570000494,
    "Lng": 39.66150900000002,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 07:00-16:00 Вс: 07:00-16:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-ryazan-ul-internatsionalnaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Рязань",
    "Address": "г. Рязань, ул. Мервинская, д. 30",
    "Lat": 54.63334547850246,
    "Lng": 39.67308381712714,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 08:00-15:00 Вс: 08:00-14:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-ryazan-ul-mervinskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, Проспект Науки, д. 17, корп. 6",
    "Lat": 60.01395338292001,
    "Lng": 30.392194730163542,
    "TimeTable": "Пн: 07:30-19:30 Вт: 07:30-19:30 Ср: 07:30-19:30 Чт: 07:30-19:30 Пт: 07:30-19:30 Сб: 08:30-18:30 Вс: 08:30-18:30",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-sankt-peterburg-prospekt-nauki/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Санкт-Петербург",
    "Address": "г. Санкт-Петербург, ул. Оптиков, д. 45, корп. 1",
    "Lat": 60.00232306408705,
    "Lng": 30.205680499999985,
    "TimeTable": "Скоро открытие",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-sankt-peterburg-ul-optikov/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Саранск",
    "Address": "г. Саранск, ул. Полежаева, д. 58",
    "Lat": 54.18954557041475,
    "Lng": 45.17413749999997,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 08:00-15:00 Вс: выходной",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-saransk-ul-polezhaeva/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Саратов",
    "Address": "г. Саратов, ул. им. А.М. Горького, д. 28",
    "Lat": 51.52992557238284,
    "Lng": 46.02950449999997,
    "TimeTable": "Пн: 07:00-20:00 Вт: 07:00-20:00 Ср: 07:00-20:00 Чт: 07:00-20:00 Пт: 07:00-20:00 Сб: 08:00-19:00 Вс: 08:00-18:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-saratov-ul-im-a-m-gorkogo/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Свердловский",
    "Address": "г. Свердловский, ул. Молодежная, д. 1",
    "Lat": 55.900698068812304,
    "Lng": 38.1524295,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 07:00-17:00 Вс: 07:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-sverdlovskiy-ul-molodezhnaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Севастополь",
    "Address": "г. Севастополь, проспект Октябрьской Революции, д. 67",
    "Lat": 44.58931530,
    "Lng": 33.46309750,
    "TimeTable": "Пн: 07:30-18:00 Вт: 07:30-18:00 Ср: 07:30-18:00 Чт: 07:30-18:00 Пт: 07:30-18:00 Сб: 08:00-15:00 Вс: 08:00-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-sevastopol-prospekt-oktyabrskoy-revolyutsii/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Севастополь",
    "Address": "г. Севастополь, ул. Партизанская, д. 5",
    "Lat": 44.61018030,
    "Lng": 33.51674550,
    "TimeTable": "Пн: 07:30-18:00 Вт: 07:30-18:00 Ср: 07:30-18:00 Чт: 07:30-18:00 Пт: 07:30-18:00 Сб: 08:00-15:00 Вс: 08:00-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-sevastopol-ul-partizanskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Сергиев Посад",
    "Address": "г. Сергиев Посад, ул. Дружбы, д. 9А",
    "Lat": 56.32866548192103,
    "Lng": 38.14480380919265,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-sergiev-posad-ul-druzhby/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Серпухов",
    "Address": "г. Серпухов, ул. Ивана Болотникова, д. 30/19",
    "Lat": 54.915103978287995,
    "Lng": 37.4375544320502,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 07:30-17:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-serpukhov-ul-ivana-bolotnikova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Симферополь",
    "Address": "г. Симферополь, ул. Ленина, д. 4",
    "Lat": 44.95070352262111,
    "Lng": 34.10805206018823,
    "TimeTable": "Пн: 08:00-18:00 Вт: 08:00-18:00 Ср: 08:00-18:00 Чт: 08:00-18:00 Пт: 08:00-18:00 Сб: 08:00-16:00 Вс: 08:00-16:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-simferopol-ul-lenina/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Смоленск",
    "Address": "г. Смоленск, проспект Гагарина, д. 29/1",
    "Lat": 54.761914069918966,
    "Lng": 32.04624800000001,
    "TimeTable": "Пн: 07:30-16:00 Вт: 07:30-16:00 Ср: 07:30-16:00 Чт: 07:30-16:00 Пт: 07:30-16:00 Сб: 08:00-13:00 Вс: выходной",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-smolensk-prospekt-gagarina/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Смоленск",
    "Address": "г. Смоленск, ул. Лавочкина, д. 47/1",
    "Lat": 54.80484056988196,
    "Lng": 32.00537449999994,
    "TimeTable": "График работы офиса временно изменен",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-smolensk-ul-lavochkina/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Смоленск",
    "Address": "г. Смоленск, ул. Юрьева, д. 2",
    "Lat": 54.810922069868106,
    "Lng": 32.044846499999984,
    "TimeTable": "Пн: 07:30-15:00 Вт: 07:30-15:00 Ср: 07:30-15:00 Чт: 07:30-15:00 Пт: 07:30-15:00 Сб: выходной Вс: выходной",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-smolensk-ul-yureva/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Солнечногорск",
    "Address": "г. Солнечногорск, ул. Юности, д. 2",
    "Lat": 56.17978078,
    "Lng": 37.00329550,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-solnechnogorsk-ul-yunosti/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Ставрополь",
    "Address": "г. Ставрополь, ул. Мира, д. 274",
    "Lat": 45.0379168,
    "Lng": 41.9756816,
    "TimeTable": "Пн: 07:30-18:00 Вт: 07:30-18:00 Ср: 07:30-18:00 Чт: 07:30-18:00 Пт: 07:30-18:00 Сб: 08:00-15:00 Вс: 08:00-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-stavropol-ul-mira/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Старая Купавна",
    "Address": "г. Старая Купавна, ул. Кирова, д. 19",
    "Lat": 55.8027400689186,
    "Lng": 38.182199499999975,
    "TimeTable": "Пн: 07:00-19:30 Вт: 07:00-19:30 Ср: 07:00-19:30 Чт: 07:00-19:30 Пт: 07:00-19:30 Сб: 07:30-17:00 Вс: 08:00-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-staraya-kupavna-ul-kirova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Ступино",
    "Address": "г. Ступино, ул. Калинина, д. 17",
    "Lat": 54.890671,
    "Lng": 38.068421,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 07:00-17:00 Вс: 07:00-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-stupino-ul-kalinina/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Тверь",
    "Address": "г. Тверь, Петербургское шоссе, д. 75",
    "Lat": 56.875453,
    "Lng": 35.838162,
    "TimeTable": "Пн: 07:30-18:00 Вт: 07:30-18:00 Ср: 07:30-18:00 Чт: 07:30-18:00 Пт: 07:30-18:00 Сб: 07:30-14:00 Вс: 07:30-14:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-tver-peterburgskoe-shosse/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Тверь",
    "Address": "г. Тверь, ул. Рыбацкая, д. 3",
    "Lat": 56.85977806785535,
    "Lng": 35.91955899999999,
    "TimeTable": "Пн: 07:30-16:00 Вт: 07:30-16:00 Ср: 07:30-16:00 Чт: 07:30-16:00 Пт: 07:30-16:00 Сб: 08:00-14:00 Вс: выходной",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-tver-ul-rybatskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Тверь",
    "Address": "г. Тверь, ул. Софьи Перовской, д. 14",
    "Lat": 56.85914327,
    "Lng": 35.88599750,
    "TimeTable": "Пн: 07:30-18:00 Вт: 07:30-18:00 Ср: 07:30-18:00 Чт: 07:30-18:00 Пт: 07:30-18:00 Сб: 07:30-14:00 Вс: 07:30-14:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-tver-ul-sofi-perovskoy/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Терек",
    "Address": "г. Терек, ул. Ленина, д. 51",
    "Lat": 43.485599,
    "Lng": 44.146339,
    "TimeTable": "Пн: 07:30-17:00 Вт: 07:30-17:00 Ср: 07:30-17:00 Чт: 07:30-17:00 Пт: 07:30-17:00 Сб: 07:30-17:00 Вс: выходной",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-terek-ul-lenina/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Тула",
    "Address": "г. Тула, ул. Кауля, д. 11, корп. 1",
    "Lat": 54.17768457044276,
    "Lng": 37.63206199999998,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 08:00-15:00 Вс: 08:00-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-tula-ul-kaulya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Тула",
    "Address": "г. Тула, ул. Лейтейзена, д. 6",
    "Lat": 54.19556128,
    "Lng": 37.60067500,
    "TimeTable": "Пн: 07:30-19:30 Вт: 07:30-19:30 Ср: 07:30-19:30 Чт: 07:30-19:30 Пт: 07:30-19:30 Сб: 08:00-15:00 Вс: 08:00-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-tula-ul-leyteyzena/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Тула",
    "Address": "г. Тула, ул. М. Горького, д. 1",
    "Lat": 54.213014,
    "Lng": 37.624157,
    "TimeTable": "Пн: 07:30-19:30 Вт: 07:30-19:30 Ср: 07:30-19:30 Чт: 07:30-19:30 Пт: 07:30-19:30 Сб: 08:00-15:00 Вс: 08:00-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-tula-ul-m-gorkogo/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Углич",
    "Address": "г. Углич, ул. Ярославская, д. 32Б",
    "Lat": 57.53059256712623,
    "Lng": 38.32878649999996,
    "TimeTable": "Пн: 07:30-18:00 Вт: 07:30-18:00 Ср: 07:30-18:00 Чт: 07:30-18:00 Пт: 07:30-18:00 Сб: 07:30-16:00 Вс: 07:30-16:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-uglich-ul-yaroslavskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Удельная",
    "Address": "г. Удельная, ул. Громова, д. 10",
    "Lat": 55.628713069126654,
    "Lng": 38.03836149999997,
    "TimeTable": "Пн: 07:00-19:30 Вт: 07:00-19:30 Ср: 07:00-19:30 Чт: 07:00-19:30 Пт: 07:00-19:30 Сб: 07:00-18:30 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-udelnaya-ul-gromova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Улан-Удэ",
    "Address": "г. Улан-Удэ, ул. Ключевская, д. 76А",
    "Lat": 51.81983,
    "Lng": 107.658434,
    "TimeTable": "Пн: 08:00-20:00 Вт: 08:00-20:00 Ср: 08:00-20:00 Чт: 08:00-20:00 Пт: 08:00-20:00 Сб: 08:00-19:00 Вс: 09:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-ulan-ude-ul-klyuchevskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Фрязино",
    "Address": "г. Фрязино, ул. Нахимова, д. 14а",
    "Lat": 55.957695,
    "Lng": 38.031531,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 07:30-19:00 Вс: 07:30-18:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-fryazino-ul-nakhimova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Хасавюрт",
    "Address": "г. Хасавюрт, ул. Новая 1-й проезд, д. 1Б",
    "Lat": 43.2241128842639,
    "Lng": 46.60087693615793,
    "TimeTable": "Пн: 08:00-18:00 Вт: 08:00-18:00 Ср: 08:00-18:00 Чт: 08:00-18:00 Пт: 08:00-18:00 Сб: 08:00-18:00 Вс: 08:00-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-khasavyurt-ul-novaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Химки",
    "Address": "г. Химки, Ленинский проспект, д. 1, корп. 1",
    "Lat": 55.89541856882856,
    "Lng": 37.45230949999989,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/meditsinskaya-klinika-cmd-khimki-leninskiy-prospekt/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Химки",
    "Address": "г. Химки, Юбилейный проспект, д. 50",
    "Lat": 55.887845,
    "Lng": 37.416633,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 08:00-19:00 Вс: 08:00-17:00",
    "Comment": "Медицинская клиника",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-khimki-yubileynyy-prospekt/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Химки",
    "Address": "г. Химки, проспект Мельникова, д. 37",
    "Lat": 55.90838777808514,
    "Lng": 37.394978110450694,
    "TimeTable": "Пн: 07:00-18:00 Вт: 07:00-18:00 Ср: 07:00-18:00 Чт: 07:00-18:00 Пт: 07:00-18:00 Сб: 08:00-17:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-khimki-prospekt-melnikova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Черноголовка",
    "Address": "г. Черноголовка, ул. Лесная, д. 9",
    "Lat": 56.01351756871389,
    "Lng": 38.37668449999993,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 07:30-16:00 Вс: 07:30-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-chernogolovka-ul-lesnaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Черноморское",
    "Address": "г. Черноморское, ул. Кирова, д. 50Б",
    "Lat": 45.50732757455112,
    "Lng": 32.704677,
    "TimeTable": "Пн: 07:30-18:00 Вт: 07:30-18:00 Ср: 07:30-18:00 Чт: 07:30-18:00 Пт: 07:30-18:00 Сб: 07:30-18:00 Вс: 07:30-15:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-chernomorskoe-ul-kirova/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Чехов",
    "Address": "г. Чехов, ул. Московская, д. 84, корп. 1",
    "Lat": 55.15037056958876,
    "Lng": 37.45992699999995,
    "TimeTable": "Пн: 07:30-19:00 Вт: 07:30-19:00 Ср: 07:30-19:00 Чт: 07:30-19:00 Пт: 07:30-19:00 Сб: 07:30-19:00 Вс: 07:30-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-chekhov-ul-moskovskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Щелково",
    "Address": "г. Щелково, 1-ый Советский переулок, д. 19",
    "Lat": 55.91955856883087,
    "Lng": 38.00420749999996,
    "TimeTable": "Пн: 07:00-20:00 Вт: 07:00-20:00 Ср: 07:00-20:00 Чт: 07:00-20:00 Пт: 07:00-20:00 Сб: 07:30-19:00 Вс: 07:30-19:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-shchelkovo-1-yy-sovetskiy-pereulok/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Щелково",
    "Address": "г. Щелково, ул. Талсинская, д. 23",
    "Lat": 55.927346,
    "Lng": 37.986007,
    "TimeTable": "Пн: 07:00-20:00 Вт: 07:00-20:00 Ср: 07:00-20:00 Чт: 07:00-20:00 Пт: 07:00-20:00 Сб: 07:30-19:00 Вс: 07:30-19:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-shchelkovo-ul-talsinskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Электросталь",
    "Address": "г. Электросталь, ул. Советская, д. 10/2",
    "Lat": 55.79042639999999,
    "Lng": 38.436420999999996,
    "TimeTable": "Пн: 06:30-19:00 Вт: 06:30-19:00 Ср: 06:30-19:00 Чт: 06:30-19:00 Пт: 06:30-19:00 Сб: 08:00-16:00 Вс: 08:00-16:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-elektrostal-ul-sovetskaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Электросталь",
    "Address": "г. Электросталь, ул. Ялагина, д. 7",
    "Lat": 55.767914,
    "Lng": 38.42885,
    "TimeTable": "Пн: 07:00-19:00 Вт: 07:00-19:00 Ср: 07:00-19:00 Чт: 07:00-19:00 Пт: 07:00-19:00 Сб: 08:00-16:00 Вс: 08:00-16:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-elektrostal-ul-yalagina/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Электроугли",
    "Address": "г. Электроугли, ул. Школьная, д. 49",
    "Lat": 55.71837478,
    "Lng": 38.22700750,
    "TimeTable": "Пн: 07:30-20:00 Вт: 07:30-20:00 Ср: 07:30-20:00 Чт: 07:30-20:00 Пт: 07:30-20:00 Сб: 08:00-19:00 Вс: 08:00-17:00",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-elektrougli-ul-shkolnaya/"
  },
  {
    "Name": "Центр молекулярной диагностики (CMD)",
    "City": "Якутск",
    "Address": "г. Якутск, ул. Ломоносова, д. 45",
    "Lat": 62.028217,
    "Lng": 129.71811,
    "TimeTable": "Пн: 08:00-16:00 Вт: 08:00-16:00 Ср: 08:00-16:00 Чт: 08:00-16:00 Пт: 08:00-16:00 Сб: 10:00-15:00 Вс: выходной",
    "Comment": "",
    "Link": "https://www.cmd-online.ru/patsientam/gde-sdat-analizy/cmd-yakutsk-ul-lomonosova/"
  },

]

export default data;