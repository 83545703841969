export const KEY_DEVELOPER = "pz2677v5d6ok";
// 6yxhxzsjigmvfdfoge75d0mfst96hjwd 
// 8ee385f3f0ae364f

// pz2677v5d6ok
export const KEY_GUEST = "e9b4e6a25210c166";

export const SERVICE = "242";
// 139
// 49
export const KEY_CONFIRM_EMAIL = "877dcdb3e278664a"; 

export const KEY_FOR_FORGET_PASSWORD = "609c8f1850839254"; // 90e38bbadd2d362d

export const KEY_EDIT_PASSWORD = "dbb1f282124bfd4a";

// export const PARENT_ID_FOR_REGISTRATION = "7717";  
export const PARENT_ID_FOR_REGISTRATION = "11334";  

export const KEY_FOR_RESET_PASSWORD = "5e59a3a4a02e04e1"; // 6e875888b31bfed2

export const INFO_BY_EMAIL = "340f725c9ccb9779"