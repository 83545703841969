import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  parentGrid: { display: 'flex', flexDirection: 'row', justifyContent: 'center' }
}));

export default function HistoryPay() {
  const classes = useStyles();
  const text = 'У вас нет покупок';
  return (
    <Grid className={classes.parentGrid}>
      <Grid>{text}</Grid>
    </Grid>
  );
}
