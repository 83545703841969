/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import { useFormik } from 'formik';
import { Icon } from '@iconify/react';
import { Container, Paper, Typography, createTheme, CardActions, CardContent,  responsiveFontSizes, ThemeProvider, Box, Stack, Grid, styled, Divider, Button, List, ListItem, ListItemText, ListItemSecondaryAction,  ListItemIcon, Checkbox, IconButton, Card, CardHeader } from '@material-ui/core';
import { ProductList, ProductCartWidget, ProductFilterSidebar, ProductSort } from 'src/components/_dashboard/products';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { AddShoppingCart, RemoveShoppingCart, HelpOutline } from '@material-ui/icons';
import Page from 'src/components/Page';
// import { listInvitro } from '../constants/constant';
import InfoIcon from '@material-ui/icons/Info';
import { formatCurrency, formatPrice, isMobile } from 'src/_validations';
import ModalWindows from "./ForService/ModalWindows";
import ModalDescription from "./ForService/ModalDescription";
import SideBar from "./ForService/SideBar";
import { setListServiceStore, setListServicesInBasket } from "../redux/GeneBook/action";

import ModalChoicePassport from "./ForService/ModalChoicePassport";
// import { typography } from '@material-ui/system';
// eslint-disable-next-line import/named
// import PRODUCTS from "../_mocks_/products";

let theme = createTheme();
theme = responsiveFontSizes(theme);

const Item = styled(Paper)(({ theme, position }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: position,
    color: theme.palette.text.secondary,
    fontWeight: position === "center" && "bold",
    // fontSize: isMobile ? "calc(0.5em + 1vw)" : "calc(0.1em + 1vw)",
    fontSize: isMobile && "calc(0.6em + 1vw)" ,
    backgroundColor: position === "center" && "#ebf8f2", 
    width: position === "center" && "7em",
    // borderRadius: "20px"
  }));

  export default function Service() {
    // setListChoiceServices
    const dispatch = useDispatch()
    const { listService, servicesInBasket } = useSelector(state => state.reducerGeneBook)
    const [openModal, setOpenModal] = useState(false)
    const [openModalChoicePassport, setOpenModalChoicePassport] = useState(false)
    const [openModalDescription, setOpenModalDescription] = useState(false)
    const [reFresh, setSeFresh] = useState(false)
    const [nowDescription, setNowDescription] = useState(null)

    const [ nowCard, setNowCard] = useState("")


    const openModalForAddPassport = ( title ) => {
        setNowCard(title)
        setOpenModalChoicePassport(true)
    }

    // const transformationList = ( idx, index, item ) => {

    //   //  подсчет выбранных услуг

    //     let temp = servicesInBasket
    
    //     if ( servicesInBasket.find( e => e.Id === item.Id ) ) {
    //         temp = servicesInBasket.filter( elem => elem.Id !== item.Id )
    //     } else {
    //         temp.push(item)
    //     }
    //     dispatch(setListServicesInBasket(temp))

    //   // логика для отображения выбранных и не выбранных элементов

    //     const choiceObject = item
    //     choiceObject.choice = !choiceObject.choice
    //     const mutationObjectFromList = { ...listService[idx] }
    //     mutationObjectFromList.Items.splice(index, 1, choiceObject)
    //     const newListService =  [ mutationObjectFromList, ...listService.filter( (el, num) => num !== idx ) ].sort(( a, b ) => a.Id - b.Id );
    //     dispatch(setListServiceStore( newListService ))

    //   // }
    //   // dispatch(setListInvitro([ mutationObjectFromList, ...listInvitro.filter( (el, num) => num !== idx ) ].sort( ( a, b ) => a.index - b.index ? 1 : -1 )))
    // }

    const showListService = () => listService
    // console.log(showListService(), "items.Items");
    return ( <Page title="Dashboard: Products | ImGenius">
        <Container style={{ padding: "0 0 2em 0" }}>
          
            { showListService().map( (items, idx) => 
                <>
                    <Stack key={idx} style={{ marginBottom: "10px", color: "Grey", marginTop: idx !== 0 && "2em" }}  direction="row" mb={5} >
                        <Typography  variant="h3"> {items.Title} </Typography>
                    </Stack>
                        {/* <Box style={{
                            display: "flex", justifyContent: "space-between",
                            flexWrap: "wrap"
                        }} > */}
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        { items.Items.map((item, index) =>
                            <Grid item xs={12} sm={8} md={4} key={index}>
                                <Card variant="outlined" style={{ height: "100%" }} >
                                    <CardHeader
                                        action={
                                            idx === 0 && <IconButton aria-label="settings" component={ NavLink } to="/dashboard/demo" >
                                                <InfoIcon/>
                                            </IconButton>
                                        }
                                        title={item.Title}
                                        // subheader="September 14, 2016"
                                    />
                                    {/* sx={{ maxWidth: "32%", marginTop: "1em" }} */}
                                    <CardContent style={{ paddingBottom: "5em" }}>
                                        {/* <Typography gutterBottom variant="h5" component="div">
                                            {item.Title}
                                        </Typography> */}
                                        <Typography variant="body2" color="text.secondary">
                                            {/* <Typography style={{paddingTop: "5px"}} > */}
                                            {item?.Description ? <div dangerouslySetInnerHTML={{ __html: item?.Description }} /> : "нет описания"}
                                            {/* </Typography>   */}
                                        </Typography>
                                    </CardContent>
                                    <CardActions style={{  padding: "24px",  justifyContent: "space-between", bottom: 0,  position: "absolute",   width: "100%" }}>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {/* {formatPrice(item.Price)} руб */}
                                            {item.Price}
                                            <span style={{ fontFamily: "system-ui" }}> ₽</span>
                                        </Typography>

                                        {/* {idx === 0 && <Button variant="contained" component={ NavLink } to="/dashboard/demo" >
                                            Демо
                                        </Button>} */}
                                        <Button variant={item.choice ? "outlined" : "contained"}  
                                            // onClick={() => transformationList(idx, index, item)} 
                                            onClick={() => openModalForAddPassport( item.Title )} 
                                        >
                                            {item.choice ? "Отменить" : "Добавить"}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )}
                        {items.Items.length < 3 ? <Grid item xs={2} sm={4} md={4} /> : null}
                    </Grid>
                </>
            )}
            {openModalChoicePassport && <ModalChoicePassport value={nowCard} openModal={openModalChoicePassport} setOpenModal={setOpenModalChoicePassport} />}

            
            <ModalWindows type="service" openModal={openModal} setOpenModal={setOpenModal} listInvitro={listService}  serviceInBasket={servicesInBasket} setServiceInBasket={setListServicesInBasket} setListService={setListServiceStore} />
            {servicesInBasket.length ? <ProductCartWidget badgeContent={servicesInBasket.length} openModal={openModal} setOpenModal={setOpenModal} /> : null}
            </Container>
        </Page> 
    )
};


























///         до изменения логики работы корзины          ////////////

// /* eslint-disable import/no-unresolved */
// /* eslint-disable no-unused-vars */
// import React, { useState } from 'react';
// import { useDispatch, useSelector } from "react-redux";
// import { NavLink } from 'react-router-dom';
// import { useFormik } from 'formik';
// import { Icon } from '@iconify/react';
// import { Container, Paper, Typography, createTheme, CardActions, CardContent,  responsiveFontSizes, ThemeProvider, Box, Stack, Grid, styled, Divider, Button, List, ListItem, ListItemText, ListItemSecondaryAction,  ListItemIcon, Checkbox, IconButton, Card, CardHeader } from '@material-ui/core';
// import { ProductList, ProductCartWidget, ProductFilterSidebar, ProductSort } from 'src/components/_dashboard/products';
// import roundFilterList from '@iconify/icons-ic/round-filter-list';
// // import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
// import { AddShoppingCart, RemoveShoppingCart, HelpOutline } from '@material-ui/icons';
// import Page from 'src/components/Page';
// // import { listInvitro } from '../constants/constant';
// import InfoIcon from '@material-ui/icons/Info';
// import { formatCurrency, formatPrice, isMobile } from 'src/_validations';
// import ModalWindows from "./ForService/ModalWindows";
// import ModalDescription from "./ForService/ModalDescription";
// import SideBar from "./ForService/SideBar";
// import { setListServiceStore, setListServicesInBasket } from "../redux/GeneBook/action";
// // import { typography } from '@material-ui/system';
// // eslint-disable-next-line import/named
// // import PRODUCTS from "../_mocks_/products";

// let theme = createTheme();
// theme = responsiveFontSizes(theme);

// const Item = styled(Paper)(({ theme, position }) => ({
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: position,
//     color: theme.palette.text.secondary,
//     fontWeight: position === "center" && "bold",
//     // fontSize: isMobile ? "calc(0.5em + 1vw)" : "calc(0.1em + 1vw)",
//     fontSize: isMobile && "calc(0.6em + 1vw)" ,
//     backgroundColor: position === "center" && "#ebf8f2", 
//     width: position === "center" && "7em",
//     // borderRadius: "20px"
//   }));

//   export default function Service() {
//     // setListChoiceServices
//     const dispatch = useDispatch()
//     const { listService, servicesInBasket } = useSelector(state => state.reducerGeneBook)
//     const [openModal, setOpenModal] = useState(false)
//     const [openModalDescription, setOpenModalDescription] = useState(false)
//     const [reFresh, setSeFresh] = useState(false)
//     const [nowDescription, setNowDescription] = useState(null)

//     const transformationList = ( idx, index, item ) => {

//     //  подсчет выбранных услуг

//         let temp = servicesInBasket
    
//         if ( servicesInBasket.find( e => e.Id === item.Id ) ) {
//             temp = servicesInBasket.filter( elem => elem.Id !== item.Id )
//         } else {
//             temp.push(item)
//         }
//         dispatch(setListServicesInBasket(temp))

//     // логика для отображения выбранных и не выбранных элементов

//         const choiceObject = item
//         choiceObject.choice = !choiceObject.choice
//         const mutationObjectFromList = { ...listService[idx] }
//         mutationObjectFromList.Items.splice(index, 1, choiceObject)
//         const newListService =  [ mutationObjectFromList, ...listService.filter( (el, num) => num !== idx ) ].sort(( a, b ) => a.Id - b.Id );
//         dispatch(setListServiceStore( newListService ))
//     // }
//         // dispatch(setListInvitro([ mutationObjectFromList, ...listInvitro.filter( (el, num) => num !== idx ) ].sort( ( a, b ) => a.index - b.index ? 1 : -1 )))
//     }

//     const showListService = () => listService
//     // console.log(showListService(), "items.Items");
//     return ( <Page title="Dashboard: Products | ImGenius">
//         <Container style={{ padding: "0 0 2em 0" }}>
          
//             { showListService().map( (items, idx) => 
//                 <>
//                     <Stack key={idx} style={{ marginBottom: "10px", color: "Grey", marginTop: idx !== 0 && "2em" }}  direction="row" mb={5} >
//                         <Typography  variant="h3"> {items.Title} </Typography>
//                     </Stack>
//                     {/* <Box style={{
//                         display: "flex", justifyContent: "space-between",
//                         flexWrap: "wrap"
//                     }} > */}
//                     <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
//                         { items.Items.map((item, index) =>
//                             <Grid item xs={12} sm={8} md={4} key={index}>
//                                 <Card variant="outlined" style={{ height: "100%" }} >
//                                     <CardHeader
//                                         action={
//                                             idx === 0 && <IconButton aria-label="settings" component={ NavLink } to="/dashboard/demo" >
//                                                 <InfoIcon/>
//                                             </IconButton>
//                                         }
//                                         title={item.Title}
//                                         // subheader="September 14, 2016"
//                                     />
//                                     {/* sx={{ maxWidth: "32%", marginTop: "1em" }} */}
//                                     <CardContent style={{ paddingBottom: "5em" }}>
//                                         {/* <Typography gutterBottom variant="h5" component="div">
//                                             {item.Title}
//                                         </Typography> */}
//                                         <Typography variant="body2" color="text.secondary">
//                                             {/* <Typography style={{paddingTop: "5px"}} > */}
//                                             {item?.Description ? <div dangerouslySetInnerHTML={{ __html: item?.Description }} /> : "нет описания"}
//                                             {/* </Typography>   */}
//                                         </Typography>
//                                     </CardContent>
//                                     <CardActions style={{  padding: "24px",  justifyContent: "space-between", bottom: 0,  position: "absolute",   width: "100%" }}>
//                                         <Typography gutterBottom variant="h5" component="div">
//                                             {/* {formatPrice(item.Price)} руб */}
//                                             {item.Price}
//                                             <span style={{ fontFamily: "system-ui" }}> ₽</span>
//                                         </Typography>

//                                         {/* {idx === 0 && <Button variant="contained" component={ NavLink } to="/dashboard/demo" >
//                                             Демо
//                                         </Button>} */}
//                                         <Button variant={item.choice ? "outlined" : "contained"}  onClick={() => transformationList(idx, index, item)}  >
//                                             {item.choice ? "Отменить" : "Добавить"}
//                                         </Button>
//                                     </CardActions>
//                                 </Card>
//                             </Grid>
//                         )}
//                         {items.Items.length < 3 ? <Grid item xs={2} sm={4} md={4} /> : null}
//                     </Grid>
//                 </>
//             )}
//             <ModalWindows type="service" openModal={openModal} setOpenModal={setOpenModal} listInvitro={listService}  serviceInBasket={servicesInBasket} setServiceInBasket={setListServicesInBasket} setListService={setListServiceStore} />
//             {servicesInBasket.length ? <ProductCartWidget badgeContent={servicesInBasket.length} openModal={openModal} setOpenModal={setOpenModal} /> : null}
//             </Container>
//         </Page> 
//     )
// };